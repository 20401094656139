import axios from 'axios';

export const storeTypeAPI = async (reqData) => {
    return await axios.post(`${process.env.REACT_APP_STONE_SHARE_URL}/store-create`, reqData);
};

export const storeEmailAPI = async (reqData, config) => {
    return await axios.post(`${process.env.REACT_APP_STONE_SHARE_URL}/email/`, reqData, config);
};

export const storeConnectAPI = async (reqData, config) => {
    return await axios.post(`${process.env.REACT_APP_STONE_SHARE_URL}/connect-store`, reqData, config);
};

export const destinationStoreApi = async (queryString, config) => {
    return await axios.get(`${process.env.REACT_APP_STONE_SHARE_URL}/destination/store/list/?` + queryString, config);
};

export const sourceStoreApi = async (queryString, config) => {
    return await axios.get(`${process.env.REACT_APP_STONE_SHARE_URL}/Store_listing/?` + queryString, config);
};
export const storeDisconnectApi = async (reqData, config) => {
    return await axios.post(`${process.env.REACT_APP_STONE_SHARE_URL}/disconnect_store/`, reqData, config);
};
export const storeStatusApi = async (storeKey) => {
    return await axios.get(`${process.env.REACT_APP_STONE_SHARE_URL}/store_status/${storeKey}`);
};
export const storeListingDdApi = async (config) => {
    return await axios.get(`${process.env.REACT_APP_STONE_SHARE_URL}/all-source-store`, config);
};
export const getDashboardRequestApi = async (reqBody, config) => {
    return await axios.post(`${process.env.REACT_APP_STONE_SHARE_URL}/dashboard-api`, reqBody, config);
};
export const getStoreRequestApi = async (reqBody, config) => {
    return await axios.post(`${process.env.REACT_APP_STONE_SHARE_URL}/source-dest-connect-listing`, reqBody, config);
};
export const storeRequestApi = async (reqBody, config) => {
    return await axios.post(`${process.env.REACT_APP_STONE_SHARE_URL}/request-access`, reqBody, config);
};
export const storeVerifiedRequestApi = async (reqBody, config) => {
    return await axios.post(`${process.env.REACT_APP_STONE_SHARE_URL}/store-verified`, reqBody, config);
};
