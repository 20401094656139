import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch, useSelector } from 'react-redux';
import { ftpClientDropdownListRequest, ftpFeedAllRecordRequest, ftpFeedAllResetData, ftpFeedListRequest, sendClientFtpFeedRequest } from '../../../redux/slices/kushDiamond/ftpFeedSlice';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { MultiSelect } from 'primereact/multiselect';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';

const FtpFeedList = () => {
    const dispatch = useDispatch();
    const { isLoading, ftpFeedData, ftpClientDropdownData, ftpFeedAllData } = useSelector((state) => state.ftpFeed);

    const ftpClientFeedItems = ftpClientDropdownData?.ftp_data?.map((item) => item);
    const colorItems = ftpClientDropdownData?.product_color?.map((item) => item);
    const cutItems = ftpClientDropdownData?.product_cut?.map((item) => item);
    const shapeItems = ftpClientDropdownData?.product_shape?.map((item) => item);

    const [selectedProducts, setSelectedProducts] = useState(null);
    const [showSelectedProducts, setShowSelectedProducts] = useState(null);
    const [ftpFeedFilterValue, setFtpFeedFilterValue] = useState({
        ftpClientFeed: [],
        color: '',
        shape: '',
        cut: ''
    });
    const [ftpFeedFilterLabel, setFtpFeedFilterLabel] = useState({
        color: '',
        shape: '',
        cut: ''
    });
    const [datatableShow, setDatatableShow] = useState({
        addSelectedTable: false
    });
    const [showTableArr, setShowTableArr] = useState({
        selectTableArr: []
    });
    const [dialogModal, setDialogModal] = useState({
        openSendFeedmodal: false,
        openRemovemodal: false
    });
    const [tableHeight, setTableHeight] = useState(false);
    const [filterTableHeight, setFilterTableHeight] = useState(false);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(25);
    const [sort_order, setSort_order] = useState('desc');
    const toast = useRef(null);

    const table_data = {
        page: 1,
        per_page: 25,
        sort_order: sort_order,
        order_column: 'created_at'
    };

    const [queryString, setQueryString] = useState(`page=${table_data.page}&per_page=${table_data.per_page}&order_column=${table_data.order_column}`);

    useEffect(() => {
        dispatch(ftpClientDropdownListRequest());
        dispatch(ftpFeedListRequest(queryString));
        if (ftpFeedAllData?.count > 0) {
            setShowTableArr({ ...showTableArr, selectTableArr: ftpFeedAllData?.results });
        }
    }, [dispatch, queryString, ftpFeedAllData]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        tableChangeHandler({ ...table_data, page: event.page + 1, per_page: event.rows, color__in: ftpFeedFilterLabel?.color, shape__in: ftpFeedFilterLabel?.shape, cut__in: ftpFeedFilterLabel?.cut });
    };

    const tableChangeHandler = (data) => {
        let queryStr = Object.keys(data)
            .map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
            })
            .join('&');
        setQueryString(queryStr);
    };

    const handleFtpClientChange = (e) => {
        setFtpFeedFilterValue({ ...ftpFeedFilterValue, ftpClientFeed: e.value });
    };

    const handleColorChange = (e) => {
        setFtpFeedFilterValue({ ...ftpFeedFilterValue, color: e.value });
        let getColorLabel = [];
        ftpClientDropdownData?.product_color?.map((ele) => {
            e.value?.map((value) => {
                if (ele.value == value) {
                    return getColorLabel.push(ele.label);
                }
            });
        });
        setFtpFeedFilterLabel({ ...ftpFeedFilterLabel, color: getColorLabel.join(',') });
        tableChangeHandler({
            ...table_data,
            color__in: getColorLabel.join(','),
            shape__in: ftpFeedFilterLabel?.shape,
            cut__in: ftpFeedFilterLabel?.cut
        });
    };
    const handleShapeChange = (e) => {
        setFtpFeedFilterValue({ ...ftpFeedFilterValue, shape: e.value });
        let getShapeLabel = [];
        ftpClientDropdownData?.product_shape?.map((ele) => {
            e.value?.map((value) => {
                if (ele.value == value) {
                    return getShapeLabel.push(ele.label);
                }
            });
        });
        setFtpFeedFilterLabel({ ...ftpFeedFilterLabel, shape: getShapeLabel.join(',') });
        tableChangeHandler({
            ...table_data,
            color__in: ftpFeedFilterLabel?.color,
            shape__in: getShapeLabel.join(','),
            cut__in: ftpFeedFilterLabel?.cut
        });
    };
    const handleCutChange = (e) => {
        setFtpFeedFilterValue({ ...ftpFeedFilterValue, cut: e.value });
        let getCutLabel = [];
        ftpClientDropdownData?.product_cut?.map((ele) => {
            e.value?.map((value) => {
                if (ele.value == value) {
                    return getCutLabel.push(ele.label);
                }
            });
        });
        setFtpFeedFilterLabel({ ...ftpFeedFilterLabel, cut: getCutLabel.join(',') });
        tableChangeHandler({
            ...table_data,
            color__in: ftpFeedFilterLabel?.color,
            shape__in: ftpFeedFilterLabel?.shape,
            cut__in: getCutLabel.join(',')
        });
    };

    const avalibityStatusBodyTemplate = (rowData) => {
        return (
            <>
                {rowData?.avalibity_status == 'True' ? (
                    <Tag icon="pi pi-check-circle" className="status-color-success" severity="success" value="YES"></Tag>
                ) : rowData?.avalibity_status == 'Memo' ? (
                    <Tag icon="pi pi-book" className="status-color-warning" severity="warning" value="Memo"></Tag>
                ) : (
                    <Tag icon="pi pi-ban" className="status-color-danger" severity="danger" value="NO"></Tag>
                )}
            </>
        );
    };

    const shopifyStatusBodyTemplate = (rowData) => {
        return <span className={`product-badge ${rowData?.status == 'ACTIVE' ? 'status-instock p-1 text-xs' : 'status-outofstock p-1 text-xs'}`}>{rowData?.status}</span>;
    };

    const colorBodyTemplate = (rowData) => {
        return <>{rowData?.color == '' ? '-' : rowData?.color}</>;
    };
    const clarityBodyTemplate = (rowData) => {
        return <>{rowData?.clarity == '' ? '-' : rowData?.clarity}</>;
    };
    const measurementBodyTemplate = (rowData) => {
        return <>{rowData?.measurement == '' ? '-' : rowData?.measurement}</>;
    };

    const priceBodyTemplate = (rowData) => {
        return <>{rowData?.price?.toLocaleString('en-US')}</>;
    };

    const certificateBodyTemplate = (rowData) => {
        return <>{rowData?.certificate_no == '' ? '-' : rowData?.certificate_no}</>;
    };

    const labBodyTemplate = (rowData) => {
        return <>{rowData?.lab == '' ? '-' : rowData?.lab}</>;
    };

    const tblBodyTemplate = (rowData) => {
        return <>{rowData?.tbl == '' ? '-' : rowData?.tbl}</>;
    };
    const cutBodyTemplate = (rowData) => {
        return <>{rowData?.cut == '' ? '-' : rowData?.cut}</>;
    };
    const deptBodyTemplate = (rowData) => {
        return <>{rowData?.dept == '' ? '-' : rowData?.dept}</>;
    };
    const flBodyTemplate = (rowData) => {
        return <>{rowData?.fl == '' ? '-' : rowData?.fl}</>;
    };
    const girdleBodyTemplate = (rowData) => {
        return <>{rowData?.girdle == '' ? '-' : rowData?.girdle}</>;
    };
    const culBodyTemplate = (rowData) => {
        return <>{rowData?.cul == '' ? '-' : rowData?.cul}</>;
    };
    const polBodyTemplate = (rowData) => {
        return <>{rowData?.pol == '' ? '-' : rowData?.pol}</>;
    };
    const rapBodyTemplate = (rowData) => {
        return <>{rowData?.rap == '' ? '-' : rowData?.rap}</>;
    };
    const symBodyTemplate = (rowData) => {
        return <>{rowData?.sym == '' ? '-' : rowData?.sym}</>;
    };

    const template = {
        layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    const addSelectedSubmitDatatable = () => {
        if (selectedProducts?.length >= 1) {
            setDatatableShow({ ...datatableShow, addSelectedTable: true });
            setShowTableArr({ ...showTableArr, selectTableArr: selectedProducts });
        } else {
            setDatatableShow({ ...datatableShow, addSelectedTable: false });
        }
    };

    const addAllSubmitDatatable = () => {
        setDatatableShow({ ...datatableShow, addSelectedTable: true });
        dispatch(ftpFeedAllRecordRequest(`color__in=${ftpFeedFilterLabel?.color}&shape__in=${ftpFeedFilterLabel?.shape}&cut__in=${ftpFeedFilterLabel?.cut}&per_page=${ftpFeedData?.count}&order_column=${table_data.order_column}`));
        setShowTableArr({ ...showTableArr, selectTableArr: ftpFeedAllData?.results });
    };
    const header = (
        <div className="flex align-items-center justify-content-between">
            <div className="my-2">
                <Button label="Add Selected" icon="pi pi-plus" className="mr-2" disabled={!selectedProducts || !selectedProducts.length} onClick={addSelectedSubmitDatatable} />
                <Button label="Add All" icon="pi pi-plus-circle" disabled={selectedProducts?.length > 0} onClick={addAllSubmitDatatable} />
            </div>
            <div className="my-2">{tableHeight === true ? <Button label="100% Height" className="mr-2" onClick={() => setTableHeight(false)} /> : <Button label="50% Height" className="mr-2" onClick={() => setTableHeight(true)} />}</div>
        </div>
    );

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button className="mr-2" label={`Total Products: ${datatableShow?.addSelectedTable == true ? (showTableArr?.selectTableArr?.length !== undefined ? showTableArr?.selectTableArr?.length : 0) : 0}`} />
                    {filterTableHeight === true ? <Button label="100% Height" className="mr-2" onClick={() => setFilterTableHeight(false)} /> : <Button label="50% Height" className="mr-2" onClick={() => setFilterTableHeight(true)} />}
                </div>
            </React.Fragment>
        );
    };

    const resetAll = () => {
        dispatch(ftpFeedAllResetData());
        setShowTableArr({ ...showTableArr, selectTableArr: [] });
        setSelectedProducts(null);
        setDatatableShow({ ...datatableShow, addSelectedTable: false });
        setShowSelectedProducts(null);
    };

    const noRecordMessage = () => {
        toast.current.show({ severity: 'warn', detail: 'Color Or Shape Or Cut value is empty please Select the value that is in your feed data', life: 3000 });
    };

    const infoMessage = () => {
        if (ftpFeedFilterValue?.ftpClientFeed?.length == 0) {
            return toast.current.show({ severity: 'warn', detail: 'Please select client for FTP Feed color,shape or cut', life: 3000 });
        } else {
            return toast.current.show({ severity: 'warn', detail: 'Please click Add all or Add Selected', life: 3000 });
        }
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span className="p-buttonset">
                    {ftpFeedFilterValue?.ftpClientFeed?.length > 0 && showTableArr?.selectTableArr?.length > 0 ? (
                        <Button label="Send Feed" icon="pi pi-send" className="p-button-outlined send-feed-icon font-bold" onClick={() => setDialogModal({ ...dialogModal, openSendFeedmodal: true })} />
                    ) : (
                        <Button label="Send Feed" icon="pi pi-send" className="p-button-outlined send-feed-icon font-bold opacity-60" onClick={ftpFeedData?.results == 0 ? noRecordMessage : infoMessage} />
                    )}

                    <Button
                        label="Remove"
                        icon="pi pi-trash"
                        className="p-button-outlined trash-icon font-bold"
                        onClick={() => setDialogModal({ ...dialogModal, openRemovemodal: true })}
                        disabled={!showSelectedProducts || !showSelectedProducts?.length}
                    />
                    <Button label="Reset" icon="pi pi-times-circle" className="p-button-outlined reset-icon font-bold" disabled={!datatableShow?.addSelectedTable} onClick={resetAll} />
                </span>
            </React.Fragment>
        );
    };

    const removeRowSubmit = () => {
        const deleteRow = showTableArr?.selectTableArr?.filter(({ id: ele }) => !showSelectedProducts?.some(({ id: item }) => item === ele));
        setShowTableArr({ ...showTableArr, selectTableArr: deleteRow });
        setDialogModal({ ...dialogModal, openRemovemodal: false });
        setShowSelectedProducts(null);
    };

    const deleteRemoveRowDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-outlined p-button-danger" onClick={() => setDialogModal({ ...dialogModal, openRemovemodal: false })} />
            <Button label="Yes" icon="pi pi-check" className="p-button-outlined ml-2" onClick={removeRowSubmit} />
        </>
    );

    const sendFeedSubmit = () => {
        const multiSku = showTableArr?.selectTableArr?.map((row) => row.sku);
        const sendFeedObj = {
            ftp: ftpFeedFilterValue?.ftpClientFeed,
            sku_list: multiSku
        };
        dispatch(sendClientFtpFeedRequest(sendFeedObj));
        setDialogModal({ ...dialogModal, openSendFeedmodal: false });
        setShowTableArr({ ...showTableArr, selectTableArr: [] });
        setSelectedProducts(null);
        setDatatableShow({ ...datatableShow, addSelectedTable: false });
        setShowSelectedProducts(null);
        setFtpFeedFilterValue([]);
    };

    const sendFeedDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-outlined p-button-danger" onClick={() => setDialogModal({ ...dialogModal, openSendFeedmodal: false })} />
            <Button label="Send Feed" icon="pi pi-check" className="p-button-outlined ml-2" onClick={sendFeedSubmit} />
        </>
    );

    const breadcrumbItems = [{ label: 'Kush Diamond' }, { label: 'FTP Feed' }];
    const dynamicHeight = Math.min(window.innerHeight * 4 + 1, 70) + 'vh';
    return (
        <>
            <div className="col px-0 pt-0 pb-2">
                <div className="breadcrumb-container flex justify-content-between align-items-center">
                    <div className="text-2xl font-bold">
                        <h3 className="m-0">FTP Feed</h3>
                    </div>
                    <div className="ml-6 text-2xl">
                        <BreadCrumb home={{ icon: 'pi pi-home', url: '/' }} model={breadcrumbItems} />
                    </div>
                </div>
            </div>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <div className="grid">
                            <div className="col-12 md:col-3">
                                <MultiSelect
                                    value={ftpFeedFilterValue?.ftpClientFeed}
                                    onChange={handleFtpClientChange}
                                    options={ftpClientFeedItems}
                                    optionLabel="label"
                                    display="chip"
                                    placeholder="Select Client for FTP Feed"
                                    maxSelectedLabels={5}
                                    className="w-full"
                                />
                            </div>
                            <div className="col-12 md:col-3">
                                <MultiSelect value={ftpFeedFilterValue?.color} onChange={handleColorChange} options={colorItems} optionLabel="label" display="chip" placeholder="Select Color" maxSelectedLabels={5} className="w-full" />
                            </div>
                            <div className="col-12 md:col-3">
                                <MultiSelect value={ftpFeedFilterValue?.shape} onChange={handleShapeChange} options={shapeItems} optionLabel="label" display="chip" placeholder="Select Shape" maxSelectedLabels={5} className="w-full" />
                            </div>

                            <div className="col-12 md:col-3">
                                <MultiSelect value={ftpFeedFilterValue?.cut} onChange={handleCutChange} options={cutItems} optionLabel="label" display="chip" placeholder="Select Cut" maxSelectedLabels={5} className="w-full" />
                            </div>
                        </div>

                        <DataTable
                            responsiveLayout="scroll"
                            scrollable
                            showGridlines
                            stripedRows
                            scrollHeight={tableHeight == true ? '280px' : dynamicHeight}
                            size="small"
                            value={ftpFeedData?.results}
                            selection={selectedProducts}
                            onSelectionChange={(e) => setSelectedProducts(e.value)}
                            dataKey="id"
                            className="datatable-responsive kd-datatable-content"
                            emptyMessage="No records found."
                            loading={isLoading}
                            header={header}
                        >
                            {ftpFeedFilterValue?.color?.length > 0 || ftpFeedFilterValue?.shape?.length > 0 || ftpFeedFilterValue?.cut?.length > 0 ? <Column selectionMode="multiple" style={{ minWidth: '50px' }} frozen></Column> : null}

                            <Column field="sku" header="SKU" style={{ minWidth: '130px' }} className="font-bold" frozen></Column>
                            <Column field="avalibity_status" header="Available ?" style={{ minWidth: '140px' }} body={avalibityStatusBodyTemplate}></Column>
                            <Column field="status" header="Shopify Status" body={shopifyStatusBodyTemplate} style={{ minWidth: '130px' }}></Column>
                            <Column field="shape" header="Shape" style={{ minWidth: '100px' }}></Column>
                            <Column field="carat" header="Carat" style={{ minWidth: '100px', justifyContent: 'end' }}></Column>
                            <Column field="color" header="color" body={colorBodyTemplate} style={{ minWidth: '140px' }}></Column>
                            <Column field="clarity" header="Clarity" body={clarityBodyTemplate} style={{ minWidth: '100px' }}></Column>
                            <Column field="measurement" header="Measurement" body={measurementBodyTemplate} style={{ minWidth: '180px' }}></Column>
                            <Column field="price" header="Price" body={priceBodyTemplate} style={{ minWidth: '120px', justifyContent: 'end' }}></Column>
                            <Column field="certificate_no" header="Certificate No" body={certificateBodyTemplate} style={{ minWidth: '180px' }}></Column>
                            <Column field="lab" header="Lab" body={labBodyTemplate} style={{ minWidth: '80px' }}></Column>
                            <Column field="tbl" header="Tbl" body={tblBodyTemplate} style={{ minWidth: '80px', justifyContent: 'end' }}></Column>
                            <Column field="cut" header="Cut" body={cutBodyTemplate} style={{ minWidth: '200px' }}></Column>
                            <Column field="dept" header="Dept" body={deptBodyTemplate} style={{ minWidth: '90px', justifyContent: 'end' }}></Column>
                            <Column field="fl" header="Fl" body={flBodyTemplate} style={{ minWidth: '90px' }}></Column>
                            <Column field="girdle" header="Girdle" body={girdleBodyTemplate} style={{ minWidth: '140px' }}></Column>
                            <Column field="cul" header="Cul" body={culBodyTemplate} style={{ minWidth: '90px' }}></Column>
                            <Column field="pol" header="Pol" body={polBodyTemplate} style={{ minWidth: '90px' }}></Column>
                            <Column field="rap" header="Rap" body={rapBodyTemplate} style={{ minWidth: '90px' }}></Column>
                            <Column field="sym" header="Sym" body={symBodyTemplate} style={{ minWidth: '90px' }}></Column>
                            <Column field="shopify_product_id" header="Shopify Product Id" style={{ minWidth: '180px' }}></Column>
                        </DataTable>
                        <Paginator template={template} first={first} rows={rows} totalRecords={ftpFeedData?.count} onPageChange={onPageChange} rowsPerPageOptions={[25, 50, 100, 200]}></Paginator>
                    </div>
                </div>
                <Dialog
                    visible={dialogModal?.openRemovemodal}
                    style={{ width: '570px' }}
                    header="Confirm"
                    modal
                    footer={deleteRemoveRowDialogFooter}
                    onHide={() => {
                        setDialogModal({ ...dialogModal, openRemovemodal: false });
                    }}
                >
                    <div className="flex align-items-center">
                        <Button icon="pi pi-exclamation-triangle text-xl" className="p-button-rounded p-button-outlined p-button-secondary mr-3" />
                        <span className="font-bold" style={{ fontSize: '15px' }}>
                            Are you sure? Do you want to remove?
                        </span>
                    </div>
                </Dialog>
                <Dialog
                    visible={dialogModal?.openSendFeedmodal}
                    style={{ width: '570px' }}
                    header="Confirm"
                    modal
                    footer={sendFeedDialogFooter}
                    onHide={() => {
                        setDialogModal({ ...dialogModal, openSendFeedmodal: false });
                    }}
                >
                    <div className="flex align-items-center">
                        <Button icon="pi pi-exclamation-circle text-xl" className="p-button-text p-button-outlined p-button-warning mr-3" />
                        <span className="font-bold" style={{ fontSize: '15px' }}>
                            Are you sure? You want to feed these {showTableArr?.selectTableArr?.length} product in FTP feed.
                        </span>
                    </div>
                </Dialog>
                <div className="col-12">
                    <div className="card">
                        <h5 className="font-bold mt-0">Product Bucket for FTP Feed</h5>
                        <Toast ref={toast} />
                        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                        <DataTable
                            selectionMode="multiple"
                            responsiveLayout="scroll"
                            metaKeySelection={false}
                            scrollable
                            showGridlines
                            scrollHeight={filterTableHeight == true ? '280px' : dynamicHeight}
                            size="small"
                            value={datatableShow?.addSelectedTable && showTableArr?.selectTableArr}
                            selection={showSelectedProducts}
                            onSelectionChange={(e) => setShowSelectedProducts(e.value)}
                            dataKey="id"
                            loading={isLoading}
                            paginator
                            rows={25}
                            rowsPerPageOptions={[25, 50, 100, 200]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="{first}-{last} of {totalRecords}"
                            className="datatable-responsive kd-datatable-content show-ftp-feed-datatable"
                            emptyMessage="No records found."
                        >
                            <Column field="sku" header="SKU" className="font-bold" style={{ minWidth: '130px' }} frozen></Column>
                            <Column field="avalibity_status" header="Available ?" style={{ minWidth: '140px' }} body={avalibityStatusBodyTemplate}></Column>
                            <Column field="status" header="Shopify Status" body={shopifyStatusBodyTemplate} style={{ minWidth: '130px' }}></Column>
                            <Column field="shape" header="Shape" style={{ minWidth: '100px' }}></Column>
                            <Column field="carat" header="Carat" style={{ minWidth: '100px', justifyContent: 'end' }}></Column>
                            <Column field="color" header="color" body={colorBodyTemplate} style={{ minWidth: '140px' }}></Column>
                            <Column field="clarity" header="Clarity" body={clarityBodyTemplate} style={{ minWidth: '100px' }}></Column>
                            <Column field="measurement" header="Measurement" body={measurementBodyTemplate} style={{ minWidth: '180px' }}></Column>
                            <Column field="price" header="Price" body={priceBodyTemplate} style={{ minWidth: '120px', justifyContent: 'end' }}></Column>
                            <Column field="certificate_no" header="Certificate No" body={certificateBodyTemplate} style={{ minWidth: '180px' }}></Column>
                            <Column field="lab" header="Lab" body={labBodyTemplate} style={{ minWidth: '80px' }}></Column>
                            <Column field="tbl" header="Tbl" body={tblBodyTemplate} style={{ minWidth: '80px', justifyContent: 'end' }}></Column>
                            <Column field="cut" header="Cut" body={cutBodyTemplate} style={{ minWidth: '200px' }}></Column>
                            <Column field="dept" header="Dept" body={deptBodyTemplate} style={{ minWidth: '90px', justifyContent: 'end' }}></Column>
                            <Column field="fl" header="Fl" body={flBodyTemplate} style={{ minWidth: '90px' }}></Column>
                            <Column field="girdle" header="Girdle" body={girdleBodyTemplate} style={{ minWidth: '140px' }}></Column>
                            <Column field="cul" header="Cul" body={culBodyTemplate} style={{ minWidth: '90px' }}></Column>
                            <Column field="pol" header="Pol" body={polBodyTemplate} style={{ minWidth: '90px' }}></Column>
                            <Column field="rap" header="Rap" body={rapBodyTemplate} style={{ minWidth: '90px' }}></Column>
                            <Column field="sym" header="Sym" body={symBodyTemplate} style={{ minWidth: '90px' }}></Column>
                            <Column field="shopify_product_id" header="Shopify Product Id" style={{ minWidth: '180px' }}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FtpFeedList;
