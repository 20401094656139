import React from 'react';
import DiamondImage from './assets/logo-dark.svg';

const AppFooter = () => {
    return (
        <div className="layout-footer" style={{ height: '2.5rem' }}>
            <div className="footer-logo-container">
                <img id="footer-logo" src={DiamondImage} alt="diamond-layout" />
                <span className="app-name">DIAMOND</span>
            </div>
            {/* <span className="copyright">&#169; Your Organization - {new Date().getFullYear()}</span> */}
            <span className="copyright">COPYRIGHT &#169; {new Date().getFullYear()} Kush Diamond, All rights Reserved</span>
        </div>
    );
};

export default AppFooter;
