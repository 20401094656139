import axios from 'axios';

export const ftpfeedLogApi = async (queryString, config) => {
    return await axios.get(`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/ftp_log/?` + queryString, config);
};

export const mediaLogApi = async (queryString, config) => {
	return await axios.get(
		`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/upload_log/?` + queryString,
		config
	);
};

export const excelLogApi = async (queryString, config) => {
	return await axios.get(
		`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/product_log/?` + queryString,
		config
	);
};

export const shopifySyncLogApi = async (queryString, config) => {
	return await axios.get(
		`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/shopify_sync_log/?` + queryString,
		config
	);
};

