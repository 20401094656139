import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connectStoreApi, connectStoreData, getDashboardDetailsRequest, sourceStoreListingRequest, storeRequest, storeVerifiedRequest } from '../../redux/slices/storeSlice';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useLocalStorage } from 'primereact/hooks';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
const DestinationDashboard = () => {
    const textRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [tabelStoreName, setTabelStoreName] = useState({
        store_name: '',
        store_id: ''
    });
    const [setstoreIdForConnect, setSetstoreIdForConnect] = useState(null);
    const [storeKey, setStoreKey] = useState('');
    const [isNewConnectStore, setIsNewConnectStore] = useState(false);
    const [copied, setCopied] = useState(false);
    const [connectKeyType, setConnectKeyType] = useState('choose');
    const [showDestinationNew, setshowDestinationNew] = useState(false);
    const [showEmail, setShowEmail] = useLocalStorage(false, 'showEmail');
    const { dashboardData, error, sourceStoreList, verifiedStoreData, storeUrlId, storeData, dashboardStoreData, storeRequestSuccessMessage, storeListingData, storeConnectKey } = useSelector((store) => store.store);
    // console.log(verifiedStoreData, 'verifiedStoreData?.store_key yes');
    const onCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    };
    const handleConnectStore = () => {
        setIsNewConnectStore(true);
        setConnectKeyType('choose');
        setVisible(true);
        dispatch(sourceStoreListingRequest());
    };
    const header = () => {
        return (
            <div className="flex gap-2 justify-content-between align-items-bottom">
                <div className="flex  align-items-center">
                    <p>Source Stores</p>
                </div>
                <div className="flex justify-content-end gap-2">
                    <div>
                        <Button icon="pi pi-list" className="p-button-info p-button-outlined" label="View All" placeholder="Mouse" tooltip="View All" onClick={() => navigate('/store')} />
                    </div>
                    <div>
                        <Button icon="pi pi-plus" className="p-button-info p-button-outlined" placeholder="Mouse" tooltip="Connect New Store" label="Connect New Store" onClick={() => handleConnectStore()} />
                    </div>
                </div>
            </div>
        );
    };
    const storeUrl = (rowData) => {
        return (
            <a href={rowData?.store_url} target="_blank">
                {rowData?.store_url}
            </a>
        );
    };
    const handleClick = (rowData, status) => {
        console.log(rowData, 'rowData');
        const payload = {
            request_key: status,
            source_store_id: storeData?.store_id,
            destination_store_id: rowData?.store_id
        };
        dispatch(storeRequest(payload));
    };
    const handleConnect = (rowData) => {
        setIsNewConnectStore(false);
        setVisible(true);
        setConnectKeyType('choose');
        setTabelStoreName({
            store_name: rowData.store_name,
            store_id: rowData.store_id
        });
    };
    const storeConnectBodyTemplate = (rowData) => {
        return (
            <div>
                {rowData.is_connected === 'approved' ? (
                    <Button label="Approved" icon="pi pi-verified" className="cursor-auto mr-2 p-button p-button-success" />
                ) : rowData.is_connected === 'pending' ? (
                    <Button label="Request Sent" icon="pi pi-stopwatch" className="cursor-auto p-button p-button-success" />
                ) : (
                    <>
                        <Button label="Request Rejected" icon="pi pi-times" className="cursor-auto p-button p-button-danger mr-3" />
                        <Button label="Connect Again" icon="pi pi-spinner" className="p-button p-button-info" onClick={() => handleConnect(rowData)} />
                    </>
                )}
            </div>
        );
    };

    const payload = {
        store_id: storeData?.store_id,
        store_type: storeData?.store_type
    };
    // const payload = {
    //     store_id: '30',
    //     store_type: 'destination'
    // };
    const handleOnClose = () => {
        setVisible(false);
        setConnectKeyType('select');
        setShowEmail(false);
    };
    const getPayload = () => {
        if (verifiedStoreData?.is_verified) {
            const payload = {
                store_id: verifiedStoreData?.store_id,
                store_type: verifiedStoreData?.store_type
            };
            return payload;
        } else {
            const payload = {
                store_id: storeData?.store_id,
                store_type: storeData?.store_type
            };
            return payload;
        }
    };
    const getConnectStorePayload = (store_key) => {
        if (verifiedStoreData.is_verified) {
            const payload = {
                store_key: store_key,
                connect_type: connectKeyType,
                store_id: verifiedStoreData?.store_id,
                store_type: verifiedStoreData?.store_type
            };
            return payload;
        } else {
            const payload = {
                store_key: store_key,
                connect_type: connectKeyType,
                store_id: storeData?.store_id,
                store_type: storeData?.store_type
            };
            return payload;
        }
    };
    const getConnectStoreByStoreListPaylaod = (store_id) => {
        if (isNewConnectStore) {
            if (verifiedStoreData.is_verified) {
                const payload = {
                    store_id: verifiedStoreData?.store_id,
                    source_store_id: store_id,
                    store_type: verifiedStoreData?.store_type,
                    connect_type: connectKeyType
                };
                return payload;
            } else {
                const payload = {
                    store_id: storeData.store_id,
                    source_store_id: store_id,
                    connect_type: connectKeyType
                };
                return payload;
            }
        } else {
            if (verifiedStoreData.is_verified) {
                const payload = {
                    store_id: verifiedStoreData?.store_id,
                    source_store_id: tabelStoreName.store_id,
                    store_type: verifiedStoreData?.store_type,
                    connect_type: connectKeyType
                };
                return payload;
            } else {
                const payload = {
                    store_id: storeData.store_id,
                    source_store_id: tabelStoreName.store_id,
                    connect_type: connectKeyType
                };
                return payload;
            }
        }
    };

    const storeConnectformik = useFormik({
        initialValues: {
            store_key: ''
        },
        validationSchema: yup.object({
            store_key: yup.string().required('Key is required')
        }),
        onSubmit: (values) => {
            const data = getConnectStorePayload(values.store_key);
            dispatch(connectStoreApi(data));
            storeConnectformik.resetForm();
        }
    });
    const sendRequestFormik = useFormik({
        initialValues: {
            store_name: '',
            connect_type: '',
            source_store_id: ''
        },
        onSubmit: (value) => {
            console.log(value, 'log');
            const data = getConnectStoreByStoreListPaylaod(value.store_id);
            dispatch(connectStoreApi(data));
            sendRequestFormik.resetForm();
        }
    });
    const handleSendRequest = () => {
        setConnectKeyType('request_access');
        setSetstoreIdForConnect();
    };
    const reqBody = {
        store_key: storeUrlId
    };
    useEffect(() => {
        dispatch(sourceStoreListingRequest());
        if (storeRequestSuccessMessage != null) {
            dispatch(getDashboardDetailsRequest(getPayload()));
        }
        if (storeConnectKey != null) {
            dispatch(connectStoreData(null));
            dispatch(getDashboardDetailsRequest(getPayload()));
            setVisible(false);
        }
    }, [storeRequestSuccessMessage, storeConnectKey]);
    useEffect(() => {
        dispatch(storeVerifiedRequest(reqBody));
        dispatch(getDashboardDetailsRequest(getPayload()));
        setShowEmail(false);
    }, []);
    return (
        <>
            <div>
                <div className="flex justify-content-center">
                    <div className="col-12 md:col-6 lg:col-4">
                        <div className="surface-0 shadow-2 p-3 pb-2 border-1 border-50 border-round">
                            <div className="flex justify-content-between mb-3">
                                <div className="">
                                    <span className="block text-500 font-medium mb-3 font-bold">Destination Store Key</span>
                                    <div className="flex  text-900 align-items-center  my-auto font-bold font-medium text-xl">
                                        <div>
                                            <span ref={textRef}>{dashboardData?.store_key}</span>
                                        </div>
                                        <div>
                                            <CopyToClipboard onCopy={onCopy} text={dashboardData?.store_key}>
                                                <Button
                                                    size="small"
                                                    icon="pi pi-copy"
                                                    // rounded
                                                    text="true"
                                                    onClick={() => {
                                                        // handleCopyClick(dashboardData?.store_key);
                                                        onCopy();
                                                    }}
                                                    tooltip={copied ? 'Copied' : 'Copy'}
                                                    tooltipOptions={{ position: 'top' }}
                                                    className="p-button-rounded p-button-text ml-1"
                                                />
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                    <i className="pi pi-info-circle text-blue-500 text-xl"></i>
                                </div>
                            </div>
                            {/* <span className="text-500">Share with Your {storeType == 'source' ? 'Destination' : 'Source'} stores</span> */}
                        </div>
                    </div>

                    <div className="col-12 md:col-6 lg:col-4">
                        <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <div className="flex justify-content-between mb-3">
                                <div>
                                    <span className="block text-500 font-medium mb-3 font-bold">
                                        <span className="mx-1">Destination</span>
                                        Store Details
                                    </span>
                                    {/* <div className="text-900 font-bold font-medium text-xl">{dashboardData?.store_name}</div> */}
                                    <a href={dashboardData?.store_url} target="_blank" className="cursor-pointer text-xl">
                                        {dashboardData?.store_name}
                                    </a>
                                </div>
                                <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                    <i className="pi pi-sitemap text-cyan-500 text-xl"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 md:col-6 lg:col-4">
                        <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <div className="flex justify-content-between mb-3">
                                <div>
                                    <span className="block text-500 font-medium mb-3 font-bold">Connected Store Count</span>
                                    <div className="text-900 font-bold font-medium text-xl">{dashboardData?.connect_count}</div>
                                    {/* <div className="text-900 font-bold font-medium text-xl">{getDateTime(dashboardData?.connect_count)}</div> */}
                                </div>
                                <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                    <i className="pi pi-box text-orange-500 text-xl"></i>
                                </div>
                            </div>
                            {/* <span className="text-green-500 font-medium">{dashboardData?.current_month_order_count} </span>
                <span className="text-500">Added in last month</span> */}
                        </div>
                    </div>
                    {/* <div className="col-12 md:col-6 lg:col-3">
                        <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <div className="flex justify-content-between mb-3">
                                <div>
                                    <span className="block text-500 font-medium mb-3 font-bold">Total Product Synced </span>
                                    <div className="text-900 font-bold font-medium text-xl">{dashboardData?.sync_count} synced</div>
                                </div>
                                <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                    <i className="pi pi-sync text-purple-500 text-xl"></i>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className=" grid  mt-5 mx-3 ">
                <div className="col-6 card">
                    <DataTable value={dashboardData?.store_detail} removableSort header={header} size="small" className="p-datatable-products ">
                        <Column field="store_name" header="Store Name " sortable></Column>
                        <Column field="store_url" header="Store Url" body={storeUrl}></Column>
                        <Column field="is_connected" header="Status" body={storeConnectBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>

            <Dialog header="Connect New Store" draggable={false} visible={visible} style={{ width: '52rem' }} onHide={() => handleOnClose()}>
                {connectKeyType == 'choose' ? (
                    <>
                        <p>Share your unique key with source stores to start importing their products to sell on your store. This unique key can also be found on your dashboard after completing this setup process.</p>
                        <div className="grid">
                            <div className="col-6">
                                <Button icon="pi pi-arrow-right" iconPos="right" label="Request access to connect with source store" className="p-button-raised mr-2 mb-2 w-12" onClick={() => setConnectKeyType('request_access')} />
                            </div>
                            <div className="col-6">
                                <Button icon="pi pi-arrow-right" iconPos="right" label="I received a source store key" onClick={() => setConnectKeyType('key')} className="p-button-outlined mr-2 mb-2 w-12" />
                            </div>
                        </div>
                    </>
                ) : null}
                {connectKeyType == 'request_access' ? (
                    <>
                        <div>
                            <h6 className="mx-auto text-xl">{`Send request access to a source store.`}</h6>
                        </div>
                        <form onSubmit={sendRequestFormik.handleSubmit} className="p-fluid ">
                            <div className="flex  flex-column gap-3  align-items-center">
                                <div className="flex flex-column gap-3"></div>
                                <label htmlFor="store_id" className="text-xl">
                                    Select source store
                                </label>
                                <div className="w-6">
                                    {/* <Dropdown inputId="store" name="store" filter className="w-12" value={values} onChange={(e) => setValues(e.target.value)} options={storeListingData} optionLabel="label" placeholder="Select a Store" /> */}
                                    <Dropdown
                                        inputId="store_id"
                                        name="store_id"
                                        filter
                                        disabled={!isNewConnectStore}
                                        className={classNames({ 'p-invalid': !isNewConnectStore ? sendRequestFormik.errors.store_id : '' })}
                                        value={sendRequestFormik.values.store_id || tabelStoreName.store_id}
                                        onChange={(e) => sendRequestFormik.setFieldValue('store_id', e.target.value)}
                                        options={storeListingData}
                                        optionLabel="label"
                                        placeholder="Select a Source Store"
                                    />
                                    {(sendRequestFormik.touched.store_id && sendRequestFormik.errors.store_id) || (error && error?.store_id) ? <small className="p-error text-lg">{sendRequestFormik.errors.store_id || error?.store_id}</small> : null}
                                </div>
                                <div className="">
                                    <Button label="Send Request" className="p-button-raised" type="submit" />
                                </div>
                            </div>
                        </form>
                    </>
                ) : null}
                {connectKeyType == 'select' ? (
                    <>
                        {/* <div className="mx-auto col-12 ">
                            <div>
                                <h5 className="font-bold text-center text-2xl" style={{ color: '#3F51B5' }}>
                                    Connect Source Store
                                </h5>
                                <p className="text-xl">Please enter the unique key of the source store to begin importing their products or send an access request to the source store to establish a connection.</p>
                            </div>
                        </div> */}
                        <div>
                            <h6 className="mx-auto text-xl">{`Send request access to a source store.`}</h6>
                        </div>
                        <form onSubmit={sendRequestFormik.handleSubmit} className="p-fluid ">
                            <div className="flex  flex-column gap-3  align-items-center">
                                <div className="flex flex-column gap-3"></div>
                                <label htmlFor="store_id" className="text-xl">
                                    Your Source Store
                                </label>
                                <div className="w-6">
                                    <Dropdown
                                        inputId="store_id"
                                        name="store_id"
                                        filter
                                        disabled
                                        className={classNames({ 'p-invalid': sendRequestFormik.errors.store_id })}
                                        value={sendRequestFormik.values.store_id || tabelStoreName.store_id}
                                        onChange={(e) => sendRequestFormik.setFieldValue('store_id', e.target.value)}
                                        options={storeListingData}
                                        optionLabel="label"
                                        placeholder="Select a Source Store"
                                    />
                                </div>
                                <div className="">
                                    <Button label="Send Request" className="p-button-raised" type="submit" />
                                </div>
                            </div>
                        </form>
                    </>
                ) : // <>
                //     <div>
                //         <h6 className="mx-auto text-xl">{`Send request access to a source store.`}</h6>
                //     </div>
                //     <form onSubmit={sendRequestFormik.handleSubmit} className="p-fluid ">
                //         <div className="flex  flex-column gap-3  align-items-center">
                //             <div className="flex flex-column gap-3"></div>
                //             <label htmlFor="store_id" className="text-xl">
                //                 Select source store
                //             </label>
                //             <div className="w-6">
                //                 {/* <Dropdown inputId="store" name="store" filter className="w-12" value={values} onChange={(e) => setValues(e.target.value)} options={storeListingData} optionLabel="label" placeholder="Select a Store" /> */}
                //                 <Dropdown
                //                     inputId="store_id"
                //                     name="store_id"
                //                     filter
                //                     className={classNames({ 'p-invalid': sendRequestFormik.errors.store_id })}
                //                     value={sendRequestFormik.values.store_id}
                //                     onChange={(e) => sendRequestFormik.setFieldValue('store_id', e.target.value)}
                //                     options={storeListingData}
                //                     optionLabel="label"
                //                     placeholder="Select a Source Store"
                //                 />
                //                 {(sendRequestFormik.touched.store_id && sendRequestFormik.errors.store_id) || (error && error?.store_id) ? <small className="p-error text-lg">{sendRequestFormik.errors.store_id || error?.store_id}</small> : null}
                //             </div>
                //             <div className="">
                //                 <Button label="Send Request" className="p-button-raised" type="submit" />
                //             </div>
                //         </div>
                //     </form>
                // </>
                null}
                {connectKeyType == 'key' ? (
                    <div className="mx-auto col-12 ">
                        <h6>Connect to a source store using a unique key.</h6>
                        <h6>Once connected, you will be able to start importing products from this source store. </h6>
                        <form onSubmit={storeConnectformik.handleSubmit} className="p-fluid">
                            <div className="field">
                                <label htmlFor="store_key">Source Store Unique Key</label>
                                <span className="p-float-label p-input-icon-right mb-2">
                                    <InputText id="store_key" className="w-9" name="store_key" onChange={storeConnectformik.handleChange} value={storeConnectformik.values.store_key} />
                                    <Button type="submit" label="Connect" icon="pi pi-plus" iconPos="right" className="p-button-raised mr-2 ml-4 w-2" />
                                </span>
                                {storeConnectformik.touched.store_key && storeConnectformik.errors.store_key ? <small className="p-error text-lg">{storeConnectformik.errors.store_key}</small> : null}
                            </div>
                        </form>
                    </div>
                ) : null}
                {showEmail && (
                    <div className="mx-auto col-12">
                        {/* <Card className="mx-auto"> */}
                        <div>
                            <h6 className="mx-auto text-xl">Send request access to a source store.</h6>
                        </div>
                        <form onSubmit={sendRequestFormik.handleSubmit} className="p-fluid ">
                            <div className="flex  flex-column gap-3  align-items-center">
                                <div className="flex flex-column gap-3"></div>
                                <label htmlFor="store_id" className="text-xl">
                                    Select source store
                                </label>
                                <div className="w-6">
                                    <Dropdown
                                        inputId="store_id"
                                        name="store_id"
                                        filter
                                        className={classNames({ 'p-invalid': sendRequestFormik.errors.store_id })}
                                        value={sendRequestFormik.values.store_id}
                                        onChange={(e) => sendRequestFormik.setFieldValue('store_id', e.target.value)}
                                        options={storeListingData}
                                        optionLabel="label"
                                        placeholder="Select a Store"
                                    />
                                    {(sendRequestFormik.touched.store_id && sendRequestFormik.errors.store_id) || (error && error?.store_id) ? <small className="p-error text-lg">{sendRequestFormik.errors.store_id || error?.store_id}</small> : null}
                                </div>
                                <div className="">
                                    <Button label="Send Request" className="p-button-raised" type="submit" />
                                </div>
                            </div>
                        </form>
                        {/* </Card> */}
                    </div>
                )}
            </Dialog>
        </>
    );
};

export default DestinationDashboard;
