import React, { useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Image } from 'primereact/image';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { AutoComplete } from 'primereact/autocomplete';
import { modifyProductMapDestinationRequest, productMapDestinationRequest, productMapSourceReset } from '../../redux/slices/productSlice';

const MapSidebar = ({ shopifyId, mapSidebarModal, setMapSidebarModal, mapSourceData, sourceStoreId, destinationStoreId, storeName, destinationName }) => {
    // console.log(storeName, 'storeName');
    const dispatch = useDispatch();
    const { productMapDestinationData } = useSelector((state) => state.product);
    const { verifiedStoreData } = useSelector((state) => state.store);

    const [openSearchBar, setOpenSearchBar] = useState(false);
    const [value, setValue] = useState('');
    const [suggestionsTitle, setSuggestionsTitle] = useState([]);
    const [destinationShopifyID, setDestinationShopifyID] = useState(null);
    const [keyLoader, setKeyLoader] = useState(false);

    useEffect(() => {
        if (productMapDestinationData?.title_suggestion?.length > 0) {
            return searchSuggestions(productMapDestinationData);
        }
        if (productMapDestinationData?.destination_product_id?.length > 0) {
            return searchSuggestions(productMapDestinationData);
        }
    }, [productMapDestinationData?.title_suggestion, productMapDestinationData?.destination_product_id]);

    const titleTemplate = () => {
        return (
            <>
                <div className="font-bold">Default title</div>
            </>
        );
    };

    const searchSuggestions = (productMapDestinationData) => {
        if (productMapDestinationData?.title_suggestion?.length > 0) {
            const suggestionTitleVal = productMapDestinationData?.title_suggestion?.map((ele) => ele.title);
            return setSuggestionsTitle(suggestionTitleVal);
        }
        if (productMapDestinationData?.destination_product_id?.length > 0) {
            const suggestionTitleVal = productMapDestinationData?.destination_product_id?.map((ele) => ele.title);
            return setSuggestionsTitle(suggestionTitleVal);
        }
    };

    const onhandleSearchKey = (e) => {
        if (e.key == 'Enter') {
            if (value?.length == 13) {
                const NumberString = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
                const getDestinationID = NumberString.test(value) === false;
                console.log(getDestinationID, 'getDestinationID');
                if (getDestinationID == false) {
                    const destTitleObj = {
                        destination_store_id: verifiedStoreData.store_id,
                        destination_shopify_id: parseInt(value),
                        product_title: null,
                        type: 'suggestion'
                    };

                    return dispatch(productMapDestinationRequest(destTitleObj));
                } else {
                    const destTitleObj = {
                        destination_store_id: verifiedStoreData.store_id,
                        destination_shopify_id: null,
                        product_title: value,
                        type: 'suggestion'
                    };

                    return dispatch(productMapDestinationRequest(destTitleObj));
                }
            } else {
                const destTitleObj = {
                    destination_store_id: verifiedStoreData.store_id,
                    destination_shopify_id: null,
                    product_title: value,
                    type: 'suggestion'
                };

                return dispatch(productMapDestinationRequest(destTitleObj));
            }
        }
    };

    const handleChange = (e) => {
        setKeyLoader(true);
        if (e.originalEvent.type == 'change') {
            setValue(e.value);
        } else {
            setValue(e.value);
            if (productMapDestinationData?.title_suggestion?.length > 0) {
                let getIdArr = [];
                productMapDestinationData?.title_suggestion?.map((ele) => {
                    if (ele.title == e.value.toString()) {
                        return getIdArr.push(ele.id);
                    }
                });
                setDestinationShopifyID(getIdArr.toString());
                const destTitleObj = {
                    destination_store_id: verifiedStoreData.store_id,
                    destination_shopify_id: getIdArr.toString(),
                    product_title: e.value,
                    type: 'detail'
                };

                return dispatch(productMapDestinationRequest(destTitleObj));
            } else {
                let getIdArr = [];
                productMapDestinationData?.destination_product_id?.map((ele) => {
                    if (ele.title == e.value.toString()) {
                        return getIdArr.push(ele.id);
                    }
                });
                setDestinationShopifyID(getIdArr.toString());
                const destIdObj = {
                    destination_store_id: verifiedStoreData.store_id,
                    destination_shopify_id: getIdArr.toString(),
                    product_title: null,
                    type: 'detail'
                };

                return dispatch(productMapDestinationRequest(destIdObj));
            }
        }
    };

    const handleMapProcess = () => {
        const mapProcessObj = {
            source_shopify_id: shopifyId,
            source_store_id: destinationStoreId,
            destination_store_id: verifiedStoreData.store_id,
            destination_shopify_id: destinationShopifyID
        };
        dispatch(modifyProductMapDestinationRequest(mapProcessObj));
        // setDestinationShopifyID(null);
    };

    return (
        <Sidebar
            visible={mapSidebarModal}
            style={{ width: '840px' }}
            onHide={() => {
                setMapSidebarModal(false);
                setOpenSearchBar(false);
                setValue('');
                dispatch(productMapSourceReset());
            }}
            baseZIndex={1000}
            position="right"
        >
            {mapSourceData !== undefined ? (
                <div className="container">
                    <div className="grid">
                        <div className="col-12 flex align-items-center justify-content-between">
                            <div className="flex align-items-center">
                                <i className="pi pi-link text-2xl ml-2 mr-3 text-yellow-500"></i>
                                <h4 className="font-bold m-0" style={{ color: '#3F51B5' }}>
                                    {/* {storeName} */}
                                    Map With Existing
                                </h4>
                            </div>
                            <div>
                                {destinationShopifyID !== null ? (
                                    <Button label="Map" className="p-button-secondary font-bold shadow-3 mr-5" onClick={handleMapProcess} />
                                ) : (
                                    <Button label="Map" className="p-button-secondary font-bold shadow-3 mr-5 opacity-60" />
                                )}
                            </div>
                        </div>
                        <div className="col-6">
                            {/* <div className="border-round border-400 border-1 h-7rem font-bold"> */}
                            <div className="card shadow-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon font-bold">FROM</span>
                                    <InputText className="text-xl font-bold" defaultValue={storeName} disabled />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card shadow-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon font-bold">TO</span>
                                    <InputText className="text-xl font-bold capitalize" defaultValue={destinationName} disabled />
                                    <Button icon="pi pi-search" className="p-button-rounded p-button-info mt-1 ml-3" onClick={() => setOpenSearchBar(!openSearchBar)} />
                                </div>
                                {/* {openSearchBar && (
                                )} */}
                            </div>
                        </div>
                        <div className="col-6"></div>
                        <div className="col-6">
                            <div className="product-map-search-content">
                                <AutoComplete
                                    value={value}
                                    placeholder="Search..."
                                    className="search-map-content hide-spinner-content"
                                    onChange={(e) => handleChange(e)}
                                    onKeyPress={onhandleSearchKey}
                                    completeMethod={searchSuggestions}
                                    suggestions={suggestionsTitle}
                                />
                            </div>
                        </div>

                        <div className="col-6">
                            {/* <div className="border-round border-400 border-1 p-3"> */}

                            <h4 className="text-xl font-bold mb-3" style={{ color: '#3f51b5' }}>
                                {mapSourceData?.title}
                            </h4>
                            <div className="card shadow-5 pt-1">
                                <div className="flex">
                                    <Image src={mapSourceData?.image_url} alt="Image" width="110" height="110" preview className="mt-1 mr-3" />
                                    {/* <h4 className="m-0 flex align-items-center text-xl font-bold">{mapSourceData?.title}</h4> */}
                                    <ul className="px-3 font-semibold">
                                        <li>Product ID : {mapSourceData?.id}</li>
                                        <li>Vendor : {mapSourceData?.vendor}</li>
                                        <li>Product Type : {mapSourceData?.product_type}</li>
                                    </ul>
                                </div>
                                <ul className="px-3 font-semibold">
                                    {/* <li>Product ID : {mapSourceData?.id}</li>
                                    <li>Vendor : {mapSourceData?.vendor}</li>
                                    <li>Product Type : {mapSourceData?.product_type}</li> */}
                                    <li>
                                        Tags :{' '}
                                        <span className="ml-1">
                                            {mapSourceData?.tags?.map((ele, index) => {
                                                return <Tag className="mr-2 p-tag-rounded my-1 bg-green-800" icon="pi pi-tags" value={ele} key={index}></Tag>;
                                            })}
                                        </span>
                                    </li>
                                </ul>
                                <DataTable scrollHeight="350px" size="small" value={[mapSourceData]} className="datatable-responsive datatable_container card p-0 shadow-3">
                                    <Column field="title" header="Title" className="font-bold" body={titleTemplate}></Column>
                                    <Column field="sku" header="Sku" className="font-bold"></Column>
                                    <Column field="quantity" header="QUANTITY" className="font-bold"></Column>
                                </DataTable>
                            </div>
                        </div>

                        {productMapDestinationData?.product_detail !== undefined ? (
                            <div className="col-6">
                                <h4 className="text-xl font-bold mb-3" style={{ color: '#3f51b5' }}>
                                    {productMapDestinationData?.product_detail?.title}
                                </h4>
                                <div className="card shadow-5 pt-1">
                                    <div className="flex">
                                        <Image src={productMapDestinationData?.product_detail?.image_url} alt="Image" width="110" height="110" preview className="mt-1 mr-3" />
                                        <ul className="px-3 font-semibold">
                                            <li>Product ID : {productMapDestinationData?.product_detail?.id}</li>
                                            <li>Vendor : {productMapDestinationData?.product_detail?.vendor}</li>
                                            <li>Product Type : {productMapDestinationData?.product_detail?.product_type}</li>
                                        </ul>
                                    </div>
                                    <ul className="px-3 font-semibold">
                                        {/* <li>Product ID : {productMapDestinationData?.product_detail?.id}</li>
                                        <li>Vendor : {productMapDestinationData?.product_detail?.vendor}</li>
                                        <li>Product Type : {productMapDestinationData?.product_detail?.product_type}</li> */}
                                        <li>
                                            Tags :{' '}
                                            <span className="ml-1">
                                                {productMapDestinationData?.product_detail?.tags?.map((ele, index) => {
                                                    return <Tag className="mr-2 p-tag-rounded my-1 bg-green-800" icon="pi pi-tags" value={ele} key={index}></Tag>;
                                                })}
                                            </span>
                                        </li>
                                    </ul>
                                    <DataTable scrollHeight="350px" size="small" value={[productMapDestinationData?.product_detail]} className="datatable-responsive datatable_container card p-0 shadow-3">
                                        <Column field="title" header="Title" className="font-bold" body={titleTemplate}></Column>
                                        <Column field="sku" header="Sku" className="font-bold"></Column>
                                        <Column field="quantity" header="QUANTITY" className="font-bold"></Column>
                                    </DataTable>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : (
                <>
                    <i className="pi pi-spin pi-spinner flex justify-content-center align-items-center" style={{ fontSize: '3rem' }}></i>
                </>
            )}
        </Sidebar>
    );
};

export default MapSidebar;
