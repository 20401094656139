import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import ColorChangePicker from 'react-pick-color';
import { ColorPicker } from 'primereact/colorpicker';
import { useDispatch, useSelector } from 'react-redux';
import { colorModifyRequest, gemFinderResetData, storeSettingDropdownRequest } from '../../redux/slices/gemFinderSlice';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Panel } from 'primereact/panel';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { Messages } from 'primereact/messages';
import DiamondImage from '../../assets/logo-dark.svg';

const StoneSearchSetting = () => {
    const dispatch = useDispatch();
    const url = window.location.href;
    const urlContent = url?.split('?')[ 1 ];
    const storeIdContent = urlContent?.split('=')[ 1 ];
    const getStoreId = storeIdContent?.substring(0, storeIdContent.indexOf('&'));
    const getSessionId = urlContent?.split('=')[ 2 ];
    const { storeSettingDropdowndata, colorModifyData, error } = useSelector((state) => state.gemFinder);

    const [ value, setValue ] = useState('');
    const [ color, setColor ] = useState('#D80B4D');
    const [ bannerColor, setBannerColor ] = useState(null);
    const [ colorCode, setColorCode ] = useState('');
    const [ storeLabel, setStoreLable ] = useState('');
    const [ submitImg, setSubmitImg ] = useState(false);
    const [ colorChange, setColorChange ] = useState(false);
    const [ file, setFile ] = useState();
    const [ showImg, setShowImg ] = useState();
    const [ selectFileName, setSelectFileName ] = useState('No Image chosen');
    const [ bannerTitle, setBannerTitle ] = useState('');
    const [ bannerContent, setBannerContent ] = useState('');
    const [ selectBannerPosition, setSelectBannerPosition ] = useState('');
    const [ domain, setDomain ] = useState('');
    const [ storeStatus, setStoreStatus ] = useState('');

    const toast = useRef(null);
    const inputRef = useRef(null);

    const msgs = useRef(null);

    const selectBannerValues = [
        { label: 'Left', value: 'left' },
        { label: 'Center', value: 'center' },
        { label: 'Right', value: 'right' }
    ];

    useEffect(() => {
        dispatch(storeSettingDropdownRequest(`store_id=${getStoreId}&session=${getSessionId}`));
        if (error !== null) {
            setSubmitImg(false);
            return setShowImg(undefined);
        }
        return () => {
            dispatch(gemFinderResetData());
        };
    }, [ error ]);

    useEffect(() => {
        if (storeSettingDropdowndata?.length > 0) {
            setValue(storeSettingDropdowndata?.[ 0 ]?.value);
            setStoreLable(storeSettingDropdowndata?.[ 0 ]?.label);
            setShowImg(storeSettingDropdowndata?.[ 0 ]?.image);
            setFile(storeSettingDropdowndata?.[ 0 ]?.image);
            setBannerTitle(storeSettingDropdowndata?.[ 0 ]?.title);
            setBannerContent(storeSettingDropdowndata?.[ 0 ]?.content);
            setSelectBannerPosition(storeSettingDropdowndata?.[ 0 ]?.position);
            setBannerColor(storeSettingDropdowndata?.[ 0 ]?.text_color);
            setDomain(storeSettingDropdowndata?.[ 0 ]?.store_domain);
            setStoreStatus(storeSettingDropdowndata?.[ 0 ]?.store_status);
            return setColorCode(storeSettingDropdowndata?.[ 0 ]?.color);
        }
    }, [ storeSettingDropdowndata ]);

    useEffect(() => {
        if (colorModifyData !== null) {
            let url = domain;
            if (!/^https?:\/\//i.test(domain)) {
                url = 'http://' + domain + '/apps/stone-search';
            }
            msgs.current.show({
                sticky: true,
                severity: 'success',
                content: (
                    <>
                        <span>
                            Congratulations! Your customized browsing experience has been saved. Click here to visit our store and experience the updated view{' '}
                            <a href={url} target="_blank">
                                <b>{url}</b>
                            </a>
                            .
                        </span>
                    </>
                )
            });
            setTimeout(() => {
                msgs.current.clear();
            }, 2000);
        }
    }, [ colorModifyData ]);

    const handleOnChange = (e) => {
        setSelectFileName(e.target.files[ 0 ]?.name);
        const selectFile = e.target.files[ 0 ];
        setFile(selectFile);
        setShowImg(URL.createObjectURL(e.target.files[ 0 ]));
        setSubmitImg(true);
    };

    const handleimgTextContentSubmit = () => {
        let formData = new FormData();
        formData.append('store_color', colorCode);
        formData.append('store_id', value);
        formData.append('store_name', storeLabel);
        formData.append('banner_img', file);
        formData.append('banner_title', bannerTitle);
        formData.append('banner_content', bannerContent);
        formData.append('banner_position', selectBannerPosition);
        formData.append('is_status', submitImg == true ? true : false);
        formData.append('session', getSessionId);
        formData.append('text_color', `${bannerColor}`);

        dispatch(colorModifyRequest(formData));
        dispatch(gemFinderResetData());
        setColorChange(true);
        setSubmitImg(false);
        setSelectFileName('No Image chosen');

        // let url = domain;
        // if (!/^https?:\/\//i.test(domain)) {
        //     url = 'http://' + domain + '/apps/stone-search';
        // }
        // msgs.current.show({
        //     sticky: true,
        //     severity: 'success',
        //     // detail: `The changes have been updated. To view, please click on the link ${domain}`
        //     content: (
        //         <>
        //             <span>
        //                 Congratulations! Your customized browsing experience has been saved. Click here to visit our store and experience the updated view{' '}
        //                 <a href={url} target="_blank">
        //                     <b>{url}</b>
        //                 </a>
        //                 .
        //             </span>
        //         </>
        //     )
        // });
    };

    const headerImgTemplate = (options) => {
        const className = `${options.className} justify-content-start`;

        return (
            <div className={className}>
                <input ref={inputRef} type={'file'} id={'csvFileInput'} accept="image/*" onChange={handleOnChange} hidden />
                <div className="flex align-items-center">
                    <div className="cursor-pointer w-9rem border-round" style={{ backgroundColor: '#607d8b', color: 'white', padding: '0.5rem' }}>
                        <label htmlFor="csvFileInput" className="flex justify-content-center align-items-center">
                            <i className="pi pi-upload mr-3"></i>
                            <span className="text-base font-bold cursor-pointer">Select Image</span>
                        </label>
                    </div>
                    <span id="file-chosen" className="ml-3 text-lg font-bold">
                        {selectFileName}
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div>
            {storeStatus == 'approve' ? (
                <>
                    <Toast ref={toast} />
                    <div className="col">
                        <div className="text-2xl font-bold">
                            <h3 className="m-0">{storeLabel == '' ? '' : storeLabel[ 0 ]?.toUpperCase() + storeLabel?.slice(1)} Store Settings</h3>
                        </div>
                    </div>

                    <div style={{ padding: '0 10px' }}>
                        <Messages ref={msgs} />
                    </div>

                    {storeSettingDropdowndata?.length > 0 ? (
                        <>
                            <div className="grid p-fluid w-full m-0">
                                <div className="col-12">
                                    <div className="card py-0">
                                        <div className="grid p-3 pb-0">
                                            <div
                                                className="col-4"
                                                style={{
                                                    alignItems: 'center',
                                                    background: colorCode,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    padding: '40px 0',
                                                    borderRadius: '10px',
                                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                                                    // height:"335px"
                                                }}
                                            >
                                                <ColorChangePicker
                                                    color={colorCode}
                                                    onChange={(color) => {
                                                        setColor(color.hex);
                                                        setColorCode(color.hex);
                                                    }}
                                                    hideAlpha
                                                    theme={{
                                                        background: '#fff',
                                                        borderColor: '#D4D4D4',
                                                        borderRadius: '5px',
                                                        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
                                                        color: '#262626',
                                                        inputBackground: '#f4f4f4',
                                                        width: '280px'
                                                    }}
                                                />
                                            </div>

                                            <div className="col-4 py-0">
                                                <h5>Banner Image</h5>
                                                <Panel headerTemplate={headerImgTemplate} className="panel-image-content">
                                                    {/* {file !== undefined || showImg !== undefined ? (
                                                <div className="flex  align-items-center">
                                                    <img src={showImg} style={{ width: '90%', height: '183px' }} />
                                                    <Button
                                                        icon="pi pi-times"
                                                        className="p-button-rounded p-button-danger p-button-text ml-2"
                                                        onClick={() => {
                                                            setSelectFileName('No Image chosen');
                                                            setFile();
                                                            setShowImg(undefined);
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <h5 className="text-center">Please select an Image for Preview</h5>
                                            )} */}
                                                </Panel>
                                                <p className="font-bold text-base mt-3">Note:- The image dimension must be 1600x300.</p>
                                                <p className="font-bold text-base mt-3">Supported image type JPG,JPEG,PNG</p>
                                                <h5 className="my-2">Banner Text Color</h5>
                                                <ColorPicker value={bannerColor} onChange={(e) => setBannerColor(e.value)} className="banner-text-content" />
                                            </div>
                                            <div className="col-4 py-0">
                                                <h5>Banner Title</h5>
                                                <InputText value={bannerTitle} type="text" onChange={(e) => setBannerTitle(e.target.value)} placeholder="Enter your banner title" />
                                                <h5 className="my-2">Banner Content</h5>
                                                <InputTextarea value={bannerContent} placeholder="Enter your banner content" onChange={(e) => setBannerContent(e.target.value)} style={{ height: '75px' }} />
                                                <h5 className="my-2">Banner Position</h5>
                                                <SelectButton className="select-button-content" value={selectBannerPosition} onChange={(e) => setSelectBannerPosition(e.value)} options={selectBannerValues} optionLabel="label" />
                                                {/* <h5 className="my-2">Banner Text Color</h5>
                                        <ColorPicker value={bannerColor} onChange={(e) => setBannerColor(e.value)} className="banner-text-content" /> */}
                                            </div>
                                        </div>

                                        <div className="col-2 mx-auto mt-2">
                                            {showImg !== undefined && bannerTitle !== '' && bannerContent !== '' && selectBannerPosition !== null && bannerColor !== null ? (
                                                <Button icon="pi pi-check" tooltip="Click to proceed" label="Save Settings" onClick={handleimgTextContentSubmit} />
                                            ) : (
                                                <Button icon="pi pi-check" label="Save Settings" disabled />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12" style={{ height: `${showImg == undefined ? '175px' : ''}` }}>
                                <div className="relative w-full">
                                    <img src={showImg} className="w-full" alt="No Image" style={{ height: '300px' }} />
                                    <div className="absolute w-full px-6" style={{ top: '15%', textAlign: `${selectBannerPosition}`, color: `#${bannerColor}` }}>
                                        <h2 className="font-bold text-3xl my-2">{bannerTitle}</h2>
                                        <h2 className="font-bold text-3xl mt-0">{bannerContent}</h2>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="card">
                            <h4 className="font-bold text-2xl text-center mb-0 py-6">No Store Records Found </h4>
                        </div>
                    )}
                    <div className={`layout-footer ${storeSettingDropdowndata?.length > 0 ? '' : 'absolute bottom-0 w-full'}`}>
                        <div className="footer-logo-container">
                            <img id="footer-logo" src={DiamondImage} alt="diamond-layout" />
                            <span className="app-name flex align-items-center font-bold">
                                MRD
                                <h3 className="my-0 mx-1">|</h3>
                                STONE SEARCH
                            </span>
                        </div>
                        <span className="copyright">COPYRIGHT &#169; {new Date().getFullYear()} Kush Diamond, All rights Reserved</span>
                    </div>
                </>
            ) : (
                <>
                    <div className="grid p-fluid w-full m-0">
                        <div className="col-12">
                            <div className="card">
                                <h4 className="font-bold text-2xl text-center">
                                    {storeLabel == '' ? '' : storeLabel[ 0 ]?.toUpperCase() + storeLabel?.slice(1)} is {storeStatus}.So you have to go to the store and approve the status of the store.
                                </h4>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default StoneSearchSetting;
