import { createSlice } from '@reduxjs/toolkit';
import { excelLogApi, ftpfeedLogApi, mediaLogApi, shopifySyncLogApi } from '../../../service/kushDiamond/kdlogService';

export const kdlogSlice = createSlice({
    name: 'kdlog',
    initialState: {
        isLoading: false,
        ftpfeedLog: [],
        mediaLogData: [],
        excelLogData: [],
        shopifySyncLogData: [],
        error: null
    },
    reducers: {
        setLoading: (state) => {
            state.isLoading = true;
        },

        ftpfeedLogList: (state, action) => {
            state.isLoading = false;
            state.ftpfeedLog = action.payload;
        },

        mediaLogList: (state, action) => {
            state.isLoading = false;
            state.mediaLogData = action.payload;
        },

        excelLogList: (state, action) => {
            state.isLoading = false;
            state.excelLogData = action.payload;
        },

        shopifySyncLogList: (state, action) => {
            state.isLoading = false;
            state.shopifySyncLogData = action.payload;
        },

        kdLogError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { setLoading, ftpfeedLogList, mediaLogList, excelLogList, shopifySyncLogList, kdLogError } = kdlogSlice.actions;
export default kdlogSlice.reducer;

export const ftpfeedLogListRequest = (queryString) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };

        const { data } = await ftpfeedLogApi(queryString, config);
        dispatch(ftpfeedLogList(data));
    } catch (error) {
        dispatch(kdLogError(error.response));
    }
};

export const mediaLogListRequest = (queryString) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };

        const { data } = await mediaLogApi(queryString, config);
        dispatch(mediaLogList(data));
    } catch (error) {
        dispatch(kdLogError(error.response));
    }
};

export const excelLogListRequest = (queryString) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };

        const { data } = await excelLogApi(queryString, config);
        dispatch(excelLogList(data));
    } catch (error) {
        dispatch(kdLogError(error.response));
    }
};

export const shopifySyncLogListRequest = (queryString) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };

        const { data } = await shopifySyncLogApi(queryString, config);
        dispatch(shopifySyncLogList(data));
    } catch (error) {
        dispatch(kdLogError(error.response));
    }
};
