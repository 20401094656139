import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import App from './App';
import { Login } from './pages/Login';
import { Error } from './pages/Error';
import { NotFound } from './pages/NotFound';
import { Access } from './pages/Access';
import ProtectedRoute from './router/ProtectedRoute';
import StoneSearchSetting from './components/gemFinder/StoneSearchSetting';
import StoreSuccess from './components/gemFinder/StoreSuccess';
import DashboardMain from './components/stoneShare/DashboardMain';

const AppWrapper = () => {
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            {location.search.includes('store_id') == true && location.search.includes('session') && <Route path="/setting" element={<StoneSearchSetting />} />}
            {location.search.includes('store_id') == true && location.search.includes('session') && location.search.includes('url') && <Route path="/success" element={<StoreSuccess />} />}
            {location.search.includes('store_id') == true && location.search.includes('store_key') && <Route path="/inventory-sync-app-install" element={<DashboardMain />} />}
            <Route path="/error" element={<Error />} />
            <Route path="/notfound" element={<NotFound />} />
            <Route path="/access" element={<Access />} />
            <Route
                path="*"
                element={
                    // <ProtectedRoute>
                    <App />
                    // </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default AppWrapper;
