import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { connectStoreApi, connectStoreEmailApi, disconnectStoreData, handleStoreReset, storeVerifiedRequest } from '../../redux/slices/storeSlice';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'primereact/hooks';

const ConnectStore = ({ showStore, showDestination, nextIndex }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [values, setValues] = useState('');
    const [showConnectionStore, setShowConnectionStore] = useState(false);
    const [showStoreNew, setShowStoreNew] = useState(showStore);
    const [showDestinationNew, setshowDestinationNew] = useState(showDestination);
    const [showConnectionDestination, setShowConnectionDestination] = useState(false);
    const [showStoreEmail, setShowStoreEmail] = useState(false);
    const [showEmail, setShowEmail] = useLocalStorage(false, 'showEmail');
    const [storeKey, setStoreKey] = useState('');
    const [connectKeyType, setConnectKeyType] = useState('');
    const toast = useRef(null);

    const { storeData, error, toastError, storeEmail, storeConnectKey, storeConnectKeyError, isLoading, storeListingData } = useSelector((state) => state.store);
    useEffect(() => {
        if (toastError) {
            return toast.current.show({ severity: 'error', summary: 'Error', detail: toastError, life: 3000 });
        }
        if (storeEmail !== null) {
            return toast.current.show({ severity: 'success', summary: 'Successful', detail: storeEmail, life: 3000 });
        }
        if (storeConnectKeyError) {
            return toast.current.show({ severity: 'error', summary: 'Error', detail: storeConnectKeyError, life: 3000 });
        }
        if (storeConnectKey !== null) {
            setTimeout(() => {
                navigate('/destination-dashboard');
            }, 300);
            return toast.current.show({ severity: 'success', summary: 'Successful', detail: storeConnectKey, life: 3000 });
        }
        return () => {
            dispatch(handleStoreReset());
            setShowEmail(false);
        };
    }, [toastError, storeEmail, storeConnectKey, storeConnectKeyError]);

    const showConnectStore = () => {
        setShowConnectionStore(!showConnectionStore);
        setShowStoreNew(!showStoreNew);
    };

    const showConnectDestination = () => {
        setShowConnectionDestination(!showConnectionDestination);
        setshowDestinationNew(!showDestinationNew);
        setConnectKeyType('key');
    };

    const showEmailStore = () => {
        setShowEmail(true);
        setShowStoreEmail(true);
        setShowStoreNew(!showStoreNew);
        setStoreKey(storeData.store_key);
    };

    const showEmailDestination = () => {
        setShowEmail(true);
        setshowDestinationNew(!showDestinationNew);
        setStoreKey(storeData?.store_key);
        setConnectKeyType('request_access');
    };
    const handleClick = () => {
        const payload = {
            store_id: storeData.store_id,
            connect_type: connectKeyType,
            source_store_id: values
        };
        dispatch(connectStoreApi(payload));
    };
    const sendRequestFormik = useFormik({
        initialValues: {
            store_id: '',
            connect_type: '',
            source_store_id: ''
        },
        validate: (data) => {
            let errors = {};

            if (data.store_id == '') {
                errors.store_id = 'Store is required.';
            }

            return errors;
        },

        onSubmit: (values) => {
            const payload = {
                store_id: storeData.store_id,
                connect_type: connectKeyType,
                source_store_id: values.store_id
            };
            dispatch(connectStoreApi(payload));
            sendRequestFormik.resetForm();
        }
    });

    const storeConnectformik = useFormik({
        initialValues: {
            store_key: ''
        },
        validationSchema: yup.object({
            store_key: yup.string().required('Key is required')
        }),
        onSubmit: (values) => {
            const { store_key } = values;
            let store_type = showConnectionDestination ? 'source' : 'destination';
            let store_id = storeData?.store_id;
            values = { store_key, store_type, store_id, connect_type: connectKeyType };
            dispatch(connectStoreApi(values));
            storeConnectformik.resetForm();
        }
    });

    return (
        <>
            <div className="grid">
                <Toast ref={toast} />
                {/* {showStore && <h2 className="col-12 text-center">Connect to your first destination store!</h2>} */}
                {showStore && <h2 className="col-12 text-center">To connect with the destination store, use this key</h2>}

                {showDestination && <h2 className="col-12 text-center">Connect to your first source store!</h2>}

                {showStoreNew ? (
                    <div className="mx-auto col-12 xl:col-6">
                        <Card>
                            <h5 className="font-bold text-center">YOUR STORE'S UNIQUE KEY:</h5>
                            {isLoading == true ? (
                                <i className="pi pi-spin pi-spinner flex justify-content-center mb-3" style={{ fontSize: '2rem' }}></i>
                            ) : (
                                <h4 className="text-center mt-0" style={{ color: 'red' }}>
                                    {storeData?.store_key}
                                </h4>
                            )}
                            {storeData.store_type == 'destination' ? (
                                <p className="text-xl">
                                    Share your unique key with destination store to allow them to import your products to sell on their store. This unique key can also be found on your dashboard after completing this setup process.
                                </p>
                            ) : (
                                <p className="text-xl">
                                    Share your unique key with destination store to allow them to import your products to sell on their store. This unique key can also be found on your dashboard after completing this setup process.
                                </p>
                            )}
                            {/* <div className="grid">
                            <div className="col-6">
                                <Button icon="pi pi-arrow-right" iconPos="right" label="Share your key via e-mail" className="p-button-raised mr-2 mb-2 w-12" onClick={() => showEmailStore()} />
                            </div>
                            <div className="col-6">
                                <Button icon="pi pi-arrow-right" iconPos="right" label="I received a destination store key" onClick={() => showConnectStore()} className="p-button-outlined  mr-2 mb-2 w-12" />
                            </div>
                        </div> */}
                        </Card>
                    </div>
                ) : null}

                {showEmail && (
                    <div className="mx-auto col-12 xl:col-6 ">
                        <Card className="mx-auto">
                            {/* <h6 className="font-bold mb-3">The {showStoreEmail ? 'destination' : 'source'} store will receive your unique key,instructions to install Syncio,and connect to your store.</h6> */}

                            {/* <form onSubmit={formik.handleSubmit} className="p-fluid ">
                                <div className="field">
                                    <label htmlFor="store">Select source store </label>
                                    <span className="p-float-label p-input-icon-right mb-2">
                                        <InputText id="store" className="w-9" name="store" onChange={formik.handleChange} value={formik.values.store} />
                                        <Dropdown
                                            inputId="store"
                                            name="store"
                                            value={formik.values.value}
                                            onChange={(e) => formik.setFieldValue('value', e.value)}
                                            options={storeListingData}
                                            optionLabel="label"
                                            placeholder="Select a Store"
                                        />
                                    </span>
                                    {(formik.touched.store && formik.errors.store) || (error && error?.store) ? <small className="p-error text-lg">{formik.errors.email || error?.store}</small> : null}
                                    <div className="mt-5">
                                        <Button type="submit" label="Send Request" className="p-button-raised mr-2 ml-4 w-2" />
                                    </div>
                                </div>
                            </form> */}
                            <div>
                                <h6 className="mx-auto text-xl">{`Send request access to a ${showStoreEmail ? 'destination' : 'source'} store.`}</h6>
                            </div>
                            <form onSubmit={sendRequestFormik.handleSubmit} className="p-fluid ">
                                <div className="flex  flex-column gap-3  align-items-center">
                                    <div className="flex flex-column gap-3"></div>
                                    <label htmlFor="store_id" className="text-xl">
                                        Select source store
                                    </label>
                                    <div className="w-6">
                                        {/* <Dropdown inputId="store" name="store" filter className="w-12" value={values} onChange={(e) => setValues(e.target.value)} options={storeListingData} optionLabel="label" placeholder="Select a Store" /> */}
                                        <Dropdown
                                            inputId="store_id"
                                            name="store_id"
                                            filter
                                            className={classNames({ 'p-invalid': sendRequestFormik.errors.store_id })}
                                            value={sendRequestFormik.values.store_id}
                                            onChange={(e) => sendRequestFormik.setFieldValue('store_id', e.target.value)}
                                            options={storeListingData}
                                            optionLabel="label"
                                            placeholder="Select a Store"
                                        />
                                        {(sendRequestFormik.touched.store_id && sendRequestFormik.errors.store_id) || (error && error?.store_id) ? (
                                            <small className="p-error text-lg">{sendRequestFormik.errors.store_id || error?.store_id}</small>
                                        ) : null}
                                    </div>
                                    <div className="">
                                        <Button
                                            label="Send Request"
                                            className="p-button-raised"
                                            type="submit"
                                            // onClick={() => {
                                            //     handleClick();
                                            // }}
                                        />
                                    </div>
                                </div>
                            </form>
                        </Card>
                    </div>
                )}

                {showConnectionStore ? (
                    <div className="mx-auto col-12 xl:col-6">
                        <Card title="Connect to a destination store using a unique key.">
                            <h6>Once connected, the destination store will be able to start importing products from your store. </h6>
                            <form onSubmit={storeConnectformik.handleSubmit} className="p-fluid">
                                <div className="field">
                                    <label htmlFor="store_key">Destination store unique key</label>
                                    <span className="p-float-label p-input-icon-right mb-2">
                                        <InputText id="store_key" className="w-9" name="store_key" onChange={storeConnectformik.handleChange} value={storeConnectformik.values.store_key} />
                                        <Button type="submit" label="Connect" icon="pi pi-plus" iconPos="right" className="p-button-raised mr-2 ml-4 w-2" />
                                    </span>
                                    {storeConnectformik.touched.store_key && storeConnectformik.errors.store_key ? <small className="p-error text-lg">{storeConnectformik.errors.store_key}</small> : null}
                                </div>
                            </form>
                        </Card>
                    </div>
                ) : null}

                {showDestinationNew ? (
                    <div className="mx-auto col-12 xl:col-6">
                        <Card>
                            <h5 className="font-bold text-center" style={{ color: 'red' }}>
                                YOUR STORE'S UNIQUE KEY:
                            </h5>
                            {isLoading == true ? (
                                <i className="pi pi-spin pi-spinner flex justify-content-center mb-3" style={{ fontSize: '2rem' }}></i>
                            ) : (
                                <h4 className="text-center mt-0" style={{ color: 'red' }}>
                                    {storeData?.store_key}
                                </h4>
                            )}
                            <p>Share your unique key with source stores to start importing their products to sell on your store. This unique key can also be found on your dashboard after completing this setup process. </p>
                            <div className="grid">
                                <div className="col-6">
                                    <Button icon="pi pi-arrow-right" iconPos="right" label="Request access to connect with source store" className="p-button-raised mr-2 mb-2 w-12" onClick={() => showEmailDestination()} />
                                </div>
                                <div className="col-6">
                                    <Button icon="pi pi-arrow-right" iconPos="right" label="I received a source store key" onClick={() => showConnectDestination()} className="p-button-outlined mr-2 mb-2 w-12" />
                                </div>
                            </div>
                        </Card>
                    </div>
                ) : null}

                {showConnectionDestination ? (
                    <div className="mx-auto col-12 xl:col-6">
                        <Card title="Connect to a source store using a unique key.">
                            <h6>Once connected, you will be able to start importing products from this source store. </h6>
                            <form onSubmit={storeConnectformik.handleSubmit} className="p-fluid">
                                <div className="field">
                                    <label htmlFor="store_key">Source store unique key</label>
                                    <span className="p-float-label p-input-icon-right mb-2">
                                        <InputText id="store_key" className="w-9" name="store_key" onChange={storeConnectformik.handleChange} value={storeConnectformik.values.store_key} />
                                        <Button type="submit" label="Connect" icon="pi pi-plus" iconPos="right" className="p-button-raised mr-2 ml-4 w-2" />
                                    </span>
                                    {storeConnectformik.touched.store_key && storeConnectformik.errors.store_key ? <small className="p-error text-lg">{storeConnectformik.errors.store_key}</small> : null}
                                </div>
                            </form>
                        </Card>
                    </div>
                ) : null}
                {/* <h5 onClick={nextIndex} className="col-12 xl:col-8 text-primary text-center cursor-pointer mx-auto">
                Skip for now
            </h5> */}

                {/* <Button icon="pi pi-arrow-right" type="submit" label="Next" iconPos="right" onClick={nextIndex} className="col-12 xl:col-8 text-primary text-center cursor-pointer mx-auto" /> */}
            </div>
            <div className="flex justify-content-center">
                <div className="w-1">
                    <Button type="submit" icon="pi pi-arrow-right" onClick={nextIndex} label="Next" iconPos="right" className="p-button-raised w-12" />
                </div>
            </div>
        </>
    );
};

export default ConnectStore;
