import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { allDiscountListRequest, createTagRequest, deleteTagRequest, discountError, getCollectionListRequest, getStoreNameDdRequest, updateTagRequest } from '../../redux/slices/stoneSearchSlice/discountTagGemFinderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { classNames } from 'primereact/utils';
import { BreadCrumb } from 'primereact/breadcrumb';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';

const GemFinderDiscountTags = () => {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [idToUpdate, setIdToUpdate] = useState('');
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);
    const [tag, setTag] = useState('');
    const [isConfirmDialog, setConfirmDialog] = useState(false);
    const [percentage, setPercentage] = useState('');
    const [storeName, setStoreName] = useState('');
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(100);
    const [sort_order, setSort_order] = useState('desc');
    const table_data = {
        page: 1,
        per_page: 100,
        sort_order: sort_order,
        order_column: 'created_at'
    };
    const columns = [
        { field: 'tag', header: 'Tag', style: { maxWidth: '15rem' } },
        { field: 'percentage', header: 'Percentage', style: { maxWidth: '6rem' } },
        {
            field: 'store_name',
            header: 'Store Name',
            align: 'center',
            headerClassName: 'justify-content-center',
            style: { maxWidth: '20rem' }
        },
        {
            field: 'actions',
            header: 'Actions',
            align: 'center',
            headerClassName: 'justify-content-center',
            style: { maxWidth: '15rem' }
        }
    ];
    const [queryString, setQueryString] = useState(`page=${table_data.page}&per_page=${table_data.per_page}&order_column=${table_data.order_column}`);
    const { isLoading, isDataTableLoading, allDiscountList, succesMessage, collectionList, storeList, errors: error } = useSelector((state) => state.discountStoneSearch);
    const tableChangeHandler = (data) => {
        let queryStr = Object.keys(data)
            .map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
            })
            .join('&');
        setQueryString(queryStr);
    };
    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        tableChangeHandler({ ...table_data, page: event.page + 1, per_page: event.rows });
    };
    const template = {
        layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };
    const bodyTemplate = (rowData, col) => {
        if (col.field === 'actions') {
            return actionBodyTemplate(rowData);
        } else {
            return (
                <div className="white-space-nowrap overflow-hidden text-overflow-ellipsis" title={rowData[col.field]}>
                    {rowData[col.field]}
                </div>
            );
        }
    };
    const updateFormValue = (tag, percentage) => {
        formik.setFieldValue('tag', tag);
        formik.setFieldValue('percentage', percentage);
    };

    const formik = useFormik({
        initialValues: visible
            ? {
                  tag: tag,
                  percentage: percentage,
                  store_name: storeName
              }
            : {
                  tag: '',
                  percentage: '',
                  store_name: ''
                  //   collection_name: []
              },
        validationSchema: yup.object({
            tag: yup.string().required('Please Enter Tag'),
            percentage: yup.number().min(1, 'Percentage must be at least 1').required('Please Enter Percentage'),
            store_name: yup.string().required('Please Select Store Name')
        }),
        validate: (data) => {
            let errors = {};
            if (!data.tag && !data.percentage) {
                errors.tag = 'Please Enter Tag';
                errors.percentage = 'Please Enter Percentage';
            }
            if (!data.tag && !data.percentage) {
                errors.tag = 'Please Enter Tag';
                errors.percentage = 'Please Enter Percentage';
            } else if (!data.tag) {
                errors.tag = 'Please Enter Tag';
            } else if (!data.percentage) {
                errors.percentage = 'Please Enter Percentage';
            }
            return errors;
        },
        onSubmit: (data) => {
            // const collection_detail = [];
            const payload = {
                // collection_detail,
                percentage: data.percentage,
                tag: data.tag,
                store_name: data.store_name
            };
            // collectionList.map((ele) => {
            //     if (data.collection_name.includes(ele.value)) {
            //         collection_detail.push({ label: ele.label, value: ele.value });
            //     }
            // });

            if (isUpdateModalOpen) {
                dispatch(updateTagRequest(idToUpdate, payload));
            } else {
                dispatch(createTagRequest(payload));
            }
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };
    const reject = () => {
        console.log('cancel');
    };
    const tagDelete = () => {
        dispatch(deleteTagRequest(idToUpdate));
    };
    const breadcrumbItems = [{ label: 'Stone Search' }, { label: 'Discount Coupon' }];
    const handleCreate = () => {
        setPercentage('');
        setTag('');
        setStoreName('');
        dispatch(discountError(null));
        setVisible(true);
        dispatch(getStoreNameDdRequest());
    };

    const handleClick = (rowData, collectionIds) => {
        dispatch(discountError());
        dispatch(getStoreNameDdRequest());
        dispatch(getCollectionListRequest(rowData?.store_name));
        formik.setFieldValue('tag', rowData?.tag);
        formik.setFieldValue('percentage', rowData?.percentage);
        formik.setFieldValue('store_name', rowData?.store_name);
        setIdToUpdate(rowData?.id);
        setIsUpdateModalOpen(true);
        setVisible(true);
    };
    const footerBody = (
        <div className="flex gap-2 justify-content-end">
            <Button label="Delete" icon="pi pi-trash" className="p-button-outlined p-button-danger" onClick={() => tagDelete()} autoFocus loading={isLoading} />
            {/* <Button label="Cancel" icon="pi pi-times" className="p-button-outlined" onClick={() => setConfirmDialog(false)} /> */}
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        var collectionIds = [];
        rowData?.collection_detail?.map((item) => {
            collectionIds.push(item?.value);
        });
        return (
            <div className="actions flex justify-content-end ">
                <Button tooltip="Update" tooltipOptions={{ position: 'bottom' }} icon="pi pi-pencil" id="edits-icons" className="p-button-rounded p-button-text  p-button-help " onClick={() => handleClick(rowData, collectionIds)} />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-text  p-button-danger"
                    id="delete-icons"
                    tooltip="Delete"
                    tooltipOptions={{ position: 'bottom' }}
                    onClick={() => {
                        setIdToUpdate(rowData?.id);
                        setConfirmDialog(true);
                    }}
                />
            </div>
        );
    };
    const closeModal = () => {
        formik.resetForm();
        setVisible(false);
        setIsUpdateModalOpen(false);
    };
    useEffect(() => {
        dispatch(allDiscountListRequest(queryString));
    }, [dispatch, queryString]);
    useEffect(() => {
        if (succesMessage != null) {
            setVisible(false);
            setConfirmDialog(false);
            setIsUpdateModalOpen(false);
            dispatch(allDiscountListRequest(queryString));
            dispatch(discountError());
            formik.resetForm();
        }
    }, [succesMessage]);
    return (
        <>
            <ConfirmDialog />
            <div className="col px-0 pt-0">
                <div className="breadcrumb-container flex justify-content-between align-items-center">
                    <div className="text-2xl font-bold">
                        <h3 className="m-0">Discount Coupon</h3>
                    </div>
                    <div className="ml-6 text-2xl">
                        <BreadCrumb home={{ icon: 'pi pi-home', url: '/dashboard' }} model={breadcrumbItems} />
                    </div>
                </div>
            </div>
            <div className="card shadow-3 col-7">
                <div className="flex flex-end mb-4">
                    <Button
                        label="Create"
                        icon="pi pi-plus"
                        className="p-button-outlined ml-auto"
                        onClick={() => {
                            handleCreate();
                        }}
                    />
                </div>
                <DataTable
                    responsiveLayout="scroll"
                    scrollable
                    removableSort
                    // showGridlines
                    stripedRows
                    // tableStyle={{ minWidth: '101rem' }}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    id="id"
                    size="small"
                    value={allDiscountList?.results}
                    loading={isDataTableLoading}
                    className="datatable-responsive b2b-datatable-content"
                    emptyMessage="No records found."
                >
                    {columns.map((col) => (
                        <Column align={col.align} key={col.field} field={col.field} header={col.header} style={col.style} headerClassName={col.headerClassName} sortable={col.field === 'username'} body={(rowData) => bodyTemplate(rowData, col)} />
                    ))}
                </DataTable>
                <Paginator template={template} first={first} rows={rows} totalRecords={allDiscountList?.count} onPageChange={onPageChange} rowsPerPageOptions={[100, 200, 300, 400, 500]}></Paginator>
            </div>
            <Dialog header={!isUpdateModalOpen ? 'Create Coupon' : 'Update Coupon'} draggable={false} visible={visible} style={{ width: '60rem' }} onHide={() => closeModal()}>
                <form className="p-fluid" onSubmit={formik.handleSubmit}>
                    <div className="flex">
                        <div className="flex flex-column col-4">
                            <label htmlFor="store_name" className="mb-3">
                                Store Name
                            </label>
                            <Dropdown
                                id="store_name"
                                name="store_name"
                                value={formik.values.store_name}
                                onChange={(e) => {
                                    formik.setFieldValue('store_name', e.value);
                                }}
                                options={storeList}
                                emptyMessage="No Stores found"
                                optionLabel="label"
                                optionValue="label"
                                placeholder="Select a Store"
                                filter
                                className={classNames({ 'p-invalid': isFormFieldInvalid('store_name') })}
                            />
                            <p className="mt-1">{getFormErrorMessage('store_name')}</p>
                        </div>
                        <div className="flex flex-column col-4">
                            <label htmlFor="tag" className="mb-3">
                                Tag
                            </label>
                            <InputText
                                id="tag"
                                name="tag"
                                value={formik.values.tag}
                                onChange={(e) => {
                                    formik.setFieldValue('tag', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('tag') })}
                            />
                            <p className="mt-1">{getFormErrorMessage('tag')}</p>
                        </div>
                        <div className="flex flex-column col-4">
                            <label htmlFor="percentage" className="mb-3">
                                Percentage %
                            </label>
                            <InputText
                                id="percentage"
                                name="percentage"
                                value={formik.values.percentage}
                                onChange={(e) => {
                                    formik.setFieldValue('percentage', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('percentage') })}
                            />
                            <p className="mt-1">{getFormErrorMessage('percentage')}</p>
                        </div>
                    </div>
                    <div className="flex"></div>

                    <div className="w-2 ml-auto">
                        <Button type="submit" loading={isLoading} icon="pi pi-check" className="p-button-outlined ml-auto" label="Submit" />
                    </div>
                </form>
            </Dialog>
            <Dialog visible={isConfirmDialog} modal draggable={false} footer={footerBody} style={{ width: '35rem' }} onHide={() => setConfirmDialog(false)} headerStyle={{ padding: '0.5rem' }}>
                <div className="flex flex-column align-items-center w-full gap-3  surface-border">
                    <i
                        className="pi pi-trash text-6xl p-3 border-circle"
                        style={{
                            color: '#d32f2f',
                            backgroundColor: '#ffe4e6'
                        }}
                    ></i>
                    <p className="text-xl font-bold text-color-secondary text-center">Would you like to delete this record?</p>
                </div>
            </Dialog>
            ;
        </>
    );
};

export default GemFinderDiscountTags;
