import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { useDispatch, useSelector } from 'react-redux';
import { Paginator } from 'primereact/paginator';
import { productLogListRequest } from '../../redux/slices/gemFinderSlice';
import moment from 'moment';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Tag } from 'primereact/tag';

const   ProductLog = () => {
    const dispatch = useDispatch();

    const { productAllData, isLoading } = useSelector((state) => state.gemFinder);
    const [ selectedProducts, setSelectedProducts ] = useState(null);
    const [ sort_order, setSort_order ] = useState('desc');
    const [ first, setFirst ] = useState(0);
    const [ rows, setRows ] = useState(25);
    const toast = useRef(null);
    const dt = useRef(null);

    const table_data = {
        page: 1,
        per_page: 25,
        sort_order: sort_order
    };

    const [ queryString, setQueryString ] = useState(`page=${table_data.page}&per_page=${table_data.per_page}`);

    useEffect(() => {
        dispatch(productLogListRequest(queryString));
    }, [ dispatch, queryString ]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        tableChangeHandler({ ...table_data, page: event.page + 1, per_page: event.rows });
    };

    const tableChangeHandler = (data) => {
        let queryStr = Object.keys(data)
            .map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[ key ]);
            })
            .join('&');
        setQueryString(queryStr);
    };

    const fileBodyTemplate = (rowData) => {
        return (
            <>
                <a href={rowData?.file} download={rowData?.file} className='cursor-pointer'>
                    {rowData?.file}
                </a>
                {/* {rowData?.file} */}
            </>
        );
    };

    const typeBodyTemplate = (rowData) => {
        return (
            <>
                <div>{rowData.type == null ? '-' : rowData.type}</div>
            </>
        );
    };
    const usernameBodyTemplate = (rowData) => {
        return (
            <>
                <div>{rowData?.user_name}</div>
            </>
        );
    };
    const dateBodyTemplate = (rowData) => {
        return (
            <>
                <div>{moment(rowData?.created_at).format('MMM DD YYYY h:mm A')}</div>
            </>
        );
    };
    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className={`w-5rem block text-center product-badge ${rowData?.status == 'success' ? 'status-instock' : rowData?.status == 'pending' ? 'status-lowstock' : 'status-outofstock'}`}>{rowData?.status}</span>
            </>
        );
    };
    const errorstatusBodyTemplate = (rowData) => {
        return (
            <>
                <div>{rowData?.error_status ? rowData?.error_status : '-'}</div>
            </>
        );
    };

    const template = {
        layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    const breadcrumbItems = [ { label: 'Stone Search' }, { label: 'Product Excel Logs' } ];
    const dynamicHeight = Math.min(window.innerHeight * 4 + 1, 70) + 'vh';
    return (
        <>
            <div className="col px-0">
                <div className="breadcrumb-container flex justify-content-between align-items-center">
                    <div className="text-2xl font-bold">
                        <h3 className="m-0">Product Excel Logs</h3>
                    </div>
                    <div className="ml-6 text-2xl">
                        <BreadCrumb home={{ icon: 'pi pi-home', url: '/dashboard' }} model={breadcrumbItems} />
                    </div>
                </div>
            </div>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <DataTable
                            // showGridlines
                            stripedRows
                            scrollHeight={dynamicHeight}
                            size="small"
                            value={productAllData?.results}
                            sortField="created_at"
                            sortOrder={-1}
                            selection={selectedProducts}
                            onSelectionChange={(e) => setSelectedProducts(e.value)}
                            dataKey="id"
                            loading={isLoading}
                            className="datatable-responsive stoneSerach-content"
                            emptyMessage="No records found."
                            responsiveLayout="scroll"
                        >
                            <Column field="created_at" header="Uploaded Date" sortable headerStyle={{ width: '13%' }} body={dateBodyTemplate}></Column>
                            <Column field="file" header="Uploaded File" headerStyle={{ width: '40%' }} body={fileBodyTemplate}></Column>
                            <Column field="type" header="File Type" headerStyle={{ width: '9%' }} body={typeBodyTemplate}></Column>
                            <Column field="user_name" header="User Name" headerStyle={{ width: '8%' }} body={usernameBodyTemplate}></Column>
                            <Column field="status" header="Status" headerStyle={{ width: '8%' }} body={statusBodyTemplate}></Column>
                            <Column field="error_status" header="Error Status" headerStyle={{ width: '25%' }} body={errorstatusBodyTemplate}></Column>
                        </DataTable>
                        <Paginator template={template} first={first} rows={rows} totalRecords={productAllData?.count} onPageChange={onPageChange} rowsPerPageOptions={[ 25, 50, 100, 200 ]}></Paginator>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductLog;
