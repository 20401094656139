import { Button } from 'primereact/button';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import image from '../../assets/success.png';

const StoreSuccess = () => {
    let location = useLocation();
    return (
        <div className="w-screen flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="card shadow-2">
                <div className="mt-1 text-center">
                    <img height={100} src={image} />
                    <h3 className="mb-0" style={{ color: '#3F51B5', fontWeight: 700 }}>
                        Installation Complete! &#128640;
                    </h3>
                    <h5>Enhance your browsing experience by accessing our powerful search functionality.</h5>
                </div>
                <div className="grid justify-content-center w-full">
                    {/* <div className="col-12 md:col-6"> */}
                    <div className="cursor-pointer" style={{maxWidth : "930px"}}>
                        {/* <h2 className="text-3xl font-bold">Store Description:</h2> */}
                        <ul className="text-lg font-semibold line-height-3 mt-4 pl-5 text-justify" style = {{wordBreak : "break-word"}}>
                            {/* <li>Enhance your browsing experience by accessing our powerful search functionality.</li> */}
                            <li>
                                Create one menu item in your store's navigation menu The URL for the Stone Search page is <b>"/apps/stone-search"</b>.
                            </li>
                            <li className="mt-2"> By adding this menu link, users can quickly access the Search functionality without manually entering the URL in their browser.</li>
                            <li>
                                Thank you for installing the App. I wish you a pleasant experience. An approval request has been sent to the administrator.Once approval has been received, you can begin your journey. Please be patient as the process may take some time. Feel free to contact us if you have any questions or need help. We are here to assist you
                            </li>
                        </ul>
                    </div>
                    {/* </div> */}
                </div>
                <div className="text-center mt-3 mb-3">
                    <a href={location.search?.split('=')[3]} target="_blank">
                        <Button label="Continue to Your Store" icon="pi pi-arrow-right" iconPos="right" className="p-button-raised mr-8 text-center w-3" />
                    </a>

                    <Link to={`/setting${location.search?.split('&')[0] + '&' + location.search?.split('&')[1]}`}>
                        <Button label="Continue to Admin" icon="pi pi-arrow-right" iconPos="right" className="p-button-raised mr-2  text-center w-3" />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default StoreSuccess;
