import { createSlice } from '@reduxjs/toolkit';
import { dashboardApi, dashboardStoneShareApi } from '../../../service/stoneSearch/api';
import { handleUnAuthorizeRequest, handleUnAuthorizeUserRequest } from '../authSlice';
export const dashboardSlice = createSlice({
    name: 'stoneSearch',
    initialState: {
        isLoading: false,
        dashboardCountData: null,
        dashboardStoneShareCountData: null,
        error: null
    },
    reducers: {
        setLoading: (state) => {
            state.isLoading = true;
        },
        dashboardList: (state, action) => {
            state.isLoading = false;
            state.dashboardCountData = action.payload?.data;
        },
        stoneShareDashboardList: (state, action) => {
            state.isLoading = false;
            state.dashboardStoneShareCountData = action.payload?.data;
        },
        dashboardResetData: (state) => {
            state.isLoading = false;
            state.error = null;
            state.dashboardCountData = null;
        },
        dashboardError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        }
    }
});

export const { setLoading, dashboardList, dashboardResetData, stoneShareDashboardList, dashboardError } = dashboardSlice.actions;
export default dashboardSlice.reducer;

export const DashboardStoneSearchRequest = () => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await dashboardApi(config);
        dispatch(dashboardList(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else {
            dispatch(dashboardError(error?.response?.data));
        }
    }
};
export const DashboardStoneShareRequest = () => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await dashboardStoneShareApi(config);
        dispatch(stoneShareDashboardList(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else {
            dispatch(dashboardError(error?.response?.data));
        }
    }
};
