import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import { stoneSearchStoreRequest, storeResetData } from '../../redux/slices/gemFinderSlice';
import { disconnectStoreRequest, storeRequest, storeRequestSuccess } from '../../redux/slices/storeSlice';
import { Dialog } from 'primereact/dialog';

const StoneSearchStore = () => {
    const dispatch = useDispatch();
    const { stoneSearchStoreData } = useSelector((state) => state.gemFinder);
    const { isLoading, storeData, verifiedStoreData, storeRequestSuccessMessage } = useSelector((state) => state.store);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [sort_order, setSort_order] = useState('desc');
    const [disconnectOpencModal, setDisconnectOpencModal] = useState(false);
    const [destStoreId, setDestStoreId] = useState(0);
    console.log(destStoreId, 'destStoreId');
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(25);
    const [selectStoreStatus, setSelectStoreStatus] = useState('all');
    const toast = useRef(null);
    const table_data = {
        page: 1,
        per_page: 25,
        sort_order: sort_order
    };
    const [queryString, setQueryString] = useState(`page=${table_data.page}&per_page=${table_data.per_page}&store_status=${selectStoreStatus}`);
    const getQueryParam = () => {
        if (verifiedStoreData?.is_verified) {
            return `${queryString}&store_id=${verifiedStoreData.store_id}&store_type=${verifiedStoreData.store_type}`;
        } else {
            return queryString;
        }
    };
    const getNavigationLink = () => {
        if (storeData && storeData.store_type === 'source') {
            return '/source-dashboard';
        } else {
            return '/destination-dashboard';
        }
    };
    const handleDeleteStore = () => {
        const deleteStoreObj = {
            store_status: 'delete',
            destination_store_id: destStoreId,
            source_store_id: verifiedStoreData.store_id
        };
        dispatch(disconnectStoreRequest(deleteStoreObj, getQueryParam()));
        setDisconnectOpencModal(false);
    };

    const handleKeepStore = () => {
        const keepStoreObj = {
            store_status: 'keep',
            destination_store_id: destStoreId,
            source_store_id: verifiedStoreData.store_id
        };
        dispatch(disconnectStoreRequest(keepStoreObj, getQueryParam()));
        setDisconnectOpencModal(false);
    };

    const getPayload = () => {
        if (verifiedStoreData?.is_verified) {
            const payload = {
                store_id: verifiedStoreData?.store_id,
                store_type: verifiedStoreData?.store_type
            };
            return payload;
        } else {
            const payload = {
                store_id: storeData?.store_id,
                store_type: storeData?.store_type
            };
            return payload;
        }
    };
    const data = getPayload();
    useEffect(() => {
        dispatch(stoneSearchStoreRequest(getQueryParam()));
        return () => {
            dispatch(storeResetData());
        };
    }, [dispatch, queryString]);

    useEffect(() => {
        if (storeRequestSuccessMessage != null) {
            dispatch(stoneSearchStoreRequest(getQueryParam()));
            dispatch(storeRequestSuccess(null));
        }
    }, [storeRequestSuccessMessage]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        tableChangeHandler({ ...table_data, page: event.page + 1, per_page: event.rows });
    };

    const tableChangeHandler = (data) => {
        let queryStr = Object.keys(data)
            .map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
            })
            .join('&');
        setQueryString(queryStr);
    };
    const handleClick = (rowData, status) => {
        const payload = {
            request_key: status,
            source_store_id: storeData?.store_id,
            destination_store_id: rowData.store_id,
            source_store_id: verifiedStoreData.store_id
        };
        dispatch(storeRequest(payload));
    };
    const getButton = (rowData) => {
        if (rowData.is_connected === 'pending') {
            return (
                <>
                    <Button label="Approve" icon="pi pi-verified" className=" p-button p-button-success  mr-2" onClick={() => handleClick(rowData, 'approve')} />
                    <Button label="Decline" icon="pi pi-times" className=" p-button p-button-danger" onClick={() => handleClick(rowData, 'decline')} />
                </>
            );
        } else {
            return (
                <>
                    <div className="actions">
                        <Button
                            icon="pi pi-link"
                            tooltip="Disconnect"
                            tooltipOptions={{ position: 'top' }}
                            className="p-button-rounded p-button-danger mt-2"
                            onClick={() => {
                                setDisconnectOpencModal(true);
                                setDestStoreId(rowData?.store_id);
                            }}
                        />
                    </div>
                </>
            );
        }
    };
    const actionBodyTemplate = (rowData) => {
        return getButton(rowData);
    };
    const storeTypeBodyTemplate = (rowData) => {
        return (
            <>
                {/* <span className={`customer-badge ${rowData.store_type !== 'destination' ? 'status-qualified' : 'status-negotiation'}`}>{rowData.store_type}</span> */}
                <a href={rowData.store_url} target="_blank">
                    {rowData.store_url}
                </a>
            </>
        );
    };
    const template = {
        layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    const breadcrumbItems = [{ label: 'Stone Search' }, { label: 'Stores' }];
    const dynamicHeight = Math.min(window.innerHeight * 4 + 1, 70) + 'vh';

    return (
        <>
            <div className="col px-0">
                <div className="breadcrumb-container flex justify-content-between align-items-center">
                    <div className="text-2xl font-bold">
                        <h3 className="m-0">Stores</h3>
                    </div>
                    <div className="ml-6 text-2xl">
                        <BreadCrumb home={{ icon: 'pi pi-home', url: getNavigationLink() }} model={breadcrumbItems} />
                    </div>
                </div>
            </div>
            <h5 className="font-bold mt-0 text-lg text-color-secondary">You can disconnect the Destination store from the Source store and Vice versa. In this, you can also disconnect and keep and disconnect and delete products.</h5>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <DataTable
                            showGridlines
                            stripedRows
                            scrollHeight={dynamicHeight}
                            size="small"
                            value={stoneSearchStoreData?.results}
                            selection={selectedProducts}
                            onSelectionChange={(e) => setSelectedProducts(e.value)}
                            dataKey="id"
                            className="datatable-responsive stoneSerach-content"
                            emptyMessage="No record found."
                            loading={isLoading}
                            responsiveLayout="scroll"
                        >
                            <Column field="store_name" className="font-semibold" header="Store Name" headerStyle={{ width: '5%' }}></Column>
                            <Column field="store_url" className="font-semibold" header="Store URL" body={storeTypeBodyTemplate} headerStyle={{ width: '15%' }}></Column>
                            {/* <Column header="Actions" className="font-semibold" body={actionBodyTemplate}></Column> */}
                        </DataTable>

                        <Paginator template={template} first={first} rows={rows} totalRecords={stoneSearchStoreData?.count} onPageChange={onPageChange} rowsPerPageOptions={[25, 50, 100, 500]}></Paginator>
                    </div>
                </div>
            </div>
            <Dialog className="unsync-modal-content cross-content" header="Please select an option for disconnecting store" draggable={false} visible={disconnectOpencModal} style={{ width: '50rem' }} onHide={() => setDisconnectOpencModal(false)}>
                <div className="grid">
                    <div className="col-6">
                        <div className="mt-3 card shadow-4 cursor-pointer border-round-xl">
                            <div className="text-center">
                                <i className="pi pi-link text-5xl" style={{ color: '#607d8b' }}></i>
                                <h4 className="font-bold m-3">Disconnect and Keep</h4>
                                <h4 className="font-semibold text-sm mt-4 mb-3">Unsync without deleting the product from your destination store.</h4>
                                <Button label="Disconnect and Keep" className="p-button-secondary font-bold shadow-3" onClick={handleKeepStore} />
                            </div>
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="mt-3 card shadow-4 cursor-pointer border-round-xl">
                            <div className="text-center">
                                <i className="pi pi-trash text-5xl" style={{ color: 'red' }}></i>
                                <h4 className="font-bold m-3">Disconnect and Delete</h4>
                                <h4 className="font-semibold text-sm mt-4 mb-3">Unsync and delete the product from your destination store.</h4>
                                <Button label="Disconnect and Delete" className="p-button-danger shadow-3 font-bold" onClick={handleDeleteStore} />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default StoneSearchStore;
