import React from 'react';
import { classNames } from 'primereact/utils';
import { Link, useNavigate } from 'react-router-dom';
import AppBreadcrumb from './AppBreadcrumb';
import AppMenu from './AppMenu';
import { RadioButton } from 'primereact/radiobutton';
import { handleLogout } from './redux/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import DiamondImage from './assets/logo-white.svg';
import ProfileImage from './assets/profile.jpg';
import { Button } from 'primereact/button';
const AppTopbar = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoggedIn } = useSelector((store) => store.auth);
    const { verifiedStoreData, storeData } = useSelector((store) => store.store);
    const profileItemClassName = classNames('profile-item', { 'active-menuitem fadeInDown': props.topbarUserMenuActive });

    const clearSessionLogOut = () => {
        dispatch(handleLogout());
        navigate('/login');
    };
    const getNavigationLink = () => {
        if (storeData && storeData.store_type === 'source') {
            return '/source-dashboard';
        } else {
            return '/destination-dashboard';
        }
    };
    return (
        <div className="layout-topbar">
            <div className="topbar-left">
                <button type="button" className="menu-button p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-chevron-left"></i>
                </button>

                {/* <Link 
                to={getNavigationLink()}
                >
                </Link> */}
                <img id="logo-horizontal" className="horizontal-logo" src={DiamondImage} alt="diamond-layout" />

                <span className="topbar-separator"></span>

                <div className="layout-breadcrumb viewname" style={{ textTransform: 'uppercase' }}>
                    <AppBreadcrumb meta={props.meta} />
                </div>

                <img id="logo-mobile" className="mobile-logo" src="assets/layout/images/logo-dark.svg" alt="diamond-layout" />
            </div>

            <AppMenu
                model={props.menu}
                menuMode={props.menuMode}
                active={props.menuActive}
                mobileMenuActive={props.staticMenuMobileActive}
                onMenuClick={props.onMenuClick}
                onMenuitemClick={props.onMenuitemClick}
                onRootMenuitemClick={props.onRootMenuitemClick}
            ></AppMenu>

            <div className="layout-mask modal-in"></div>

            <div className="topbar-right">
                {isLoggedIn ? <Button icon="pi pi-power-off" className="p-button-rounded p-button-rounded-outlined" tooltip="Logout" tooltipOptions={{ position: 'left' }} raised="true" onClick={clearSessionLogOut} /> : null}
                {/* <ul className="topbar-menu">
                    <li className={profileItemClassName}>
                        <button type="button" className="p-link" onClick={props.onTopbarUserMenu}>
                            <img src={ProfileImage} alt="diamond-layout" className="profile-image" />
                            <span className="profile-name">Amelia Stone</span>
                        </button>
                        <ul className="profile-menu fade-in-up">
                            <li>
                                <button type="button" className="p-link" onClick={clearSessionLogOut}>
                                    <i className="pi pi-power-off"></i>
                                    <span>Logout</span>
                                </button>
                            </li>
                        </ul>
                    </li>
                </ul> */}
            </div>
        </div>
    );
};

export default AppTopbar;
