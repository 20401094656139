import axios from 'axios';

export default class ProductService {
    getProductsSmall() {
        return axios.get('assets/demo/data/products-small.json').then((res) => res.data.data);
    }

    getProducts() {
        return axios.get('assets/demo/data/products.json').then((res) => res.data.data);
    }

    getProductsMixed() {
        return axios.get('assets/demo/data/products-mixed.json').then((res) => res.data.data);
    }

    getProductsWithOrdersSmall() {
        return axios.get('assets/demo/data/products-orders-small.json').then((res) => res.data.data);
    }
}

export const getProductList = async (queryString, config) => {
    return axios.get(`${process.env.REACT_APP_STONE_SHARE_URL}/product/list/?` + queryString, config);
};

export const productSyncApi = async (reqData, config) => {
    return axios.post(`${process.env.REACT_APP_STONE_SHARE_URL}/shopify_product_sync`, reqData, config);
};

export const productViewApi = async (userId, config) => {
    return await axios.get(`${process.env.REACT_APP_STONE_SHARE_URL}/product/view/${userId}`, config);
};

export const productMapSourceApi = async (userId, destinationStoreId, config) => {
    return await axios.get(`${process.env.REACT_APP_STONE_SHARE_URL}/source_product_map_view/${userId}?source_store_id=${destinationStoreId}`, config);
};

export const productViewSyncApi = async (reqData, config) => {
    return await axios.post(`${process.env.REACT_APP_STONE_SHARE_URL}/view_sync/`, reqData, config);
};

export const bulkSelectedProductSyncApi = async (reqData, config) => {
    return axios.post(`${process.env.REACT_APP_STONE_SHARE_URL}/get_shopify_product/`, reqData, config);
};

export const reSyncApi = async (reqData, config) => {
    return axios.post(`${process.env.REACT_APP_STONE_SHARE_URL}/product_resync_view/`, reqData, config);
};

export const productUnSyncApi = async (reqData, config) => {
    return axios.post(`${process.env.REACT_APP_STONE_SHARE_URL}/Unsync_product/`, reqData, config);
};

export const productMapDestinationApi = async (reqData, config) => {
    return axios.post(`${process.env.REACT_APP_STONE_SHARE_URL}/destination_product_search_map_view/`, reqData, config);
};

export const modifyProductMapDestinationApi = async (reqData, config) => {
    return axios.post(`${process.env.REACT_APP_STONE_SHARE_URL}/product_map_view/`, reqData, config);
};

export const productshopifyLogApi = async (queryString, config) => {
    return await axios.get(`${process.env.REACT_APP_STONE_SHARE_URL}/shopify_sync_log/?` + queryString, config);
};

export const bulkStatusApi = async (sourceStoreId, config) => {
    return await axios.get(`${process.env.REACT_APP_STONE_SHARE_URL}/product/status/count/?source_store_id=${sourceStoreId}`, config);
};
