import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import ProductService from '../service/ProductService';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardRequest } from '../redux/slices/dashboardSlice';
import { Link } from 'react-router-dom';
import { DashboardStoneSearchRequest, DashboardStoneShareRequest } from '../redux/slices/stoneSearchSlice/dashboardSlice';
import { Tag } from 'primereact/tag';
import moment from 'moment';
const ordersChart = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'New',
            data: [2, 7, 20, 9, 16, 9, 5],
            backgroundColor: ['rgba(100, 181, 246, 0.2)'],
            borderColor: ['#64B5F6'],
            borderWidth: 3,
            fill: true,
            tension: 0.4
        }
    ]
};

const ordersChartOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
        legend: {
            display: true
        }
    },
    responsive: true,
    hover: {
        mode: 'index'
    },
    scales: {
        y: {
            ticks: {
                min: 0,
                max: 20
            }
        }
    }
};

const revenueChart = {
    labels: ['Direct', 'Promoted', 'Affiliate'],
    datasets: [
        {
            data: [40, 35, 25],
            backgroundColor: ['#64B5F6', '#7986CB', '#4DB6AC']
        }
    ]
};

const Dashboard = () => {
    const dispatch = useDispatch();
    const moment = require('moment');
    const { dashboardData } = useSelector((state) => state.dashboardStore);
    const { isLoggedIn } = useSelector((state) => state.auth);
    const { dashboardCountData, dashboardStoneShareCountData } = useSelector((state) => state.stoneSearch);

    const orderWeek = [
        { name: 'This Week', code: '0' },
        { name: 'Last Week', code: '1' }
    ];

    const [selectedOrderWeek, setSelectedOrderWeek] = useState(orderWeek[0]);
    const [products, setProducts] = useState(null);
    const [productsThisWeek, setProductsThisWeek] = useState(null);
    const [productsLastWeek, setProductsLastWeek] = useState(null);

    const items = [
        {
            label: 'Shipments',
            items: [
                { label: 'Tracker', icon: 'pi pi-compass' },
                { label: 'Map', icon: 'pi pi-map-marker' },
                { label: 'Manage', icon: 'pi pi-pencil' }
            ]
        }
    ];

    const menuRef = useRef(null);
    const chartRef = useRef(null);

    // useEffect(() => {
    //     const productService = new ProductService();
    //     productService.getProducts().then((data) => setProducts(data));
    //     productService.getProducts().then((data) => setProductsThisWeek(data));
    //     productService.getProductsMixed().then((data) => setProductsLastWeek(data));
    // }, []);

    useEffect(() => {
        // dispatch(DashboardRequest());
        if (isLoggedIn) {
            dispatch(DashboardStoneSearchRequest());
        } else {
            dispatch(DashboardStoneShareRequest());
        }
    }, []);

    const changeDataset = (event) => {
        const dataSet = [
            [2, 7, 20, 9, 16, 9, 5],
            [2, 4, 9, 20, 16, 12, 20],
            [2, 17, 7, 15, 4, 20, 8],
            [2, 2, 20, 4, 17, 16, 20]
        ];

        ordersChart.datasets[0].data = dataSet[parseInt(event.currentTarget.getAttribute('data-index'))];
        ordersChart.datasets[0].label = event.currentTarget.getAttribute('data-label');
        ordersChart.datasets[0].borderColor = event.currentTarget.getAttribute('data-stroke');
        ordersChart.datasets[0].backgroundColor = event.currentTarget.getAttribute('data-fill');
    };

    const recentSales = (event) => {
        if (event.value.code === '0') {
            setProducts(productsThisWeek);
        } else {
            setProducts(productsLastWeek);
        }

        setSelectedOrderWeek(event.value);
    };

    const menuToggle = (event) => {
        menuRef.current.toggle(event);
    };

    const refreshDataset = () => {
        chartRef.current.refresh();
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });
    };

    const storeNameTemplate = (data) => {
        return (
            <>
                <p className="capitalize">{data?.store_name}</p>
            </>
        );
    };
    const stoneShareNameTemplate = (data) => {
        return (
            <>
                <p className="capitalize">{data?.destination_store_name}</p>
            </>
        );
    };
    const storeNameHistoryTemplate = (data) => {
        return (
            <>
                <p className="capitalize">{data?.store_name}</p>
            </>
        );
    };
    const storeLogUrlTemplate = (data) => {
        return <a href={data?.file}>{data?.file}</a>;
    };
    const usernameBodyTemplate = (rowData) => {
        return <>{rowData?.created_by_id__username}</>;
    };
    const dateBodyTemplate = (data) => {
        let formattedDate = moment(data.created_at).format('DD-MM-YY');
        return (
            <>
                <p style={{ fontWeight: 'normal' }} className="capitalize">
                    {formattedDate}
                </p>
            </>
        );
    };

    const storeUrlTemplate = (data) => {
        return (
            <>
                <a href={data?.store_url} target="_blank">
                    {data?.store_url}
                    {/* <Button icon="pi pi-external-link" className='p-button-rounded p-button-text' severity="danger" aria-label="Cancel" /> */}
                </a>
            </>
        );
    };
    const stoneShareUrlTemplate = (data) => {
        return (
            <>
                <a href={data?.destination_store_url} target="_blank">
                    {data?.destination_store_url}
                    {/* <Button icon="pi pi-external-link" className='p-button-rounded p-button-text' severity="danger" aria-label="Cancel" /> */}
                </a>
            </>
        );
    };
    const getSeverity = (status) => {
        switch (status) {
            case 'decline':
                return 'danger';

            case 'approve':
                return 'success';
            case 'pending':
                return 'info';
            case 'renewal':
                return null;
        }
    };
    const getSeverityStatus = (status) => {
        switch (status) {
            case 'decline':
                return 'danger';

            case 'success':
                return 'success';
            case 'pending':
                return 'info';
            case 'error':
                return 'danger';
            case 'renewal':
                return null;
        }
    };
    const statusStoreTemplate = (rowData) => {
        return <Tag value={rowData.status} className="capitalize w-4rem" severity={getSeverityStatus(rowData.status)} />;
    };

    const onOrderTabClick = (event) => {
        changeDataset(event);
        refreshDataset(event);
    };

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };
    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className={`customer-badge status-${rowData?.status == 'success' ? 'qualified' : rowData?.status == 'pending' ? 'proposal' : 'unqualified'}`}>{rowData?.status}</span>
            </>
        );
    };

    const priceBodyTemplate = (data) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {formatCurrency(data.price)}
            </>
        );
    };

    return (
        <div className="layout-dashboard">
            <div className="grid">
                <div className="col-12 md:col-6 ">
                    <div className="card grid-nogutter widget-overview-box widget-overview-box-2">
                        <span className="overview-icon">
                            <i className="far fa-gem"></i>
                        </span>
                        <span className="overview-title">Stone Search Products</span>
                        <div className="grid overview-detail">
                            <div className="col-6">
                                <div className="overview-number">{dashboardCountData?.product_count == undefined ? 0 : dashboardCountData?.product_count}</div>
                                <div className="overview-subtext">Total Products</div>
                            </div>
                            <div className="col-6">
                                <div className="overview-number">{dashboardCountData?.order_count == undefined ? 0 : dashboardCountData?.order_count}</div>
                                <div className="overview-subtext">Total Orders</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 md:col-6 ">
                    <div className="card grid-nogutter widget-overview-box widget-overview-box-2">
                        <span className="overview-icon">
                            <i className="far fa-gem"></i>
                        </span>
                        <span className="overview-title">Stone Search Stores</span>
                        <div className="grid overview-detail">
                            <div className="col-6">
                                <div className="overview-number">{dashboardCountData?.total_store_count == undefined ? 0 : dashboardCountData?.total_store_count}</div>
                                <div className="overview-subtext">Total Stores</div>
                            </div>
                            <div className="col-6">
                                <div className="overview-number">{dashboardCountData?.store_count == undefined ? 0 : dashboardCountData?.store_count}</div>
                                <div className="overview-subtext text-sm">Total Installed Stores</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-12 md:col-6 xl:col-3">
                    <div className="card grid-nogutter widget-overview-box widget-overview-box-1">
                        <span className="overview-icon">
                            <i className="pi pi-sync"></i>
                        </span>
                        <span className="overview-title">Stone Share</span>
                        <div className="grid flex justify-content-center card-margin-top">
                            <div className="col-6 border-none flex flex-column justify-content-center">
                                <div className="overview-number flex justify-content-center align-items-center">{dashboardStoneShareCountData?.total_product == undefined ? 0 : dashboardStoneShareCountData?.total_product}</div>
                                <div className="overview-subtext mx-auto">Total Products</div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="col-12 md:col-6 xl:col-3">
                    <div className="card grid-nogutter widget-overview-box widget-overview-box-1">
                        <span className="overview-icon">
                            <i className="pi pi-sync"></i>
                        </span>
                        <span className="overview-title">Stone Share</span>
                        <div className="grid card-margin-top flex  justify-content-center">
                            <div className="col-8 border-none ">
                                <div className="overview-number flex  justify-content-center">{dashboardStoneShareCountData?.installed_store == undefined ? 0 : dashboardStoneShareCountData?.installed_store}</div>
                                <div className="overview-subtext flex justify-content-center">Total Installed Store</div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="col-12 lg:col-6 xl:col-6">
                    <div
                        className=" card"
                        // style={{ height: '27.2rem' }}
                        style={{ height: '35rem' }}
                    >
                        <div className="card-header">
                            <h4 className="my-2">Recent Stone Search Stores</h4>
                            <Link to={'/ds-store'}>
                                <Button label="View All" icon="pi pi-list" className="p-button-rounded p-button-outlined my-2" tooltip="View all stores" tooltipOptions={{ position: 'top' }} />
                            </Link>
                        </div>

                        <DataTable value={dashboardCountData?.install_store} removableSort stripedRows key="id" className="p-datatable-products">
                            <Column field="store_name" className="font-bold" sortable header="Store Name" style={{ color: '#000000' }} body={storeNameTemplate}></Column>
                            <Column field="store_url" className="font-bold" header="Store URL" style={{ color: '#000000' }} body={storeUrlTemplate}></Column>
                            {/* <Column field="store_status" className="font-bold" header="Store Status" style={{ color: '#000000' }} body={statusTemplate}></Column> */}
                        </DataTable>
                    </div>
                </div>
                {/* <div className="col-12 lg:col-6 xl:col-6">
                    <div className=" card" style={{ height: '27.2rem' }}>
                        <div className="card-header">
                            <h4 className="my-2">Recent Stone Share Destination Stores</h4>
                            <Link to={'/store'}>
                                <Button label="View All" icon="pi pi-list" className="p-button-rounded p-button-outlined my-2" tooltip="View all stores" tooltipOptions={{ position: 'top' }} />
                            </Link>
                        </div>

                        <DataTable value={dashboardStoneShareCountData?.destination_store} removableSort stripedRows key="id" className="p-datatable-products">
                            <Column field="destination_store_name" sortable className="font-bold" header="Store Name" style={{ color: '#000000' }} body={stoneShareNameTemplate}></Column>
                            <Column field="destination_store_url" className="font-bold" header="Store URL" style={{ color: '#000000' }} body={stoneShareUrlTemplate}></Column>
                            <Column field="store_status" className="font-bold" header="Store Status" style={{ color: '#000000' }} body={statusTemplate}></Column>
                        </DataTable>
                    </div>
                </div> */}
                {/* <div className="col-12 lg:col-6 xl:col-6">
                    <div className=" card" style={{ height: '40.2rem' }}>
                        <div className="card-header">
                            <h4 className="my-2">Stone Share Shopify Sync Log</h4>
                            <Link to={'/sync-shopifySyncLog'}>
                                <Button label="View All" icon="pi pi-list" className="p-button-rounded p-button-outlined my-2" tooltip="View all stores" tooltipOptions={{ position: 'top' }} />
                            </Link>
                        </div>

                        <DataTable value={dashboardStoneShareCountData?.sync_log} removableSort stripedRows key="id" className="p-datatable-products ">
                            <Column field="datetime" header="Date" sortable body={dateBodyTemplate}></Column>
                            <Column field="created_by_id__username" header="UserName" style={{ minWidth: '200px' }} body={usernameBodyTemplate}></Column>
                            <Column field="status" header="Status" body={statusBodyTemplate}></Column>
                        </DataTable>
                    </div>
                </div> */}
                <div className="col-12 lg:col-6 xl:col-6">
                    <div className=" card " style={{ height: '35rem' }}>
                        <div className="card-header">
                            <h4 className="my-2">Latest Excel Log History</h4>
                            <Link to={'/ds-product-log'}>
                                <Button label="View All" icon="pi pi-list" className="p-button-rounded p-button-outlined my-2" tooltip="View All Excel Log" tooltipOptions={{ position: 'top' }} />
                            </Link>
                        </div>

                        <DataTable value={dashboardCountData?.product_log} removableSort stripedRows key="id" className="p-datatable-products " responsiveLayout="scroll">
                            <Column field="date" className="font-bold" header="Date" style={{ color: '#000000', minWidth: '100px', width: '100px' }} body={dateBodyTemplate}></Column>
                            <Column field="file" className="font-bold " header="File Url" body={storeLogUrlTemplate}></Column>
                            <Column field="status" className="font-bold" header="Status" body={statusStoreTemplate}></Column>
                        </DataTable>
                    </div>
                </div>

                {/* <div className="col-12 md:col-6 xl:col-6">
                    <div className="card grid-nogutter widget-overview-box widget-overview-box-3">
                        <span className="overview-icon">
                            <i className="pi pi-users"></i>
                        </span>
                        <span className="overview-title">Customers</span>
                        <div className="grid overview-detail">
                            <div className="col-6">
                                <div className="overview-number">8272</div>
                                <div className="overview-subtext">Active</div>
                            </div>
                            <div className="col-6">
                                <div className="overview-number">25402</div>
                                <div className="overview-subtext">Registered</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-6 xl:col-6">
                    <div className="card grid-nogutter widget-overview-box widget-overview-box-4">
                        <span className="overview-icon">
                            <i className="pi pi-comment"></i>
                        </span>
                        <span className="overview-title">Comments</span>
                        <div className="grid overview-detail">
                            <div className="col-6">
                                <div className="overview-number">12</div>
                                <div className="overview-subtext">New</div>
                            </div>
                            <div className="col-6">
                                <div className="overview-number">85</div>
                                <div className="overview-subtext">Responded</div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="col-12 xl:col-6">
                    <div className="card grid-nogutter orders">
                        <div className="card-header">
                            <h4>Orders</h4>
                            <Menu id="orders-button" popup model={items} ref={menuRef} appendTo={document.body}></Menu>
                            <Button type="button" icon="pi pi-search" className="p-button-text p-button-secondary" onClick={menuToggle} aria-controls="orders-button" aria-haspopup="true"></Button>
                        </div>

                        <div className="grid">
                            <div className="col-12">
                                <div id="order-tabs-container" className="grid order-tabs">
                                    <div className="order-tab order-tab-new col-6 md:col-3" onClick={onOrderTabClick} data-label="New Orders" data-index="0" data-stroke="#BBDEFB" data-fill="rgba(100, 181, 246, 0.2)">
                                        <i className="pi pi-plus-circle"></i>
                                        <span className="order-label">New</span>
                                        <i className="stat-detail-icon icon-arrow-right-circle"></i>
                                        <img src="assets/demo/images/dashboard/graph-new.svg" alt="order" />
                                    </div>
                                    <div className="order-tab order-tab-completed col-6 md:col-3" onClick={onOrderTabClick} data-label="Completed Orders" data-index="1" data-stroke="#C5CAE9" data-fill="rgba(121, 134, 203, 0.2)">
                                        <i className="pi pi-check-circle"></i>
                                        <span className="order-label">Completed</span>
                                        <i className="stat-detail-icon icon-arrow-right-circle"></i>
                                        <img src="assets/demo/images/dashboard/graph-completed.svg" alt="order" />
                                    </div>
                                    <div className="order-tab order-tab-refunded col-6 md:col-3" onClick={onOrderTabClick} data-label="Refunded Orders" data-index="2" data-stroke="#B2DFDB" data-fill="rgba(224, 242, 241, 0.5)">
                                        <i className="pi pi-refresh"></i>
                                        <span className="order-label">Refunded</span>
                                        <i className="stat-detail-icon icon-arrow-right-circle"></i>
                                        <img src="assets/demo/images/dashboard/graph-refunded.svg" alt="order" />
                                    </div>
                                    <div className="order-tab order-tab-cancelled col-6 md:col-3" onClick={onOrderTabClick} data-label="Cancelled Orders" data-index="3" data-stroke="#B2EBF2" data-fill="rgba(224, 247, 250, 0.5)">
                                        <i className="pi pi-times-circle"></i>
                                        <span className="order-label">Cancelled</span>
                                        <i className="stat-detail-icon icon-arrow-right-circle"></i>
                                        <img src="assets/demo/images/dashboard/graph-cancelled.svg" alt="order" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="overview-chart">
                                    <Chart ref={chartRef} type="line" data={ordersChart} options={ordersChartOptions} id="order-chart"></Chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 xl:col-6">
                    <div className="card">
                        <div className="card-header">
                            <h4>Recent Sales</h4>
                            <Dropdown options={orderWeek} value={selectedOrderWeek} optionLabel="name" onChange={recentSales} className="dashbard-demo-dropdown"></Dropdown>
                        </div>

                        <p>Your sales activity over time.</p>

                        <DataTable value={products} paginator rows={5} className="p-datatable-products">
                            <Column field="id" header="ID" sortable body={bodyTemplate}></Column>
                            <Column field="category" header="Category" sortable body={bodyTemplate}></Column>
                            <Column field="price" header="Price" sortable body={priceBodyTemplate}></Column>
                            <Column field="inventoryStatus" header="Status" sortable body={statusBodyTemplate}></Column>
                            <Column bodyStyle={{ textAlign: 'center', justifyContent: 'center' }} body={() => <Button type="button" icon="pi pi-search"></Button>}></Column>
                        </DataTable>
                    </div>
                </div>

                <div className="col-12 lg:col-4">
                    <div className="card widget-tasks">
                        <h4>Tasks</h4>
                        <p>Overview of your pending tasks.</p>
                        <div>
                            <div className="task task-1">
                                <div className="task-name">
                                    <span>12 Orders</span> to fulfill
                                </div>
                                <div className="task-progress">
                                    <div></div>
                                </div>
                            </div>
                            <div className="task task-2">
                                <div className="task-name">
                                    <span>40+ Payments</span> to withdraw
                                </div>
                                <div className="task-progress">
                                    <div></div>
                                </div>
                            </div>
                            <div className="task task-3">
                                <div className="task-name">
                                    <span>4 Reports</span> to revise
                                </div>
                                <div className="task-progress">
                                    <div></div>
                                </div>
                            </div>
                            <div className="task task-4">
                                <div className="task-name">
                                    <span>6 Questions</span> to respond
                                </div>
                                <div className="task-progress">
                                    <div></div>
                                </div>
                            </div>
                            <div className="task task-5">
                                <div className="task-name">
                                    <span>2 Chargebacks</span> to review
                                </div>
                                <div className="task-progress">
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <h4>Best Sellers</h4>
                        <p>Most popular products from your collection.</p>

                        <ul className="widget-image-list">
                            <li>
                                <span>Product</span>
                                <span>Sales</span>
                            </li>
                            <li>
                                <span>
                                    <img src="assets/demo/images/product/bamboo-watch.jpg" alt="diamond-layout" />
                                    <span>Bamboo Watch</span>
                                </span>
                                <span className="listitem-value">82</span>
                            </li>
                            <li>
                                <span>
                                    <img src="assets/demo/images/product/blue-band.jpg" alt="diamond-layout" />
                                    <span>Blue Band</span>
                                </span>
                                <span className="listitem-value">75</span>
                            </li>
                            <li>
                                <span>
                                    <img src="assets/demo/images/product/game-controller.jpg" alt="diamond-layout" />
                                    <span>Game Controller</span>
                                </span>
                                <span className="listitem-value">64</span>
                            </li>
                            <li>
                                <span>
                                    <img src="assets/demo/images/product/lime-band.jpg" alt="diamond-layout" />
                                    <span>Lime Band</span>
                                </span>
                                <span className="listitem-value">62</span>
                            </li>
                            <li>
                                <span>
                                    <img src="assets/demo/images/product/gold-phone-case.jpg" alt="diamond-layout" />
                                    <span>Phone Case</span>
                                </span>
                                <span className="listitem-value">55</span>
                            </li>
                            <li>
                                <span>
                                    <img src="assets/demo/images/product/green-t-shirt.jpg" alt="diamond-layout" />
                                    <span>Green T-Shirt</span>
                                </span>
                                <span className="listitem-value">48</span>
                            </li>
                            <li>
                                <span>
                                    <img src="assets/demo/images/product/purple-t-shirt.jpg" alt="diamond-layout" />
                                    <span>Purple T-Shirt</span>
                                </span>
                                <span className="listitem-value">32</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-12 lg:col-8">
                    <div className="card revenue">
                        <h4>Revenue Stream</h4>
                        <p>Comparison of your revenue sources.</p>
                        <div className="revenue-chart-container">
                            <div className="flex justify-content-center">
                                <Chart style={{ position: 'relative', width: '50%' }} type="pie" id="revenue-chart" data={revenueChart}></Chart>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <h4>Team Members</h4>
                        <ul className="widget-person-list">
                            <li>
                                <div className="person-item">
                                    <img src="assets/demo/images/avatar/amyelsner.png" alt="diamond-layout" />
                                    <div className="person-info">
                                        <div className="person-name">Amy Elsner</div>
                                        <div className="person-subtext">Accounting</div>
                                    </div>
                                </div>
                                <div className="person-actions">
                                    <Button type="button" className="p-button-rounded p-button-success" icon="pi pi-envelope"></Button>
                                    <Button type="button" className="p-button-rounded p-button-warning" icon="pi pi-cog"></Button>
                                </div>
                            </li>
                            <li>
                                <div className="person-item">
                                    <img src="assets/demo/images/avatar/annafali.png" alt="diamond-layout" />
                                    <div className="person-info">
                                        <div className="person-name">Anna Fali</div>
                                        <div className="person-subtext">Procurement</div>
                                    </div>
                                </div>
                                <div className="person-actions">
                                    <Button type="button" className="p-button-rounded p-button-success" icon="pi pi-envelope"></Button>
                                    <Button type="button" className="p-button-rounded p-button-warning" icon="pi pi-cog"></Button>
                                </div>
                            </li>
                            <li>
                                <div className="person-item">
                                    <img src="assets/demo/images/avatar/bernardodominic.png" alt="diamond-layout" />
                                    <div className="person-info">
                                        <div className="person-name">Bernardo Dominic</div>
                                        <div className="person-subtext">Finance</div>
                                    </div>
                                </div>
                                <div className="person-actions">
                                    <Button type="button" className="p-button-rounded p-button-success" icon="pi pi-envelope"></Button>
                                    <Button type="button" className="p-button-rounded p-button-warning" icon="pi pi-cog"></Button>
                                </div>
                            </li>
                            <li>
                                <div className="person-item">
                                    <img src="assets/demo/images/avatar/ivanmagalhaes.png" alt="diamond-layout" />
                                    <div className="person-info">
                                        <div className="person-name">Ivan Magalhaes</div>
                                        <div className="person-subtext">Sales</div>
                                    </div>
                                </div>
                                <div className="person-actions">
                                    <Button type="button" className="p-button-rounded p-button-success" icon="pi pi-envelope"></Button>
                                    <Button type="button" className="p-button-rounded p-button-warning" icon="pi pi-cog"></Button>
                                </div>
                            </li>
                            <li>
                                <div className="person-item">
                                    <img src="assets/demo/images/avatar/xuxuefeng.png" alt="diamond-layout" />
                                    <div className="person-info">
                                        <div className="person-name">Xuxue Feng</div>
                                        <div className="person-subtext">Management</div>
                                    </div>
                                </div>
                                <div className="person-actions">
                                    <Button type="button" className="p-button-rounded p-button-success" icon="pi pi-envelope"></Button>
                                    <Button type="button" className="p-button-rounded p-button-warning" icon="pi pi-cog"></Button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default Dashboard;
