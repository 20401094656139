import React, { useEffect, useState } from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useDispatch, useSelector } from 'react-redux';
import { addFtpClientRequest, ftpClientListRequest, ftpClientResetData, modifyFtpClientRequest, removeFtpClientRequest, viewFtpClientRequest } from '../../../redux/slices/kushDiamond/ftpSlice';
import { Paginator } from 'primereact/paginator';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';

const FtpClientList = () => {
    const dispatch = useDispatch();
    const { isLoading, ftpClientData, addFtpClientMessage, viewFtpClientData, modifyFtpClientMessage, removeFtpClientMessage, error: addFtpError, error: modifyFtpClientError } = useSelector((state) => state.ftp);

    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(25);
    const [sort_order, setSort_order] = useState('desc');
    const [ftpDialogModal, setFtpDialogModal] = useState({
        addFtpModal: false,
        modifyFtpModal: false,
        removeFtpModal: false
    });
    const [ftpformValue, setFtpFormValue] = useState({
        client_name: '',
        protocol: undefined,
        port: undefined,
        hostname: '',
        username: '',
        password: '',
        folder_path: ''
    });
    const [ftpModifyformValue, setFtpModifyFormValue] = useState({
        client_name: '',
        protocol: undefined,
        port: undefined,
        hostname: '',
        username: '',
        password: '',
        folder_path: ''
    });
    const [submitForm, setSubmitForm] = useState({
        ftpSubmit: false,
        ftpModifySubmit: false
    });
    const [removeFtpClientId, setremoveFtpClientId] = useState(0);

    const protocolItems = [
        { label: 'FTP', value: 'FTP' },
        { label: 'SFTP', value: 'SFTP' }
    ];

    const portItems = [
        { label: '21', value: 21 },
        { label: '22', value: 22 }
    ];

    const table_data = {
        page: 1,
        per_page: 25,
        sort_order: sort_order,
        order_column: 'created_at'
    };

    const [queryString, setQueryString] = useState(`page=${table_data.page}&per_page=${table_data.per_page}&order_column=${table_data.order_column}`);

    useEffect(() => {
        dispatch(ftpClientListRequest(queryString));
        return () => {
            dispatch(ftpClientResetData());
        };
    }, [dispatch, queryString]);

    useEffect(() => {
        if (addFtpClientMessage !== null) {
            hideDialog();
            dispatch(ftpClientResetData());
            dispatch(ftpClientListRequest(queryString));
        }

        setFtpModifyFormValue({
            client_name: viewFtpClientData?.client_name,
            protocol: viewFtpClientData?.protocol,
            port: viewFtpClientData?.port,
            hostname: viewFtpClientData?.hostname,
            username: viewFtpClientData?.username,
            password: viewFtpClientData?.password,
            folder_path: viewFtpClientData?.folder_path
        });

        if (modifyFtpClientMessage !== null) {
            hideModifyDialog();
            dispatch(ftpClientResetData());
            dispatch(ftpClientListRequest(queryString));
        }
        if (removeFtpClientMessage !== null) {
            dispatch(ftpClientListRequest(queryString));
        }
    }, [addFtpClientMessage, viewFtpClientData, modifyFtpClientMessage, removeFtpClientMessage]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        tableChangeHandler({ ...table_data, page: event.page + 1, per_page: event.rows });
    };

    const tableChangeHandler = (data) => {
        let queryStr = Object.keys(data)
            .map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
            })
            .join('&');
        setQueryString(queryStr);
    };

    const template = {
        layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    const modifyFtpClientSubmit = (id) => {
        dispatch(viewFtpClientRequest(id));
        setFtpDialogModal({ ...ftpDialogModal, modifyFtpModal: true });
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex">
                <Button className="p-button-text p-button-success mr-2" style={{ height: '25px', width: '25px' }} icon="pi pi-pencil" onClick={() => modifyFtpClientSubmit(rowData?.id)}></Button>
                <Button
                    className="p-button-text p-button-danger"
                    style={{ height: '25px', width: '25px' }}
                    icon="pi pi-trash"
                    onClick={() => {
                        setremoveFtpClientId(rowData?.id);
                        setFtpDialogModal({ ...ftpDialogModal, removeFtpModal: true });
                    }}
                ></Button>
            </div>
        );
    };

    const header = (
        <div className="flex justify-content-end my-1">
            <Button label="Create FTP" icon="pi pi-plus" onClick={() => setFtpDialogModal({ ...ftpDialogModal, addFtpModal: true })} />
        </div>
    );

    const hideDialog = () => {
        setFtpDialogModal({ ...ftpDialogModal, addFtpModal: false });
        resetFtpFormValue();
        setSubmitForm({ ...submitForm, ftpSubmit: false });
        dispatch(ftpClientResetData());
    };

    const hideModifyDialog = () => {
        setFtpDialogModal({ ...ftpDialogModal, modifyFtpModal: false });
        resetFtpModifyFormValue();
        setSubmitForm({ ...submitForm, ftpModifySubmit: false });
        dispatch(ftpClientResetData());
    };

    const onInputChange = (e) => {
        setFtpFormValue({ ...ftpformValue, [e.target.name]: e.target.value });
    };

    const onModifyInputChange = (e) => {
        setFtpModifyFormValue({ ...ftpModifyformValue, [e.target.name]: e.target.value });
    };

    const resetFtpFormValue = () => {
        setFtpFormValue({
            client_name: '',
            protocol: undefined,
            port: undefined,
            hostname: '',
            username: '',
            password: '',
            folder_path: ''
        });
    };

    const resetFtpModifyFormValue = () => {
        setFtpModifyFormValue({
            client_name: '',
            protocol: undefined,
            port: undefined,
            hostname: '',
            username: '',
            password: '',
            folder_path: ''
        });
    };

    const handleSubmit = () => {
        setSubmitForm({ ...submitForm, ftpSubmit: true });
        const { client_name, protocol, port, hostname, username, password, folder_path } = ftpformValue;
        if (client_name !== '' && protocol !== undefined && port !== undefined && hostname !== '' && username !== '' && password !== '' && folder_path !== '') {
            dispatch(addFtpClientRequest(ftpformValue));
        }
    };

    const handleFtpModifySubmit = () => {
        setSubmitForm({ ...submitForm, ftpModifySubmit: true });
        const { client_name, protocol, port, hostname, username, password, folder_path } = ftpModifyformValue;
        if (client_name !== '' && protocol !== undefined && port !== undefined && hostname !== '' && username !== '' && password !== '' && folder_path !== '') {
            dispatch(modifyFtpClientRequest(viewFtpClientData?.id, ftpModifyformValue));
        }
    };

    const ftpDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-secondary p-button-outlined" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={handleSubmit} />
        </>
    );

    const ftpModifyDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-secondary p-button-outlined" onClick={hideModifyDialog} />
            <Button label="Save" icon="pi pi-check" onClick={handleFtpModifySubmit} />
        </>
    );

    const removeFtpRecord = () => {
        dispatch(removeFtpClientRequest(removeFtpClientId));
        setFtpDialogModal({ ...ftpDialogModal, removeFtpModal: false });
        setremoveFtpClientId(0);
        dispatch(ftpClientResetData());
    };

    const deleteFtpDialogFooter = (
        <>
            <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-outlined p-button-danger"
                onClick={() => {
                    setFtpDialogModal({ ...ftpDialogModal, removeFtpModal: false });
                    setremoveFtpClientId(0);
                }}
            />
            <Button label="Yes" icon="pi pi-check" className="p-button-outlined ml-2" onClick={removeFtpRecord} />
        </>
    );

    const breadcrumbItems = [{ label: 'Kush Diamond' }, { label: 'FTP List' }];
    const dynamicHeight = Math.min(window.innerHeight * 4 + 1, 70) + 'vh';
    return (
        <>
            <div className="col px-0 pt-0 pb-2">
                <div className="breadcrumb-container flex justify-content-between align-items-center">
                    <div className="text-2xl font-bold">
                        <h3 className="m-0">FTP List</h3>
                    </div>
                    <div className="ml-6 text-2xl">
                        <BreadCrumb home={{ icon: 'pi pi-home', url: '/' }} model={breadcrumbItems} />
                    </div>
                </div>
            </div>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <Dialog visible={ftpDialogModal?.addFtpModal} style={{ width: '50vw' }} header="Create FTP" modal className="p-fluid" footer={ftpDialogFooter} onHide={hideDialog}>
                            <div className="grid p-3">
                                <div className="col-6 py-0">
                                    <div className="field">
                                        <label htmlFor="client_name" className="font-bold">
                                            Client Name
                                        </label>
                                        <InputText name="client_name" autoFocus placeholder="Enter Your Client Name" value={ftpformValue?.client_name} onChange={(e) => onInputChange(e)} className="mb-2" />
                                        {(submitForm?.ftpSubmit && ftpformValue?.client_name == '') || (addFtpError && addFtpError?.client_name) ? (
                                            <small className="p-error text-lg">{(submitForm?.ftpSubmit && ftpformValue?.client_name == '' && 'Client Name is required.') || addFtpError?.client_name}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-6 py-0">
                                    <div className="field">
                                        <label htmlFor="protocol" className="font-bold">
                                            Protocol
                                        </label>
                                        <Dropdown
                                            className="mb-2"
                                            options={protocolItems}
                                            value={ftpformValue?.protocol}
                                            onChange={(e) => setFtpFormValue({ ...ftpformValue, protocol: e.value })}
                                            optionLabel="label"
                                            showClear
                                            placeholder="Select Protocol"
                                        />
                                        {(submitForm?.ftpSubmit && ftpformValue?.protocol == undefined) || (addFtpError && addFtpError?.protocol) ? (
                                            <small className="p-error text-lg">{(submitForm?.ftpSubmit && ftpformValue?.protocol == undefined && 'Protocol is required.') || addFtpError?.protocol}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-6 py-0">
                                    <div className="field">
                                        <label htmlFor="port" className="font-bold">
                                            Port
                                        </label>
                                        <Dropdown className="mb-2" options={portItems} value={ftpformValue?.port} onChange={(e) => setFtpFormValue({ ...ftpformValue, port: e.value })} showClear optionLabel="label" placeholder="Select Port" />
                                        {(submitForm?.ftpSubmit && ftpformValue?.port == undefined) || (addFtpError && addFtpError?.port) ? (
                                            <small className="p-error text-lg">{(submitForm?.ftpSubmit && ftpformValue?.port == undefined && 'Port is required.') || addFtpError?.port}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-6 py-0">
                                    <div className="field">
                                        <label htmlFor="hostname" className="font-bold">
                                            Host Name
                                        </label>
                                        <InputText name="hostname" autoFocus placeholder="Enter Your Host Name" value={ftpformValue?.hostname} onChange={(e) => onInputChange(e)} className="mb-2" />
                                        {(submitForm?.ftpSubmit && ftpformValue?.hostname == '') || (addFtpError && addFtpError?.hostname) ? (
                                            <small className="p-error text-lg">{(submitForm?.ftpSubmit && ftpformValue?.hostname == '' && 'Hostname is required.') || addFtpError?.hostname}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-6 py-0">
                                    <div className="field">
                                        <label htmlFor="username" className="font-bold">
                                            User Name
                                        </label>
                                        <InputText name="username" autoFocus placeholder="Enter Your User Name" value={ftpformValue?.username} onChange={(e) => onInputChange(e)} className="mb-2" />
                                        {(submitForm?.ftpSubmit && ftpformValue?.username == '') || (addFtpError && addFtpError?.username) ? (
                                            <small className="p-error text-lg">{(submitForm?.ftpSubmit && ftpformValue?.username == '' && 'Username is required.') || addFtpError?.username}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-6 py-0">
                                    <div className="field">
                                        <label htmlFor="password" className="font-bold">
                                            Password
                                        </label>
                                        <Password name="password" placeholder="Enter Your Password" toggleMask feedback={false} value={ftpformValue?.password} onChange={(e) => onInputChange(e)} className="mb-2" />
                                        {(submitForm?.ftpSubmit && ftpformValue?.password == '') || (addFtpError && addFtpError?.password) ? (
                                            <small className="p-error text-lg">{(submitForm?.ftpSubmit && ftpformValue?.password == '' && 'Password is required.') || addFtpError?.password}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-6 py-0">
                                    <div className="field">
                                        <label htmlFor="folder_path" className="font-bold">
                                            Folder Path
                                        </label>
                                        <InputText name="folder_path" placeholder="Enter Your Folder Path" value={ftpformValue?.folder_path} onChange={(e) => onInputChange(e)} className="mb-2" />
                                        {(submitForm?.ftpSubmit && ftpformValue?.folder_path == '') || (addFtpError && addFtpError?.folder_path) ? (
                                            <small className="p-error text-lg">{(submitForm?.ftpSubmit && ftpformValue?.folder_path == '' && 'Folder path is required.') || addFtpError?.folder_path}</small>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                        <Dialog visible={ftpDialogModal?.modifyFtpModal} style={{ width: '50vw' }} header="Update FTP" modal className="p-fluid" footer={ftpModifyDialogFooter} onHide={hideModifyDialog}>
                            {viewFtpClientData == null ? (
                                <>
                                    <i className="pi pi-spin pi-spinner flex justify-content-center align-items-center" style={{ fontSize: '3rem' }}></i>
                                </>
                            ) : (
                                <div className="grid p-3">
                                    <div className="col-6 py-0">
                                        <div className="field">
                                            <label htmlFor="client_name" className="font-bold">
                                                Client Name
                                            </label>
                                            <InputText name="client_name" autoFocus placeholder="Enter Your Client Name" value={ftpModifyformValue?.client_name} onChange={(e) => onModifyInputChange(e)} className="mb-2" />
                                            {(submitForm?.ftpModifySubmit && ftpModifyformValue?.client_name == '') || (modifyFtpClientError && modifyFtpClientError?.client_name) ? (
                                                <small className="p-error text-lg">{(submitForm?.ftpModifySubmit && ftpModifyformValue?.client_name == '' && 'Client Name is required.') || modifyFtpClientError?.client_name}</small>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-6 py-0">
                                        <div className="field">
                                            <label htmlFor="protocol" className="font-bold">
                                                Protocol
                                            </label>
                                            <Dropdown
                                                className="mb-2"
                                                options={protocolItems}
                                                value={ftpModifyformValue?.protocol}
                                                onChange={(e) => setFtpModifyFormValue({ ...ftpModifyformValue, protocol: e.value })}
                                                optionLabel="label"
                                                showClear
                                                placeholder="Select Protocol"
                                            />
                                            {(submitForm?.ftpModifySubmit && ftpModifyformValue?.protocol == undefined) || (modifyFtpClientError && modifyFtpClientError?.protocol) ? (
                                                <small className="p-error text-lg">{(submitForm?.ftpModifySubmit && ftpModifyformValue?.protocol == undefined && 'Protocol is required.') || modifyFtpClientError?.protocol}</small>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-6 py-0">
                                        <div className="field">
                                            <label htmlFor="port" className="font-bold">
                                                Port
                                            </label>
                                            <Dropdown
                                                className="mb-2"
                                                options={portItems}
                                                value={ftpModifyformValue?.port}
                                                onChange={(e) => setFtpModifyFormValue({ ...ftpModifyformValue, port: e.value })}
                                                showClear
                                                optionLabel="label"
                                                placeholder="Select Port"
                                            />
                                            {(submitForm?.ftpModifySubmit && ftpModifyformValue?.port == undefined) || (modifyFtpClientError && modifyFtpClientError?.port) ? (
                                                <small className="p-error text-lg">{(submitForm?.ftpModifySubmit && ftpModifyformValue?.port == undefined && 'Port is required.') || modifyFtpClientError?.port}</small>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-6 py-0">
                                        <div className="field">
                                            <label htmlFor="hostname" className="font-bold">
                                                Host Name
                                            </label>
                                            <InputText name="hostname" autoFocus placeholder="Enter Your Host Name" value={ftpModifyformValue?.hostname} onChange={(e) => onModifyInputChange(e)} className="mb-2" />
                                            {(submitForm?.ftpModifySubmit && ftpModifyformValue?.hostname == '') || (modifyFtpClientError && modifyFtpClientError?.hostname) ? (
                                                <small className="p-error text-lg">{(submitForm?.ftpModifySubmit && ftpModifyformValue?.hostname == '' && 'Hostname is required.') || modifyFtpClientError?.hostname}</small>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-6 py-0">
                                        <div className="field">
                                            <label htmlFor="username" className="font-bold">
                                                User Name
                                            </label>
                                            <InputText name="username" autoFocus placeholder="Enter Your User Name" value={ftpModifyformValue?.username} onChange={(e) => onModifyInputChange(e)} className="mb-2" />
                                            {(submitForm?.ftpModifySubmit && ftpModifyformValue?.username == '') || (modifyFtpClientError && modifyFtpClientError?.username) ? (
                                                <small className="p-error text-lg">{(submitForm?.ftpModifySubmit && ftpModifyformValue?.username == '' && 'Username is required.') || modifyFtpClientError?.username}</small>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-6 py-0">
                                        <div className="field">
                                            <label htmlFor="password" className="font-bold">
                                                Password
                                            </label>
                                            <Password name="password" placeholder="Enter Your Password" toggleMask feedback={false} value={ftpModifyformValue?.password} onChange={(e) => onModifyInputChange(e)} className="mb-2" />
                                            {(submitForm?.ftpModifySubmit && ftpModifyformValue?.password == '') || (modifyFtpClientError && modifyFtpClientError?.password) ? (
                                                <small className="p-error text-lg">{(submitForm?.ftpModifySubmit && ftpModifyformValue?.password == '' && 'Password is required.') || modifyFtpClientError?.password}</small>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-6 py-0">
                                        <div className="field">
                                            <label htmlFor="folder_path" className="font-bold">
                                                Folder Path
                                            </label>
                                            <InputText name="folder_path" placeholder="Enter Your Folder Path" value={ftpModifyformValue?.folder_path} onChange={(e) => onModifyInputChange(e)} className="mb-2" />
                                            {(submitForm?.ftpModifySubmit && ftpModifyformValue?.folder_path == '') || (modifyFtpClientError && modifyFtpClientError?.folder_path) ? (
                                                <small className="p-error text-lg">{(submitForm?.ftpModifySubmit && ftpModifyformValue?.folder_path == '' && 'Folder path is required.') || modifyFtpClientError?.folder_path}</small>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Dialog>

                        <Dialog
                            visible={ftpDialogModal?.removeFtpModal}
                            style={{ width: '570px' }}
                            header="Confirm"
                            modal
                            footer={deleteFtpDialogFooter}
                            onHide={() => {
                                setFtpDialogModal({ ...ftpDialogModal, removeFtpModal: false });
                                setremoveFtpClientId(0);
                            }}
                        >
                            <div className="flex align-items-center">
                                <Button icon="pi pi-exclamation-triangle text-xl" className="p-button-rounded p-button-outlined p-button-secondary mr-3" />
                                <span className="font-bold" style={{ fontSize: '15px' }}>
                                    Are you sure? Once deleted, you will not be able to recover This data!
                                </span>
                            </div>
                        </Dialog>
                        <DataTable
                            size="small"
                            scrollable
                            showGridlines
                            stripedRows
                            scrollHeight={dynamicHeight}
                            value={ftpClientData?.results}
                            dataKey="id"
                            loading={isLoading}
                            className="datatable-responsive kd-datatable-content"
                            header={header}
                            emptyMessage="No records found."
                            responsiveLayout="scroll"
                        >
                            <Column field="client_name" header="Client Name" style={{ minWidth: '4rem' }}></Column>
                            <Column field="hostname" header="Host Name" style={{ minWidth: '28rem' }}></Column>
                            <Column field="username" header="User Name" style={{ minWidth: '4rem' }}></Column>
                            <Column field="port" header="Port" style={{ minWidth: '4rem' }}></Column>
                            <Column field="actions" header="Actions" style={{ minWidth: '24rem' }} body={actionBodyTemplate}></Column>
                        </DataTable>
                        <Paginator template={template} first={first} rows={rows} totalRecords={ftpClientData?.count} onPageChange={onPageChange} rowsPerPageOptions={[25, 50, 100, 200]}></Paginator>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FtpClientList;
