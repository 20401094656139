import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { allDiscountListRequest, createTagRequest, deleteTagRequest, discountError, getCollectionListRequest, getStoreNameDdRequest, updateTagRequest } from '../../../redux/slices/b2b/discountTagsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { classNames } from 'primereact/utils';
import { BreadCrumb } from 'primereact/breadcrumb';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';

const DiscountTags = () => {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [idToUpdate, setIdToUpdate] = useState('');
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);
    const [tag, setTag] = useState('');
    const [percentage, setPercentage] = useState('');
    const [storeName, setStoreName] = useState('');
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(100);
    const [sort_order, setSort_order] = useState('desc');
    const table_data = {
        page: 1,
        per_page: 100,
        sort_order: sort_order,
        order_column: 'created_at'
    };
    const [queryString, setQueryString] = useState(`page=${table_data.page}&per_page=${table_data.per_page}&order_column=${table_data.order_column}`);
    const { isLoading, isDataTableLoading, allDiscountList, succesMessage, collectionList, storeList, errors: error } = useSelector((state) => state.discountTags);
    const tableChangeHandler = (data) => {
        let queryStr = Object.keys(data)
            .map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
            })
            .join('&');
        setQueryString(queryStr);
    };
    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        tableChangeHandler({ ...table_data, page: event.page + 1, per_page: event.rows });
    };
    const template = {
        layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };
    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-2 flex flex-wrap fadein animation-duration-1000">
                {data?.collection_detail?.map((ele, index) => (
                    <ul
                        className="list-none text-overflow-clip	 pl-1"
                        style={{
                            textWrape: 'no-wrape'
                        }}
                        key={index}
                    >
                        <li className="surface-300 p-2 border-round-lg">{ele.label}</li>
                    </ul>
                ))}
            </div>
        );
    };
    const updateFormValue = (tag, percentage) => {
        formik.setFieldValue('tag', tag);
        formik.setFieldValue('percentage', percentage);
    };

    const formik = useFormik({
        initialValues: visible
            ? {
                  tag: tag,
                  percentage: percentage,
                  store_name: storeName
              }
            : {
                  tag: '',
                  percentage: '',
                  store_name: ''
                  //   collection_name: []
              },
        validationSchema: yup.object({
            tag: yup.string().required('Tag is required'),
            percentage: yup.number().min(1, 'Percentage must be at least 1').required('Percentage is required'),
            // store_name: yup.string().required('Store Name is required')
            store_name: yup.string().required('Store Name is required'),
            collection_name: yup.array().min(1).required('Collection Name is required')
        }),
        validate: (data) => {
            let errors = {};
            if (!data.tag && !data.percentage) {
                errors.tag = 'Tag is required.';
                errors.percentage = 'Percentage is required.';
            }
            if (!data.tag && !data.percentage) {
                errors.tag = 'Tag is required.';
                errors.percentage = 'Percentage is required.';
            } else if (!data.tag) {
                errors.tag = 'Tag is required.';
            } else if (!data.percentage) {
                errors.percentage = 'Percentage is required.';
            }
            return errors;
        },
        onSubmit: (data) => {
            const collection_detail = [];
            const payload = {
                collection_detail,
                percentage: data.percentage,
                tag: data.tag,
                store_name: data.store_name
            };
            collectionList.map((ele) => {
                if (data.collection_name.includes(ele.value)) {
                    collection_detail.push({ label: ele.label, value: ele.value });
                }
            });

            if (isUpdateModalOpen) {
                dispatch(updateTagRequest(idToUpdate, payload));
            } else {
                dispatch(createTagRequest(payload));
            }
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };
    const reject = () => {
        console.log('cancel');
    };
    const tagDelete = (id) => {
        const data = {
            id
        };
        dispatch(deleteTagRequest(data));
    };
    const breadcrumbItems = [{ label: 'B2B' }, { label: 'Wholesale Connect' }];
    const handleCreate = () => {
        setPercentage('');
        setTag('');
        setStoreName('');
        dispatch(discountError(null));
        setVisible(true);
        dispatch(getStoreNameDdRequest());
    };
    const collectionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <div>
                    <h6 className="mb-0">{option.label}</h6>
                    <span className="">{option.count} Products</span>
                </div>
            </div>
        );
    };

    const handleClick = (rowData, collectionIds) => {
        dispatch(discountError());
        dispatch(getStoreNameDdRequest());
        dispatch(getCollectionListRequest(rowData?.store_name));
        formik.setFieldValue('tag', rowData?.tag);
        formik.setFieldValue('percentage', rowData?.percentage);
        formik.setFieldValue('store_name', rowData?.store_name);
        formik.setFieldValue('collection_name', collectionIds);
        setIdToUpdate(rowData?.id);
        setIsUpdateModalOpen(true);
        setVisible(true);
    };
    const actionBodyTemplate = (rowData) => {
        var collectionIds = [];
        rowData?.collection_detail?.map((item) => {
            collectionIds.push(item?.value);
        });
        return (
            <div className="actions flex justify-content-end ">
                <Button tooltip="Edit" tooltipOptions={{ position: 'bottom' }} icon="pi pi-pencil" id="edits-icons" className="p-button-rounded p-button-text  p-button-help " onClick={() => handleClick(rowData, collectionIds)} />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-text  p-button-danger"
                    id="delete-icons"
                    tooltip="Delete"
                    tooltipOptions={{ position: 'bottom' }}
                    onClick={() => {
                        setIdToUpdate(rowData?.id);
                        confirmDialog({
                            message: 'Do you want to delete this record?',
                            header: 'Delete Confirmation',
                            icon: 'pi pi-info-circle',
                            acceptClassName: 'p-button p-button-danger',
                            draggable: false,
                            accept: () => tagDelete(rowData?.id),
                            reject
                        });
                    }}
                />
            </div>
        );
    };
    // const collectionBodyTemplate = (rowData) => {
    //     const collectionArr = rowData.collection_detail?.map((item) => item.label).join(', ');
    //     return (
    //         <div className="gap-1">
    //             <span>{collectionArr}</span>
    //         </div>
    //     );
    // };
    const allowExpansion = (rowData) => {
        return rowData.collection_detail?.length > 0;
    };

    const closeModal = () => {
        formik.resetForm();
        setVisible(false);
        setIsUpdateModalOpen(false);
    };
    useEffect(() => {
        dispatch(allDiscountListRequest(queryString));
    }, [dispatch, queryString]);
    useEffect(() => {
        if (succesMessage != null) {
            setVisible(false);
            dispatch(allDiscountListRequest(queryString));
            dispatch(discountError());
            formik.resetForm();
        }
    }, [succesMessage]);
    return (
        <>
            <ConfirmDialog />
            <div className="col px-0 pt-0">
                <div className="breadcrumb-container flex justify-content-between align-items-center">
                    <div className="text-2xl font-bold">
                        <h3 className="m-0">Wholesale Connect</h3>
                    </div>
                    <div className="ml-6 text-2xl">
                        <BreadCrumb home={{ icon: 'pi pi-home', url: '/' }} model={breadcrumbItems} />
                    </div>
                </div>
            </div>
            <div className="card shadow-3 w-full">
                <div className="flex flex-end mb-4">
                    <Button
                        label="Create"
                        icon="pi pi-plus"
                        className="p-button-outlined ml-auto"
                        onClick={() => {
                            handleCreate();
                        }}
                    />
                </div>
                <DataTable
                    responsiveLayout="scroll"
                    scrollable
                    removableSort
                    // showGridlines
                    stripedRows
                    // tableStyle={{ minWidth: '101rem' }}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate}
                    id="id"
                    size="small"
                    value={allDiscountList?.results}
                    loading={isDataTableLoading}
                    className="datatable-responsive b2b-datatable-content"
                    emptyMessage="No records found."
                >
                    <Column expander={allowExpansion} style={{ maxWidth: '4rem', display: 'block' }} />
                    <Column field="tag" sortable header="Tag" style={{ minWidth: '80px', display: 'block' }}></Column>
                    <Column field="percentage" sortable header="Percentage" className="" style={{ minWidth: '5px', display: 'block' }}></Column>
                    <Column field="store_name" sortable header="Store Name" className="" style={{ minWidth: '400px', display: 'block' }}></Column>
                    {/* <Column field="collection_name" header="Collection Name" className="" style={{ minWidth: '400px', display: 'block' }} body={collectionBodyTemplate}></Column> */}
                    <Column header="Actions" className="action" body={actionBodyTemplate}></Column>
                </DataTable>
                <Paginator template={template} first={first} rows={rows} totalRecords={allDiscountList?.count} onPageChange={onPageChange} rowsPerPageOptions={[100, 200, 300, 400, 500]}></Paginator>
            </div>
            <Dialog header="Wholesale Connect" draggable={false} visible={visible} style={{ width: '50vw' }} onHide={() => closeModal()}>
                <form className="p-fluid" onSubmit={formik.handleSubmit}>
                    <div className="flex">
                        <div className="flex flex-column col-6">
                            <label htmlFor="store_name" className="mb-3">
                                Store Name
                            </label>
                            <Dropdown
                                id="store_name"
                                name="store_name"
                                value={formik.values.store_name}
                                onChange={(e) => {
                                    formik.setFieldValue('store_name', e.value);
                                    dispatch(getCollectionListRequest(e.value));
                                }}
                                options={storeList}
                                emptyMessage="No Stores found"
                                optionLabel="label"
                                optionValue="label"
                                placeholder="Select a Store"
                                filter
                                className={classNames({ 'p-invalid': isFormFieldInvalid('store_name') })}
                            />

                            {getFormErrorMessage('store_name')}
                        </div>
                        <div className="flex flex-column col-6">
                            <label htmlFor="store_name" className="mb-3">
                                Collection Name
                            </label>
                            <MultiSelect
                                id="collection_name"
                                name="collection_name"
                                value={formik.values.collection_name}
                                onChange={(e) => {
                                    formik.setFieldValue('collection_name', e.value);
                                }}
                                options={collectionList}
                                disabled={collectionList.length === 0 ? true : false}
                                optionLabel="label"
                                optionValue="value"
                                loading={isLoading}
                                placeholder="Select a Collection"
                                clearIcon
                                filter
                                itemTemplate={collectionTemplate}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('collection_name') })}
                            />
                            {getFormErrorMessage('collection_name')}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="flex flex-column col-6">
                            <label htmlFor="tag" className="mb-3">
                                Tag
                            </label>
                            <InputText
                                id="tag"
                                name="tag"
                                value={formik.values.tag}
                                onChange={(e) => {
                                    formik.setFieldValue('tag', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('tag') })}
                            />
                            {getFormErrorMessage('tag')}
                        </div>
                        <div className="flex flex-column col-6">
                            <label htmlFor="percentage" className="mb-3">
                                Percentage %
                            </label>
                            <InputText
                                id="percentage"
                                name="percentage"
                                value={formik.values.percentage}
                                onChange={(e) => {
                                    formik.setFieldValue('percentage', e.target.value);
                                }}
                                className={classNames({ 'p-invalid': isFormFieldInvalid('percentage') })}
                            />
                            {getFormErrorMessage('percentage')}
                        </div>
                    </div>

                    <div className="w-2 ml-auto">
                        <Button type="submit" loading={isLoading} icon="pi pi-check" className="p-button-outlined ml-auto" label="Submit" />
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default DiscountTags;
