import { createSlice } from '@reduxjs/toolkit';
import { b2bDeleteTagApi, b2bDiscountListApi, b2bOrderListApi, b2bUpdateTagApi, bsbCreateTagApi, bsbOrderDetailsApi, getCollectionListApi, getStoreNameDdApi } from '../../../service/b2b/b2bService';
import { toast } from 'react-hot-toast';
import { handleUnAuthorizeUserRequest } from '../authSlice';

export const discountSlice = createSlice({
    name: 'discountTags',
    initialState: {
        isLoading: false,
        isDataTableLoading: false,
        succesMessage: null,
        allDiscountList: [],
        storeList: [],
        collectionList: [],
        errors: null
    },
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setDataTableLoading: (state, action) => {
            state.isDataTableLoading = action.payload;
        },

        allOrdersListData: (state, action) => {
            state.isDataTableLoading = false;
            state.allDiscountList = action.payload;
        },

        handleCreateSuccess: (state, action) => {
            state.isLoading = false;
            state.succesMessage = action.payload;
        },
        handleSuccessDdvalues: (state, action) => {
            state.isLoading = false;
            state.storeList = action.payload;
        },
        handleSuccessCollectionList: (state, action) => {
            state.isLoading = false;
            state.collectionList = action.payload;
        },
        discountError: (state, action) => {
            state.isLoading = false;
            state.succesMessage = null;
            state.errors = action.payload;
        },
        handleError: (state, action) => {
            state.isLoading = false;
            state.errors = action.payload;
        }
    }
});

export const { setLoading, setDataTableLoading, allOrdersListData, handleCreateSuccess, discountError, handleSuccessDdvalues, handleSuccessCollectionList, handleError } = discountSlice.actions;
export default discountSlice.reducer;

export const allDiscountListRequest = (queryString) => async (dispatch, getState) => {
    dispatch(setDataTableLoading(true));
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };

        const { data } = await b2bDiscountListApi(queryString, config);
        dispatch(allOrdersListData(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else {
            dispatch(discountError(error?.response));
        }
    }
};

export const createTagRequest = (userData) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await bsbCreateTagApi(userData, config);
        if (data?.statusCode === 201) {
            toast.success(data?.message);
            dispatch(handleCreateSuccess(data?.message));
        }
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error?.response?.data) {
            toast.error(error?.response?.data?.message, { duration: 5000 });
            return dispatch(discountError(error?.response?.data?.errors));
        }
    }
};
export const getStoreNameDdRequest = () => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await getStoreNameDdApi(config);
        if (data?.statusCode === 200) {
            dispatch(handleSuccessDdvalues(data?.store_settings_list));
        }
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error?.response?.data) {
            toast.error(error?.response?.data?.message, { duration: 5000 });
            return dispatch(discountError(error?.response?.data?.errors));
        }
    }
};
export const getCollectionListRequest = (storeName) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await getCollectionListApi(storeName, config);
        if (data?.statusCode === 200) {
            dispatch(handleSuccessCollectionList(data?.collection_list));
        }
    } catch (error) {
        return dispatch(discountError(error));
    }
};
export const updateTagRequest = (idToUpdate, userData) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await b2bUpdateTagApi(idToUpdate, userData, config);
        if (data?.statusCode === 200) {
            toast.success(data?.message);
            dispatch(handleCreateSuccess(data?.message));
        }
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error?.response?.data) {
            return dispatch(discountError(error?.response?.data?.errors));
        }
    }
};
export const deleteTagRequest = (id) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await b2bDeleteTagApi(id, config);
        if (data?.statusCode === 200) {
            toast.success(data?.message);
            dispatch(handleCreateSuccess(data?.message));
        }
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error?.response?.data) {
            return dispatch(discountError(error?.response?.data?.errors));
        }
    }
};
