import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import ColorChangePicker from 'react-pick-color';
import { useDispatch, useSelector } from 'react-redux';
import { colorModifyRequest, gemFinderResetData, storeSettingDropdownRequest } from '../../redux/slices/gemFinderSlice';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Editor } from 'primereact/editor';
import { Panel } from 'primereact/panel';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { ColorPicker } from 'primereact/colorpicker';
import { Messages } from 'primereact/messages';
import { Checkbox } from 'primereact/checkbox';
const StoneShareStoreSetting = () => {
    const dispatch = useDispatch();
    const { storeSettingDropdowndata, colorModifyData, error } = useSelector((state) => state.gemFinder);
    // const [selectedProductCategories, setSelectedProductCategories] = useState([]);
    // const onProductFiltersChange = (e) => {
    //     let _data = [...selectedProductCategories];

    //     if (e.checked) _data.push(e.value);
    //     else _data.splice(_data.indexOf(e.value), 1);

    //     setSelectedProductCategories(_data);
    // };

    const [value, setValue] = useState('');
    const [color, setColor] = useState('#D80B4D');
    const [bannerColor, setBannerColor] = useState(null);
    const [colorCode, setColorCode] = useState('');
    const [storeLabel, setStoreLable] = useState('');
    const [submitImg, setSubmitImg] = useState(false);
    const [colorChange, setColorChange] = useState(false);
    const [file, setFile] = useState();
    const [showImg, setShowImg] = useState();
    const [selectFileName, setSelectFileName] = useState('No Image chosen');
    const [bannerTitle, setBannerTitle] = useState('');
    const [bannerContent, setBannerContent] = useState('');
    const [selectBannerPosition, setSelectBannerPosition] = useState('');
    const [domain, setDomain] = useState('');
    const toast = useRef(null);
    const inputRef = useRef(null);
    const [lab, setLab] = useState(false);
    const [mined, setMined] = useState(false);
    const [fancy, setFancy] = useState(false);

    const msgs = useRef(null);

    const legendSourceTemplate = (
        <div className="flex align-items-center">
            <span className="font-bold text-lg">Select a store</span>
        </div>
    );

    const selectBannerValues = [
        { label: 'Left', value: 'left' },
        { label: 'Center', value: 'center' },
        { label: 'Right', value: 'right' }
    ];

    const handleChange = (e) => {
        setValue(e.value);

        storeSettingDropdowndata?.map((elem) => {
            if (e.value == elem.value) {
                setColorCode(elem.color);
                setStoreLable(elem.label);
                setStoreLable(elem.label);
                setBannerTitle(elem.title == null ? '' : elem.title);
                setBannerContent(elem.content == null ? '' : elem.content);
                setSelectBannerPosition(elem.position == null ? '' : elem.position);
                setShowImg(elem.image);
                setFile(elem.image);
                setDomain(elem.store_domain);
                setBannerColor(elem.text_color);
                setLab(elem.lab);
                setMined(elem.mined);
                setFancy(elem.fancy);
            }
        });
    };

    useEffect(() => {
        if (error !== null) {
            setSubmitImg(false);
            return setShowImg(undefined);
        }
        dispatch(storeSettingDropdownRequest());
        return () => {
            dispatch(gemFinderResetData());
        };
    }, [error]);

    useEffect(() => {
        if (colorChange == false) {
            setValue(storeSettingDropdowndata?.[0]?.value);
            setStoreLable(storeSettingDropdowndata?.[0]?.label);
            setShowImg(storeSettingDropdowndata?.[0]?.image);
            setFile(storeSettingDropdowndata?.[0]?.image);
            setBannerTitle(storeSettingDropdowndata?.[0]?.title);
            setBannerContent(storeSettingDropdowndata?.[0]?.content);
            setSelectBannerPosition(storeSettingDropdowndata?.[0]?.position);
            setBannerColor(storeSettingDropdowndata?.[0]?.text_color);
            setDomain(storeSettingDropdowndata?.[0]?.store_domain);
            // const array = [];
            // console.log('storeSettingDropdowndata====', storeSettingDropdowndata);
            // storeSettingDropdowndata.map((item) => {
            //     if (item.lab) {
            //         array.push('lab');
            //     }
            //     if (item.mined) {
            //         array.push('mined');
            //     }
            //     if (item.fancy) {
            //         array.push('fancy');
            //     }
            // });

            // setSelectedProductCategories(array);
            return setColorCode(storeSettingDropdowndata?.[0]?.color);
        }
    }, [storeSettingDropdowndata]);

    useEffect(() => {
        if (colorModifyData !== null) {
            let url = domain;
            if (!/^https?:\/\//i.test(domain)) {
                url = 'http://' + domain + '/apps/stone-search';
            }
            msgs.current.show({
                sticky: true,
                severity: 'success',
                content: (
                    <>
                        <span>
                            Congratulations! Your customized browsing experience has been saved. Click here to visit our store and experience the updated view{' '}
                            <a href={url} target="_blank">
                                <b>{url}</b>
                            </a>
                            .
                        </span>
                    </>
                )
            });
            setTimeout(() => {
                msgs.current.clear();
            }, 2000);
        }
    }, [colorModifyData]);

    const handleOnChange = (e) => {
        setSelectFileName(e.target.files[0]?.name);
        const selectFile = e.target.files[0];
        setFile(selectFile);
        setShowImg(URL.createObjectURL(e.target.files[0]));
        setSubmitImg(true);
    };

    const handleimgTextContentSubmit = () => {
        let formData = new FormData();
        formData.append('store_color', colorCode);
        formData.append('lab', lab);
        formData.append('mined', mined);
        formData.append('fancy', fancy);
        // formData.append('lab', selectedProductCategories.includes('lab'));
        // formData.append('mined', selectedProductCategories.includes('mined'));
        // formData.append('fancy', selectedProductCategories.includes('fancy'));
        formData.append('store_id', value);
        formData.append('store_name', storeLabel);
        formData.append('banner_img', file);
        formData.append('banner_title', bannerTitle);
        formData.append('banner_content', bannerContent);
        formData.append('banner_position', selectBannerPosition);
        formData.append('is_status', submitImg == true ? true : false);
        formData.append('text_color', `${bannerColor}`);
        dispatch(colorModifyRequest(formData));
        dispatch(gemFinderResetData());
        setColorChange(true);
        setSubmitImg(false);
        setSelectFileName('No Image chosen');

        msgs.current.clear();
    };

    const headerImgTemplate = (options) => {
        const className = `${options.className} justify-content-start`;

        return (
            <div className={className}>
                <input ref={inputRef} type={'file'} id={'csvFileInput'} accept="image/*" onChange={handleOnChange} hidden />
                <div className="flex align-items-center">
                    <div className="cursor-pointer w-9rem border-round" style={{ backgroundColor: '#607d8b', color: 'white', padding: '0.5rem' }}>
                        <label htmlFor="csvFileInput" className="flex justify-content-center align-items-center">
                            <i className="pi pi-upload mr-3"></i>
                            <span className="text-base font-bold cursor-pointer">Select Image</span>
                        </label>
                    </div>
                    <span id="file-chosen" className="ml-3 text-lg font-bold">
                        {selectFileName}
                    </span>
                </div>
            </div>
        );
    };

    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
                <select className="ql-size">
                    <option value="small"></option>
                    <option defaultValue="normal"></option>
                    <option value="large"></option>
                    <option value="huge"></option>
                </select>
            </span>
        );
    };

    const header = renderHeader();

    const breadcrumbItems = [{ label: 'Stone Search' }, { label: 'Stone Search Store Settings' }];

    return (
        <div className="store-search-store-setting-content">
            <Toast ref={toast} />
            <div className="col px-0">
                <div className="breadcrumb-container flex justify-content-between align-items-center">
                    <div className="text-2xl font-bold">
                        <h3 className="m-0">Stone Search Store Settings</h3>
                    </div>
                    <div className="ml-6 text-2xl">
                        <BreadCrumb home={{ icon: 'pi pi-home', url: '/' }} model={breadcrumbItems} />
                    </div>
                </div>
            </div>
            <Messages ref={msgs} />
            <div className="grid p-fluid">
                <div className="col-12">
                    <div className="card py-0">
                        <div className="col-12 md:col-4 product-store-content px-0 pt-0">
                            <Fieldset legend={legendSourceTemplate}>
                                <Dropdown id="sourceSelect" options={storeSettingDropdowndata} value={value} onChange={(e) => handleChange(e)} optionLabel="label" required />
                            </Fieldset>
                        </div>
                        <div className="grid p-3 pb-0">
                            <div
                                className="col-4"
                                style={{
                                    alignItems: 'center',
                                    background: colorCode,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    padding: '40px 0',
                                    // border: '3px solid #e1e1e1',
                                    borderRadius: '10px',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                                }}
                            >
                                <ColorChangePicker
                                    color={colorCode}
                                    onChange={(color) => {
                                        setColor(color.hex);
                                        setColorCode(color.hex);
                                    }}
                                    hideAlpha
                                    theme={{
                                        background: '#fff',
                                        borderColor: '#D4D4D4',
                                        borderRadius: '5px',
                                        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
                                        color: '#262626',
                                        inputBackground: '#f4f4f4',
                                        width: '280px'
                                    }}
                                />
                            </div>

                            <div className="col-4 py-0">
                                <h5>Banner Image</h5>
                                <Panel headerTemplate={headerImgTemplate} className="panel-image-content">
                                    {/* {file !== undefined || showImg !== undefined ? (
                                        <div className="flex  align-items-center">
                                            <img src={showImg} height={200} style={{ width: '90%' }} />
                                            <Button
                                                icon="pi pi-times"
                                                className="p-button-rounded p-button-danger p-button-text ml-2"
                                                onClick={() => {
                                                    setSelectFileName('No Image chosen');
                                                    setFile();
                                                    setShowImg(undefined);
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <h5 className="text-center">Please select an Image for Preview</h5>
                                    )} */}
                                </Panel>
                                <p className="font-bold text-base mt-3">Note:- The image dimension must be 1600x300.</p>
                                <p className="font-bold text-base mt-3">Supported image type JPG,JPEG,PNG</p>
                                <h5 className="my-2">Banner Text Color</h5>
                                <ColorPicker value={bannerColor} onChange={(e) => setBannerColor(e.value)} className="banner-text-content" />
                            </div>
                            <div className="col-4 py-0">
                                <h5>Banner Title</h5>
                                <InputText value={bannerTitle} type="text" onChange={(e) => setBannerTitle(e.target.value)} placeholder="Enter your banner title" />
                                <h5 className="my-2">Banner Content</h5>
                                <InputTextarea value={bannerContent} placeholder="Enter your banner content" onChange={(e) => setBannerContent(e.target.value)} style={{ height: '70px' }} />
                                <h5 className="my-2">Banner Position</h5>
                                <SelectButton className="select-button-content" value={selectBannerPosition} onChange={(e) => setSelectBannerPosition(e.value)} options={selectBannerValues} optionLabel="label" />
                                <h5>Product Filter</h5>

                                <div className="flex flex-wrap justify-content-start gap-3">
                                    <div className="flex align-items-center">
                                        <Checkbox
                                            inputId="lab_grown"
                                            name="Lab Grown"
                                            value={lab}
                                            onChange={(e) => {
                                                console.log(e.checked, 'e');

                                                setLab(e.checked);
                                            }}
                                            checked={lab}
                                        />
                                        <label htmlFor="lab_grown" className="ml-2">
                                            Lab Grown
                                        </label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <Checkbox inputId="mined" name="mined" value={mined} onChange={(e) => setMined(e.checked)} checked={mined} />
                                        <label htmlFor="mined" className="ml-2">
                                            Natural
                                        </label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <Checkbox inputId="fancy_color" name="Fancy Color" value={fancy} onChange={(e) => setFancy(e.checked)} checked={fancy} />
                                        <label htmlFor="fancy_color" className="ml-2">
                                            Fancy Color
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-2 mx-auto mt-2">
                            {showImg !== undefined && bannerTitle !== '' && bannerContent !== '' && selectBannerPosition !== '' && bannerColor !== null ? (
                                <Button icon="pi pi-check" tooltip="Click to proceed" label="Save Settings" onClick={handleimgTextContentSubmit} style={{ backgroundColor: '#607d8b' }} />
                            ) : (
                                <Button icon="pi pi-check" label="Save Settings" disabled />
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-12" style={{ height: `${showImg == undefined ? '150px' : ''}` }}>
                    <div className="relative w-full">
                        <img src={showImg} className="w-full" alt="No Banner Image" style={{ height: '300px' }} />
                        <div className="absolute w-full px-6" style={{ top: '15%', textAlign: `${selectBannerPosition}`, color: `#${bannerColor}` }}>
                            <h2 className="font-bold text-3xl my-2">{bannerTitle}</h2>
                            <h2 className="font-bold text-3xl mt-0">{bannerContent}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StoneShareStoreSetting;
