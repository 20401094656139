import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from 'primereact/image';
import { InputNumber } from 'primereact/inputnumber';
import { Divider } from 'primereact/divider';
import { Card } from 'primereact/card';
import { Timeline } from 'primereact/timeline';
import { Link, useParams } from 'react-router-dom';
import { Badge } from 'primereact/badge';
import moment from 'moment';
import { customerConfirmStatusRequest, orderDetailsRequest, orderStatusRequest, sendInvoiceRequest, setOrderStatusLoader } from '../../../redux/slices/b2b/orderSlice';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
const OrderDetails = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [ selectedProducts, setSelectedProducts ] = useState([]);
    const [ shippingNo, setShippingNo ] = useState(null);
    const [ price, setPrice ] = useState("");
    const [ trackingUrl, setTrackingUrl ] = useState(null);
    const [ trackingNo, setTrackingNo ] = useState(null);
    const [ isNotify, setIsNotify ] = useState(true);
    const [ isFulfillItemModalOpen, SetIsFulfillItemModalOpen ] = useState(false);
    const [ selectedValue, setSelectedValue ] = useState(null);
    const [ newPrice, setNewPrice ] = useState("");
    const [ previousPrice, setPreviousPrice ] = useState("")
    console.log(newPrice, previousPrice, 'newPrice', "previousPrice");
    const menuLeft = useRef(null);
    const { orderDetailsData, isOrderStatusLoaderStart } = useSelector((state) => state.b2bOrders);
    const getOrderNumber = orderDetailsData?.customer_detail_data?.map((ele) => ele.order_number);
    const getDate = orderDetailsData?.customer_detail_data?.map((ele) => ele.processed_at);
    const getTimelineEvents = orderDetailsData?.timeline_data?.map((ele) => ele);
    const customerDetailData = orderDetailsData?.customer_detail_data?.map((ele) => ele);
    console.log(customerDetailData, 'customerDetailData')
    const orderStatusData = {
        order_id: id,
        status: 'ITEM_SENT',
        shipping_tracking_number: shippingNo,
        shipping_tracking_company: trackingNo,
        shipping_tracking_url: trackingUrl,
        email_send: isNotify
    };
    const handleClose = () => {
        setSelectedValue(null);
        SetIsFulfillItemModalOpen(false);
    };
    let items = [
        {
            label: 'Items Sent',
            command: () => {
                dispatch(orderStatusRequest(orderStatusData));
            }
        }
    ];

    useEffect(() => {
        dispatch(orderDetailsRequest(id));
    }, []);
    useEffect(() => {
        if (customerDetailData != null) {
            setTrackingNo(customerDetailData[ 0 ]?.shipping_tracking_company);
            setTrackingUrl(customerDetailData[ 0 ]?.shipping_tracking_url);
            setPrice(customerDetailData[ 0 ]?.shipping_amount);
            dispatch(setOrderStatusLoader(false));
        }
    }, [ orderDetailsData ]);

    const customizedContent = (data) => {
        return (
            <>
                {/* <p className="mb-0">{data?.date}</p> */}

                <div className="flex justify-content-between align-items-center">
                    <p className="mb-0 text-lg" style={{ width: '70%' }}>
                        {data?.text}
                    </p>
                    <p className="text-lg text-color-secondary mb-0"> {moment(data?.date).format('MMM DD YYYY')}</p>
                    <p className="text-lg text-color-secondary"> {moment(data?.time, 'HH:mm:ss').format('hh:mm A')}</p>
                </div>
            </>
        );
    };

    const customizedMarker = (rowData) => {
        return (
            // <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle" style={{ backgroundColor: '#e7e8ec' }}>
            //     <i className="pi pi-circle-fill" style={{ color: '#000000', fontSize: '6px' }} />
            // </span>
            <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-2" style={{ backgroundColor: rowData.color }}>
                {/* <i className={classNames('marker-icon', item.icon)}></i> */}
                <i className={`pi ${rowData.icon}`} />
            </span>
        );
    };
    const imageBodyTemplate = (rowData) => {
        return (
            <div className="flex">
                <Image src={rowData?.product_image_url} alt="Image" width="90" height="90" preview />
                <div>
                    <h5 className="my-0 mx-3 text-xl" style={{ color: '#3f51b5' }}>
                        {rowData?.title}
                    </h5>
                    <p className="flex align-items-center mb-0 ml-3 mt-2 text-color-secondary font-semibold">
                        SKU: <p className="ml-1 text-900">{rowData?.sku}</p>
                    </p>
                    <p className="flex align-items-center mb-0 ml-3 font-semibold text-color-secondary">
                        Discount: <p className="ml-1 text-900">{rowData?.discount}</p>
                    </p>
                </div>
            </div>
        );
    };
    const handlePrice = (price) => {
        console.log(price, 'price')
        setPreviousPrice(price)

    }
    const handlePriceChange = (e) => {
        // console.log(price, 'price')
        var isPriceValueChange = false;
        // setPreviousPrice(e.value);
        isPriceValueChange = true;
        if (isPriceValueChange) {
            setNewPrice(e.value)
        }
    }
    const priceBodyTemplate = (rowData) => {
        // setPreviousPrice(rowData?.price);
        return (
            <>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={previousPrice} onChange={(e) => {

                    handlePriceChange(e);
                    // handlePrice(rowData?.price);
                }} style={{ width: "7rem !important" }} />
            </>
        )

        // <InputText value={rowData?.price} className="p-inputtext-sm"></InputText>
        // <p className="text-xl font-normal">{rowData?.customer_confirmed_status == 'False' ? <del className="mr-6">${rowData?.price?.toLocaleString('en-US')}</del> : <span className="mr-6">${rowData?.price?.toLocaleString('en-US')}</span>}</p>;
    };
    const statuBodyTemplate = (rowData) => {
        return (
            <h6 className="text-xl font-normal">
                {rowData?.customer_confirmed_status == 'False' ? (
                    <Button icon="pi pi-times-circle" className="p-button p-component p-button-rounded p-button-text p-button-danger" rounded outlined aria-label="Filter" />
                ) : (
                    <Button icon="pi pi-check-circle" className="p-button p-component p-button-rounded p-button-text p-button-success " rounded text raised aria-label="Filter" />
                )}
            </h6>
        );
    };
    const quantityBodyTemplate = (rowData) => {
        return (
            <h6 className="text-xl font-normal">
                <del className="">{items?.price?.toLocaleString('en-US')}</del>$
                {orderDetailsData?.disount_price?.map((ele) => {
                    if (ele.orders_lineitem_id === rowData.orders_lineitem_id) {
                        return ele?.price_after_discount?.toLocaleString('en-US');
                    }
                })}
                &nbsp; x &nbsp; {rowData?.quantity}
            </h6>
        );
    };
    const totalBodyTemplate = (rowData) => {
        return (
            <h6 className="text-lg font-normal">
                $
                {orderDetailsData?.disount_price?.map((ele) => {
                    if (ele.orders_lineitem_id === rowData.orders_lineitem_id) {
                        return ele?.total_price_of_total_quantities?.toLocaleString('en-US');
                    }
                })}
            </h6>
        );
    };
    const accept = () => {
        setPurchaseModelVisible(false);
        const ordersLineitemIds = selectedProducts.map((item) => item.orders_lineitem_id);
        const userData = {
            order_details_id: parseInt(id),
            orders_lineitem_id: ordersLineitemIds,
            status: 'accept'
        };

        dispatch(customerConfirmStatusRequest(userData));
        dispatch(orderDetailsRequest(id));
    };

    const orderCancel = () => {
        console.log('done ')
        const userData = {
            order_id: parseInt(id),
            status: 'CANCELLED'
        };
        dispatch(orderStatusRequest(userData));
        dispatch(orderDetailsRequest(id));
    };
    const reject = () => {
        setPurchaseModelVisible(false);
    };
    const handleSubmit = () => {
        dispatch(setOrderStatusLoader(true));
        dispatch(orderStatusRequest(orderStatusData));
        SetIsFulfillItemModalOpen(false);
        dispatch(orderDetailsRequest(id));
    };

    const [ purchaseModelvisible, setPurchaseModelVisible ] = useState(false);
    // const footerContent = (
    //     <div>
    //         <Button label="No" icon="pi pi-times" onClick={() => setPurchaseModelVisible(false)} className="p-button-text" />
    //         <Button label="Yes" icon="pi pi-check" onClick={() => handleAccept()} autoFocus />
    //     </div>
    // );
    // const order_cancel = () => {
    //     handleReject();

    // }
    const handleConfirm = () => {
        confirmDialog({
            message: 'Selected products will be marked as Purchased and the quantities of other products will be reversed back to Shopify inventory.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept,
            reject
        });
    };
    return (
        <div>
            {/* <Button label="Show" icon="pi pi-external-link" onClick={() => setPurchaseModelVisible(true)} /> */}
            {/* <Dialog header="Mark as Purchased" visible={purchaseModelvisible} style={{ width: '50vw' }} onHide={() => setPurchaseModelVisible(false)} footer={footerContent}>
                <p className="m-0">
                    Selected products will be marked as 'Purchased,' and the quantities of other products will be reversed back to Shopify inventory.
                </p>
            </Dialog> */}
            <ConfirmDialog className="w-3" />
            {isOrderStatusLoaderStart ? (
                <div className=" flex justify-content-center align-items-center h-screen">
                    <ProgressSpinner />
                </div>
            ) : (
                <>
                    <div className="col px-0 pt-0 pb-2">
                        <div className="flex align-items-center">
                            <Link to="/b2b-orderList" className="mr-3" style={{ color: '#000000' }}>
                                <i className="pi pi-arrow-left text-xl" />
                            </Link>
                            <h3 className="my-0 mr-4 text-2xl">Order #{getOrderNumber}</h3>
                            <i className="pi pi-calendar text-xl mr-2" />
                            <h6 className="my-0 font-semibold text-color-secondary">{moment(getDate?.toLocaleString()).format('MMM DD YYYY h:mm A')}</h6>
                            <Tag className="ml-3" style={{ fontSize: '15px' }} icon="pi pi-circle-fill" severity={orderDetailsData?.progress_status_color} value={orderDetailsData?.progress_status}></Tag>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-8 b2b-order-details-card">
                            {/* <div className="card"> */}

                            <Card
                                title={
                                    <div className="flex align-items-center text-xl">
                                        <i className="pi pi-box mr-2" />
                                        Order details
                                        <Badge value={orderDetailsData?.product_detail_data?.length} className="ml-3 bg-gray-400" style={{ color: '#000000' }}></Badge>
                                        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
                                        {/* <Button label="Confirm" severity="success" disabled={selectedProducts.length >= 1 ? false : true} className='font-bold ml-4 p-button-success p-button-outlined text-sm '
                                    style={{
                                        display: selectedProducts.length >= 1 ? 'block' : 'none'
                                    }}
                                    onClick={() => handleAccept()}

                                />
                                <Button label="Reject" severity="danger" disabled={selectedProducts.length >= 1 ? false : true} className='font-bold ml-4 p-button-danger p-button-outlined text-sm'
                                    style={{
                                        display: selectedProducts.length >= 1 ? 'block' : 'none'
                                    }}
                                    onClick={() => {
                                        handledecline()

                                    }} />

                                <Button label="Items Sent" severity="warning" disabled={selectedProducts.length >= 1 ? false : true} className='font-bold ml-4 p-button-warning p-button-outlined text-sm'
                                    style={{
                                        display: selectedProducts.length >= 1 ? 'block' : 'none'
                                    }}
                                    onClick={() => {
                                        dispatch(orderStatusRequest(orderStatusData))
                                    }} />
                                <Button label="Fullfill Item" severity="warning" disabled={selectedProducts.length >= 1 ? false : true} className='font-bold ml-4 p-button-warning p-button-outlined text-sm'
                                    style={{
                                        display: selectedProducts.length >= 1 ? 'block' : 'none'
                                    }}
                                    onClick={() => {
                                        SetIsFulfillItemModalOpen(true);
                                    }} /> */}
                                        {/* <Button icon="pi pi-ellipsis-h" className="font-bold p-button-rounded p-button-text p-button-plain ml-auto" onClick={(event) => menuLeft.current.toggle(event)} aria-controls="popup_menu_left" aria-haspopup /> */}
                                        {/* <SelectButton className=" ml-2 select-button-content" value={selectedValue} onChange={(e) =>
                                    setSelectedValue(e.value)} options={ordersFilterOptions} optionLabel="label" /> */}
                                        <span className="p-buttonset ml-4">
                                            <Button
                                                icon={orderDetailsData?.is_mark_as_purchased == true ? 'pi pi-check' : ''}
                                                label="Mark as Purchased"
                                                className="p-button-outlined font-bold"
                                                style={{
                                                    display: orderDetailsData?.is_mark_as_purchased == true ? true : false
                                                }}
                                                // onClick={() => handleAccept()}
                                                disabled={selectedProducts.length > 0 ? false : true}
                                                // onClick={() => setPurchaseModelVisible(true)}
                                                onClick={() => handleConfirm()}
                                            />
                                            {/* <Button
                                        label="Reject"
                                        className="p-button-outlined font-bold"
                                        style={{
                                            display: selectedProducts.length >= 1 ? null : 'none'
                                        }}
                                        onClick={() => handledecline()}
                                    /> */}
                                            {/* <Button
                                        label="Mark as"
                                        className="p-button-outlined font-bold"
                                        style={{
                                            display: selectedProducts.length >= 1 ? null : 'none'
                                        }}
                                    />
                                        onClick={() => {
                                            dispatch(orderStatusRequest(orderStatusData));
                                        }}
                                    /> */}
                                            <Button
                                                label="Item Shipment"
                                                className="p-button-outlined font-bold"
                                                style={{
                                                    // display: orderDetailsData?.is_order_cancelled == true ? false : true
                                                }}
                                                disabled={orderDetailsData?.is_order_cancelled == true ? true : false}
                                                onClick={() => {
                                                    SetIsFulfillItemModalOpen(true);
                                                }}
                                            />
                                            <Button
                                                label="Send Invoice"
                                                className="p-button-outlined font-bold"
                                                disabled={orderDetailsData?.is_order_cancelled == true ? true : false}
                                                onClick={() => {
                                                    dispatch(sendInvoiceRequest(id));
                                                }}
                                            />
                                        </span>
                                        <div className=" ml-auto">
                                            <Button
                                                icon="pi pi-times"
                                                label={orderDetailsData?.is_order_cancelled == true ? "Order Cancelled" :"Cancel Order"}
                                                className="p-button-outlined p-button-danger font-bold"
                                                tooltip="Cancel Order" tooltipOptions={{ position: 'mouse' }}
                                                onClick={() => {
                                                    confirmDialog({
                                                        message: 'Do you want to cancel this  order?',
                                                        header: 'Cancel Confirmation',
                                                        icon: 'pi pi-info-circle',
                                                        acceptClassName: 'p-button p-button-danger',
                                                        accept: () => orderCancel(),
                                                        reject
                                                    });
                                                }}
                                                disabled={orderDetailsData?.is_order_cancelled == true ? true : false}
                                                outlined
                                                // severity="secondary"
                                            />
                                        </div>
                                    </div>
                                }
                            >
                                <div className="grid b2b-order-details-content">
                                    {/* {orderDetailsData?.product_detail_data?.map((items) => {
                                return (
                                    <>
                                        <div className="col-12 md:col-6" key={items?.orders_lineitem_id}>
                                            <div className="flex">
                                                <Image src={items?.product_image_url} alt="Image" width="90" height="90" preview />
                                                <div>
                                                    <h5 className="my-0 mx-3 text-xl" style={{ color: '#3f51b5' }}>
                                                        {items?.title}
                                                    </h5>
                                                    <p className="flex align-items-center mb-0 ml-3 mt-2 text-color-secondary font-semibold">
                                                        SKU: <p className="ml-1 text-900">{items?.sku}</p>
                                                    </p>
                                                    <p className="flex align-items-center mb-0 ml-3 font-semibold text-color-secondary">
                                                        Discount: <p className="ml-1 text-900">{items?.discount}</p>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <h6 className="text-xl font-normal">
                                                <del className="mr-6">${items?.price?.toLocaleString('en-US')}</del>$
                                                {orderDetailsData?.disount_price?.map((ele) => {
                                                    if (ele.orders_lineitem_id === items.orders_lineitem_id) {
                                                        return ele?.price_after_discount?.toLocaleString('en-US');
                                                    }
                                                })}
                                                &nbsp; x &nbsp; {items?.quantity}
                                            </h6>
                                        </div>
                                        <div className="col-12 md:col-2 text-right">
                                            <h6 className="text-xl font-normal">
                                                $
                                                {orderDetailsData?.disount_price?.map((ele) => {
                                                    if (ele.orders_lineitem_id === items.orders_lineitem_id) {
                                                        return ele?.total_price_of_total_quantities?.toLocaleString('en-US');
                                                    }
                                                })}
                                            </h6>
                                        </div>
                                        <Divider className="mx-3 my-0" />
                                    </>
                                );
                            })} */}
                                    <div className="col pt-1">
                                        <div className="invoice p-0">
                                            <DataTable
                                                value={orderDetailsData?.product_detail_data}
                                                selection={selectedProducts}
                                                onSelectionChange={(e) => setSelectedProducts(e.value)}
                                                dataKey={orderDetailsData?.product_detail_data?.orders_lineitem_id}
                                                tableStyle={{ width: '10%' }}
                                                style={{
                                                    border: 'none'
                                                }}
                                            >
                                                {orderDetailsData?.is_mark_as_purchased == false && orderDetailsData?.is_order_cancelled == false &&
                                                    <Column selectionMode="multiple" headerStyle={{ width: '2%' }}></Column>
                                                }
                                                {orderDetailsData?.is_mark_as_purchased == true &&
                                                    <Column field="customer_confirmed_status" header="Selected" headerStyle={{ width: '4%' }} body={statuBodyTemplate}></Column>
                                                }
                                                <Column field="product_image_url" header="Description" headerStyle={{ width: '15%' }} body={imageBodyTemplate}></Column>
                                                <Column field="category" header="Price" headerStyle={{ width: '10%' }} body={priceBodyTemplate}></Column>
                                                <Column field="quantity" header="Quantity" headerStyle={{ width: '8%' }} body={quantityBodyTemplate}></Column>
                                                <Column field="price" header="Total" headerStyle={{ width: '4%' }} body={totalBodyTemplate}></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>

                                {/* </div> */}
                            </Card>

                            <Card title={<div className="flex align-items-center text-xl">Total</div>} className="mt-4">
                                {orderDetailsData?.customer_detail_data?.map((ele, i) => {
                                    return (
                                        <>
                                            <div className="grid" key={i}>
                                                <div className="col-12 md:col-3 pb-0">
                                                    <p className="mb-1 text-lg text-color-secondary">Subtotal</p>
                                                    <p className="mb-1 text-lg text-color-secondary">Shipping</p>
                                                    <p className="mb-1 text-lg text-color-secondary">Tax</p>
                                                    <p className="mb-1 text-lg font-bold">Total</p>
                                                </div>
                                                <div className="col-12 md:col-6 pb-0 text-center">
                                                    <p className="mb-1 text-lg">{orderDetailsData.total_price_object?.item_count} items</p>
                                                    <p className="mb-1 text-lg">Free shipping (0.0 lb)</p>
                                                    <p className="mb-1 text-lg">
                                                        {/* {ele?.tax_title}&nbsp;{ele?.tax_rate}% */}
                                                        {ele?.tax_title}&nbsp;{orderDetailsData?.total_price_object.tax_rate}%
                                                    </p>
                                                </div>
                                                <div className="col-12 md:col-3 text-right pb-0">
                                                    {/* <p className="mb-1 text-lg">${ele?.subtotal_price?.toLocaleString('en-US')}</p>
                                                    <p className="mb-1 text-lg">$0.00</p>
                                                    <p className="mb-1 text-lg">${ele?.tax_price?.toLocaleString('en-US')}</p>
                                                    <p className="mb-1 text-lg font-bold">${ele?.current_total_price?.toLocaleString('en-US')}</p> */}
                                                    <p className="mb-1 text-lg">${orderDetailsData?.total_price_object.sub_total_price?.toLocaleString('en-US')}</p>
                                                    <p className="mb-1 text-lg">$0.00</p>
                                                    <p className="mb-1 text-lg">${orderDetailsData?.total_price_object.tax_total_price?.toLocaleString('en-US')}</p>
                                                    <p className="mb-1 text-lg font-bold">${orderDetailsData?.total_price_object.order_total_price?.toLocaleString('en-US')}</p>
                                                </div>
                                            </div>

                                            <Divider />
                                            <div className="grid">
                                                <div className="col-12 md:col-9 pb-0">
                                                    <p className="mb-1 text-lg text-color-secondary">Payment Method</p>
                                                </div>

                                                <div className="col-12 md:col-3 text-right pb-0">
                                                    <p className="mb-1 text-lg">{ele?.gateway}</p>
                                                    {/* <p className="mb-1 text-lg">${ele?.current_total_price?.toLocaleString('en-US')}</p> */}
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                            </Card>

                            <Card title={<div className="flex align-items-center text-xl">Timeline</div>} className="mt-4">
                                <Timeline className="timeline-content" marker={customizedMarker} value={getTimelineEvents} content={customizedContent} />
                            </Card>
                        </div>
                        <div className="col-12 md:col-4 b2b-order-details-card ">
                            <Card
                                title={
                                    <div className="text-xl">
                                        <i className="pi pi-user mr-2 bg-bluegray-100" style={{ padding: '5px', borderRadius: '4px' }} />
                                        Customer
                                    </div>
                                }
                            >
                                {orderDetailsData?.customer_detail_data?.map((items) => {
                                    return (
                                        <div>
                                            <div className="pt-0">
                                                {/* <h2 className="mt-0 mb-1 text-2xl">Customer</h2> */}
                                                <div>
                                                <p><span className="text-lg">Name: </span><span className="font-bold mb-1 text-lg" style={{ color: '#3f51b5',textTransform: 'capitalize' }}>{items?.customer_first_name}&nbsp;{items?.customer_last_name}</span></p>
                                                <p><span className="text-lg">Tags: </span>{items?.customer_tags ? items?.customer_tags : "-"}</p>
                                                </div>
                                                <Divider />

                                                <div>
                                                    <h2 className='text-xl font-normal'>Tracking Information</h2>
                                                    <p className='text-color-secondary'>Tracking No:- {items?.shipping_tracking_number}</p>
                                                    <p className='text-color-secondary'>Shipping carrier:- {items?.shipping_tracking_company}</p>
                                                    <p className='text-color-secondary'>Tracking Url:- {items?.shipping_tracking_url}</p>
                                                </div>
                                                {/* <p className="text-base text-color-secondary">Customer is tax-exempt</p> */}
                                            </div>
                                            <Divider />
                                            <div>
                                                <h2 className="mb-3 text-xl font-normal">
                                                    <i className="pi pi-phone mr-2 bg-green-400" style={{ padding: '5px', borderRadius: '4px' }} />
                                                    Contact Information
                                                </h2>

                                                {items?.customer_email_id === null ? null : (
                                                    <div className="flex justify-content-between mb-1" style={{ color: '#3f51b5' }}>
                                                        <div className="flex align-items-center">
                                                            <i className="pi pi-at mr-2" style={{ color: '#000000' }} />
                                                            <h6 className="m-0 text-lg"> {items?.customer_email_id === null ? '-' : items?.customer_email_id}</h6>
                                                        </div>
                                                        <Button
                                                            icon="pi pi-copy"
                                                            tooltip="Copy"
                                                            style={{ color: '#000000' }}
                                                            tooltipOptions={{ position: 'top' }}
                                                            className="p-button-rounded p-button-text"
                                                            onClick={() => navigator.clipboard.writeText(`${items}`)}
                                                        />
                                                    </div>
                                                )}

                                                {items?.shipping_phone === null ? null : (
                                                    <div className="text-base flex justify-content-between mb-0" style={{ color: '#3f51b5' }}>
                                                        <div className="flex align-items-center">
                                                            <i className="pi pi-mobile mr-2" style={{ color: '#000000' }} />
                                                            <h6 className="m-0"> {items?.shipping_phone === null ? '-' : items?.shipping_phone}</h6>
                                                        </div>
                                                        <Button
                                                            icon="pi pi-copy"
                                                            tooltip="Copy"
                                                            style={{ color: '#000000' }}
                                                            tooltipOptions={{ position: 'top' }}
                                                            className="p-button-rounded p-button-text"
                                                            onClick={() => navigator.clipboard.writeText(items?.shipping_phone)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <Divider />
                                            <div>
                                                <h2 className="mt-0 mb-3 text-xl font-normal">
                                                    <i className="pi pi-map-marker mr-2 bg-yellow-400" style={{ padding: '5px', borderRadius: '4px' }} />
                                                    Shipping address
                                                </h2>
                                                <div className="text-color-secondary grid">
                                                    <div className="col-12 md:col-8">
                                                        <p className="mb-1 text-base">{items?.shipping_address1}</p>
                                                        <p className="mb-1 text-base">
                                                            {items?.shipping_city} OR {items?.shipping_zip}
                                                        </p>
                                                        <p className="mb-1 text-base text-color-secondary">{items?.shipping_address_country}</p>
                                                    </div>
                                                    <div className="col-12 md:col-4 pt-2 text-right">
                                                        <Button
                                                            icon="pi pi-copy"
                                                            tooltip="Copy"
                                                            style={{ color: '#000000' }}
                                                            tooltipOptions={{ position: 'top' }}
                                                            className="p-button-rounded p-button-text p-0"
                                                            onClick={() => navigator.clipboard.writeText(`${items?.shipping_address1} ${items?.shipping_city} ${items?.shipping_zip} OR ${items?.shipping_address_country}`)}
                                                        />
                                                    </div>
                                                </div>

                                                <a href={items?.google_map_url} target="_blank" className="underline">
                                                    View map
                                                </a>
                                            </div>
                                            <Divider />
                                            <div>
                                                <h2 className="mt-0 mb-2 text-xl font-normal">Billing address</h2>
                                                <p className="mb-1 text-base text-color-secondary">Same as Shipping address</p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </Card>
                        </div>
                        <Dialog header="Add Shipment Details" visible={isFulfillItemModalOpen} style={{ width: '84vw' }} onHide={() => handleClose()}>
                            <div className="flex  p-2">
                                <div
                                    className="w-9 card "
                                    style={{
                                        height: '24rem'
                                    }}
                                >
                                    {selectedProducts?.map((items) => {
                                        return (
                                            <>
                                                <div className="flex">
                                                    <Image src={items?.product_image_url} alt="Image" width="50" height="50" preview />
                                                    <div>
                                                        <h5 className="my-0 mx-3 text-xl" style={{ color: '#3f51b5' }}>
                                                            {items?.title}
                                                        </h5>
                                                        <p className="flex align-items-center mb-0 ml-3 mt-2 text-color-secondary font-semibold">
                                                            SKU: <p className="ml-1 text-900">{items?.sku}</p>
                                                        </p>
                                                        <p className="flex align-items-center mb-0 ml-3 font-semibold text-color-secondary">
                                                            Discount: <p className="ml-1 text-900">{items?.discount}</p>
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })}
                                    <div>
                                        <p className="text-bold text-xl">Tracking information (optional)</p>
                                        <div className="flex">
                                            <div className="flex flex-column w-4 ml-2">
                                                <label>Tracking No </label>
                                                <InputText value={trackingNo} className="" placeholder="Enter tracking no" onChange={(e) => setTrackingNo(e.target.value)} />
                                            </div>
                                            <div className="flex flex-column w-4 ml-4">
                                                <label>Shipping carrier</label>
                                                <InputText value={shippingNo} className="" placeholder="Enter shipping no" onChange={(e) => setShippingNo(e.target.value)} />
                                            </div>
                                            <div className="flex flex-column w-4 ml-4">
                                                <label>Price</label>
                                                <InputText value={price} className="" placeholder="Enter price" onChange={(e) => setPrice(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="flex flex-column w-12 ml-2 mt-3">
                                            <label>Tracking Url</label>
                                            <InputText value={trackingUrl} className="w-full" placeholder="Enter a valid tracking page link" onChange={(e) => setTrackingUrl(e.target.value)} />
                                        </div>
                                    </div>

                                    <h6 className="text-xl">Notify costomer of shipment</h6>
                                    <div className="flex">
                                        <Checkbox onChange={(e) => setIsNotify(e.checked)} checked={isNotify}></Checkbox>
                                        <p className="ml-1">Sent shippment details email to customer now </p>
                                    </div>
                                    {/* <Divider /> */}
                                    <Button
                                        label="Save Shipment Details"
                                        severity="warning"
                                        className="font-bold p-button-warning p-button mt-4"
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    />
                                </div>
                                <div className="w-3 flex flex-column">
                                    <div className=" card border-round-xl ml-2">
                                        <div>
                                            {orderDetailsData?.customer_detail_data?.map((items) => {
                                                return (
                                                    <div>
                                                        <h2 className="mt-0 mb-3 text-xl font-bold">
                                                            <i className="pi pi-map-marker mr-2 bg-yellow-400" style={{ padding: '5px', borderRadius: '4px' }} />
                                                            Shipping address
                                                        </h2>
                                                        <h4>
                                                            {items.customer_first_name} {items.customer_last_name}
                                                        </h4>
                                                        <div className="text-color-secondary grid">
                                                            <div className="col-12 md:col-12">
                                                                <div className="flex ">
                                                                    <div>
                                                                        <p className="mb-1 text-base">{items?.shipping_address1}</p>
                                                                        <p className="mb-1 text-base">
                                                                            {items?.shipping_city} OR {items?.shipping_zip}
                                                                        </p>
                                                                        <p className="mb-1 text-base text-color-secondary">{items?.shipping_address_country}</p>
                                                                    </div>
                                                                    <div>
                                                                        <Button
                                                                            icon="pi pi-copy"
                                                                            tooltip="Copy"
                                                                            style={{ color: '#000000' }}
                                                                            onClick={() => navigator.clipboard.writeText(`${items?.shipping_address1} ${items?.shipping_city} ${items?.shipping_zip} ${items?.shipping_address_country}`)}
                                                                            tooltipOptions={{ position: 'top' }}
                                                                            className="p-button-rounded p-button-text p-0"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <a href={items?.google_map_url} target="_blank" className="underline">
                                                            View map
                                                        </a>
                                                        <p className="text-base text-color-secondary mt-3">
                                                            {orderDetailsData?.product_detail_data.length} of {orderDetailsData?.product_detail_data.length} items
                                                        </p>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    {/* <div className=" card border-round-xl ml-2">
                                <h6 className="text-xl ">Summary</h6>
                                {orderDetailsData?.customer_detail_data?.map((items) => {
                                    return <p className="text-color-secondary">{items?.shipping_address1}</p>;
                                })}
                                <p className="text-color-secondary">
                                    {selectedProducts?.length} of {orderDetailsData?.product_detail_data?.length} item
                                </p>
                                <Button
                                    label="Fulfill Item"
                                    severity="warning"
                                    className="font-bold w-full p-button-warning p-button text-sm"
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                />
                            </div> */}
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </>
            )}
        </div>
    );
};

export default OrderDetails;
