import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { useDispatch, useSelector } from 'react-redux';
import { Paginator } from 'primereact/paginator';
import moment from 'moment';
import { BreadCrumb } from 'primereact/breadcrumb';
import { productShopifyLogRequest } from '../../redux/slices/productSlice';
import { Tag } from 'primereact/tag';

const StoneSyncLog = () => {
    const dispatch = useDispatch();

    const { productSyncLog, isLoading } = useSelector((state) => state.product);
    const { verifiedStoreData } = useSelector((state) => state.store);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [sort_order, setSort_order] = useState('desc');
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(25);
    const toast = useRef(null);
    const dt = useRef(null);

    const table_data = {
        page: 1,
        per_page: 25,
        sort_order: sort_order
    };

    const [queryString, setQueryString] = useState(`page=${table_data.page}&per_page=${table_data.per_page}&destination_store_id=${verifiedStoreData.store_id}`);

    useEffect(() => {
        dispatch(productShopifyLogRequest(queryString));
    }, [dispatch, queryString]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        tableChangeHandler({ ...table_data, page: event.page + 1, per_page: event.rows });
    };

    const tableChangeHandler = (data) => {
        let queryStr = Object.keys(data)
            .map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
            })
            .join('&');
        setQueryString(queryStr);
    };

    const usernameBodyTemplate = (rowData) => {
        return <div className="">{rowData?.activity_name ? rowData?.activity_name : '-'}</div>;
    };
    const dateBodyTemplate = (rowData) => {
        return <>{moment(rowData?.datetime).format('MMM DD YYYY h:mm A')}</>;
    };
    const statusBodyTemplate = (rowData) => {
        return <>{rowData.status === 'pending' ? <Tag className="mr-2" value="Pending"></Tag> : <Tag className="mr-2" severity="success" value="Success"></Tag>}</>;
    };

    const template = {
        layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    const breadcrumbItems = [{ label: 'Stone Share' }, { label: 'Shopify Share Log' }];
    const dynamicHeight = Math.min(window.innerHeight * 4 + 1, 70) + 'vh';
    return (
        <>
            <div className="col px-0">
                <div className="breadcrumb-container flex justify-content-between align-items-center">
                    <div className="text-2xl font-bold">
                        <h3 className="m-0">Sync Activity Logs</h3>
                    </div>
                    <div className="ml-6 text-2xl">
                        <BreadCrumb home={{ icon: 'pi pi-home', url: '/' }} model={breadcrumbItems} />
                    </div>
                </div>
            </div>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <DataTable
                            scrollable
                            // showGridlines
                            stripedRows
                            scrollHeight={dynamicHeight}
                            size="small"
                            value={productSyncLog?.results}
                            selection={selectedProducts}
                            onSelectionChange={(e) => setSelectedProducts(e.value)}
                            dataKey="id"
                            className="datatable-responsive stoneShare-datatable-text"
                            emptyMessage="No record found."
                            loading={isLoading}
                            removableSort
                            resizableColumns
                            responsiveLayout="scroll"
                        >
                            <Column field="datetime" header="Date" style={{ maxWidth: '200px' }} body={dateBodyTemplate}></Column>
                            <Column field="activity_name" header="Activity" style={{ maxWidth: '150px' }} body={usernameBodyTemplate}></Column>
                            <Column field="status" header="Status" sortable style={{ maxWidth: '200px' }} body={statusBodyTemplate}></Column>
                        </DataTable>
                        <Paginator template={template} first={first} rows={rows} totalRecords={productSyncLog?.count} onPageChange={onPageChange} rowsPerPageOptions={[25, 50, 100, 500]}></Paginator>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StoneSyncLog;
