import { createSlice } from '@reduxjs/toolkit';
import { ViewFtpClientApi, addFtpClientApi, ftpClientListApi, modifyFtpClientApi, removeFtpClientApi } from '../../../service/kushDiamond/ftpService';
import { toast } from 'react-hot-toast';

export const ftpSlice = createSlice({
    name: 'ftp',
    initialState: {
        isLoading: false,
        ftpClientData: [],
        viewFtpClientData: null,
        addFtpClientMessage: null,
        modifyFtpClientMessage: null,
        removeFtpClientMessage: null,
        error: null
    },
    reducers: {
        setLoading: (state) => {
            state.isLoading = true;
        },

        ftpClientList: (state, action) => {
            state.isLoading = false;
            state.ftpClientData = action.payload;
        },

        viewFtpClientList: (state, action) => {
            state.isLoading = false;
            state.viewFtpClientData = action.payload?.data;
        },

        addFtpClientMessageList: (state, action) => {
            state.isLoading = false;
            state.addFtpClientMessage = action.payload;
        },

        modifyFtpClientMessageList: (state, action) => {
            state.isLoading = false;
            state.modifyFtpClientMessage = action.payload;
        },

        removeFtpClientMessageList: (state, action) => {
            state.isLoading = false;
            state.removeFtpClientMessage = action.payload;
        },

        ftpClientErrorList: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        ftpClientResetData: (state) => {
            state.isLoading = false;
            state.error = null;
            state.addFtpClientMessage = null;
            state.viewFtpClientData = null;
            state.modifyFtpClientMessage = null;
            state.removeFtpClientMessage = null;
        }
    }
});

export const { setLoading, ftpClientList, viewFtpClientList, addFtpClientMessageList, modifyFtpClientMessageList, removeFtpClientMessageList, ftpClientErrorList, ftpClientResetData } = ftpSlice.actions;
export default ftpSlice.reducer;

export const ftpClientListRequest = (queryString) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await ftpClientListApi(queryString, config);
        dispatch(ftpClientList(data));
    } catch (error) {
        dispatch(ftpClientErrorList(error?.response));
    }
};

export const viewFtpClientRequest = (userid) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await ViewFtpClientApi(userid, config);
        dispatch(viewFtpClientList(data));
    } catch (error) {
        if (error?.response?.data) {
            toast.error(error?.response?.data?.message);
            return dispatch(ftpClientErrorList(error?.response?.data?.message));
        }
    }
};

export const addFtpClientRequest = (addFtpClient) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };

        const { data } = await addFtpClientApi(addFtpClient, config);

        const { statusCode, message } = data;

        if (statusCode === 201) {
            toast.success(message);
            dispatch(addFtpClientMessageList(message));
        }
    } catch (error) {
        if (error?.response && error?.response?.data?.errors) {
            return dispatch(ftpClientErrorList(error?.response?.data?.errors));
        } else {
            return dispatch(ftpClientErrorList(error?.message));
        }
    }
};

export const modifyFtpClientRequest = (id, modifyFtpClient) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await modifyFtpClientApi(id, modifyFtpClient, config);

        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message);
            dispatch(modifyFtpClientMessageList(message));
        }
    } catch (error) {
        if (error?.response && error?.response?.data?.errors) {
            return dispatch(ftpClientErrorList(error?.response?.data?.errors));
        } else {
            return dispatch(ftpClientErrorList(error?.message));
        }
    }
};

export const removeFtpClientRequest = (removeId) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait your data is deleteing...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };

        const { data } = await removeFtpClientApi(removeId, config);
        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(removeFtpClientMessageList(message));
        }
    } catch (error) {
        const { statusCode, message } = error?.response?.data;
        if (statusCode === 422) {
            toast.error(message, {
                id: toastId
            });
            return dispatch(ftpClientErrorList(message));
        }
    }
};
