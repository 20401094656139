import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { bulkSelectedProductSyncRequest, bulkStatusRequest, productbulkSyncReset } from '../../redux/slices/productSlice';
import { SelectButton } from 'primereact/selectbutton';

const BulkMapperProcess = ({ viewBulkMapperModal, setViewBulkMapperModal, sourceStoreId, destinationStoreId }) => {
    const dispatch = useDispatch();

    const { bulkStatusData, productList } = useSelector((state) => state.product);
    const { verifiedStoreData } = useSelector((state) => state.store);
    const statusArr = bulkStatusData?.product_status_count?.map((ele) => ele);

    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedStatusValue, setSelectedStatusValue] = useState(null);
    // const statusArr = [
    //     { label: 'All', value: 'all' },
    //     { label: 'Active', value: 'active' },
    //     { label: 'Draft', value: 'draft' }
    // ];
    const getStatusType = () => {
        if (selectedStatus == 'ACTIVE') {
            return 'active';
        } else {
            return selectedStatus;
        }
    };
    const bulkStartSync = () => {
        const bulkSyncObj = {
            sync_type: 'bulk_sync',
            shopify_product_status: getStatusType(),
            destination_store_id: verifiedStoreData.store_id,
            source_store_id: destinationStoreId
        };
        dispatch(bulkSelectedProductSyncRequest(bulkSyncObj));
        dispatch(productbulkSyncReset());
        setViewBulkMapperModal(false);
        setSelectedStatus(null);
    };

    useEffect(() => {
        dispatch(bulkStatusRequest(sourceStoreId));
        return () => {
            dispatch(productbulkSyncReset());
        };
    }, []);

    const handleChange = (e) => {
        bulkStatusData?.product_status_count?.map((ele) => {
            if (ele.value == e.value) {
                setSelectedStatus(ele.label);
                setSelectedStatusValue(e.value);
            }
        });
    };

    const bulkmapperProcessDialogFooter = (
        <>
            {selectedStatus !== null ? <Button label="Start" icon="pi pi-check" onClick={() => bulkStartSync()} /> : <Button label="Start" icon="pi pi-check" className="opacity-60" />}
            {/* {<Button label="Start" icon="pi pi-check" onClick={() => bulkStartSync()} />} */}
            <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-secondary p-button-outlined mr-3"
                onClick={() => {
                    setViewBulkMapperModal(false);
                    setSelectedStatus(null);
                }}
            />
        </>
    );

    return (
        <Dialog
            header="Bulk Sync"
            className="bulk-modal-content bulk-title"
            visible={viewBulkMapperModal}
            draggable={false}
            style={{ width: '40vw' }}
            footer={bulkmapperProcessDialogFooter}
            onHide={() => {
                setViewBulkMapperModal(false);
                setSelectedStatus(null);
            }}
        >
            <div className="grid">
                <div className="col-12 py-1">
                    <div className="mt-2">
                        <h4 className="mt-0 text-lg font-semibold line-height-3 text-justify">
                            By clicking the start button, all source store products will sync and attempt to find the products that are matched in the destination store. If the SKU is found in the destination, the product will be updated. Otherwise,
                            a new product will be created.
                        </h4>
                        {/* <div className="text-center">
                            <Button label="Think-dev-test" className="p-button-outlined text-xl font-bold" />
                        </div> */}

                        {/* <Dropdown
                            value={selectedStatus}
                            onChange={(e) => setSelectedStatus(e.value)}
                            options={statusArr}
                            optionLabel="label"
                            placeholder="Select Status"
                            className="w-15rem mt-4 font-semibold"
                            style={{ borderColor: '#673AB7' }}
                            showClear
                        /> */}

                        <h5>Select Status</h5>
                        <SelectButton value={selectedStatus} onChange={handleChange} options={statusArr} optionLabel="label" />
                        <div className="border-1 border-400 surface-300 p-2 mt-3">
                            {/* <p className="text-lg font-semibold">A total of {productList.count ? productList.count : 0} products will be synced to the store.</p> */}
                            <p className="text-lg font-semibold">A total of {selectedStatusValue ? selectedStatusValue : 0} products will be synced to the store.</p>
                        </div>

                        {/* <h2 className="text-3xl font-bold">How to use bulk mapper:</h2>
                        <ul className="text-lg font-semibold line-height-3 mt-2 pl-5 text-justify">
                            <li> Ensure that each SKU is unique per variant.</li>
                            <li>Ensure that the SKUs match the Source and Destination stores.</li>
                            <li> When you click "Start", Syncio will search the two stores for SKU matches across the products that are not duplicate SKUs to map. Once mapped, the product status will update to "Synced" on the Sync Products page.</li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default BulkMapperProcess;
