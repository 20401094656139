import React, { useState, useEffect, useRef } from 'react';
import { Tag } from 'primereact/tag';
import { Image } from 'primereact/image';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { useDispatch, useSelector } from 'react-redux';
import { inventoryListDropdownApi, inventoryResetData } from '../../redux/slices/productInventorySlice';
import {
    allproductResetData,
    bulkSelectedProductSyncRequest,
    getProducts,
    productMapSourceRequest,
    productSyncDataRequest,
    productSyncReset,
    productUnSyncDataRequest,
    productUnSyncReset,
    productViewRequest,
    productViewReset,
    productViewSyncRequest,
    productbulkSyncReset,
    reSyncRequest
} from '../../redux/slices/productSlice';
import { Paginator } from 'primereact/paginator';
import { Sidebar } from 'primereact/sidebar';
import { Carousel } from 'primereact/carousel';
import ProductSidebarViewSync from './ProductSidebarViewSync';
import { Dialog } from 'primereact/dialog';
import BulkMapperProcess from './BulkMapperProcess';
import parse from 'html-react-parser';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { FilterMatchMode } from 'primereact/api';
import MapSidebar from './MapSidebar';

const ProductStoreDatatable = ({ sourceStoreId, destinationStoreId, destChangeValue, setDestChangeValue, storeName, destinationName }) => {
    console.log(sourceStoreId, destinationStoreId, 'sourceStoreId, destinationStoreId');
    const dispatch = useDispatch();
    const { inventoryData } = useSelector((state) => state.productInventory);
    const { verifiedStoreData } = useSelector((state) => state.store);
    const { isLoading, productList, viewProductData, viewProductSyncData, bulkSelectedProductSyncData, productSyncData, productunSyncData, productMapSourceData, modifyProductMapDestination } = useSelector((state) => state.product);
    // console.log(productSyncData, 'productSyncData');

    const [selectedProductArr, setSelectedProductArr] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(undefined);
    const [selectedProduct, setSelectedProduct] = useState(undefined);
    const [selectedTags, setSelectedTags] = useState(undefined);
    const [collection, setCollection] = useState(undefined);
    const [selectedStatus, setSelectedStatus] = useState(undefined);
    const [allFilterLabel, setAllFilterLabel] = useState({
        product_status: '',
        product_type: '',
        // tags: '',
        collection_name: '',
        status: ''
    });
    const [destIdState, setDestIdState] = useState(false);
    const [unSyncRowData, setUnSyncRowData] = useState(null);
    const [sort_order, setSort_order] = useState('desc');
    const [searchFilter, setSearchFilter] = useState({
        productTitle: '',
        productSku: ''
    });
    const [shopifyId, setShopifyId] = useState(null);
    const [checked, setChecked] = useState(false);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(25);
    const [visibleLeft, setVisibleLeft] = useState(false);
    const [viewSyncLeft, setViewSyncLeft] = useState(false);
    const [viewUnSyncModal, setViewUnSyncModal] = useState(false);
    const [multiviewUnSyncModal, setMultiViewUnSyncModal] = useState(false);
    const [viewBulkMapperModal, setViewBulkMapperModal] = useState(false);
    const [mapSidebarModal, setMapSidebarModal] = useState(false);
    const [openSyncModal, setOpenSyncModal] = useState(false);
    const [filters, setFilters] = useState(null);
    const [productView, setProductView] = useState([]);
    const toast = useRef(null);
    const dt = useRef(null);

    const CategoryItem = inventoryData?.stone_status?.map((product_status) => product_status);
    const productItem = inventoryData?.product_type?.map((product) => product);
    const tagName = inventoryData?.tag_name?.map((tag) => tag);
    const collectionName = inventoryData?.collection_name?.map((collection) => collection);
    // const statusName = inventoryData?.product_status?.map((status) => status);
    const totalSync = selectedProductArr?.filter((ele) => ele.map_pro_id?.length == 0);
    const mapProID = selectedProductArr?.map((ele) => ele.map_pro_id).flat();
    const totalSyncProduct = mapProID?.filter((ele) => ele.synced_status == false);
    const totalunSyncProduct = mapProID?.filter((ele) => ele.synced_status == true);

    const statusName = [
        { label: 'All', value: 'all' },
        { label: 'Synced', value: true },
        { label: 'Not Synced', value: false }
    ];

    const table_data = {
        page: 1,
        per_page: 25,
        sort_order: sort_order
    };

    const [queryString, setQueryString] = useState(`page=${table_data.page}&per_page=${table_data.per_page}`);

    const getQueryParam = () => {
        if (verifiedStoreData?.is_verified) {
            // return `${queryString}&source_store_id=${destinationStoreId ?? ''}&store_type=${verifiedStoreData.store_type}`;
            return `${queryString}&source_store_id=${sourceStoreId ?? ''}&store_type=${verifiedStoreData.store_type}`;
        } else {
            return `${queryString}`;
        }
    };
    const tableChangeHandler = (data) => {
        let queryStr = Object.keys(data)
            .map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
            })
            .join('&');
        setQueryString(queryStr);
    };

    const template = {
        layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    useEffect(() => {
        initFilters();
        // if (bulkSelectedProductSyncData !== null) {
        //     return setOpenSyncModal(false);
        // }
        if (destChangeValue == true) {
            dispatch(inventoryListDropdownApi(destinationStoreId));
            tableChangeHandler({ ...table_data, destination_store_id: verifiedStoreData.store_id, product_status: '' });
            setSelectedProductArr([]);
            setDestIdState(true);
            setSearchFilter({ ...searchFilter, productTitle: '', productSku: '' });
            setSelectedCategory('');
            setSelectedProduct(undefined);
            setSelectedTags(undefined);
            setSelectedStatus(undefined);
            setChecked(false);
            setFirst(0);
            return setDestChangeValue(false);
        }
        if (verifiedStoreData.store_id == undefined) {
            return setDestIdState(false);
        }
        if (destinationStoreId !== undefined) {
            console.log(destinationStoreId, 'in this ');

            dispatch(getProducts(getQueryParam()));
        }
        return () => {
            dispatch(productbulkSyncReset());
            dispatch(allproductResetData());
            // dispatch(inventoryResetData());
        };
    }, [dispatch, queryString, destChangeValue, verifiedStoreData.store_id, destinationStoreId]);

    useEffect(() => {
        if (productSyncData !== null) {
            dispatch(getProducts(getQueryParam()));
        }
        if (productunSyncData !== null) {
            dispatch(getProducts(getQueryParam()));
            // setViewUnSyncModal(false);
            // setMultiViewUnSyncModal(false);
            // setSelectedProductArr([]);
        }

        if (destIdState == false) {
            setSelectedCategory(undefined);
        }

        if (modifyProductMapDestination !== null) {
            dispatch(getProducts(getQueryParam()));
        }
        return () => {
            dispatch(productSyncReset());
            dispatch(productUnSyncReset());
            // dispatch(inventoryResetData());
        };
    }, [productSyncData, productunSyncData, destIdState, modifyProductMapDestination]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        tableChangeHandler({ ...table_data, page: event.page + 1, per_page: event.rows, destination_store_id: verifiedStoreData.store_id });
    };

    const initFilters = () => {
        setFilters({
            product: { value: null, matchMode: FilterMatchMode.CONTAINS },
            sku: { value: null, matchMode: FilterMatchMode.CONTAINS }
        });
    };

    const handleCategory = (e) => {
        setSelectedCategory(e.value);
        setSelectedProductArr([]);
        inventoryData?.stone_status?.map((ele) => {
            if (ele.value == '') {
                setAllFilterLabel({ ...allFilterLabel, product_status: '' });
                return tableChangeHandler({
                    ...table_data,
                    product_status: '',
                    product_type: allFilterLabel?.product_type == undefined ? '' : allFilterLabel?.product_type,
                    product_quantity: checked,
                    tags: allFilterLabel?.tags == undefined ? '' : allFilterLabel?.tags,
                    sync_status: selectedStatus == undefined ? '' : selectedStatus,
                    search_title: searchFilter?.productTitle,
                    search_sku: searchFilter?.productSku,
                    destination_store_id: verifiedStoreData.store_id
                });
            } else if (ele.value == e.value) {
                setAllFilterLabel({ ...allFilterLabel, product_status: ele.label });
                return tableChangeHandler({
                    ...table_data,
                    product_status: ele.label,
                    product_type: allFilterLabel?.product_type == undefined ? '' : allFilterLabel?.product_type,
                    product_quantity: checked,
                    tags: allFilterLabel?.tags == undefined ? '' : allFilterLabel?.tags,
                    sync_status: selectedStatus == undefined ? '' : selectedStatus,
                    search_title: searchFilter?.productTitle,
                    search_sku: searchFilter?.productSku,
                    destination_store_id: verifiedStoreData.store_id
                });
            }
        });
    };

    const handleProductType = (e) => {
        setSelectedProduct(e.value);
        setSelectedProductArr([]);
        inventoryData?.product_type?.map((ele) => {
            if (ele.value == '') {
                setAllFilterLabel({ ...allFilterLabel, product_type: '' });
                return tableChangeHandler({
                    ...table_data,
                    product_status: allFilterLabel?.product_status == undefined ? '' : allFilterLabel?.product_status,
                    product_type: '',
                    product_quantity: checked,
                    tags: allFilterLabel?.tags == undefined ? '' : allFilterLabel?.tags,
                    sync_status: selectedStatus == undefined ? '' : selectedStatus,
                    search_title: searchFilter?.productTitle,
                    search_sku: searchFilter?.productSku,
                    destination_store_id: verifiedStoreData.store_id
                });
            } else if (ele.value == e.value) {
                setAllFilterLabel({ ...allFilterLabel, product_type: ele.label });
                return tableChangeHandler({
                    ...table_data,
                    product_status: allFilterLabel?.product_status == undefined ? '' : allFilterLabel?.product_status,
                    product_type: ele.label,
                    product_quantity: checked,
                    tags: allFilterLabel?.tags == undefined ? '' : allFilterLabel?.tags,
                    sync_status: selectedStatus == undefined ? '' : selectedStatus,
                    search_title: searchFilter?.productTitle,
                    search_sku: searchFilter?.productSku,
                    destination_store_id: verifiedStoreData.store_id
                });
            }
        });
    };

    const handleTags = (e) => {
        setSelectedTags(e.value);
        setSelectedProductArr([]);
        inventoryData?.tag_name?.map((ele) => {
            if (ele.value == '') {
                setAllFilterLabel({ ...allFilterLabel, tags: '' });
                return tableChangeHandler({
                    ...table_data,
                    product_status: allFilterLabel?.product_status == undefined ? '' : allFilterLabel?.product_status,
                    product_type: allFilterLabel?.product_type == undefined ? '' : allFilterLabel?.product_type,
                    product_quantity: checked,
                    tags: 'all',
                    sync_status: selectedStatus == undefined ? '' : selectedStatus,
                    search_title: searchFilter?.productTitle,
                    search_sku: searchFilter?.productSku,
                    destination_store_id: verifiedStoreData.store_id
                });
            } else if (ele.value == e.value) {
                setAllFilterLabel({ ...allFilterLabel, tags: ele.label });
                return tableChangeHandler({
                    ...table_data,
                    product_status: allFilterLabel?.product_status == undefined ? '' : allFilterLabel?.product_status,
                    product_type: allFilterLabel?.product_type == undefined ? '' : allFilterLabel?.product_type,
                    product_quantity: checked,
                    tags: ele.label,
                    sync_status: selectedStatus == undefined ? '' : selectedStatus,
                    search_title: searchFilter?.productTitle,
                    search_sku: searchFilter?.productSku,
                    destination_store_id: verifiedStoreData.store_id
                });
            }
        });
    };
    const handleCollection = (e) => {
        setCollection(e.value);
        setSelectedProductArr([]);
        inventoryData?.collection_name?.map((ele) => {
            if (ele.value == '') {
                setAllFilterLabel({ ...allFilterLabel, collection_name: '' });
                return tableChangeHandler({
                    ...table_data,
                    product_status: allFilterLabel?.product_status == undefined ? '' : allFilterLabel?.product_status,
                    product_type: allFilterLabel?.product_type == undefined ? '' : allFilterLabel?.product_type,
                    product_quantity: checked,
                    // tags: 'all',
                    collection_name: '',
                    sync_status: selectedStatus == undefined ? '' : selectedStatus,
                    search_title: searchFilter?.productTitle,
                    search_sku: searchFilter?.productSku,
                    destination_store_id: verifiedStoreData.store_id
                });
            } else if (ele.value == e.value) {
                setAllFilterLabel({ ...allFilterLabel, collection_name: ele.label });
                return tableChangeHandler({
                    ...table_data,
                    product_status: allFilterLabel?.product_status == undefined ? '' : allFilterLabel?.product_status,
                    product_type: allFilterLabel?.product_type == undefined ? '' : allFilterLabel?.product_type,
                    product_quantity: checked,
                    // tags: ele.label,
                    collection_name: ele.label,
                    sync_status: selectedStatus == undefined ? '' : selectedStatus,
                    search_title: searchFilter?.productTitle,
                    search_sku: searchFilter?.productSku,
                    destination_store_id: verifiedStoreData.store_id
                });
            }
        });
    };

    const handleStatus = (e) => {
        setSelectedStatus(e.value);
        setSelectedProductArr([]);
        setAllFilterLabel({ ...allFilterLabel, status: e.value });
        tableChangeHandler({
            ...table_data,
            product_status: allFilterLabel?.product_status == undefined ? '' : allFilterLabel?.product_status,
            product_type: allFilterLabel?.product_type == undefined ? '' : allFilterLabel?.product_type,
            product_quantity: checked,
            // tags: allFilterLabel?.tags == undefined ? '' : allFilterLabel?.tags,
            collection_name: allFilterLabel?.collection_name == undefined ? '' : allFilterLabel?.collection_name,
            sync_status: e.value,
            search_title: searchFilter?.productTitle,
            search_sku: searchFilter?.productSku,
            destination_store_id: verifiedStoreData.store_id
        });
    };

    const handleCheckStatus = (e) => {
        setChecked(e.value);
        setSelectedProductArr([]);
        tableChangeHandler({
            ...table_data,
            product_status: allFilterLabel?.product_status == undefined ? '' : allFilterLabel?.product_status,
            product_type: allFilterLabel?.product_type == undefined ? '' : allFilterLabel?.product_type,
            product_quantity: e.value,
            // tags: allFilterLabel?.tags == undefined ? '' : allFilterLabel?.tags,
            collection_name: allFilterLabel?.collection_name == undefined ? '' : allFilterLabel?.collection_name,
            sync_status: selectedStatus == undefined ? '' : selectedStatus,
            search_title: searchFilter?.productTitle,
            search_sku: searchFilter?.productSku,
            destination_store_id: verifiedStoreData.store_id
        });
    };

    const productFilterApplyCallback = (options) => {
        tableChangeHandler({
            ...table_data,
            product_status: allFilterLabel?.product_status == undefined ? '' : allFilterLabel?.product_status,
            product_type: allFilterLabel?.product_type == undefined ? '' : allFilterLabel?.product_type,
            product_quantity: checked,
            // tags: allFilterLabel?.tags == undefined ? '' : allFilterLabel?.tags,
            collection_name: allFilterLabel?.collection_name == undefined ? '' : allFilterLabel?.collection_name,
            sync_status: selectedStatus == undefined ? '' : selectedStatus,
            search_title: searchFilter?.productTitle,
            search_sku: searchFilter?.productSku,
            destination_store_id: destinationStoreId
        });
        options.filterApplyCallback();
    };

    const productFilterClearCallback = (options) => {
        setSearchFilter({ ...searchFilter, productTitle: '' });
        tableChangeHandler({
            ...table_data,
            product_status: allFilterLabel?.product_status == undefined ? '' : allFilterLabel?.product_status,
            product_type: allFilterLabel?.product_type == undefined ? '' : allFilterLabel?.product_type,
            product_quantity: checked,
            // tags: allFilterLabel?.tags == undefined ? '' : allFilterLabel?.tags,
            collection_name: allFilterLabel?.collection_name == undefined ? '' : allFilterLabel?.collection_name,
            sync_status: selectedStatus == undefined ? '' : selectedStatus,
            search_title: '',
            search_sku: searchFilter?.productSku,
            destination_store_id: destinationStoreId
        });
        options.filterClearCallback();
    };

    const productFilterClearTemplate = (options) => {
        return <Button type="button" className="p-button-outlined p-button-danger" onClick={() => productFilterClearCallback(options)} label="Clear" />;
    };

    const productFilterApplyTemplate = (options) => {
        return <Button type="button" className="p-button-outlined" icon="pi pi-check" onClick={() => productFilterApplyCallback(options)} severity="success" label="Apply" />;
    };

    const handleTitleFilter = (e) => {
        setSearchFilter({ ...searchFilter, productTitle: e.target.value });
    };

    const productFilterTemplate = () => {
        return <InputText type="search" className="w-15rem" value={searchFilter?.productTitle} onChange={(e) => handleTitleFilter(e)} placeholder="Search Products" />;
    };

    const productSkuFilterApplyCallback = (options) => {
        tableChangeHandler({
            ...table_data,
            product_status: allFilterLabel?.product_status == undefined ? '' : allFilterLabel?.product_status,
            product_type: allFilterLabel?.product_type == undefined ? '' : allFilterLabel?.product_type,
            product_quantity: checked,
            // tags: allFilterLabel?.tags == undefined ? '' : allFilterLabel?.tags,
            collection_name: allFilterLabel?.collection_name == undefined ? '' : allFilterLabel?.collection_name,
            sync_status: selectedStatus == undefined ? '' : selectedStatus,
            search_title: searchFilter?.productTitle,
            search_sku: searchFilter?.productSku,
            destination_store_id: destinationStoreId
        });
        options.filterApplyCallback();
    };

    const productSkuFilterClearCallback = (options) => {
        setSearchFilter({ ...searchFilter, productSku: '' });
        tableChangeHandler({
            ...table_data,
            product_status: allFilterLabel?.product_status == undefined ? '' : allFilterLabel?.product_status,
            product_type: allFilterLabel?.product_type == undefined ? '' : allFilterLabel?.product_type,
            product_quantity: checked,
            tags: allFilterLabel?.tags == undefined ? '' : allFilterLabel?.tags,
            sync_status: selectedStatus == undefined ? '' : selectedStatus,
            search_title: searchFilter?.productTitle,
            search_sku: '',
            destination_store_id: destinationStoreId
        });
        options.filterClearCallback();
    };

    const productSkuFilterClearTemplate = (options) => {
        return <Button type="button" className="p-button-outlined p-button-danger" onClick={() => productSkuFilterClearCallback(options)} label="Clear" />;
    };

    const productSkuFilterApplyTemplate = (options) => {
        return <Button type="button" className="p-button-outlined" icon="pi pi-check" onClick={() => productSkuFilterApplyCallback(options)} severity="success" label="Apply" />;
    };

    const handleSkuFilter = (e) => {
        setSearchFilter({ ...searchFilter, productSku: e.target.value });
    };
    const productSkuFilterTemplate = () => {
        return <InputText type="search" className="w-15rem" value={searchFilter?.productSku} onChange={(e) => handleSkuFilter(e)} placeholder="Search SKU" />;
    };

    const openSidebar = (rowData, id) => {
        dispatch(productViewRequest(id));
        setVisibleLeft(true);
        setProductView(rowData);
    };

    const closeSidebar = () => {
        setVisibleLeft(false);
        dispatch(productViewReset());
    };

    const productBodyTemplate = (rowData) => {
        return (
            <>
                <div className="flex align-items-center font-semibold">
                    <Image src={rowData?.Product_image_url || 'https://placehold.co/45x45/png'} alt="Image" width="45" height="45" preview />
                    <h5 className="my-0 mx-3 font-bold text-lg text-color-secondary">{rowData?.product_title}</h5>
                </div>
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        let data = rowData?.map_pro_id?.[0]?.synced_status ? 'Synced' : 'Not Synced';
        return (
            <>
                {/* <span className={`customer-badge ${rowData?.map_pro_id?.[0]?.synced_status == true ? 'status-qualified' : 'status-negotiation'}`}>{rowData?.map_pro_id?.[0]?.synced_status == true ? 'Synced' : 'Not Synced'}</span> */}
                <span className={`customer-badge ${rowData?.map_pro_id?.[0]?.synced_status == true ? 'status-qualified' : 'status-negotiation'}`}>{data}</span>
            </>
        );
    };
    const productStatusBodyTemplate = (rowData) => {
        return (
            <>
                <span className={`customer-badge ${rowData?.product_status == 'ACTIVE' ? 'status-qualified' : 'status-negotiation'}`}>{rowData?.product_status == 'ACTIVE' ? 'Active' : 'Draft'}</span>
            </>
        );
    };

    const titleTemplate = () => {
        return (
            <>
                <div className="font-semibold">Default title</div>
            </>
        );
    };

    const inventoryBodyTemplate = (rowData) => {
        return (
            <>
                <div className="font-semibold">{rowData?.inventory_quantity}</div>
            </>
        );
    };

    const showWarn = () => {
        toast.current.show({ severity: 'warn', detail: 'Please select a Destination store', life: 3000 });
    };

    const handleSync = (rowData) => {
        const syncObj = {
            product_id: rowData.product_id,
            // source_store_id: destinationStoreId,
            source_store_id: sourceStoreId,
            // destination_store_id: sourceStoreId
            destination_store_id: verifiedStoreData.store_id
        };

        dispatch(productSyncDataRequest(syncObj));
        dispatch(productSyncReset());
    };

    const handleViewSync = (rowData) => {
        setViewSyncLeft(!viewSyncLeft);
        setProductView(rowData);
        const viewSync = {
            product_id: rowData.product_id,
            destination_store_id: verifiedStoreData.store_id,
            // source_store_id: destinationStoreId
            source_store_id: sourceStoreId
        };
        dispatch(productViewSyncRequest(viewSync));
    };

    const openMapSider = (id) => {
        setMapSidebarModal(true);
        dispatch(productMapSourceRequest(id, sourceStoreId));
    };

    const handleUnSync = (rowData) => {
        setViewUnSyncModal(true);
        setUnSyncRowData(rowData);
    };

    const handleReSync = (rowData) => {
        const reSyncObj = {
            product_id: rowData.product_id,
            // source_store_id: destinationStoreId,
            source_store_id: sourceStoreId,
            destination_store_id: verifiedStoreData.store_id
        };
        dispatch(reSyncRequest(reSyncObj));
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex">
                {rowData?.map_pro_id?.length > 0 && rowData?.map_pro_id?.[0]?.synced_status == true ? (
                    <>
                        <Button
                            label="View Sync"
                            className="font-bold p-button-info mr-2 py-0 px-2 text-sm"
                            tooltip="View Sync"
                            tooltipOptions={{ position: 'top' }}
                            onClick={destinationStoreId !== undefined ? () => handleViewSync(rowData) : () => showWarn()}
                        />
                        <Button
                            label="Resync"
                            className="font-bold p-button-help mr-2  py-0 px-2 text-sm"
                            tooltip="Resync products"
                            tooltipOptions={{ position: 'top' }}
                            onClick={destinationStoreId !== undefined ? () => handleReSync(rowData) : () => showWarn()}
                        />
                        <Button
                            label="Unsync"
                            className="font-bold p-button-danger mr-2   py-0 px-2 text-sm"
                            tooltip="Unsync products"
                            tooltipOptions={{ position: 'top' }}
                            onClick={destinationStoreId !== undefined ? () => handleUnSync(rowData) : () => showWarn()}
                        />
                    </>
                ) : (
                    <>
                        <Button
                            label="Map"
                            className="font-bold p-button-secondary mr-2  py-0 px-2 text-sm"
                            tooltip="Map products"
                            tooltipOptions={{ position: 'top' }}
                            onClick={
                                destinationStoreId !== undefined
                                    ? () => {
                                          openMapSider(rowData.product_id);
                                          setShopifyId(rowData?.shopify_product_id);
                                      }
                                    : () => showWarn()
                            }
                        />
                        <Button
                            label="Sync"
                            className="font-bold p-button-success mr-2  py-0 px-2 text-sm"
                            tooltip="Sync products"
                            tooltipOptions={{ position: 'top' }}
                            onClick={destinationStoreId !== undefined ? () => handleSync(rowData) : () => showWarn()}
                        />
                    </>
                )}

                <Button
                    className="   "
                    tooltip="View products"
                    label="View products"
                    tooltipOptions={{ position: 'top' }}
                    // icon="pi pi-eye"
                    onClick={() => openSidebar(rowData, rowData.product_id)}
                    // style={{ height: '30px', width: '30px' }}
                ></Button>
            </div>
        );
    };

    const openBulkMapper = () => {
        setViewBulkMapperModal(true);
    };

    const onRefreshDataTable = () => {
        dispatch(getProducts(getQueryParam()));
    };

    const syncMultiProducts = () => {
        let tempArr = [];
        totalSync?.map((ele) => {
            return tempArr.push(ele.shopify_product_id);
        });
        const syncedStatusVal = selectedProductArr?.filter((ele) => ele.map_pro_id.length !== 0);
        syncedStatusVal?.map((elem) => {
            elem.map_pro_id.map((item) => {
                if (item.synced_status == false) {
                    return tempArr.push(elem.shopify_product_id);
                }
            });
        });

        const syncProductsObj = {
            sync_type: 'selected_sync',
            shopify_product_id_list: tempArr,
            // source_store_id: destinationStoreId,
            source_store_id: sourceStoreId,
            destination_store_id: verifiedStoreData.store_id
        };
        dispatch(bulkSelectedProductSyncRequest(syncProductsObj));
        dispatch(productbulkSyncReset());
        setSelectedProductArr([]);
        setOpenSyncModal(false);
    };

    const handleUnSyncKeep = () => {
        const destinationShopifyProductID = unSyncRowData?.map_pro_id?.map((ele) => ele.destination_shopify_product_id);
        const totalDestinationProductID = totalunSyncProduct?.map((ele) => ele.destination_shopify_product_id);
        if (viewUnSyncModal == true) {
            const unSyncKeepObj = {
                Unsync_status: 'keep',
                destination_shopify_product_id: destinationShopifyProductID,
                // source_store_id: destinationStoreId,
                source_store_id: sourceStoreId,
                destination_store_id: verifiedStoreData.store_id
            };
            dispatch(productUnSyncDataRequest(unSyncKeepObj));
            setViewUnSyncModal(false);
            setMultiViewUnSyncModal(false);
            setSelectedProductArr([]);
            return dispatch(productUnSyncReset());
        } else if (multiviewUnSyncModal == true) {
            const multiunSyncKeepObj = {
                Unsync_status: 'keep',
                destination_shopify_product_id: totalDestinationProductID,
                // source_store_id: destinationStoreId,
                source_store_id: sourceStoreId,
                destination_store_id: verifiedStoreData.store_id
            };
            dispatch(productUnSyncDataRequest(multiunSyncKeepObj));
            setViewUnSyncModal(false);
            setMultiViewUnSyncModal(false);
            setSelectedProductArr([]);
            return dispatch(productUnSyncReset());
        }
    };

    const handleUnSyncDelete = () => {
        const destinationShopifyProductID = unSyncRowData?.map_pro_id?.map((ele) => ele.destination_shopify_product_id);
        const totalDestinationProductID = totalunSyncProduct?.map((ele) => ele.destination_shopify_product_id);
        if (viewUnSyncModal == true) {
            const unSyncDeleteObj = {
                Unsync_status: 'delete',
                destination_shopify_product_id: destinationShopifyProductID,
                // source_store_id: destinationStoreId,
                source_store_id: sourceStoreId,
                destination_store_id: verifiedStoreData.store_id
            };
            dispatch(productUnSyncDataRequest(unSyncDeleteObj));
            setViewUnSyncModal(false);
            setMultiViewUnSyncModal(false);
            setSelectedProductArr([]);
            return dispatch(productUnSyncReset());
        } else if (multiviewUnSyncModal == true) {
            const multiunSyncKeepObj = {
                Unsync_status: 'delete',
                destination_shopify_product_id: totalDestinationProductID,
                // source_store_id: destinationStoreId,
                source_store_id: sourceStoreId,
                destination_store_id: verifiedStoreData.store_id
            };
            dispatch(productUnSyncDataRequest(multiunSyncKeepObj));
            setViewUnSyncModal(false);
            setMultiViewUnSyncModal(false);
            setSelectedProductArr([]);
            return dispatch(productUnSyncReset());
        }
    };

    const syncProductsDialogFooter = (
        <>
            <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-outlined p-button-secondary"
                onClick={() => {
                    setOpenSyncModal(false);
                    setSelectedProductArr([]);
                }}
            />
            <Button label="Sync" icon="pi pi-check" onClick={() => syncMultiProducts()} />
        </>
    );

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div className="my-2">
                <Button label="Bulk Sync" icon="pi pi-cog" className="font-bold" onClick={openBulkMapper} />
            </div>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <Button icon="pi pi-refresh" className="p-button-rounded mr-2" tooltip="Refresh Table" tooltipOptions={{ position: 'top' }} onClick={onRefreshDataTable} />
            </span>
        </div>
    );

    const dynamicHeight = Math.min(window.innerHeight * 4 + 1, 70) + 'vh';

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                {/* <div className="card "> */}
                <Toast ref={toast} />
                {/* <h5 className="font-bold mt-0 text-color-secondary">Sync and manage your inventory</h5> */}
                <div className="grid">
                    <div className="col-12 md:col-2">
                        <Dropdown value={selectedCategory} onChange={(e) => handleCategory(e)} options={destIdState == true ? CategoryItem : null} optionLabel="label" placeholder="Select Product Status" className="w-full" showClear />
                    </div>
                    <div className="col-12 md:col-2">
                        <Dropdown value={selectedProduct} onChange={(e) => handleProductType(e)} options={destIdState == true ? productItem : null} optionLabel="label" placeholder="Select Product Type" className="w-full" showClear />
                    </div>
                    {/* <div className="col-12 md:col-2">
                        <Dropdown value={selectedTags} onChange={(e) => handleTags(e)} options={destIdState == true ? tagName : null} optionLabel="label" placeholder="Select Tags" className="w-full" showClear />
                    </div> */}
                    <div className="col-12 md:col-2">
                        <Dropdown value={collection} onChange={(e) => handleCollection(e)} options={destIdState == true ? collectionName : null} optionLabel="label" placeholder="Select Collections" className="w-full" showClear />
                    </div>
                    <div className="col-12 md:col-3">
                        <Dropdown value={selectedStatus} onChange={(e) => handleStatus(e)} options={destIdState == true ? statusName : null} optionLabel="label" placeholder="Select Status" className="w-full" showClear />
                    </div>
                    <div className="col-12 md:col-3">
                        <div className="flex flex-column md:flex-row  md:align-items-center" style={{ background: '#ffffff', padding: '0.4rem 0.5rem', border: '1px solid #ced4da', borderRadius: '4px' }}>
                            {destIdState == true ? <InputSwitch checked={checked} onChange={(e) => handleCheckStatus(e)} /> : <InputSwitch disabled />}

                            <h5 className="my-0 ml-4 font-bold text-color-secondary">Hide 0 Stock Products</h5>
                        </div>
                    </div>
                </div>
                {/* <div className="flex flex-column md:flex-row  md:align-items-center my-3">
                        {destIdState == true ? <InputSwitch checked={checked} onChange={(e) => handleCheckStatus(e)} /> : <InputSwitch disabled />}

                        <h5 className="my-0 ml-4 font-bold text-color-secondary">Hide 0 Stock Products</h5>
                    </div> */}
                <div className="flex justify-content-between align-items-center my-3">
                    <div className="flex align-items-center">
                        {destIdState == true ? (
                            <Button icon="pi pi-refresh" className="p-button-rounded mr-2" tooltip="Refresh Table" tooltipOptions={{ position: 'top' }} onClick={onRefreshDataTable} />
                        ) : (
                            <Button icon="pi pi-refresh" className="p-button-rounded opacity-60 mr-2" tooltip="Refresh Table" tooltipOptions={{ position: 'top' }} />
                        )}
                        <h5 className="my-0 font-bold text-color-secondary mr-5">{selectedProductArr?.length} Products Selected</h5>
                        {selectedProductArr?.length > 0 && (totalSync?.length || totalSyncProduct?.length) ? (
                            <Button label={`Total Sync Products: ${totalSync?.length + totalSyncProduct?.length}`} className="p-button-secondary font-bold p-button-outlined mr-3" onClick={() => setOpenSyncModal(true)} />
                        ) : (
                            <Button label={`Total Sync Products: 0`} className="p-button-secondary font-bold p-button-outlined mr-3 opacity-60" />
                        )}
                        {selectedProductArr?.length > 0 && totalunSyncProduct?.length > 0 ? (
                            <Button label={`Total UnSync Products: ${totalunSyncProduct?.length}`} className="p-button-secondary font-bold p-button-outlined mr-3" onClick={() => setMultiViewUnSyncModal(true)} />
                        ) : (
                            <Button label={`Total UnSync Products: ${totalunSyncProduct?.length}`} className="p-button-secondary font-bold p-button-outlined mr-3 opacity-60" />
                        )}
                    </div>
                    <div>{destIdState == true ? <Button label="Bulk Sync" icon="pi pi-cog" className="font-bold" onClick={openBulkMapper} /> : <Button label="Bulk Sync" icon="pi pi-cog" className="opacity-60 font-bold" />}</div>
                </div>
                <DataTable
                    ref={dt}
                    // scrollHeight="350px"
                    scrollHeight={dynamicHeight}
                    scrollable
                    showGridlines
                    stripedRows
                    removableSort
                    size="small"
                    selection={selectedProductArr}
                    value={productList?.results}
                    onSelectionChange={(e) => setSelectedProductArr(e.value)}
                    dataKey="product_id"
                    className="datatable-responsive stoneShare-datatable-content"
                    globalFilter={globalFilter}
                    emptyMessage="No record found."
                    loading={isLoading}
                    // header={header}
                    responsiveLayout="scroll"
                    selectionMode={checked ? null : 'checkbox'}
                    filters={filters}
                    fixedheaderscrollheight={dynamicHeight}
                >
                    <Column selectionMode="multiple" style={{ maxWidth: '40px' }}></Column>
                    <Column
                        field="product"
                        header="Product"
                        className="font-bold"
                        style={{ minWidth: '50rem', display: 'block' }}
                        body={productBodyTemplate}
                        showFilterMatchModes={false}
                        filter={destIdState == true ? true : false}
                        filterElement={productFilterTemplate}
                        filterClear={productFilterClearTemplate}
                        filterApply={productFilterApplyTemplate}
                    ></Column>
                    <Column
                        field="sku"
                        className="font-bold"
                        header="SKU"
                        showFilterMatchModes={false}
                        style={{ minWidth: '8rem' }}
                        filter={destIdState == true ? true : false}
                        filterElement={productSkuFilterTemplate}
                        filterClear={productSkuFilterClearTemplate}
                        filterApply={productSkuFilterApplyTemplate}
                    ></Column>
                    <Column field="inventory" header="Inventory" className="font-bold" style={{ minWidth: '8rem' }} body={inventoryBodyTemplate}></Column>
                    <Column field="product_status" header="Product Status" className="font-bold" sortable style={{ minWidth: '10rem' }} body={productStatusBodyTemplate}></Column>
                    <Column field="synced_status" header="Sync Status" className="font-bold" style={{ minWidth: '10rem' }} body={statusBodyTemplate}></Column>
                    <Column field="actions" header="Actions" className="font-bold" style={{ minWidth: '30rem' }} body={actionBodyTemplate}></Column>
                </DataTable>
                {/* <Paginator template={template} first={first} rows={rows} totalRecords={productList?.count} onPageChange={onPageChange} rowsPerPageOptions={[25, 50, 100, 500]}></Paginator> */}
                {destIdState == true ? <Paginator template={template} first={first} rows={rows} totalRecords={productList?.count} onPageChange={onPageChange} rowsPerPageOptions={[25, 50, 100, 200]}></Paginator> : null}
                {/* </div> */}
            </div>
            <Dialog
                visible={openSyncModal}
                style={{ width: '450px' }}
                header="Confirm"
                modal
                onHide={() => {
                    setOpenSyncModal(false);
                    setSelectedProductArr([]);
                }}
                footer={syncProductsDialogFooter}
            >
                <div className="flex align-items-center">
                    <i className="pi pi-sync mr-3 text-yellow-500" style={{ fontSize: '1.5rem' }} />
                    <span className="font-bold text-xl">Are you sure you want to Sync Products?</span>
                </div>
            </Dialog>
            <Dialog
                header="Select an unsync option:"
                visible={multiviewUnSyncModal == true ? multiviewUnSyncModal : viewUnSyncModal}
                style={{ width: '50%' }}
                onHide={() => (multiviewUnSyncModal == true ? setMultiViewUnSyncModal(false) : setViewUnSyncModal(false))}
                className="unsync-modal-content cross-content"
            >
                <div className="grid">
                    <div className="col-6">
                        <div className="mt-3 card shadow-4 cursor-pointer border-round-xl">
                            <div className="text-center">
                                <i className="pi pi-link text-5xl" style={{ color: '#607d8b' }}></i>
                                <h4 className="font-bold m-3">Unsync and Keep</h4>
                                <h4 className="font-semibold text-sm mt-4 mb-3">Unsync without deleting the product from your destination store.</h4>
                                <Button label="Unsync and Keep" className="p-button-secondary shadow-3 font-bold" onClick={() => handleUnSyncKeep()} />
                            </div>
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="mt-3 card shadow-4 cursor-pointer border-round-xl">
                            <div className="text-center">
                                <i className="pi pi-trash text-5xl" style={{ color: 'red' }}></i>
                                <h4 className="font-bold m-3">Unsync and Delete</h4>
                                <h4 className="font-semibold text-sm mt-4 mb-3">Unsync and delete the product from your destination store.</h4>
                                <Button label="Unsync and Delete" className="p-button-danger shadow-3 font-bold" onClick={() => handleUnSyncDelete()} />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            {viewBulkMapperModal && <BulkMapperProcess viewBulkMapperModal={viewBulkMapperModal} setViewBulkMapperModal={setViewBulkMapperModal} destinationStoreId={destinationStoreId} sourceStoreId={sourceStoreId} />}

            <Sidebar visible={visibleLeft} style={{ width: '700px' }} onHide={() => closeSidebar()} baseZIndex={1000} position="right">
                {viewProductData?.length !== 0 ? (
                    <div className="container">
                        <div className="grid">
                            <div className="col-6">
                                <h4 className="font-bold" style={{ color: '#3F51B5' }}>
                                    {viewProductData?.title}
                                </h4>
                                <p className="line-height-1 font-bold">Product ID: {viewProductData?.id}</p>
                                <p className="line-height-1 font-bold">Product Status: {viewProductData?.product_status} </p>
                                <p className="line-height-1 font-bold">Vendor: {viewProductData?.vendor}</p>
                                <p className="font-bold">
                                    Tags:
                                    <span className="ml-1">
                                        {viewProductData?.tags?.map((ele, index) => {
                                            return <Tag className="mr-2 p-tag-rounded my-1 bg-green-800" icon="pi pi-tags" value={ele} key={index}></Tag>;
                                        })}
                                    </span>
                                </p>
                            </div>
                            <div className="col-6">
                                <h4 className="font-bold">PRODUCT DESCRIPTION</h4>
                                <ul className="px-3 font-bold">{parse(`${viewProductData?.description}`)}</ul>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12">
                                <h4 className="font-bold">1 variants in total</h4>
                                <DataTable ref={dt} scrollHeight="350px" size="small" value={[viewProductData]} dataKey="id" className="datatable-responsive datatable_container">
                                    <Column field="title" header="Title" className="font-bold" body={titleTemplate}></Column>
                                    <Column field="sku" header="Sku" className="font-bold"></Column>
                                    <Column field="quantity" header="QUANTITY" className="font-bold"></Column>
                                </DataTable>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12">
                                <h4 className="font-bold">Media Content</h4>
                                <Swiper
                                    // slidesPerView={1}
                                    // spaceBetween={30}

                                    pagination={{
                                        type: 'fraction'
                                    }}
                                    navigation={true}
                                    modules={[Pagination, Navigation]}
                                    // className="mySwiper"
                                    style={{ width: '450px' }}
                                >
                                    {viewProductData?.media?.map((elem, index) => {
                                        if (elem.mediaContentType == 'IMAGE') {
                                            return (
                                                <SwiperSlide key={index}>
                                                    <div className="card my-3 shadow-5">
                                                        <img src={elem.url} />
                                                    </div>
                                                </SwiperSlide>
                                            );
                                        } else {
                                            return (
                                                <SwiperSlide key={index}>
                                                    <div className="card my-3 shadow-5">
                                                        <video width="230" height="155" controls>
                                                            <source src={elem.url} type="video/mp4" />
                                                        </video>
                                                    </div>
                                                </SwiperSlide>
                                            );
                                        }
                                    })}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <i className="pi pi-spin pi-spinner flex justify-content-center align-items-center" style={{ fontSize: '3rem' }}></i>
                    </>
                )}
            </Sidebar>
            <ProductSidebarViewSync viewSyncLeft={viewSyncLeft} setViewSyncLeft={setViewSyncLeft} viewProductSyncData={viewProductSyncData} storeName={storeName} destinationName={destinationName} />

            <MapSidebar
                sourceStoreId={sourceStoreId}
                mapSidebarModal={mapSidebarModal}
                setMapSidebarModal={setMapSidebarModal}
                mapSourceData={productMapSourceData?.source_data}
                destinationStoreId={destinationStoreId}
                storeName={storeName}
                destinationName={destinationName}
                shopifyId={shopifyId}
            />
        </div>
    );
};

export default ProductStoreDatatable;
