import { createSlice } from '@reduxjs/toolkit';
import { addUserApi, allUserApi, modifyUserApi, removeUserApi, viewUserApi } from '../../../service/kushDiamond/kdUserService';
import { toast } from 'react-hot-toast';



export const userSlice = createSlice({
    name: 'kdUser',
    initialState: {
        isLoading: false,
        allUser: [],
        viewUser: '',
        addUser: null,
        removeUser: null,
        modifyUser: null,
        error: null,
        viewIdError: null,
        removeIdError: null
    },
    reducers: {
        setLoading: (state) => {
            state.isLoading = true;
        },

        allUserList: (state, action) => {
            state.isLoading = false;
            state.allUser = action.payload;
        },

        viewUserData: (state, action) => {
            state.isLoading = false;
            state.viewUser = action.payload?.data;
        },

        addUserList: (state, action) => {
            state.isLoading = false;
            state.addUser = action.payload;
        },

        removeUserList: (state, action) => {
            state.isLoading = false;
            state.removeUser = action.payload;
        },

        modifyUserList: (state, action) => {
            state.isLoading = false;
            state.modifyUser = action.payload;
        },

        kdUserError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        kdUserViewError: (state, action) => {
            state.isLoading = false;
            state.viewIdError = action.payload;
        },

        kdUserRemoveError: (state, action) => {
            state.isLoading = false;
            state.removeIdError = action.payload;
        },

        userResetData: (state) => {
            state.isLoading = false;
            state.error = null;
            state.removeIdError = null;
            state.viewIdError = null;
            state.addUser = null;
            state.removeUser = null;
            state.modifyUser = null;
        }
    }
});

export const { setLoading, allUserList, viewUserData, addUserList, removeUserList, modifyUserList, kdUserError, kdUserViewError, kdUserRemoveError, userResetData } = userSlice.actions;
export default userSlice.reducer;

export const userListRequest = (queryString) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };

        const { data } = await allUserApi(queryString, config);
        dispatch(allUserList(data));
    } catch (error) {
        dispatch(kdUserError(error.response));
    }
};

export const viewUserRequest = (userid) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await viewUserApi(userid, config);
        dispatch(viewUserData(data));
    } catch (error) {
        if (error.response?.data) {
            toast.error(error.response?.data.message);
            return dispatch(kdUserViewError(error.response?.data.message));
        }
    }
};

export const addUserRequest = (userData) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };

        const { data } = await addUserApi(userData, config);

        const { statusCode, message } = data;

        if (statusCode === 201) {
            toast.success(message);
            dispatch(addUserList(message));
            dispatch(userListRequest());
        }
    } catch (error) {
        if (error.response && error.response.data.errors) {
            return dispatch(kdUserError(error.response.data.errors));
        } else {
            return dispatch(kdUserError(error.message));
        }
    }
};

export const removeUserRequest = (removeId) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait your data is deleteing...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };

        const { data } = await removeUserApi(removeId, config);
        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(removeUserList(message));
            dispatch(userListRequest());
        }
    } catch (error) {
        const { statusCode, message } = error.response.data;
        if (statusCode === 422) {
            toast.error(message, {
                id: toastId
            });
            return dispatch(kdUserRemoveError(message));
        }
    }
};

export const modifyUserRequest = (id, modifyData) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await modifyUserApi(id, modifyData, config);

        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message);
            dispatch(modifyUserList(message));
            dispatch(userListRequest());
        }
    } catch (error) {
        if (error.response && error.response.data.errors) {
            return dispatch(kdUserError(error.response.data.errors));
        } else {
            toast.error(error.response.data.message);
            return dispatch(kdUserError(error.response.data.message));
        }
    }
};
