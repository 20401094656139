import { createSlice } from '@reduxjs/toolkit';
import { dashboardApi } from '../../service/dashboardService';
import { handleUnAuthorizeUserRequest } from './authSlice';

export const dashboardSlice = createSlice({
    name: 'dashboardStore',
    initialState: {
        isLoading: false,
        dashboardData: null,
        error: null
    },
    reducers: {
        setLoading: (state) => {
            state.isLoading = true;
        },
        dashboardList: (state, action) => {
            state.isLoading = false;
            state.dashboardData = action.payload?.data;
        },
        dashboardResetData: (state) => {
            state.isLoading = false;
            state.error = null;
            state.dashboardData = null;
        },
        dashboardError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        }
    }
});

export const { setLoading, dashboardList, dashboardResetData, dashboardError } = dashboardSlice.actions;
export default dashboardSlice.reducer;

export const DashboardRequest = () => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await dashboardApi(config);
        dispatch(dashboardList(data));
    } catch (error) {
        if (error?.response?.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else {
            dispatch(dashboardError(error?.response?.data));
        }
    }
};
