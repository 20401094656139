import React, { useEffect, useState } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { InputSwitch } from 'primereact/inputswitch';
// import ProductTab from './ProductTab';
// import VariantTab from './VariantTab';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { addStoneSettingDataRequest, allStoneSettingRequest, stoneSettingResetData } from '../../redux/slices/stoneSyncSettingSlice';
import { Button } from 'primereact/button';

const ProductSettings = () => {
    const dispatch = useDispatch();
    const { allStoneSettingData } = useSelector((state) => state.stoneSyncSettings);
    const { verifiedStoreData } = useSelector((state) => state.store);
    const [activeIndex, setActiveIndex] = useState(0);
    const [value, setValue] = useState();
    const [title, setTitle] = useState(false);
    const [description, setDescription] = useState(false);
    const [tags, setTags] = useState(false);
    const [url, setUrl] = useState(false);
    const [media, setMedia] = useState(false);
    const [store, setStore] = useState(false);
    const [hideSynced, setHideSynced] = useState(false);
    const [price, setPrice] = useState(false);
    const [syncCost, setSyncCost] = useState(false);

    var destItemsArr = [];
    allStoneSettingData?.map((ele) => {
        return destItemsArr.push({ label: ele.label, value: ele.value });
    });

    const legendSourceTemplate = (
        <div className="flex align-items-center text-primary">
            <span className="font-bold text-lg">Select a Destination store</span>
        </div>
    );

    useEffect(() => {
        dispatch(allStoneSettingRequest(verifiedStoreData.store_id));

        if (value == undefined) {
            setTitle(false);
            setPrice(false);
            setMedia(false);
            setDescription(false);
            setSyncCost(false);
            setStore(false);
            setUrl(false);
            setTags(false);
            return setHideSynced(false);
        }

        return () => {
            dispatch(stoneSettingResetData());
        };
    }, [dispatch, value]);

    const handleChange = (e) => {
        setValue(e.value);
        allStoneSettingData?.map((ele) => {
            if (ele.value == e.value) {
                setTitle(ele.title);
                setPrice(ele.price);
                setMedia(ele.media_images);
                setDescription(ele.description);
                setSyncCost(ele.sync_cost_per_item);
                setStore(ele.Sales_channel);
                setUrl(ele.url_handle);
                setTags(ele.tags);
                setHideSynced(ele.hide_synced_product);
            }
        });
    };

    const handleSubmit = () => {
        const addStoneSettingObj = {
            title: title,
            description: description,
            tags: tags,
            media_images: media,
            price: price,
            url_handle: url,
            sync_cost_per_item: syncCost,
            Sales_channel: store,
            hide_synced_product: hideSynced,
            destination_store_id: verifiedStoreData.store_id,
            source_store_id: value
        };
        dispatch(addStoneSettingDataRequest(addStoneSettingObj, verifiedStoreData.store_id));
        dispatch(stoneSettingResetData());
    };

    // const checkActiveIndex = () => {
    //     switch (activeIndex) {
    //         case 0:
    //             return <ProductTab />;
    //         case 1:
    //             return <VariantTab />;
    //         default:
    //             return null;
    //     }
    // };

    const items = [
        {
            label: 'PRODUCT'
        },
        {
            label: 'VARIANT'
        }
    ];
    const breadcrumbItems = [{ label: 'Stone Share' }, { label: 'Stone Share Store Settings' }];

    return (
        <div>
            <div className="col px-0">
                <div className="breadcrumb-container flex justify-content-between align-items-center">
                    <div className="text-2xl font-bold">
                        <h3 className="m-0">Store Settings</h3>
                    </div>
                    <div className="ml-6 text-2xl">
                        <BreadCrumb home={{ icon: 'pi pi-home', url: '/' }} model={breadcrumbItems} />
                    </div>
                </div>
            </div>
            <h5 className="font-bold mt-0 text-lg">Manage how your products sync with your connected Source store.</h5>
            {/* <div className="grid p-fluid">
                <div className="col-12 md:col-3 product-settings-tab">
                    <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                </div>
            </div>
            <div>{checkActiveIndex()}</div> */}
            <div className="grid p-fluid">
                <div className="col-12">
                    <div className="card">
                        <div className="col-3 px-0 pt-0">
                            {/* <Fieldset legend={legendSourceTemplate}> */}
                            <span className="p-float-label mt-3">
                                <Dropdown id="sourceSelect" options={destItemsArr} value={value} onChange={(e) => handleChange(e)} optionLabel="label" />
                                <label htmlFor="destination_store" className="text-lg font-bold" style={{ color: '#000000' }}>
                                    Source store
                                </label>
                            </span>
                            {/* </Fieldset> */}
                        </div>
                        <h5 className="font-bold mt-0">Switch on any product attributes that you would like to sync across all connected source stores.</h5>
                        <div className="grid ">
                            <div className="col-12 md:col-4">
                                <div className="border-1 border-gray-400 border-round-xl flex flex-column md:flex-row  md:align-items-center mb-3 p-3">
                                    <InputSwitch checked={title} onChange={(e) => setTitle(e.value)} />
                                    <h5 className="my-0 ml-4 font-bold text-lg">Title</h5>
                                </div>
                                <div className="border-1 border-gray-400 border-round-xl flex flex-column md:flex-row  md:align-items-center mb-3 p-3">
                                    <InputSwitch checked={description} onChange={(e) => setDescription(e.value)} />
                                    <h5 className="my-0 ml-4 font-bold text-lg">Description</h5>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="border-1 border-gray-400 border-round-xl flex flex-column md:flex-row  md:align-items-center mb-3 p-3">
                                    <InputSwitch checked={price} onChange={(e) => setPrice(e.value)} />
                                    <h5 className="my-0 ml-4 font-bold text-lg">Price</h5>
                                </div>
                                <div className="border-1 border-gray-400 border-round-xl flex flex-column md:flex-row  md:align-items-center mb-3 p-3">
                                    <InputSwitch checked={syncCost} onChange={(e) => setSyncCost(e.value)} />
                                    <h5 className="my-0 ml-4 font-bold text-lg">Sync Cost Per Item</h5>
                                </div>
                                {/* <div className="border-1 border-gray-400 border-round-xl flex flex-column md:flex-row  md:align-items-center mb-3 p-3">
                                    <InputSwitch checked={url} onChange={(e) => setUrl(e.value)} />
                                    <h5 className="my-0 ml-4 font-bold text-lg">URL Handle</h5>
                                </div> */}
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="border-1 border-gray-400 border-round-xl flex flex-column md:flex-row  md:align-items-center mb-3 p-3">
                                    <InputSwitch checked={media} onChange={(e) => setMedia(e.value)} />
                                    <h5 className="my-0 ml-4 font-bold text-lg">Media-Images</h5>
                                </div>
                                <div className="border-1 border-gray-400 border-round-xl flex flex-column md:flex-row  md:align-items-center mb-3 p-3">
                                    <InputSwitch checked={tags} onChange={(e) => setTags(e.value)} />
                                    <h5 className="my-0 ml-4 font-bold text-lg">tags</h5>
                                </div>
                                {/* <div className="border-1 border-gray-400 border-round-xl flex flex-column md:flex-row  md:align-items-center mb-3 p-3">
                                    <InputSwitch checked={store} onChange={(e) => setStore(e.value)} />
                                    <h5 className="my-0 ml-4 font-bold text-lg">Online Store Sales Channel</h5>
                                </div>
                                <div className="border-1 border-gray-400 border-round-xl flex flex-column md:flex-row  md:align-items-center mb-3 p-3">
                                    <InputSwitch checked={hideSynced} onChange={(e) => setHideSynced(e.value)} />
                                    <h5 className="my-0 ml-4 font-bold text-lg">Hide Synced Product By Default</h5>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-1 p-0">{value !== undefined ? <Button icon="pi pi-check" tooltip="Click to proceed" label="Save" onClick={handleSubmit} /> : <Button icon="pi pi-check" label="Save" disabled />}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductSettings;
