import React, { useEffect, useState, lazy, useRef } from 'react';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { useDispatch, useSelector } from 'react-redux';
import { connectStoreApi, connectStoreEmailApi, storeStatusRequest, storeApi, setConnectStoreLoading, sourceStoreListingRequest, storeVerifiedRequest, handleStoreUrl } from '../redux/slices/storeSlice';
// import soureceImg from '../assets/source-store.jpg';
import sourceImg from '../assets/source.png';
// import destinationImg from '../assets/destination-store.jpg';
import destinationImg from '../assets/destination.png';
import { useNavigate } from 'react-router';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
const StoreType = ({ nextStoreIndex, nextDestinationIndex }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { storeData, verifiedStoreData, isLoading } = useSelector((state) => state.store);
    const location = window.location.href;
    const getkey = location?.split('?')[1];
    const StoreId = getkey?.split('=')[1];
    const getStoreId = StoreId?.substring(0, StoreId.indexOf('&'));
    const getStoreKey = getkey?.split('=')[2];

    const nextStore = () => {
        const storeObj = {
            store_type: 'source',
            store_id: getStoreId,
            store_key: getStoreKey
        };
        dispatch(storeApi(storeObj));
    };
    const reqBody = {
        // store_id: getStoreId
        store_key: getStoreKey
    };
    const nextDestination = () => {
        const destinationObj = {
            store_type: 'destination',
            store_id: getStoreId,
            store_key: getStoreKey
        };

        dispatch(storeApi(destinationObj));
        nextDestinationIndex();
    };
    useEffect(() => {
        dispatch(handleStoreUrl(getStoreKey));
        dispatch(storeVerifiedRequest(reqBody));
        dispatch(sourceStoreListingRequest());
    }, []);
    useEffect(() => {
        if (storeData !== null) {
            if (verifiedStoreData.is_verified === false) {
                setTimeout(() => {
                    nextStoreIndex();
                }, 800);
            }
        }
    }, [storeData]);
    useEffect(() => {
        if (verifiedStoreData && verifiedStoreData?.is_verified) {
            if (verifiedStoreData?.store_type === 'source') {
                navigate('/source-dashboard');
            } else {
                navigate('/destination-dashboard');
            }
        }
    }, [verifiedStoreData]);

    return (
        <>
            <div className="flex justify-content-center">
                <div className="my-5 p-3 w-4 card border-round-xl shadow-5" style={{ background: '#3f51b5' }}>
                    <p className="text-2xl font-bold text-center" style={{ color: '#fff' }}>
                        You can choose your store by clicking here
                    </p>
                </div>
            </div>
            <div className="grid justify-content-center mx-0">
                <div className="col-12 md:col-3" style={{ height: '41.4rem', width: '25rem' }}>
                    <div className="card hover:shadow-7 border-round-xl">
                        <div className="flex">
                            <Image loading={lazy} src={sourceImg} alt="Image" width="200" className="mx-auto" />
                        </div>
                        <h4 className="text-center text-2xl font-bold" style={{ color: '#000' }}>
                            Source Store
                        </h4>
                        <h6 className="mb-6" style={{ color: '#000' }}>
                            This is the Source Store. Holds the source inventory & product information. All products sync from this store to the destination store.
                        </h6>
                        <ul className="-ml-4" style={{ color: '#000' }}>
                            <p className="-ml-3 my-0">Examples:</p>
                            <li>Sync Product Information</li>
                            <li>Real-Time Inventory Update</li>
                            <li>Product Mapping</li>
                        </ul>
                        <Button label="Choose this store type" disabled={isLoading} onClick={() => nextStore()} className="p-button-raised  mr-2 mb-2" />
                    </div>
                </div>
                <div className="col-12 md:col-3" style={{ height: '41.4rem', width: '25rem' }}>
                    <div className="card hover:shadow-7  border-round-xl">
                        <div className="flex">
                            <Image loading={lazy} src={destinationImg} alt="Image" width="200" className="mx-auto" />
                        </div>
                        <h4 className="text-center text-2xl font-bold" style={{ color: '#000' }}>
                            Destination Store
                        </h4>
                        <h6 className="mb-5" style={{ color: '#000' }}>
                            I sync inventory and product information from the source store. I can also send orders to the source store to fulfill.
                        </h6>
                        <ul className=" -ml-4" style={{ color: '#000' }}>
                            <p className="-ml-3 my-0">Examples:</p>
                            <li>Secondary retail location (different region)</li>
                            <li>Third party retailer</li>
                            <li>Drop shipping store</li>
                            <li>Sync from source</li>
                        </ul>
                        <Button label="Choose this store type" value="destination" disabled={isLoading} className="p-button-raised  mr-2 mb-2" onClick={nextDestination} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default StoreType;
