import { createSlice } from '@reduxjs/toolkit';
import {
    allProductsApi,
    folderImgRemoveApi,
    inventoryExcelTypeTwoApi,
    productExcelTypeOneApi,
    productmultiRemoveAvailableUnavailableApi,
    productsViewDetailApi,
    singleMultiImgFolderApi,
    statusUpdateExceleApi
} from '../../../service/kushDiamond/kdProductService';
import { toast } from 'react-hot-toast';

export const productSlice = createSlice({
    name: 'kdProducts',
    initialState: {
        isLoading: false,
        allProductData: [],
        productsDetailViewData: [],
        productmultiRemoveAvailableUnavailable: null,
        singleMultiImgFolderMessage: null,
        imgRemoveMessage: null,
        statusUpdateExcelMessage: null,
        productExcelTypeOnemessage: null,
        inventoryExcelTypeTwomessage: null,
        error: null
    },
    reducers: {
        setLoading: (state) => {
            state.isLoading = true;
        },
        allProductList: (state, action) => {
            state.isLoading = false;
            state.allProductData = action.payload;
        },
        productsDetailViewList: (state, action) => {
            state.isLoading = false;
            state.productsDetailViewData = action.payload;
        },
        productmultiRemoveAvailableUnavailableData: (state, action) => {
            state.isLoading = false;
            state.productmultiRemoveAvailableUnavailable = action.payload;
        },
        singleMultiImgFolderMessageData: (state, action) => {
            state.isLoading = false;
            state.singleMultiImgFolderMessage = action.payload;
        },
        imgRemoveMessageData: (state, action) => {
            state.isLoading = false;
            state.imgRemoveMessage = action.payload;
        },
        statusUpdateExcelMessageData: (state, action) => {
            state.isLoading = false;
            state.statusUpdateExcelMessage = action.payload;
        },
        productExcelTypeOnemessageData: (state, action) => {
            state.isLoading = false;
            state.productExcelTypeOnemessage = action.payload;
        },
        inventoryExcelTypeTwomessageData: (state, action) => {
            state.isLoading = false;
            state.inventoryExcelTypeTwomessage = action.payload;
        },
        handleErrorList: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        handleResetList: (state) => {
            state.isLoading = false;
            state.error = null;
            state.productmultiRemoveAvailableUnavailable = null;
            state.singleMultiImgFolderMessage = null;
            state.imgRemoveMessage = null;
            state.statusUpdateExcelMessage = null;
        },
        excelResetData: (state) => {
            state.isLoading = false;
            state.error = null;
            state.productExcelTypeOnemessage = null;
            state.inventoryExcelTypeTwomessage = null;
        }
    }
});

export const {
    setLoading,
    allProductList,
    productsDetailViewList,
    productmultiRemoveAvailableUnavailableData,
    singleMultiImgFolderMessageData,
    imgRemoveMessageData,
    statusUpdateExcelMessageData,
    productExcelTypeOnemessageData,
    inventoryExcelTypeTwomessageData,
    handleErrorList,
    handleResetList,
    excelResetData
} = productSlice.actions;
export default productSlice.reducer;

export const allProductListRequest = (queryString) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await allProductsApi(queryString, config);
        dispatch(allProductList(data));
    } catch (error) {
        dispatch(handleErrorList(error.response));
    }
};

export const productsViewDetailRequest = (details_id) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await productsViewDetailApi(details_id, config);

        const { statusCode } = data;
        if (statusCode === 200) {
            dispatch(productsDetailViewList(data));
        }
    } catch (error) {
        const { statusCode, message } = error?.response?.data;
        if (statusCode === 422) {
            toast.error(message);
        }
        dispatch(handleErrorList(message));
    }
};

export const productmultiRemoveAvailableUnavailableRequest = (reqData) => async (dispatch, getState) => {
    const toastId = toast.loading('Please wait,Processing...');
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await productmultiRemoveAvailableUnavailableApi(reqData, config);

        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(productmultiRemoveAvailableUnavailableData(message));
        }
    } catch (error) {
        if (error?.response && error?.response?.data) {
            toast.error(error?.response?.data?.message, {
                id: toastId
            });
            dispatch(handleErrorList(error?.response?.data?.message));
        }
    }
};

export const singleMultiImgFolderRequest = (singleMultiImgFolder) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait your folder is Uploading...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await singleMultiImgFolderApi(singleMultiImgFolder, config);

        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(singleMultiImgFolderMessageData(message));
        }
    } catch (error) {
        const { statusCode, errors } = error.response.data;
        if (statusCode === 422) {
            toast.error(errors.message, {
                id: toastId
            });
            dispatch(handleErrorList(errors.message));
        }
    }
};

export const folderImgRemoveRequest = (removeImg) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };

        const { data } = await folderImgRemoveApi(removeImg, config);
        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(imgRemoveMessageData(message));
        }
    } catch (error) {
        const { statusCode, message } = error.response.data;
        if (statusCode === 422) {
            toast.error(message, {
                id: toastId
            });
            dispatch(handleErrorList(message));
        }
    }
};

export const statusUpdateExcelRequest = (statusUpdateExcel) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait your excel uploading...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await statusUpdateExceleApi(statusUpdateExcel, config);
        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(statusUpdateExcelMessageData(message));
        }
    } catch (error) {
        const { statusCode, message } = error?.response?.data;
        if (statusCode === 422) {
            toast.error(message, {
                id: toastId
            });
            dispatch(handleErrorList(message));
        }
    }
};

export const productExcelTypeOneRequest = (productExcelTypeOne) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait your excel is Uploading...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await productExcelTypeOneApi(productExcelTypeOne, config);
        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(productExcelTypeOnemessageData(message));
        }
    } catch (error) {
        const { statusCode, message } = error?.response?.data;
        if (statusCode === 422) {
            toast.error(message, {
                id: toastId
            });
            dispatch(handleErrorList(message));
        }
    }
};

export const inventoryExcelTypeTwoRequest = (inventoryExcelTypeTwo) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait your excel type two is Uploading...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await inventoryExcelTypeTwoApi(inventoryExcelTypeTwo, config);
        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(inventoryExcelTypeTwomessageData(message));
        }
    } catch (error) {
        const { statusCode, message } = error?.response?.data;
        if (statusCode === 422) {
            toast.error(message, {
                id: toastId
            });
            dispatch(handleErrorList(message));
        }
    }
};
