import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DataTable, Column, Button } from 'primereact';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getDashboardDetailsRequest, storeRequest, storeRequestSuccess, storeVerifiedRequest } from '../../redux/slices/storeSlice';
const SourceDashboard = () => {
    const textRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [copied, setCopied] = useState(false);
    const { dashboardData, verifiedStoreData, storeData, dashboardStoreData, storeRequestSuccessMessage, storeUrlId } = useSelector((store) => store.store);
    console.log(storeData, 'storeData yes');
    const onCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    };
    const header = () => {
        return (
            <div className="flex gap-2 justify-content-between align-items-bottom">
                <div className="flex  align-items-center">
                    <p>Destination Stores</p>
                </div>
                <Button icon="pi pi-list" className="p-button-info p-button-outlined" label="View All" placeholder="Mouse" tooltip="View All" onClick={() => navigate('/store')} />
            </div>
        );
    };
    const storeUrl = (rowData) => {
        return (
            <a href={rowData?.store_url} target="_blank">
                {rowData?.store_url}
            </a>
        );
    };
    const handleClick = (rowData, status) => {
        // console.log(rowData, 'rowData');
        const payload = {
            request_key: status,
            source_store_id: storeData?.store_id,
            destination_store_id: rowData.store_id,
            source_store_id: verifiedStoreData.store_id
        };
        dispatch(storeRequest(payload));
    };
    const storeConnectBodyTemplate = (rowData) => {
        return (
            <div>
                {rowData.is_connected === 'approved' ? (
                    <Button label="Approved" icon="pi pi-check" className="cursor-auto mr-2 p-button p-button-success" />
                ) : (
                    <>
                        <Button label="Approve" icon="pi pi-verified" className=" p-button p-button-success  mr-2" onClick={() => handleClick(rowData, 'approve')} />
                        <Button label="Decline" icon="pi pi-times" className=" p-button p-button-danger" onClick={() => handleClick(rowData, 'decline')} />
                    </>
                )}
            </div>
        );
    };
    const getPayload = () => {
        if (verifiedStoreData?.is_verified) {
            const payload = {
                store_id: verifiedStoreData?.store_id,
                store_type: verifiedStoreData?.store_type
            };
            return payload;
        } else {
            const payload = {
                store_id: storeData?.store_id,
                store_type: storeData?.store_type
            };
            return payload;
        }
    };
    const payload = {
        store_id: storeData?.store_id,
        store_type: storeData?.store_type
    };
    const data = getPayload();
    const reqBody = {
        store_key: storeUrlId
    };
    useEffect(() => {
        if (storeRequestSuccessMessage != null) {
            dispatch(getDashboardDetailsRequest(data));
            dispatch(storeRequestSuccess(null));
        }
    }, [storeRequestSuccessMessage]);
    useEffect(() => {
        // dispatch(getDashboardDetailsRequest(getPayload()))
        dispatch(storeVerifiedRequest(reqBody));
        dispatch(getDashboardDetailsRequest(data));
    }, []);
    return (
        <>
            <div>
                <div className="flex justify-content-center">
                    <div className="col-12 md:col-6 lg:col-4">
                        <div className="surface-0 shadow-2 p-3 pb-2 border-1 border-50 border-round">
                            <div className="flex justify-content-between mb-3">
                                <div className="">
                                    <span className="block text-500 font-medium mb-3 font-bold">Source Store Key</span>
                                    <div className="flex  text-900 align-items-center  my-auto font-bold font-medium text-xl">
                                        <div>
                                            <span ref={textRef}>{dashboardData?.store_key}</span>
                                        </div>
                                        <div>
                                            <CopyToClipboard onCopy={onCopy} text={dashboardData?.store_key}>
                                                <Button
                                                    size="small"
                                                    icon="pi pi-copy"
                                                    // rounded
                                                    text
                                                    onClick={() => {
                                                        // handleCopyClick(dashboardData?.store_key);
                                                        onCopy();
                                                    }}
                                                    tooltip={copied ? 'Copied' : 'Copy'}
                                                    tooltipOptions={{ position: 'top' }}
                                                    className="p-button-rounded p-button-text ml-1"
                                                />
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                    <i className="pi pi-info-circle text-blue-500 text-xl"></i>
                                </div>
                            </div>
                            {/* <span className="text-500">Share with Your {storeType == 'source' ? 'Destination' : 'Source'} stores</span> */}
                        </div>
                    </div>

                    <div className="col-12 md:col-6 lg:col-4" style={{ 'min-height': '7.4rem' }}>
                        <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <div className="flex justify-content-between mb-3">
                                <div>
                                    <span className="block text-500 font-medium mb-3 font-bold">
                                        <span className="mx-1">Source Store Details</span>
                                    </span>
                                    {/* <div className="text-900 font-bold font-medium text-xl">{dashboardData?.store_name}</div> */}
                                    <a href={dashboardData?.store_url} target="_blank" className="cursor-pointer text-xl">
                                        {dashboardData?.store_name}
                                    </a>
                                </div>
                                <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                    <i className="pi pi-sitemap text-cyan-500 text-xl"></i>
                                </div>
                            </div>

                            <>
                                {/* <span className="text-green-500 font-medium">{dashboardData?.store_url} </span> */}
                                {/* <span className="text-500">newly connected stores</span> */}
                            </>
                        </div>
                    </div>
                    <div className="col-12 md:col-6 lg:col-4" style={{ 'min-height': '7.4rem' }}>
                        <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <div className="flex justify-content-between mb-3">
                                <div>
                                    <span className="block text-500 font-medium mb-3 font-bold">
                                        {/* Connected <span className="capitalize mr-1">{storeData?.store_type === 'source ' ? 'Destination' : 'Source'} Store</span> */}
                                        Connected Store Count
                                    </span>
                                    <div className="text-900 font-bold font-medium text-xl">{dashboardData?.connect_count}</div>
                                </div>
                                <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                    <i className="pi pi-box text-orange-500 text-xl"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid  mt-5 mx-3 ">
                <div className="col-6 card">
                    <DataTable value={dashboardData?.store_detail} removableSort header={header} size="small" className="p-datatable-products ">
                        <Column field="store_name" header="Store Name " sortable></Column>
                        <Column field="store_url" header="Store Url" body={storeUrl}></Column>
                        <Column field="is_connected" header="Status" body={storeConnectBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </>
    );
};

export default SourceDashboard;
