import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../rootReducer';
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
const persistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['store', 'auth']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.REACT_APP_MODE !== 'production',
    middleware: [thunk]
});

export const persistor = persistStore(store);
