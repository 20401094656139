import { createSlice } from '@reduxjs/toolkit';
import { ftpFeedListApi, sendClientFtpFeedApi } from '../../../service/kushDiamond/ftpFeedService';
import { ftpClientDropdownListApi } from '../../../service/kushDiamond/ftpService';
import { toast } from 'react-hot-toast';

export const ftpFeedSlice = createSlice({
    name: 'ftpFeed',
    initialState: {
        isLoading: false,
        ftpFeedData: [],
        ftpFeedAllData: [],
        ftpClientDropdownData: [],
        sendClientFtpFeedData: [],
        error: null
    },
    reducers: {
        setLoading: (state) => {
            state.isLoading = true;
        },
        ftpFeedDataList: (state, action) => {
            state.isLoading = false;
            state.ftpFeedData = action.payload;
        },
        ftpFeedAllDataList: (state, action) => {
            state.isLoading = false;
            state.ftpFeedAllData = action.payload;
        },
        ftpClientDropdownDatalist: (state, action) => {
            state.isLoading = false;
            state.ftpClientDropdownData = action.payload?.data;
        },
        sendClientFtpFeedDatalist: (state, action) => {
            state.isLoading = false;
            state.sendClientFtpFeedData = action.payload;
        },

        ftpFeedErrorList: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        ftpFeedAllResetData: (state) => {
            state.isLoading = false;
            state.ftpFeedAllData = [];
        }
    }
});

export const { setLoading, ftpFeedDataList, ftpFeedAllDataList, ftpClientDropdownDatalist, sendClientFtpFeedDatalist, ftpFeedErrorList, ftpFeedAllResetData } = ftpFeedSlice.actions;
export default ftpFeedSlice.reducer;

export const ftpFeedListRequest = (queryString) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await ftpFeedListApi(queryString, config);
        dispatch(ftpFeedDataList(data));
    } catch (error) {
        dispatch(ftpFeedErrorList(error?.response));
    }
};

export const ftpFeedAllRecordRequest = (queryString) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await ftpFeedListApi(queryString, config);
        dispatch(ftpFeedAllDataList(data));
    } catch (error) {
        dispatch(ftpFeedErrorList(error?.response));
    }
};

export const ftpClientDropdownListRequest = () => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await ftpClientDropdownListApi(config);
        dispatch(ftpClientDropdownDatalist(data));
    } catch (error) {
        dispatch(ftpFeedErrorList(error?.response));
    }
};

export const sendClientFtpFeedRequest = (sendClientFtpFeed) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait FTP connection establish...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await sendClientFtpFeedApi(sendClientFtpFeed, config);
        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(sendClientFtpFeedDatalist(data));
        }
    } catch (error) {
        const { statusCode, message } = error?.response?.data;
        if (statusCode === 422) {
            toast?.error(message, {
                id: toastId
            });
            dispatch(ftpFeedErrorList(message));
        }
    }
};
