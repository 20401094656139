import auth from './slices/authSlice';
import productInventory from './slices/productInventorySlice';
import store from './slices/storeSlice';
import product from './slices/productSlice';
import gemFinder from './slices/gemFinderSlice';
import discountStoneSearch from './slices/stoneSearchSlice/discountTagGemFinderSlice'
import ftp from './slices/kushDiamond/ftpSlice';
import kdlog from './slices/kushDiamond/kdlogSlice';
import kdUser from './slices/kushDiamond/userSlice';
import kdProducts from './slices/kushDiamond/productSlice';
import stoneSyncSettings from './slices/stoneSyncSettingSlice';
import dashboardStore from './slices/dashboardSlice';
import ftpFeed from './slices/kushDiamond/ftpFeedSlice';
import b2bOrders from './slices/b2b/orderSlice';
import discountTags from './slices/b2b/discountTagsSlice';
import stoneSearch from './slices/stoneSearchSlice/dashboardSlice';
import { combineReducers } from '@reduxjs/toolkit';
const rootReducer = combineReducers({
    auth,
    store,
    productInventory,
    product,
    gemFinder,
    discountStoneSearch,
    ftp,
    kdlog,
    kdUser,
    kdProducts,
    stoneSyncSettings,
    dashboardStore,
    ftpFeed,
    b2bOrders,
    discountTags,
    stoneSearch
});

export default rootReducer;
