import React from 'react';

const NewConnectSore = ({ nextEmailIndex, nextStoreKeyIndex }) => {
    return (
        <>
            <h2 className="text-center text-3xl font-bold">How would you like to connect?</h2>
            <div className="grid justify-content-center">
                <div className="col-12 md:col-5">
                    <div className="flex flex-wrap md:justify-content-between justify-content-center card-container">
                        <div className="border-500 hover:border-700 border-1 border-gray-400 border-round-xl font-bold p-2 text-center cursor-pointer" style={{ minWidth: '200px', minHeight: '100px' }} onClick={nextEmailIndex}>
                            <i className="pi pi-envelope text-5xl mt-5 mb-3"></i>
                            <h3 className="text-center text-xl font-bold mb-2 mt-2">Invite via email</h3>
                            <h6 className="text-sm text-500 mt-0">For new or existing destination stores</h6>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-5">
                    <div className="flex flex-wrap md:justify-content-between justify-content-center card-container">
                        <div className="border-500 hover:border-700 border-1 border-gray-400 border-round-xl font-bold p-2 text-center cursor-pointer" style={{ minWidth: '200px', minHeight: '100px' }} onClick={nextStoreKeyIndex}>
                            <i className="pi pi-key text-5xl mt-5 mb-3"></i>
                            <h3 className="text-center text-xl font-bold mb-2 mt-2">Connect via store key</h3>
                            <h6 className="text-sm text-500 mt-0 mb-5">For existing destination stores</h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewConnectSore;
