import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gemFinderResetData, uploadCSV } from '../../redux/slices/gemFinderSlice';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';

const UploadCsv = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { csvData, error } = useSelector((state) => state.gemFinder);
    const [file, setFile] = useState();
    const [submitted, setSubmitted] = useState(false);
    const inputRef = useRef(null);
    const toast = useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append('file', file);
        dispatch(uploadCSV(formData));
        setSubmitted(false);
        inputRef.current.value = null;
    };

    const handleOnChange = (e) => {
        const selectFile = e.target.files[0];
        setFile(selectFile);
        setSubmitted(true);
    };

    useEffect(() => {
        if (error) {
            return toast.current.show({ severity: 'error', summary: 'Error', detail: error, life: 3000 });
        }
        if (csvData !== null) {
            setTimeout(() => {
                navigate('/gemfinder-product');
            }, 400);
            return toast.current.show({ severity: 'success', summary: 'Successful', detail: csvData, life: 3000 });
        }
        return () => {
            dispatch(gemFinderResetData());
        };
    }, [error, csvData]);

    const breadcrumbItems = [{ label: 'Stone Search' }, { label: 'CSV' }];

    return (
        <>
            <Toast ref={toast} />
            <div className="grid">
            <div className="col-4 py-0 mt-3">
                <div className="breadcrumb-container flex justify-contain">
                    <div className="text-2xl font-bold">
                        <h3>Upload csv</h3>
                    </div>
                    <div className="ml-6 text-2xl">
                        <BreadCrumb home={{ icon: 'pi pi-home', to: '/' }} model={breadcrumbItems} />
                    </div>
                </div>
            </div>
                <div className="col-12">
                    <div className="card ">
                        <form>
                            <input ref={inputRef} type={'file'} id={'csvFileInput'} accept={'.csv'} onChange={handleOnChange} />
                            <div className="grid p-fluid mt-2">
                                <div className="col-12 md:col-1">{submitted == true ? <Button label="Submit" onClick={handleSubmit} /> : <Button label="Submit" disabled />}</div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UploadCsv;
