import React, { useEffect, useState } from 'react';
import { Steps } from 'primereact/steps';
import StoreType from '../StoreType';
import ConnectStore from './ConnectStore';
import CompleteStore from './CompleteStore';
import { useDispatch, useSelector } from 'react-redux';
import { updateStepCount } from '../../redux/slices/storeSlice';
import { useSessionStorage } from 'primereact/hooks';
const DashboardMain = () => {
    const dispatch = useDispatch();
    // const [ activeIndex, setActiveIndex ] = useState(0);
    // const [activeIndex, setActiveIndex] = useState(sessionStorage.getItem('activeIndexNumber') == undefined ? 0 : sessionStorage.getItem('activeIndexNumber'));
    const [activeIndex, setActiveIndex] = useSessionStorage(0, 'activeIndex');
    const { activeStepCount } = useSelector((store) => store.store);

    // console.log(activeStepCount, "activeStepCount");
    const [showStore, setshowStore] = useState(false);
    const [showDestination, setshowDestination] = useState(false);
    const StoreIndex = () => {
        setActiveIndex(activeIndex + 1);
        setshowStore(!showStore);
    };

    const BackActiveIndex = () => {
        setActiveIndex(activeIndex - 1);
    };

    const showDestinationIndex = () => {
        setActiveIndex(activeIndex + 1);
        sessionStorage.setItem('activeIndexNumber', activeIndex + 1);
        setshowDestination(!showDestination);
    };
    const getActivePage = () => {
        switch (activeIndex) {
            case 0:
                return <StoreType nextStoreIndex={() => StoreIndex()} nextDestinationIndex={() => showDestinationIndex()} />;
            case 1:
                return <ConnectStore nextIndex={() => setActiveIndex(activeIndex + 1)} showStore={showStore} showDestination={showDestination} />;
            case 2:
                return <CompleteStore BackActiveIndex={BackActiveIndex} />;
            default:
                return null;
        }
    };
    const items = [
        {
            label: 'Select Store Type'
        },
        {
            label: 'Connect Store'
        },
        {
            label: 'Complete'
        }
    ];
    let stepCount = 0;
    const updateStep = () => {
        if (activeStepCount < 3) {
            dispatch(updateStepCount());
            stepCount = sessionStorage.getItem('activeStepNumber');
            if (stepCount == undefined) {
                sessionStorage.setItem('activeStepNumber', 1);
                // console.log("in if");
            } else if (activeStepCount == 1) {
                setshowStore(true);
                // setshowDestination(true);
            } else {
                // console.log("in else");
                sessionStorage.setItem('activeStepNumber', activeStepCount + 1);
            }
        }
    };
    // useEffect(() => {
    //     if (activeIndex != 0) {
    //         updateStep();
    //     }
    // }, [activeIndex]);
    // useEffect(() => {
    //     setActiveIndex(activeStepCount);
    // }, []);
    return (
        <div className="layout-dashboard overflow-x-hidden">
            <div className="grid w-12 justify-content-center mt-3">
                {/* <div className=" md:col-4 card"> */}
                <div className="col-5 card">
                    <Steps model={items} activeIndex={activeIndex} />
                </div>
            </div>
            <div>{getActivePage()}</div>
        </div>
    );
};

export default DashboardMain;
