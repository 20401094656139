import React, { useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Password } from 'primereact/password';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { authLoginApi, handleResetAuth } from '../redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
    const { error, isLoggedIn, isLoading } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema: yup.object({
            username: yup.string().required('Email or Mobile No is required'),
            password: yup.string().min(3, 'Password must be at least 3 characters').required('Password is required')
        }),
        onSubmit: (values) => {
            dispatch(authLoginApi(values));
        }
    });

    useEffect(() => {
        if (isLoggedIn) {
            setTimeout(() => {
                navigate('/dashboard');
            }, 100);
        }
        return () => {
            dispatch(handleResetAuth());
        };
    }, [isLoggedIn]);

    return (
        <>
            <div className="login-card-div">
                <div className="auth-wrapper auth-v1 px-2">
                    <div className="auth-inner py-2">
                        <Card className="login-card">
                            <div className="text-center mb-3">
                                <img src="assets/layout/images/logo-dark.svg" height="55" className="login-img mr-4" alt="diamond-layout" />
                                <h2 className="text-center font-bold text-3xl mt-3" style={{ color: '#214e8f' }}>
                                    Kush Diamond
                                </h2>
                            </div>
                            {/* <h6 className="mt-4 mb-0 text-xl">Welcome to Stone Diamond!</h6>

                            <h6 className="mt-3 text-xl">Please sign-in to your account and start the adventure</h6> */}
                            <form onSubmit={formik.handleSubmit} className="p-fluid">
                                <div className="field">
                                    <label htmlFor="username">Email or Mobile No</label>
                                    <span className="p-input-icon-right mb-2">
                                        <i className="pi pi-user" />
                                        <InputText id="username" autoFocus name="username" onChange={formik.handleChange} value={formik.values.username} placeholder="Enter Your Email or Mobile No" />
                                    </span>
                                    {(formik.touched.username && formik.errors.username) || (error && error.username) ? <small className="p-error text-lg">{formik.errors.username || error.username}</small> : null}
                                </div>
                                <div className="field">
                                    <label htmlFor="password">Password</label>
                                    <span className="mb-2">
                                        <Password placeholder="Enter Your Password" name="password" onChange={formik.handleChange} value={formik.values.password} toggleMask feedback={false} />
                                    </span>
                                    {(formik.touched.password && formik.errors.password) || (error && error.password) ? <small className="p-error text-lg">{formik.errors.password || error.password}</small> : null}
                                </div>
                                <Button type="submit" label="Sign in" loading={isLoading} className="mt-2 login-btn" />
                            </form>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};
