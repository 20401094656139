import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const AdminRoute = ({ children }) => {
    const { authData } = useSelector((store) => store.auth);
    if (authData?.role == 'admin') {
        return children;
    } else {
        return <Navigate to="/login" />;
    }
};

export default AdminRoute;
