import axios from 'axios';

export const uploadCSVApi = async (reqData, config) => {
    return await axios.post(`${process.env.REACT_APP_GEMFINDER_URL_API}/api/upload/`, reqData, config);
};

export const storeSettingDropdowntApi = async (queryString) => {
    return await axios.get(`${process.env.REACT_APP_GEMFINDER_URL_API}/api/get/settings/?` + queryString);
};

export const storeListDropdownApi = async (config) => {
    return await axios.get(`${process.env.REACT_APP_GEMFINDER_URL_API}/api/label/store/list/`, config);
};

export const filterDropdownApi = async (queryString, config) => {
    return await axios.get(`${process.env.REACT_APP_GEMFINDER_URL_API}/api/GetProductData/?` + queryString, config);
};

export const addFilterLabelApi = async (reqData, config) => {
    return await axios.post(`${process.env.REACT_APP_GEMFINDER_URL_API}/api/store/filter/`, reqData, config);
};

export const storeApproveDeclineApi = async (reqData, config) => {
    return await axios.post(`${process.env.REACT_APP_GEMFINDER_URL_API}/api/store_status/`, reqData, config);
};

export const stoneSearchstoreApi = async (queryString, config) => {
    // return await axios.get(`${process.env.REACT_APP_STONE_SHARE_URL}/Store_listing/?` + queryString, config);
    return await axios.get(`${process.env.REACT_APP_GEMFINDER_URL_API}/api/store/list/?` + queryString, config);
};
export const stoneSearchstoreSourceApi = async (queryString, config) => {
    return await axios.get(`${process.env.REACT_APP_STONE_SHARE_URL}/Store_listing/?` + queryString, config);
    // return await axios.get(`${process.env.REACT_APP_GEMFINDER_URL_API}/api/store/list/?` + queryString, config);
};

export const storeColorModifyApi = async (reqData) => {
    return await axios.post(`${process.env.REACT_APP_GEMFINDER_URL_API}/api/update/settings/`, reqData);
};

export const productListApi = async (queryString, config) => {
    return await axios.get(`${process.env.REACT_APP_GEMFINDER_URL_API}/api/product/list/?` + queryString, config);
};
export const productListForCsvApi = async (config) => {
    return await axios.get(`${process.env.REACT_APP_GEMFINDER_URL_API}/api/products/list/`, config);
};

export const productLogApi = async (queryString, config) => {
    return await axios.get(`${process.env.REACT_APP_GEMFINDER_URL_API}/api/product_log/?` + queryString, config);
};
