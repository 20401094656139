import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { Paginator } from 'primereact/paginator';
import { addFilterLabelRequest, filterDropdownRequest, productAllListRequest, storeProductDropdownRequest } from '../../redux/slices/gemFinderSlice';
import { BreadCrumb } from 'primereact/breadcrumb';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Sidebar } from 'primereact/sidebar';

const ProductCompanyFilter = () => {
    const dispatch = useDispatch();

    const { productLogData, filterDropdownData, storeProductDropdowndata, isLoading } = useSelector((state) => state.gemFinder);

    const [sort_order, setSort_order] = useState('desc');
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(25);
    const [stoneSearchFilter, setStoneSerachFilter] = useState({
        color: '',
        shape: '',
        clarity: ''
    });
    const [stoneSearchLabel, setStoneSerachLabel] = useState({
        color: '',
        shape: '',
        clarity: ''
    });
    const [selectedStoreValue, setSelectedStoreValue] = useState();
    const [selectChangeValue, setSelectChangeValue] = useState(false);
    const [storeChange, setStoreChange] = useState(false);
    const [visibleLeft, setVisibleLeft] = useState(false);
    const [productView, setProductView] = useState([]);

    const colorItems = filterDropdownData?.color?.map((ele) => ele);
    const shapeItems = filterDropdownData?.shape?.map((ele) => ele);
    const clarityItems = filterDropdownData?.clarity?.map((ele) => ele);

    const table_data = {
        page: 1,
        per_page: 25,
        sort_order: sort_order
    };

    const [queryString, setQueryString] = useState(`page=${table_data.page}&per_page=${table_data.per_page}`);

    useEffect(() => {
        dispatch(storeProductDropdownRequest());
        if (selectChangeValue == true) {
            dispatch(filterDropdownRequest(`store_id=${selectedStoreValue}`));
            setStoreChange(true);
            return setSelectChangeValue(false);
        }

        dispatch(productAllListRequest(queryString));
    }, [dispatch, queryString, selectChangeValue]);

    useEffect(() => {
        if (storeChange) {
            let getColorValue = [];
            let getShapeValue = [];
            let getClarityValue = [];
            filterDropdownData?.color_list?.map((ele) => {
                return getColorValue.push(ele.Product_field_value);
            });
            filterDropdownData?.shape_list?.map((ele) => {
                return getShapeValue.push(ele.Product_field_value);
            });
            filterDropdownData?.clarity_list?.map((ele) => {
                return getClarityValue.push(ele.Product_field_value);
            });
            setStoneSerachFilter({ ...stoneSearchFilter, color: getColorValue, shape: getShapeValue, clarity: getClarityValue });
            tableChangeHandler({ ...table_data, color__in: getColorValue.join(','), shape__in: getShapeValue.join(','), clarity__in: getClarityValue.join(',') });
        }
    }, [filterDropdownData]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);

        tableChangeHandler({ ...table_data, page: event.page + 1, per_page: event.rows });
    };

    const tableChangeHandler = (data) => {
        let queryStr = Object.keys(data)
            .map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
            })
            .join('&');
        setQueryString(queryStr);
    };

    const openSideBar = (rowData) => {
        setVisibleLeft(true);
        setProductView(rowData);
    };

    const priceBodyTemplate = (rowData) => {
        return <>{rowData?.rap_price?.toLocaleString('en-US')}</>;
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className={`product-badge ${rowData?.status == 'ACTIVE' ? 'status-instock' : 'status - outofstock'}`}>{rowData?.status}</span>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button className="p-button-text" style={{ width: '20px', height: '20px' }} tooltip="View products" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => openSideBar(rowData)}></Button>
            </div>
        );
    };

    const template = {
        layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    const handlestoreChange = (e) => {
        setSelectedStoreValue(e.target.value);
        setSelectChangeValue(true);
    };

    const handleColorChange = (e) => {
        setStoneSerachFilter({ ...stoneSearchFilter, color: e.value });
        var getColorLabel = [];
        filterDropdownData?.color?.map((ele) => {
            e.value?.map((value) => {
                if (ele.value == value) {
                    return getColorLabel.push(value);
                }
            });
        });
        tableChangeHandler({
            ...table_data,
            color__in: getColorLabel.join(','),
            shape__in: stoneSearchFilter.shape,
            clarity__in: stoneSearchFilter.clarity
        });
    };

    const handleShapeChange = (e) => {
        setStoneSerachFilter({ ...stoneSearchFilter, shape: e.value });
        var getShapeLabel = [];
        filterDropdownData?.shape?.map((ele) => {
            e.value?.map((value) => {
                if (ele.value == value) {
                    return getShapeLabel.push(value);
                }
            });
        });
        setStoneSerachLabel({ ...stoneSearchLabel, shape: getShapeLabel.join(',') });
        tableChangeHandler({
            ...table_data,
            color__in: stoneSearchFilter.color,
            shape__in: getShapeLabel.join(','),
            clarity__in: stoneSearchFilter.clarity
        });
    };

    const handleClarityChange = (e) => {
        setStoneSerachFilter({ ...stoneSearchFilter, clarity: e.value });
        var getClarityLabel = [];
        filterDropdownData?.clarity?.map((ele) => {
            e.value?.map((value) => {
                if (ele.value == value) {
                    return getClarityLabel.push(value);
                }
            });
        });
        setStoneSerachLabel({ ...stoneSearchLabel, clarity: getClarityLabel.join(',') });
        tableChangeHandler({
            ...table_data,
            color__in: stoneSearchFilter.color,
            shape__in: stoneSearchFilter.shape,
            clarity__in: getClarityLabel.join(',')
            // store_id: state?.storeId == null ? 0 : state?.storeId
        });
    };

    const handleSubmit = () => {
        const filterObj = {
            store_id: selectedStoreValue,
            color: stoneSearchFilter.color.join(','),
            shape: stoneSearchFilter.shape.join(','),
            clarity: stoneSearchFilter.clarity.join(',')
        };
        dispatch(addFilterLabelRequest(filterObj));
    };

    const header = (
        <div className="flex justify-content-end">
            <span className="block mt-2 md:mt-0 p-input-icon-left mx-2">
                {/* {stoneSearchFilter.color == '' && stoneSearchFilter.shape == '' && stoneSearchFilter.clarity == '' */}
                {storeChange == false ? <Button label="Submit" className="opacity-60" /> : <Button label="Submit" onClick={handleSubmit} />}
            </span>
        </div>
    );

    const breadcrumbItems = [{ label: 'Stone Search' }, { label: 'Stone Search Product Filters' }];
    const dynamicHeight = Math.min(window.innerHeight * 4 + 1, 70) + 'vh';
    return (
        <>
            <div className="col px-0">
                <div className="breadcrumb-container flex justify-content-between align-items-center">
                    <div className="text-2xl font-bold">
                        <h3 className="m-0">Stone Search Product Filters</h3>
                    </div>
                    <div className="ml-6 text-2xl">
                        <BreadCrumb home={{ icon: 'pi pi-home', url: '/' }} model={breadcrumbItems} />
                    </div>
                </div>
            </div>

            <div className="col-12 md:col-4 px-0">
                <label htmlFor="destination_store" className="text-lg font-bold">
                    Select store:
                </label>

                <Dropdown options={storeProductDropdowndata} value={selectedStoreValue} onChange={(e) => handlestoreChange(e)} optionLabel="label" className="w-20rem ml-2" placeholder="Select Store" />
            </div>

            <div className="grid table-demo">
                <div className="col-12">
                    <div className="card">
                        <div className="grid">
                            <div className="col-12 md:col-3">
                                {storeChange == false ? (
                                    <MultiSelect disabled placeholder="Select Color" maxSelectedLabels={3} className="w-full font-bold" />
                                ) : (
                                    <MultiSelect value={stoneSearchFilter.color} onChange={handleColorChange} options={colorItems} optionLabel="label" display="chip" placeholder="Select Color" maxSelectedLabels={5} className="w-full" />
                                )}
                            </div>
                            <div className="col-12 md:col-3">
                                {storeChange == false ? (
                                    <MultiSelect disabled display="chip" placeholder="Select Shape" className="w-full font-bold" />
                                ) : (
                                    <MultiSelect value={stoneSearchFilter.shape} onChange={handleShapeChange} options={shapeItems} optionLabel="label" display="chip" placeholder="Select Shape" maxSelectedLabels={5} className="w-full" />
                                )}
                            </div>
                            <div className="col-12 md:col-3">
                                {storeChange == false ? (
                                    <MultiSelect disabled display="chip" placeholder="Select Clarity" className="w-full font-bold" />
                                ) : (
                                    <MultiSelect value={stoneSearchFilter.clarity} onChange={handleClarityChange} options={clarityItems} optionLabel="label" display="chip" placeholder="Select Clarity" maxSelectedLabels={5} className="w-full" />
                                )}
                            </div>

                            <div className="col-12 md:col-3 flex justify-content-end">{storeChange == false ? <Button label="Submit" className="opacity-60" /> : <Button label="Submit" onClick={handleSubmit} />}</div>
                        </div>
                        <DataTable
                            responsiveLayout="scroll"
                            scrollable
                            // showGridlines
                            stripedRows
                            scrollHeight={dynamicHeight}
                            size="small"
                            value={productLogData?.results}
                            className="datatable-responsive stoneSerach-content"
                            emptyMessage="No records found."
                            // header={header}
                            loading={isLoading}
                        >
                            <Column field="stock" header="Stock" style={{ minWidth: '130px' }} frozen></Column>
                            <Column field="title" header="Title" style={{ minWidth: '250px' }}></Column>

                            <Column field="color" header="Color" style={{ minWidth: '80px' }}></Column>
                            <Column field="shape" header="Shape" style={{ minWidth: '100px' }}></Column>
                            <Column field="carat" header="Carat" style={{ minWidth: '150px' }}></Column>
                            <Column field="rap_price" header="Price" style={{ minWidth: '150px' }} body={priceBodyTemplate}></Column>
                            <Column field="clarity" header="Clarity" style={{ minWidth: '150px' }}></Column>
                            <Column field="status" header="Status" style={{ minWidth: '150px' }} body={statusBodyTemplate}></Column>
                            <Column field="actions" header="Actions" style={{ minWidth: '150px' }} body={actionBodyTemplate}></Column>
                        </DataTable>
                        <Paginator template={template} first={first} rows={rows} totalRecords={productLogData?.count} onPageChange={onPageChange} rowsPerPageOptions={[10, 25, 50, 100, 200]}></Paginator>
                    </div>
                    <Sidebar visible={visibleLeft} style={{ width: '700px' }} onHide={() => setVisibleLeft(false)} baseZIndex={1000} position="right">
                        <div className="container">
                            <div className="grid">
                                <div className="col-6">
                                    <img src={productView?.image_url} className="w-22rem h-18rem" alt="diamond" />
                                </div>
                                <div className="col-6">
                                    <h4 className="font-bold" style={{ color: '#673ab7' }}>
                                        {productView?.title}
                                    </h4>
                                    <p className="line-height-1 font-bold">Product ID: {productView?.shopify_product_id == null ? '-' : productView?.shopify_product_id}</p>
                                    <p className="line-height-1 font-bold">Product Status: {productView?.status} </p>

                                    <p className="font-bold">PRODUCT DESCRIPTION</p>
                                    <ul className="px-3 font-bold">
                                        <li>Stock: {productView?.stock}</li>
                                        <li>Carat: {productView?.carat}</li>
                                        <li>Cut: {productView?.cut_grade == '' ? '-' : productView?.cut_grade}</li>
                                        <li>Shape: {productView?.shape}</li>
                                        <li>Color: {productView?.color}</li>
                                        <li>Ratio: {productView?.ratio}</li>
                                        <li>Clarity: {productView?.clarity}</li>
                                        <li>Measurement :{productView?.measurement}</li>
                                        <li>Depth Perentage :{productView?.depth_perentage}</li>
                                        <li>Table Percenatge: {productView?.table_percenatge}</li>
                                        <li>Certification: {productView?.certification}</li>
                                        <li>Polish: {productView?.polish}</li>
                                        <li>Symmetry: {productView?.symmetry}</li>
                                        <li>Rap Price": {productView?.rap_price}</li>
                                        <li>Location: {productView?.location}</li>
                                        <li>Fluorescence: {productView?.fluorescence}</li>
                                        <li>Off: {productView?.off}</li>
                                        <li>Total Price: {productView?.total_price}</li>
                                        <li>Price Ct: {productView?.price_ct}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Sidebar>
                </div>
            </div>
        </>
    );
};

export default ProductCompanyFilter;
