import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Image } from 'primereact/image';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { productViewSyncReset } from '../../redux/slices/productSlice';

const ProductSidebarViewSync = ({ viewSyncLeft, setViewSyncLeft, viewProductSyncData, storeName, destinationName }) => {
    const dispatch = useDispatch();
    const { verifiedStoreData } = useSelector((state) => state.store);

    const [openSearchBar, setOpenSearchBar] = useState(false);
    const titleTemplate = () => {
        return (
            <>
                <div className="font-bold">Default title</div>
            </>
        );
    };

    return (
        <Sidebar
            visible={viewSyncLeft}
            style={{ width: '840px' }}
            onHide={() => {
                setViewSyncLeft(false);
                setOpenSearchBar(false);
                dispatch(productViewSyncReset());
            }}
            baseZIndex={1000}
            position="right"
        >
            {viewProductSyncData !== null ? (
                <div className="container">
                    <div className="grid">
                        <div className="col-12 flex align-items-center">
                            <i className="pi pi-link text-2xl ml-2 mr-3 text-yellow-500"></i>
                            <h4 className="font-bold m-0" style={{ color: '#3F51B5' }}>
                                Map With Existing
                            </h4>
                        </div>
                        {/* 
                        <div className="col-6">
                            <div className="card shadow-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon font-bold">TO</span>
                                    <InputText className="text-xl font-bold" defaultValue={destinationName} disabled />
                                </div>
                            </div>
                        </div> */}

                        <div className="col-6">
                            {/* <div className="border-round border-400 border-1 p-3"> */}
                            <div className="card shadow-5 pt-1">
                                <div className="p-inputgroup my-3">
                                    <span className="p-inputgroup-addon font-bold">FROM</span>
                                    <InputText className="text-xl font-bold capitalize" defaultValue={verifiedStoreData.store_name} disabled />
                                </div>
                                <div className="flex">
                                    <Image src={viewProductSyncData?.destination?.image_url} alt="Image" width="110" height="110" preview className="mt-1 mr-3" />
                                    <h4 className="m-0 flex align-items-center text-xl font-bold">{viewProductSyncData?.destination?.title}</h4>
                                </div>
                                <ul className="px-3 font-semibold">
                                    <li>Product ID : {viewProductSyncData?.destination?.id}</li>
                                    <li>Vendor : {viewProductSyncData?.destination?.vendor}</li>
                                    <li>Product Type : {viewProductSyncData?.destination?.product_type}</li>
                                    <li>
                                        Tags :{' '}
                                        <span className="ml-1">
                                            {viewProductSyncData?.destination?.tags?.map((ele, index) => {
                                                return <Tag className="mr-2 p-tag-rounded my-1 bg-green-800" icon="pi pi-tags" value={ele} key={index}></Tag>;
                                            })}
                                        </span>
                                    </li>
                                </ul>
                                <DataTable scrollHeight="350px" size="small" value={[viewProductSyncData?.destination]} className="datatable-responsive datatable_container card p-0 shadow-3">
                                    <Column field="title" header="Title" className="font-bold" body={titleTemplate}></Column>
                                    <Column field="sku" header="Sku" className="font-bold"></Column>
                                    <Column field="quantity" header="QUANTITY" className="font-bold"></Column>
                                </DataTable>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="card shadow-5 pt-1">
                                <div className="p-inputgroup my-3">
                                    <span className="p-inputgroup-addon font-bold">TO</span>
                                    <InputText className="text-xl font-bold capitalize" defaultValue={storeName} disabled />
                                </div>
                                <div className="flex">
                                    <Image src={viewProductSyncData?.source?.image_url} alt="Image" width="110" height="110" preview className="mt-1 mr-3" />
                                    <h4 className="m-0 flex align-items-center text-xl font-bold">{viewProductSyncData?.source?.title}</h4>
                                </div>
                                <ul className="px-3 font-semibold">
                                    <li>Product ID : {viewProductSyncData?.source?.id}</li>
                                    <li>Vendor : {viewProductSyncData?.source?.vendor}</li>
                                    <li>Product Type : {viewProductSyncData?.source?.product_type}</li>
                                    <li>
                                        Tags :{' '}
                                        <span className="ml-1">
                                            {viewProductSyncData?.source?.tags?.map((ele, index) => {
                                                return <Tag className="mr-2 p-tag-rounded my-1 bg-green-800" icon="pi pi-tags" value={ele} key={index}></Tag>;
                                            })}
                                        </span>
                                    </li>
                                </ul>
                                <DataTable scrollHeight="350px" size="small" value={[viewProductSyncData?.source]} className="datatable-responsive datatable_container card p-0 shadow-3">
                                    <Column field="title" header="Title" className="font-bold" body={titleTemplate}></Column>
                                    <Column field="sku" header="Sku" className="font-bold"></Column>
                                    <Column field="quantity" header="QUANTITY" className="font-bold"></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <i className="pi pi-spin pi-spinner flex justify-content-center align-items-center" style={{ fontSize: '3rem' }}></i>
                </>
            )}
        </Sidebar>
    );
};

export default ProductSidebarViewSync;
