import axios from 'axios';

export const b2bOrderListApi = async (queryString, config) => {
    return await axios.get(`${process.env.REACT_APP_B2B_URL_API}/api/orderlist?` + queryString, config);
};
export const b2bDiscountListApi = async (queryString, config) => {
    return await axios.get(`${process.env.REACT_APP_B2B_URL_API}/api/list/discount_tag?` + queryString, config);
};
export const bsbCreateTagApi = async (data, config) => {
    return await axios.post(`${process.env.REACT_APP_B2B_URL_API}/api/create/discount_tag`, data, config);
};
export const getStoreNameDdApi = async (config) => {
    return await axios.get(`${process.env.REACT_APP_B2B_URL_API}/api/store-listing`, config);
};
export const getCollectionListApi = async (storeName, config) => {
    return await axios.get(`${process.env.REACT_APP_B2B_URL_API}/api/get-colletion?store_name=${storeName}`, config);
};
export const b2bDeleteTagApi = async (id, config) => {
    return await axios.delete(`${process.env.REACT_APP_B2B_URL_API}/api/delete/discount_tag/${id}`, config);
};
export const b2bUpdateTagApi = async (id, userData, config) => {
    return await axios.put(`${process.env.REACT_APP_B2B_URL_API}/api/update/discount_tag/${id}`, userData, config);
};

export const bsbOrderDetailsApi = async (userId, config) => {
    return await axios.get(`${process.env.REACT_APP_B2B_URL_API}/api/ordered-product-detail/${userId}/`, config);
};
export const bsbOrderStatusApi = async (userData, config) => {
    return await axios.patch(`${process.env.REACT_APP_B2B_URL_API}/api/order-status/`, userData, config);
};
export const customerConfirmStatusApi = async (userData, config) => {
    return await axios.post(`${process.env.REACT_APP_B2B_URL_API}/api/customer-confirmed-status/`, userData, config);
};
export const sendInvoiceApi = async (productId, config) => {
    return await axios.get(`${process.env.REACT_APP_B2B_URL_API}/api/invoice-send/${productId}/`, config);
};
