import axios from 'axios';

export const allProductsApi = async (queryString, config) => {
    return await axios.get(`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/product/list/?` + queryString, config);
};

export const productsViewDetailApi = async (id, config) => {
    return await axios.get(`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/productview/${id}`, config);
};

export const productmultiRemoveAvailableUnavailableApi = async (reqData, config) => {
    return await axios.delete(`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/product/delete_available/`, {
        headers: { Authorization: config?.headers?.Authorization },
        data: reqData
    });
};

export const folderImgRemoveApi = async (reqdata, config) => {
    return await axios.post(`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/product_image/delete/`, reqdata, config);
};

export const singleMultiImgFolderApi = async (reqData, config) => {
    return await axios.post(`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/uploadfile/`, reqData, config);
};

export const statusUpdateExceleApi = async (reqData, config) => {
    return await axios.post(`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/Product_not_available/`, reqData, config);
};

export const productExcelTypeOneApi = async (reqData, config) => {
    return await axios.post(`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/upload1/`, reqData, config);
};

export const inventoryExcelTypeTwoApi = async (reqData, config) => {
    return await axios.post(`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/upload2/`, reqData, config);
};
