import { createSlice } from '@reduxjs/toolkit';
import {
    addFilterLabelApi,
    filterDropdownApi,
    productListApi,
    productListForCsvApi,
    productLogApi,
    stoneSearchstoreApi,
    stoneSearchstoreSourceApi,
    storeApproveDeclineApi,
    storeColorModifyApi,
    storeListDropdownApi,
    storeSettingDropdowntApi,
    uploadCSVApi
} from '../../service/gemFinderService';
import { productListSuccess } from './productSlice';
import { toast } from 'react-hot-toast';
import { handleUnAuthorizeUserRequest } from './authSlice';

export const gemFinderSlice = createSlice({
    name: 'gemFinder',
    initialState: {
        isLoading: false,
        csvData: null,
        storeSettingDropdowndata: [],
        storeProductDropdowndata: [],
        colorModifyData: null,
        stoneSearchStoreData: [],
        stoneSearchStoreSourceData: [],
        filterDropdownData: [],
        addFilterLabelData: null,
        storeApproveDeclineMessage: null,
        productLogData: [],
        productLogCsvData: [],
        productAllData: [],
        shopifyData: [],
        error: null
    },
    reducers: {
        setLoading: (state) => {
            state.isLoading = true;
        },
        csvDataList: (state, action) => {
            state.isLoading = false;
            state.csvData = action.payload;
        },
        storeSettingDropdownList: (state, action) => {
            state.isLoading = false;
            state.storeSettingDropdowndata = action.payload?.data;
        },
        storeProductDropdownList: (state, action) => {
            state.isLoading = false;
            state.storeProductDropdowndata = action.payload?.data;
        },

        colorModifyList: (state, action) => {
            state.isLoading = false;
            state.colorModifyData = action.payload;
        },

        stoneSearchStoreDataList: (state, action) => {
            state.isLoading = false;
            state.stoneSearchStoreData = action.payload;
        },
        stoneSearchStoreDataSourceList: (state, action) => {
            state.isLoading = false;
            state.stoneSearchStoreSourceData = action.payload;
        },

        filterDropdownDataList: (state, action) => {
            state.isLoading = false;
            state.filterDropdownData = action.payload?.data;
        },

        addFilterLabelDataList: (state, action) => {
            state.isLoading = false;
            state.addFilterLabelData = action.payload;
        },

        storeApproveDeclineMessageData: (state, action) => {
            state.isLoading = false;
            state.storeApproveDeclineMessage = action.payload;
        },

        productAllDataList: (state, action) => {
            state.isLoading = false;
            state.productAllData = action.payload;
        },
        productLogDataList: (state, action) => {
            state.isLoading = false;
            state.productLogData = action.payload;
        },
        productLogCsvDataList: (state, action) => {
            state.isLoading = false;
            state.productLogCsvData = action.payload;
        },
        gemFinderError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        gemFinderDropdownFilterReset: (state) => {
            state.isLoading = false;
            state.error = null;
            state.filterDropdownData = [];
        },
        gemFinderResetData: (state) => {
            state.isLoading = false;
            state.error = null;
            state.csvData = null;
            state.colorModifyData = null;
            state.productLogData = [];
        },
        storeResetData: (state) => {
            state.isLoading = false;
            state.error = null;
            state.storeApproveDeclineMessage = null;
        }
    }
});

export const {
    setLoading,
    csvDataList,
    storeSettingDropdownList,
    storeProductDropdownList,
    colorModifyList,
    stoneSearchStoreDataList,
    stoneSearchStoreDataSourceList,
    filterDropdownDataList,
    addFilterLabelDataList,
    storeApproveDeclineMessageData,
    productAllDataList,
    productLogDataList,
    productLogCsvDataList,
    gemFinderError,
    gemFinderDropdownFilterReset,
    gemFinderResetData,
    storeResetData
} = gemFinderSlice.actions;
export default gemFinderSlice.reducer;

export const uploadCSV = (uploadCsvRequest) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait file is uploading...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await uploadCSVApi(uploadCsvRequest, config);
        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(csvDataList(message));
        }
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error.response && error.response.data) {
            toast.error(error.response.data.message, {
                id: toastId
            });
            return dispatch(gemFinderError(error.response.data.message));
        }
    }
};

export const storeSettingDropdownRequest = (queryData) => async (dispatch) => {
    dispatch(setLoading());
    try {
        const { data } = await storeSettingDropdowntApi(queryData);
        dispatch(storeSettingDropdownList(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else {
            dispatch(gemFinderError(error.response));
        }
    }
};

export const storeProductDropdownRequest = () => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await storeListDropdownApi(config);
        dispatch(storeProductDropdownList(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else {
            dispatch(gemFinderError(error.response));
        }
    }
};

export const colorModifyRequest = (colorUpdate) => async (dispatch) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait...');
    try {
        const { data } = await storeColorModifyApi(colorUpdate);
        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(colorModifyList(message));
        }
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error.response && error.response.data) {
            toast.error(error.response.data.message, {
                id: toastId
            });
            return dispatch(gemFinderError(error.response.data.message));
        }
    }
};

export const productAllListRequest = (queryData) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await productListApi(queryData, config);
        dispatch(productLogDataList(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else {
            dispatch(gemFinderError(error));
        }
    }
};
export const getAllProductListForCsvRequest = () => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await productListForCsvApi(config);
        dispatch(productLogCsvDataList(data?.results));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else {
            dispatch(gemFinderError(error));
        }
    }
};

export const productLogListRequest = (queryData) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await productLogApi(queryData, config);
        dispatch(productAllDataList(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else {
            return dispatch(gemFinderError(error));
        }
    }
};

export const stoneSearchStoreRequest = (queryData) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await stoneSearchstoreApi(queryData, config);
        dispatch(stoneSearchStoreDataList(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else {
            dispatch(gemFinderError(error?.response));
        }
    }
};
export const stoneSearchStoreSourceRequest = (queryData) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await stoneSearchstoreSourceApi(queryData, config);
        dispatch(stoneSearchStoreDataSourceList(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else {
            dispatch(gemFinderError(error?.response));
        }
    }
};

export const filterDropdownRequest = (queryData) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await filterDropdownApi(queryData, config);
        dispatch(filterDropdownDataList(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else {
            return dispatch(gemFinderError(error?.response));
        }
    }
};

export const addFilterLabelRequest = (filterLabel) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await addFilterLabelApi(filterLabel, config);
        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(addFilterLabelDataList(message));
        }
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error?.response && error?.response?.data) {
            toast.error(error?.response?.data?.message, {
                id: toastId
            });
            return dispatch(gemFinderError(error?.response?.data?.message));
        }
    }
};

export const storeApproveDeclineRequest = (storeApproveDeclineData) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await storeApproveDeclineApi(storeApproveDeclineData, config);
        const { statusCode, message } = data;
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(storeApproveDeclineMessageData(message));
        }
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error?.response && error?.response?.data) {
            toast.error(error?.response?.data?.message, {
                id: toastId
            });
            return dispatch(gemFinderError(error?.response?.data?.message));
        }
    }
};
