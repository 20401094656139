import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { useDispatch, useSelector } from 'react-redux';
import { Paginator } from 'primereact/paginator';
import { BreadCrumb } from 'primereact/breadcrumb';
import { addUserRequest, modifyUserRequest, removeUserRequest, userListRequest, userResetData, viewUserRequest } from '../../../redux/slices/kushDiamond/userSlice';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';

const UserList = () => {
    const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const mobilePattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
    const dispatch = useDispatch();

    const { allUser, isLoading, addUser, removeUser, error, removeIdError, viewIdError, viewUser, modifyUser } = useSelector((state) => state.kdUser);

    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(25);
    const [sort_order, setSort_order] = useState('desc');
    const [userDialog, setUserDialog] = useState(false);
    const [modifyUserDialog, setModifyUserDialog] = useState(false);
    const [formValue, setFormValue] = useState({
        username: '',
        email: '',
        mobile_no: '',
        password: '',
        confirmPassword: ''
    });
    const [modifyFormValue, setmodifyFormValue] = useState({
        username: '',
        email: '',
        mobile_no: ''
    });

    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [modifyUserSubmitted, setModifyUserSubmitted] = useState(false);
    const [removeUserId, setRemoveUserId] = useState(0);
    const toast = useRef(null);

    const table_data = {
        page: 1,
        per_page: 25,
        sort_order: sort_order,
        order_column: 'created_at'
    };

    const [queryString, setQueryString] = useState(`page=${table_data.page}&per_page=${table_data.per_page}&order_column=${table_data.order_column}`);

    useEffect(() => {
        dispatch(userListRequest(queryString));
        if (addUser !== null) {
            setUserDialog(false);
            return resetFormValue();
        }
        if (removeUser !== null) {
            setDeleteProductsDialog(false);
            return setRemoveUserId(0);
        }
        if (removeIdError !== null) {
            setDeleteProductsDialog(false);
            return setRemoveUserId(0);
        }

        setmodifyFormValue({
            username: viewUser && viewUser?.username,
            mobile_no: viewUser && viewUser?.mobile_no,
            email: viewUser && viewUser?.email
        });

        if (modifyUser !== null) {
            setModifyUserDialog(false);
            dispatch(userResetData());
            return resetModifyFormValue();
        }

        return () => {
            dispatch(userResetData());
        };
    }, [dispatch, queryString, addUser, removeUser, removeIdError, viewUser, modifyUser]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        tableChangeHandler({ ...table_data, page: event.page + 1, per_page: event.rows });
    };

    const tableChangeHandler = (data) => {
        let queryStr = Object.keys(data)
            .map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
            })
            .join('&');
        setQueryString(queryStr);
    };

    const resetFormValue = () => {
        setFormValue({
            username: '',
            email: '',
            mobile_no: '',
            password: '',
            confirmPassword: ''
        });
        setSubmitted(false);
    };

    const template = {
        layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    const openNew = () => {
        setUserDialog(true);
    };

    const hideDialog = () => {
        setUserDialog(false);
        resetFormValue();
        dispatch(userResetData());
    };

    const resetModifyFormValue = () => {
        setmodifyFormValue({
            username: '',
            email: '',
            mobile_no: ''
        });
        setModifyUserSubmitted(false);
        dispatch(userResetData());
    };

    const hideModifyUserDialog = () => {
        setModifyUserDialog(false);
        resetModifyFormValue();
        dispatch(userResetData());
    };

    const confirmDeleteSelected = (id) => {
        setRemoveUserId(id);
        setDeleteProductsDialog(true);
    };

    const onModifyInputChange = (e) => {
        setmodifyFormValue({ ...modifyFormValue, [e.target.name]: e.target.value });
    };

    const openModifyUserDialog = (id) => {
        dispatch(viewUserRequest(id));
        setModifyUserDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex">
                <Button className="p-button-text p-button-success mr-2" style={{ height: '25px', width: '25px' }} icon="pi pi-user-edit" onClick={() => openModifyUserDialog(rowData.id)}></Button>
                <Button className="p-button-text p-button-danger" style={{ height: '25px', width: '25px' }} onClick={() => confirmDeleteSelected(rowData.id)} icon="pi pi-trash"></Button>
            </div>
        );
    };

    const onInputChange = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };

    const emailvalidationeError = () => {
        if ((submitted && formValue.email == '') || (modifyUserSubmitted && modifyFormValue.email == '')) {
            return <small className="p-error text-lg">Email is required.</small>;
        } else if ((submitted && regexEmail.test(formValue.email) === false) || (modifyUserSubmitted && regexEmail.test(modifyFormValue.email) === false)) {
            return <small className="p-error text-lg">Please enter valid email.</small>;
        } else if (error && error?.email) {
            return <small className="p-error text-lg">{error?.email}</small>;
        } else {
            return null;
        }
    };

    const mobilevalidationeError = () => {
        if ((submitted && formValue.mobile_no == '') || (modifyUserSubmitted && modifyFormValue.mobile_no == '')) {
            return <small className="p-error text-lg">Mobile No. is required.</small>;
        } else if ((submitted && mobilePattern.test(formValue.mobile_no) === false) || (modifyUserSubmitted && mobilePattern.test(modifyFormValue.mobile_no) === false)) {
            return <small className="p-error text-lg">Please enter number only.</small>;
        } else if (error && error?.mobile_no) {
            return <small className="p-error text-lg">{error?.mobile_no}</small>;
        } else {
            return null;
        }
    };

    const confirmPasswordError = () => {
        if (submitted && formValue.confirmPassword == '') {
            return <small className="p-error text-lg">Confirm password is required.</small>;
        } else if (submitted && formValue.password !== formValue.confirmPassword) {
            return <small className="p-error text-lg">Confirm password is Not Matched.</small>;
        } else {
            return null;
        }
    };

    const handleSubmit = () => {
        setSubmitted(true);
        const { username, email, mobile_no, password, confirmPassword } = formValue;
        if (username !== '' && email !== '' && mobile_no !== '' && password !== '' && confirmPassword !== '' && password == confirmPassword && regexEmail.test(formValue.email) === true && mobilePattern.test(formValue.mobile_no) === true) {
            dispatch(addUserRequest(formValue));
        }
    };

    const modifyUserSubmit = () => {
        setModifyUserSubmitted(true);
        const { username, email, mobile_no } = modifyFormValue;
        if (username !== '' && email !== '' && mobile_no !== '' && regexEmail.test(modifyFormValue.email) === true && mobilePattern.test(modifyFormValue.mobile_no) === true) {
            dispatch(modifyUserRequest(viewUser.id, modifyFormValue));
            dispatch(userResetData());
        }
    };

    const header = (
        <div className="flex justify-content-end my-1">
            <Button label="Create User" icon="pi pi-plus" onClick={openNew} />
        </div>
    );

    const userDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-secondary p-button-outlined" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={handleSubmit} />
        </>
    );

    const modifyUserDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-secondary p-button-outlined" onClick={hideModifyUserDialog} />
            <Button label="Save" icon="pi pi-check" onClick={modifyUserSubmit} />
        </>
    );

    const removeUserRecord = () => {
        dispatch(removeUserRequest(removeUserId));
    };

    const deleteProductsDialogFooter = (
        <>
            <Button
                label="No"
                icon="pi pi-times"
                className="p-button-outlined p-button-danger"
                onClick={() => {
                    setDeleteProductsDialog(false);
                    setRemoveUserId(0);
                }}
            />
            <Button label="Yes" icon="pi pi-check" className="p-button-outlined" onClick={() => removeUserRecord()} />
        </>
    );

    const breadcrumbItems = [{ label: 'Kush Diamond' }, { label: 'Users' }];
    const dynamicHeight = Math.min(window.innerHeight * 4 + 1, 70) + 'vh';
    return (
        <>
            <div className="col px-0 pt-0 pb-2">
                <div className="breadcrumb-container flex justify-content-between align-items-center">
                    <div className="text-2xl font-bold">
                        <h3 className="m-0">Users</h3>
                    </div>
                    <div className="ml-6 text-2xl">
                        <BreadCrumb home={{ icon: 'pi pi-home', url: '/' }} model={breadcrumbItems} />
                    </div>
                </div>
            </div>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        {/* {create user Dialog} */}
                        <Dialog visible={userDialog} style={{ width: '50vw' }} header="Create User" modal className="p-fluid" footer={userDialogFooter} onHide={hideDialog}>
                            <div className="grid p-3">
                                <div className="col-6 py-0">
                                    <div className="field">
                                        <label htmlFor="username" className="font-bold">
                                            User Name
                                        </label>
                                        <InputText name="username" autoFocus placeholder="Enter Your User Name" value={formValue.username} onChange={(e) => onInputChange(e)} className="mb-2" />
                                        {(submitted && formValue.username == '') || (error && error.username) ? <small className="p-error text-lg">{(submitted && formValue.username == '' && 'Username is required.') || error?.username}</small> : null}
                                    </div>
                                </div>
                                <div className="col-6 py-0">
                                    <div className="field">
                                        <label htmlFor="email" className="font-bold">
                                            Email
                                        </label>
                                        <InputText type="email" name="email" placeholder="Enter Your Email" value={formValue.email} onChange={(e) => onInputChange(e)} className="mb-2" />
                                        {emailvalidationeError()}
                                    </div>
                                </div>
                                <div className="col-6 py-0">
                                    <div className="field">
                                        <label htmlFor="mobile_no" className="font-bold">
                                            Mobile No
                                        </label>
                                        <InputText name="mobile_no" placeholder="Enter Your Mobile No" value={formValue.mobile_no} onChange={(e) => onInputChange(e)} maxLength="10" className="mb-2" />
                                        {mobilevalidationeError()}
                                    </div>
                                </div>
                                <div className="col-6 py-0">
                                    <div className="field">
                                        <label htmlFor="password" className="font-bold">
                                            Password
                                        </label>
                                        <Password name="password" placeholder="Enter Your Password" toggleMask feedback={false} value={formValue.password} onChange={(e) => onInputChange(e)} className="mb-2" />
                                        {(submitted && formValue.password == '') || (error && error.password) ? <small className="p-error text-lg">{(submitted && formValue.password == '' && 'Password is required.') || error?.password}</small> : null}
                                    </div>
                                </div>
                                <div className="col-6 py-0">
                                    <div className="field">
                                        <label htmlFor="confirmPassword" className="font-bold">
                                            Confirm Password
                                        </label>
                                        <Password name="confirmPassword" placeholder="Enter Your Confirm Password" toggleMask feedback={false} value={formValue.confirmPassword} onChange={(e) => onInputChange(e)} className="mb-2" />
                                        {confirmPasswordError()}
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                        <DataTable
                            size="small"
                            scrollable
                            showGridlines
                            stripedRows
                            scrollHeight={dynamicHeight}
                            value={allUser?.results}
                            dataKey="id"
                            loading={isLoading}
                            className="datatable-responsive kd-datatable-content"
                            header={header}
                            emptyMessage="No records found."
                            responsiveLayout="scroll"
                        >
                            <Column field="username" header="User Name" style={{ minWidth: '4rem' }}></Column>
                            <Column field="email" header="Email" style={{ minWidth: '8rem' }}></Column>
                            <Column field="mobile_no" header="Mobile No." style={{ minWidth: '4rem' }}></Column>
                            <Column field="actions" header="Actions" style={{ minWidth: '44rem' }} body={actionBodyTemplate}></Column>
                        </DataTable>
                        <Paginator template={template} first={first} rows={rows} totalRecords={allUser?.count} onPageChange={onPageChange} rowsPerPageOptions={[25, 50, 100, 200]}></Paginator>
                        {/* {remove user Dialog} */}
                        <Dialog
                            visible={deleteProductsDialog}
                            style={{ width: '450px' }}
                            header="Confirm"
                            modal
                            onHide={() => {
                                setDeleteProductsDialog(false);
                                setRemoveUserId(0);
                            }}
                            footer={deleteProductsDialogFooter}
                        >
                            <div className="flex align-items-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                <span>Are you sure you want to remove the user?</span>
                            </div>
                        </Dialog>
                        {/* {modify user Dialog} */}

                        <Dialog visible={modifyUserDialog} style={{ width: '50vw' }} header="Update User" modal className="p-fluid" footer={modifyUserDialogFooter} onHide={hideModifyUserDialog}>
                            {viewUser?.length == 0 || (modifyFormValue.username == '' && modifyFormValue.email == '' && modifyFormValue.mobile_no == '') ? (
                                <>
                                    <i className="pi pi-spin pi-spinner flex justify-content-center align-items-center" style={{ fontSize: '3rem' }}></i>
                                </>
                            ) : (
                                <div className="grid p-3">
                                    <div className="col-6 py-0">
                                        <div className="field">
                                            <label htmlFor="username" className="font-bold">
                                                User Name
                                            </label>
                                            <InputText name="username" autoFocus placeholder="Enter Your User Name" value={modifyFormValue?.username} onChange={(e) => onModifyInputChange(e)} className="mb-2" />
                                            {(modifyUserSubmitted && modifyFormValue.username == '') || (error && error.username) ? (
                                                <small className="p-error text-lg">{(modifyUserSubmitted && modifyFormValue.username == '' && 'Username is required.') || error?.username}</small>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-6 py-0">
                                        <div className="field">
                                            <label htmlFor="email" className="font-bold">
                                                Email
                                            </label>
                                            <InputText type="email" name="email" placeholder="Enter Your Email" value={modifyFormValue?.email} onChange={(e) => onModifyInputChange(e)} className="mb-2" />
                                            {emailvalidationeError()}
                                        </div>
                                    </div>
                                    <div className="col-6 py-0">
                                        <div className="field">
                                            <label htmlFor="mobile_no" className="font-bold">
                                                Mobile No
                                            </label>
                                            <InputText name="mobile_no" placeholder="Enter Your Mobile No" value={modifyFormValue?.mobile_no} onChange={(e) => onModifyInputChange(e)} maxLength="10" className="mb-2" />
                                            {mobilevalidationeError()}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserList;
