import React, { useRef, useState } from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';
import { excelResetData, inventoryExcelTypeTwoRequest } from '../../../redux/slices/kushDiamond/productSlice';
import { Link } from 'react-router-dom';

const UploadMedia = () => {
    const dispatch = useDispatch();

    const [inventoryExcelState, setInventoryExcelState] = useState({
        selectFileName: 'No file choosen',
        submitted: false,
        file: null
    });
    const inputRef = useRef(null);

    const handleOnChange = (e) => {
        const selectFile = e.target.files[0];
        setInventoryExcelState({ ...inventoryExcelState, selectFileName: e.target.files[0]?.name, file: selectFile, submitted: true });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // let formData = new FormData();
        // formData.append('file', inventoryExcelState?.file);
        // dispatch(inventoryExcelTypeTwoRequest(formData));
        // dispatch(excelResetData());
        // inputRef.current.value = null;
        // setInventoryExcelState({ ...inventoryExcelState, selectFileName: 'No file choosen', file: null, submitted: false });
    };

    const breadcrumbItems = [{ label: 'Kush Diamond' }, { label: 'Upload Media' }];
    return (
        <>
            <div className="col px-0 pt-0 pb-2">
                <div className="breadcrumb-container flex justify-content-between align-items-center">
                    <div className="text-2xl font-bold">
                        <h3 className="m-0">Upload Media</h3>
                    </div>
                    <div className="ml-6 text-2xl">
                        <BreadCrumb home={{ icon: 'pi pi-home', url: '/' }} model={breadcrumbItems} />
                    </div>
                </div>
            </div>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <div className="border-1 border-400 surface-300 p-3">
                            <p className="text-xl font-bold mb-1">NOTE :</p>
                            <p className="text-lg font-semibold">The Folder name should be the same as SKU No. Please upload images and videos only.</p>
                        </div>
                       
                        <form className="mt-4">
                            <input ref={inputRef} type={'file'} id={'excelFile'} onChange={handleOnChange} hidden />
                            <div className="flex align-items-center">
                                <div className="cursor-pointer w-9rem border-round" style={{ backgroundColor: '#607d8b', color: 'white', padding: '0.5rem' }}>
                                    <label htmlFor="excelFile" className="flex justify-content-center align-items-center">
                                        <i className="pi pi-upload mr-3"></i>
                                        <span className="text-lg font-bold cursor-pointer">Select file</span>
                                    </label>
                                </div>
                                <span id="file-chosen" className="ml-3 text-lg font-bold">
                                    {inventoryExcelState?.selectFileName}
                                </span>
                            </div>

                            <div className="flex align-items-center mt-3">
                                <div className="mr-4">{inventoryExcelState?.submitted == true ? <Button icon="pi pi-check" label="Submit" onClick={handleSubmit} /> : <Button icon="pi pi-check" label="Submit" disabled />}</div>
                                <Link to={-1}>
                                    <Button className="p-button-outlined" label="Back" />
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UploadMedia;
