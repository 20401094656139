import { createSlice } from '@reduxjs/toolkit';
import { b2bOrderListApi, bsbOrderDetailsApi, bsbOrderStatusApi, customerConfirmStatusApi, sendInvoiceApi } from '../../../service/b2b/b2bService';
import { toast } from 'react-hot-toast';

export const orderSlice = createSlice({
    name: 'b2bOrders',
    initialState: {
        isLoading: false,
        isOrderStatusLoaderStart: false,
        allOrdersList: [],
        orderStatusDataList: [],
        orderDetailsData: null,
        error: null
    },
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setOrderStatusLoader: (state, action) => {
            state.isLoading = action.false;
            state.isOrderStatusLoaderStart = action.payload;
        },

        allOrdersListData: (state, action) => {
            state.isLoading = false;
            state.allOrdersList = action.payload;
        },

        orderDetailsListData: (state, action) => {
            state.isLoading = false;
            state.orderDetailsData = action.payload?.data;
        },

        b2bError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { setLoading, allOrdersListData, orderDetailsListData, setOrderStatusLoader, b2bError } = orderSlice.actions;
export default orderSlice.reducer;

export const allOrderListRequest = (queryString) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };

        const { data } = await b2bOrderListApi(queryString, config);
        dispatch(allOrdersListData(data));
    } catch (error) {
        dispatch(b2bError(error?.response));
    }
};

export const orderDetailsRequest = (userid) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await bsbOrderDetailsApi(userid, config);
        dispatch(orderDetailsListData(data));
    } catch (error) {
        if (error?.response?.data) {
            toast.error(error?.response?.data?.message);
            return dispatch(b2bError(error?.response?.data?.message));
        }
    }
};

export const orderStatusRequest = (userData) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await bsbOrderStatusApi(userData, config);
        toast.success(data?.message, {
            duration: 5000,
        });

        dispatch(setLoading(false));
    } catch (error) {
        if (error?.response?.data) {
            // toast.error(error?.message, {
            //     duration: 5000,
            // });
            // return dispatch(b2bError(error?.response?.data?.message));
            toast.error(error?.response?.data?.message, {
                duration: 5000,
            });
        }
    }
};
export const customerConfirmStatusRequest = (userData) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await customerConfirmStatusApi(userData, config);
        console.log(data, 'data')
        toast.success(data?.message);
    } catch (error) {
        if (error?.response?.data) {
            toast.error(error?.message);
            return dispatch(b2bError(error?.response?.data?.message));
        }
    }
};
export const sendInvoiceRequest = (productId) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await sendInvoiceApi(productId, config);
        toast.success(data?.message);
    } catch (error) {
        if (error?.response?.data) {
            toast.error(error?.message);
            return dispatch(b2bError(error?.response?.data?.message));
        }
    }
};
