import axios from 'axios';

export const ftpClientListApi = async (queryString, config) => {
    return await axios.get(`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/ftp/list/?` + queryString, config);
};

export const ViewFtpClientApi = async (id, config) => {
    return await axios.get(`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/ftpclientview/${id}`, config);
};

export const addFtpClientApi = async (reqData, config) => {
    return await axios.post(`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/ftp/create/`, reqData, config);
};

export const modifyFtpClientApi = async (id, reqData, config) => {
    return await axios.put(`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/ftp/modify/${id}`, reqData, config);
};

export const removeFtpClientApi = async (id, config) => {
    return await axios.delete(`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/ftp/modify/${id}`, config);
};

export const ftpClientDropdownListApi = async (config) => {
    return await axios.get(`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/get_ftp_data/`, config);
};
