import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Steps } from 'primereact/steps';
import NewConnectSore from '../NewConnectSore';
import KeyStore from '../KeyStore';
import EmailStore from '../EmailStore';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { connectStoreApi, connectStoreData, disconnectStoreRequest, sourceStoreListeApi, storeRequest, storeRequestSuccess } from '../../redux/slices/storeSlice';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { stoneSearchStoreRequest, stoneSearchStoreSourceRequest, storeResetData } from '../../redux/slices/gemFinderSlice';
const StoreDatatable = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { stoneSearchStoreData, stoneSearchStoreSourceData } = useSelector((store) => store.gemFinder);
    const { sourceStoreList, isLoading, storeData, storeDisconnectData, verifiedStoreData, storeListingData, error, storeRequestSuccessMessage, storeConnectKey } = useSelector((state) => state.store);
    const [expandedRows, setExpandedRows] = useState(null);
    const [visible, setVisible] = useState(false);
    const [connectKeyType, setConnectKeyType] = useState('choose');
    const [tabelStoreName, setTabelStoreName] = useState({
        store_name: '',
        store_id: ''
    });
    const [activeIndex, setActiveIndex] = useState(0);
    const [showEmail, setShowEmail] = useState(false);
    const [showKey, setShowKey] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [storeDialog, setStoreDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [sort_order, setSort_order] = useState('desc');
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(25);
    const [filterValue, setFilterValue] = useState('');
    const [disconnectOpencModal, setDisconnectOpencModal] = useState(false);
    const [destStoreId, setDestStoreId] = useState(0);
    const toast = useRef(null);
    const dt = useRef(null);

    const table_data = {
        page: 1,
        per_page: 25,
        sort_order: sort_order,
        filterValue: filterValue
    };

    const [queryString, setQueryString] = useState(`page=${table_data.page}&search=${table_data.filterValue}&per_page=${table_data.per_page}`);
    const getQueryParam = () => {
        if (verifiedStoreData?.is_verified) {
            return `${queryString}&store_id=${verifiedStoreData.store_id}&store_type=${verifiedStoreData.store_type}`;
        } else {
            return queryString;
        }
    };
    const getPayload = () => {
        if (verifiedStoreData.is_verified) {
            const payload = {
                store_id: verifiedStoreData?.store_id,
                store_type: verifiedStoreData?.store_type
            };
            return payload;
        } else {
            const payload = {
                store_id: storeData?.store_id,
                store_type: storeData?.store_type
            };
            return payload;
        }
    };
    const getConnectStorePayload = (store_key) => {
        if (verifiedStoreData.is_verified) {
            const payload = {
                store_key: store_key,
                connect_type: connectKeyType,
                store_id: verifiedStoreData?.store_id,
                store_type: verifiedStoreData?.store_type
            };
            return payload;
        } else {
            const payload = {
                store_key: store_key,
                connect_type: connectKeyType,
                store_id: tabelStoreName?.store_id,
                store_type: storeData?.store_type
            };
            return payload;
        }
    };
    const getConnectStoreByStoreListPaylaod = (value) => {
        if (verifiedStoreData.is_verified) {
            const payload = {
                store_id: verifiedStoreData?.store_id,
                store_type: verifiedStoreData?.store_type,
                connect_type: connectKeyType,
                source_store_id: value
            };
            return payload;
        } else {
            const payload = {
                store_id: storeData.store_id,
                connect_type: connectKeyType,
                source_store_id: value
            };
            return payload;
        }
    };

    const storeConnectformik = useFormik({
        initialValues: {
            store_key: ''
        },
        validationSchema: yup.object({
            store_key: yup.string().required('Key is required')
        }),
        onSubmit: (values) => {
            const data = getConnectStorePayload(values.store_key);
            dispatch(connectStoreApi(data));
            storeConnectformik.resetForm();
        }
    });
    const handleSendRequest = () => {
        const data = getConnectStorePayload(tabelStoreName.id);
        const payload = {
            source_store_id: tabelStoreName.store_id,
            connect_type: connectKeyType,
            store_id: verifiedStoreData?.store_id,
            store_type: verifiedStoreData?.store_type
        };
        dispatch(connectStoreApi(payload));
        setVisible(false);
    };
    const sendRequestFormik = useFormik({
        initialValues: {
            store_id: '',
            connect_type: '',
            source_store_id: ''
        },
        validate: (data) => {
            let errors = {};

            if (data.store_id == '') {
                errors.store_id = 'Store is required.';
            }

            return errors;
        },

        onSubmit: (values) => {
            const data = getConnectStoreByStoreListPaylaod(values.store_id);
            dispatch(connectStoreApi(data));
            sendRequestFormik.resetForm();
        }
    });
    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        tableChangeHandler({ ...table_data, page: event.page + 1, per_page: event.rows });
    };

    const tableChangeHandler = (data) => {
        let queryStr = Object.keys(data)
            .map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
            })
            .join('&');
        setQueryString(queryStr);
    };

    const handleFilter = (e) => {
        let value = e.target.value;
        tableChangeHandler({ ...table_data, search: value });
        setFilterValue(value);
    };

    const emailIndex = () => {
        setActiveIndex(activeIndex + 1);
        setShowEmail(!showEmail);
    };

    const storeKeyIndex = () => {
        setActiveIndex(activeIndex + 1);
        setShowKey(!showKey);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setStoreDialog(false);
        setShowEmail(false);
        setShowKey(false);
        setActiveIndex(0);
    };
    const handleOnClose = () => {
        setVisible(false);
        setConnectKeyType('select');
        setShowEmail(false);
    };
    const nextproductStoreRoute = (storeName, storeId) => {
        navigate('/products', { state: { storeName: storeName, storeId: storeId } });
    };

    const storeTypeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="customer-badge status-qualified">{verifiedStoreData?.store_type == 'source' ? 'Destination' : 'Source'}</span>
            </>
        );
    };

    const storeUrlBodyTemplate = (rowData) => {
        return (
            <>
                <a href={rowData?.store_url} target="_blank">
                    {rowData?.store_url}
                </a>
            </>
        );
    };
    const storeDestinationUrlBodyTemplate = (rowData) => {
        return (
            <>
                <a href={rowData?.destination_store_url} target="_blank">
                    {rowData?.destination_store_url}
                </a>
            </>
        );
    };
    const handleClick = (rowData, status) => {
        const payload = {
            request_key: status,
            source_store_id: storeData?.store_id,
            destination_store_id: rowData.store_id,
            source_store_id: verifiedStoreData.store_id
        };
        dispatch(storeRequest(payload));
    };
    const getButton = (rowData) => {
        if (rowData.is_connected === 'pending') {
            return <Button label="Request Send" icon="pi pi-check" className="p-button p-button-success cursor-auto" />;
        } else if (rowData.is_connected === 'declined') {
            return (
                <Button
                    label="Connect again"
                    className="p-button p-button-info"
                    icon="pi pi-spinner"
                    onClick={() => {
                        setVisible(true);
                        setTabelStoreName(rowData);
                    }}
                />
            );
        } else {
            return (
                <>
                    {/* <Button label="Approve" icon="pi pi-verified" className=" p-button p-button-success  mr-2" onClick={() => handleClick(rowData, 'approve')} /> */}
                    <div className="actions">
                        {verifiedStoreData.store_type == 'destination' ? (
                            <Button
                                icon="pi pi-eye"
                                label="View products"
                                tooltip="View products"
                                tooltipOptions={{ position: 'top' }}
                                onClick={() => nextproductStoreRoute(rowData?.store_name, rowData?.store_id)}
                                className="p-button-rounded mr-3"
                                style={{ background: '#3f51b5' }}
                            />
                        ) : null}
                        <Button
                            icon="pi pi-link"
                            label="Disconnect Store"
                            tooltip="Disconnect Store"
                            tooltipOptions={{ position: 'top' }}
                            className="p-button-rounded p-button-danger mt-2"
                            onClick={() => {
                                setDisconnectOpencModal(true);
                                setDestStoreId(rowData?.store_id);
                            }}
                        />
                    </div>
                </>
            );
        }
    };
    const actionBodyTemplate = (rowData) => {
        return getButton(rowData);
        // return rowData.is_connected === 'pending' ? (
        //     <Button
        //         label="Connect again"
        //         className="p-button p-button-info"
        //         onClick={() => {
        //             setVisible(true);
        //             setTabelStoreName(rowData);
        //         }}
        //     />
        // ) : (
        //     <div className="actions">
        //         <Button
        //             icon="pi pi-sitemap"
        //             tooltip="View products"
        //             tooltipOptions={{ position: 'top' }}
        //             onClick={() => nextproductStoreRoute(rowData?.store_name, rowData?.source_id)}
        //             className="p-button-rounded mr-3"
        //             style={{ background: '#3f51b5' }}
        //         />
        //         {rowData?.store_type == 'destination' ? (
        //             <Button
        //                 icon="pi pi-link"
        //                 tooltip="Disconnect"
        //                 tooltipOptions={{ position: 'top' }}
        //                 className="p-button-rounded p-button-danger mt-2"
        //                 onClick={() => {
        //                     setDisconnectOpencModal(true);
        //                     setDestStoreId(rowData?.store_id);
        //                 }}
        //             />
        //         ) : null}
        //     </div>
        // );
    };
    const handleRequestConnectToSourceStore = () => {
        setShowEmail(true);
        // setshowDestinationNew(!showDestinationNew);
        // setStoreKey(storeData?.store_key);
        // setConnectKeyType('request_access');
        setConnectKeyType('request_access');
    };
    const handleKeepStore = () => {
        const keepStoreObj = {
            store_status: 'keep',
            destination_store_id: verifiedStoreData.store_id,
            source_store_id: destStoreId
        };
        dispatch(disconnectStoreRequest(keepStoreObj, getQueryParam()));
        setDisconnectOpencModal(false);
    };

    const handleDeleteStore = () => {
        const deleteStoreObj = {
            store_status: 'delete',
            destination_store_id: verifiedStoreData.store_id,
            source_store_id: destStoreId
        };
        dispatch(disconnectStoreRequest(deleteStoreObj, getQueryParam()));
        setDisconnectOpencModal(false);
    };

    const template = {
        layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    const breadcrumbItems = [{ label: 'Stone Share' }, { label: 'Stores' }];
    useEffect(() => {
        dispatch(stoneSearchStoreSourceRequest(getQueryParam()));
        setVisible(false);
        return () => {
            dispatch(storeResetData());
        };
    }, [dispatch, queryString]);

    useEffect(() => {
        if (storeRequestSuccessMessage != null || storeConnectKey != null) {
            dispatch(stoneSearchStoreSourceRequest(getQueryParam()));
            setVisible(false);
            dispatch(storeRequestSuccess(null));
        }
    }, [storeRequestSuccessMessage, storeConnectKey]);
    return (
        <>
            <div className="col px-0">
                <div className="breadcrumb-container flex justify-content-between align-items-center">
                    <div className="text-2xl font-bold">
                        <h3 className="m-0">Stores</h3>
                    </div>
                    <div className="ml-6 text-2xl">
                        <BreadCrumb home={{ icon: 'pi pi-home', url: '/' }} model={breadcrumbItems} />
                    </div>
                </div>
            </div>
            <h5 className="font-bold mt-0 text-lg text-color-secondary">You can disconnect the Destination store from the Source store and Vice versa. In this, you can also disconnect and keep and disconnect and delete products.</h5>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <h5 className="font-bold mt-0">Manage your stores</h5>
                        <DataTable
                            scrollHeight="350px"
                            size="small"
                            value={stoneSearchStoreSourceData?.results}
                            selection={selectedProducts}
                            onSelectionChange={(e) => setSelectedProducts(e.value)}
                            dataKey="id"
                            className="datatable-responsive datatable_container"
                            emptyMessage="No record found."
                            loading={isLoading}
                            responsiveLayout="scroll"
                        >
                            <Column field="store_name" className="font-semibold" header="Store Name" headerStyle={{ width: '20%' }}></Column>
                            <Column field="store_type" className="font-semibold" header="Store Type" body={storeTypeBodyTemplate} headerStyle={{ width: '15%' }}></Column>
                            <Column header="Actions" className="font-semibold" body={actionBodyTemplate}></Column>
                        </DataTable>
                        <Paginator template={template} first={first} rows={rows} totalRecords={sourceStoreList?.count} onPageChange={onPageChange} rowsPerPageOptions={[25, 50, 100, 500]}></Paginator>
                    </div>
                </div>
                <Dialog className="unsync-modal-content cross-content" header="Select a disconnect option:" visible={disconnectOpencModal} style={{ width: '50rem' }} onHide={() => setDisconnectOpencModal(false)}>
                    <div className="grid">
                        <div className="col-6">
                            <div className="mt-3 card shadow-4 cursor-pointer border-round-xl">
                                <div className="text-center">
                                    <i className="pi pi-link text-5xl" style={{ color: '#607d8b' }}></i>
                                    <h4 className="font-bold m-3">Disconnect and Keep</h4>
                                    <h4 className="font-semibold text-sm mt-4 mb-3">Unsync without deleting the product from your destination store.</h4>
                                    <Button label="Disconnect and Keep" className="p-button-secondary font-bold shadow-3" onClick={handleKeepStore} />
                                </div>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="mt-3 card shadow-4 cursor-pointer border-round-xl">
                                <div className="text-center">
                                    <i className="pi pi-trash text-5xl" style={{ color: 'red' }}></i>
                                    <h4 className="font-bold m-3">Disconnect and Delete</h4>
                                    <h4 className="font-semibold text-sm mt-4 mb-3">Unsync and delete the product from your destination store.</h4>
                                    <Button label="Disconnect and Delete" className="p-button-danger shadow-3 font-bold" onClick={handleDeleteStore} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Dialog header="Connect Source Store" draggable={false} visible={visible} style={{ width: '56rem' }} onHide={() => handleOnClose()}>
                    {connectKeyType == 'choose' ? (
                        <>
                            <p className="text-sm">Share your unique key with source stores to start importing their products to sell on your store. This unique key can also be found on your dashboard after completing this setup process.</p>
                            <div className="grid">
                                <div className="col-6">
                                    <Button
                                        icon="pi pi-arrow-right"
                                        iconPos="right"
                                        label="Request access to connect with source store"
                                        className="p-button-raised mr-2 mb-2 w-12"
                                        onClick={() => {
                                            setConnectKeyType('request_access');
                                        }}
                                    />
                                </div>
                                <div className="col-6">
                                    <Button icon="pi pi-arrow-right" iconPos="right" label="I received a source store key" onClick={() => setConnectKeyType('key')} className="p-button-outlined mr-2 mb-2 w-12" />
                                </div>
                            </div>
                        </>
                    ) : null}
                    {connectKeyType == 'request_access' ? (
                        <>
                            <div>
                                <h6 className="mx-auto text-xl">{`Send request access to a source store.`}</h6>
                            </div>
                            <div className="flex  flex-column gap-3  align-items-center">
                                <div className="flex flex-column gap-3"></div>
                                <label htmlFor="store_id" className="text-xl">
                                    Select source store
                                </label>
                                <div className="w-6">
                                    <InputText value={tabelStoreName.store_name} id="store_key" className="w-12" name="store_key" disabled />
                                </div>
                                <div className="">
                                    <Button
                                        label="Send Request"
                                        className="p-button-raised"
                                        onClick={() => {
                                            handleSendRequest();
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    ) : null}
                    {connectKeyType == 'select' ? (
                        <div className="mx-auto col-12 ">
                            <div>
                                <p className="text-xl">Please enter the unique key of the source store to begin importing their products or send an access request to the source store to establish a connection.</p>
                                <div className="grid">
                                    <div className="col-6">
                                        <Button icon="pi pi-arrow-right" iconPos="right" label="Request access to connect with source store" className="p-button-raised mr-2 mb-2 w-12" onClick={() => handleRequestConnectToSourceStore()} />
                                    </div>
                                    <div className="col-6">
                                        <Button icon="pi pi-arrow-right" iconPos="right" label="I received a source store key" onClick={() => setConnectKeyType('key')} className="p-button-outlined mr-2 mb-2 w-12" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {connectKeyType == 'key' ? (
                        <div className="mx-auto col-12 ">
                            <h6>Connect to a source store using a unique key.</h6>
                            <h6>Once connected, you will be able to start importing products from this source store. </h6>
                            <form onSubmit={storeConnectformik.handleSubmit} className="p-fluid">
                                <div className="field">
                                    <label htmlFor="store_key">Source Store Unique Key</label>
                                    <span className="p-float-label p-input-icon-right mb-2">
                                        <InputText id="store_key" className="w-12" name="store_key" onChange={storeConnectformik.handleChange} value={storeConnectformik.values.store_key} />
                                        <Button type="submit" label="Connect" icon="pi pi-plus" iconPos="right" className="p-button-raised mr-2 ml-4 w-2" />
                                    </span>
                                    {storeConnectformik.touched.store_key && storeConnectformik.errors.store_key ? <small className="p-error text-lg">{storeConnectformik.errors.store_key}</small> : null}
                                </div>
                            </form>
                        </div>
                    ) : null}
                </Dialog>
                ;
            </div>
        </>
    );
};

export default StoreDatatable;
