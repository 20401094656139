import axios from 'axios';

export const allUserApi = async (queryString, config) => {
    return await axios.get(`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/staff/list/?` + queryString, config);
};

export const viewUserApi = async (userId, config) => {
	return await axios.get(`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/staffview/${userId}`, config);
};

export const addUserApi = async (reqData, config) => {
    return await axios.post(`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/staff/create/`, reqData, config);
};

export const removeUserApi = async (userId, config) => {
    return await axios.delete(`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/staff/modify/${userId}`, config);
};

export const modifyUserApi = async (userId, modifyData, config) => {
    return await axios.put(`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/api/staff/modify/${userId}`, modifyData, config);
};
