import { createSlice } from '@reduxjs/toolkit';
import { addStoneSettingApi, allStoneSettingApi } from '../../service/stoneSettingService';
import { toast } from 'react-hot-toast';
import { handleUnAuthorizeUserRequest } from './authSlice';

export const stoneSyncSettingSlice = createSlice({
    name: 'stoneSyncSettings',
    initialState: {
        isLoading: false,
        allStoneSettingData: null,
        addStoneSettingData: null,
        error: null
    },
    reducers: {
        setLoading: (state) => {
            state.isLoading = true;
        },
        allStoneSettingDataList: (state, action) => {
            state.isLoading = false;
            state.allStoneSettingData = action.payload?.data;
        },
        addStoneSettingDataList: (state, action) => {
            state.isLoading = false;
            state.addStoneSettingData = action.payload;
        },
        stoneSettingResetData: (state) => {
            state.isLoading = false;
            state.error = null;
            state.addStoneSettingData = null;
        },
        stoneSettingError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { setLoading, allStoneSettingDataList, addStoneSettingDataList, stoneSettingResetData, stoneSettingError } = stoneSyncSettingSlice.actions;
export default stoneSyncSettingSlice.reducer;

export const allStoneSettingRequest = (destStoreId) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await allStoneSettingApi(destStoreId, config);
        dispatch(allStoneSettingDataList(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error.response && error.response.data) {
            return dispatch(stoneSettingError(error.response.data));
        }
    }
};

export const addStoneSettingDataRequest = (addstoneData, destination_store_id) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await addStoneSettingApi(addstoneData, config);

        const { statusCode, message } = data;

        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(addStoneSettingDataList(message));
            dispatch(allStoneSettingRequest(destination_store_id));
        }
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error.response.data) {
            toast.error(error.response.data.message, {
                id: toastId
            });
            return dispatch(stoneSettingError(error.response.data.message));
        }
    }
};
