import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { Paginator } from 'primereact/paginator';
import { gemFinderResetData, getAllProductListForCsvRequest, productAllListRequest, storeProductDropdownRequest, uploadCSV } from '../../redux/slices/gemFinderSlice';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Toolbar } from 'primereact/toolbar';
import { Tag } from 'primereact/tag';
import { Dialog } from 'primereact/dialog';
import { FilterMatchMode } from 'primereact/api';
import { productListForCsvApi } from '../../service/gemFinderService';

const Product = () => {
    const dispatch = useDispatch();

    const { productLogData, productLogCsvData, csvData, isLoading } = useSelector((state) => state.gemFinder);
    const { Token } = useSelector((store) => store.auth);
    const [ selectedProducts, setSelectedProducts ] = useState(null);
    const [ sort_order, setSort_order ] = useState('desc');
    const [ visibleLeft, setVisibleLeft ] = useState(false);
    const [ displayBasic, setDisplayBasic ] = useState(false);
    const [ submitted, setSubmitted ] = useState(false);
    const [ storeList, setStoreList ] = useState(false);
    const [ file, setFile ] = useState();
    const [ productView, setProductView ] = useState([]);
    const [ filters, setFilters ] = useState(null);
    const [ exportCsvLoader, setExportCsvLoader ] = useState(false);
    const [ searchFilter, setSearchFilter ] = useState({
        stock: '',
        title: '',
        color: '',
        shape: '',
        carat: ''
    });
    const [ selectFileName, setSelectFileName ] = useState('No file choosen');
    const [ first, setFirst ] = useState(0);
    const [ rows, setRows ] = useState(100);
    const toast = useRef(null);
    const inputRef = useRef(null);

    const table_data = {
        page: 1,
        per_page: 100,
        sort_order: sort_order
    };

    useEffect(() => {
        dispatch(productAllListRequest(`page=${table_data.page}&per_page=${table_data.per_page}`));
        dispatch(getAllProductListForCsvRequest())
        initFilters();
        if (csvData !== null) {
            dispatch(productAllListRequest(`page=${table_data.page}&per_page=${table_data.per_page}`));
        }

        return () => {
            dispatch(gemFinderResetData());
        };
    }, [ csvData ]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);

        dispatch(productAllListRequest(`page=${event.page + 1}&per_page=${event.rows}`));
    };

    const initFilters = () => {
        setFilters({
            stock: { value: null, matchMode: FilterMatchMode.CONTAINS },
            title: { value: null, matchMode: FilterMatchMode.CONTAINS },
            color: { value: null, matchMode: FilterMatchMode.CONTAINS },
            shape: { value: null, matchMode: FilterMatchMode.CONTAINS },
            carat: { value: null, matchMode: FilterMatchMode.CONTAINS }
        });
    };

    const filterApplyCallback = (options) => {
        dispatch(
            productAllListRequest(
                `page=${table_data.page}&search_stock=${searchFilter?.stock}&search_title=${searchFilter?.title}&search_color=${searchFilter?.color}&search_shape=${searchFilter?.shape}&search_carat=${searchFilter?.carat}&per_page=${table_data.per_page}`
            )
        );
        options.filterApplyCallback();
    };

    const filterStockClearCallback = (options) => {
        setSearchFilter({
            ...searchFilter,
            stock: ''
        });
        dispatch(
            productAllListRequest(
                `page=${table_data.page}&search_stock=${''}&search_title=${searchFilter?.title}&search_color=${searchFilter?.color}&search_shape=${searchFilter?.shape}&search_carat=${searchFilter?.carat}&per_page=${table_data.per_page}`
            )
        );
        options.filterClearCallback();
    };
    const filterTitleClearCallback = (options) => {
        setSearchFilter({
            ...searchFilter,
            title: ''
        });
        dispatch(
            productAllListRequest(
                `page=${table_data.page}&search_stock=${searchFilter?.stock}&search_title=${''}&search_color=${searchFilter?.color}&search_shape=${searchFilter?.shape}&search_carat=${searchFilter?.carat}&per_page=${table_data.per_page}`
            )
        );
        options.filterClearCallback();
    };
    const filterColorClearCallback = (options) => {
        setSearchFilter({
            ...searchFilter,
            color: ''
        });
        dispatch(
            productAllListRequest(
                `page=${table_data.page}&search_stock=${searchFilter?.stock}&search_title=${searchFilter?.title}&search_color=${''}&search_shape=${searchFilter?.shape}&search_carat=${searchFilter?.carat}&per_page=${table_data.per_page}`
            )
        );
        options.filterClearCallback();
    };
    const filterShapeClearCallback = (options) => {
        setSearchFilter({
            ...searchFilter,
            shape: ''
        });
        dispatch(
            productAllListRequest(
                `page=${table_data.page}&search_stock=${searchFilter?.stock}&search_title=${searchFilter?.title}&search_color=${searchFilter?.color}&search_shape=${''}&search_carat=${searchFilter?.carat}&per_page=${table_data.per_page}`
            )
        );
        options.filterClearCallback();
    };

    const filterCaratClearCallback = (options) => {
        setSearchFilter({
            ...searchFilter,
            carat: ''
        });
        dispatch(
            productAllListRequest(
                `page=${table_data.page}&search_stock=${searchFilter?.stock}&search_title=${searchFilter?.title}&search_color=${searchFilter?.color}&search_shape=${searchFilter?.shape}&search_carat=${''}&per_page=${table_data.per_page}`
            )
        );
        options.filterClearCallback();
    };

    const filterStockClearTemplate = (options) => {
        return <Button type="button" className="p-button-outlined p-button-danger" onClick={() => filterStockClearCallback(options)} label="Clear" />;
    };

    const filterTitleClearTemplate = (options) => {
        return <Button type="button" className="p-button-outlined p-button-danger" onClick={() => filterTitleClearCallback(options)} label="Clear" />;
    };

    const filterColorClearTemplate = (options) => {
        return <Button type="button" className="p-button-outlined p-button-danger" onClick={() => filterColorClearCallback(options)} label="Clear" />;
    };

    const filterShapeClearTemplate = (options) => {
        return <Button type="button" className="p-button-outlined p-button-danger" onClick={() => filterShapeClearCallback(options)} label="Clear" />;
    };

    const filterCaratClearTemplate = (options) => {
        return <Button type="button" className="p-button-outlined p-button-danger" onClick={() => filterCaratClearCallback(options)} label="Clear" />;
    };

    const filterApplyTemplate = (options) => {
        return <Button type="button" className="p-button-outlined" icon="pi pi-check" onClick={() => filterApplyCallback(options)} severity="success" label="Apply" />;
    };

    const handlestockFilter = (e) => {
        const value = e.target.value;
        setSearchFilter({ ...searchFilter, stock: value });
    };

    const handletitleFilter = (e) => {
        const value = e.target.value;
        setSearchFilter({ ...searchFilter, title: value });
    };

    const handlecolorFilter = (e) => {
        const value = e.target.value;
        setSearchFilter({ ...searchFilter, color: value });
    };

    const handleshapeFilter = (e) => {
        const value = e.target.value;
        setSearchFilter({ ...searchFilter, shape: value });
    };
    const handlecaratFilter = (e) => {
        const value = e.target.value;
        setSearchFilter({ ...searchFilter, carat: value });
    };

    const stockFilterTemplate = () => {
        return <InputText type="search" className="w-15rem" value={searchFilter?.stock} onChange={(e) => handlestockFilter(e)} placeholder="Search SKU" />;
    };

    const titleFilterTemplate = () => {
        return <InputText type="search" className="w-15rem" value={searchFilter?.title} onChange={(e) => handletitleFilter(e)} placeholder="Search Title" />;
    };

    const colorFilterTemplate = () => {
        return <InputText type="search" className="w-15rem" value={searchFilter?.color} onChange={(e) => handlecolorFilter(e)} placeholder="Search Color" />;
    };

    const shapeFilterTemplate = () => {
        return <InputText type="search" className="w-15rem" value={searchFilter?.shape} onChange={(e) => handleshapeFilter(e)} placeholder="Search Shape" />;
    };

    const caratFilterTemplate = () => {
        return <InputText type="search" className="w-15rem" value={searchFilter?.carat} onChange={(e) => handlecaratFilter(e)} placeholder="Search Carat" />;
    };

    const openSideBar = (rowData) => {
        setVisibleLeft(true);
        setProductView(rowData);
    };

    const priceBodyTemplate = (rowData) => {
        return <>{rowData?.memototal_cost?.toLocaleString('en-US')}</>;
    };
    const retailPriceBodyTemplate = (rowData) => {
        return <>{rowData?.retail_price?.toLocaleString('en-US')}</>;
    };
    const wholesalePriceBodyTemplate = (rowData) => {
        return <>{rowData?.total_price?.toLocaleString('en-US')}</>;
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className={`product-badge ${rowData?.status == 'ACTIVE' ? 'status-instock' : 'status - outofstock'}`}>{rowData?.status}</span>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button className="p-button-text" style={{ width: '20px', height: '20px' }} tooltip="View products" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => openSideBar(rowData)}></Button>
            </div>
        );
    };

    const template = {
        layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([ buffer ], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
        toast.current.show({ severity: 'success', summary: 'Success', detail: "Successfully export excel file", life: 5000 });
        setExportCsvLoader(false);
    };
    const exportExcel = (excelData) => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(excelData);
            const workbook = { Sheets: { data: worksheet }, SheetNames: [ 'data' ] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'products');
        });
    };
    const getCsvFile = async () => {
        setExportCsvLoader(true);
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: Token
                }
            };
            const { data } = await productListForCsvApi(config);
            console.log(data);
            const filteredCsvData = data.map((ele) => {
                let csvDataObj = {
                    "Stock": ele.stock,
                    "Shape": ele.shape,
                    "Cut": ele.cut_name,
                    "Color": ele.color,
                    "Clarity": ele.clarity,
                    "Size Group": ele.size_group,
                    "Carat": ele.carat,
                    "Lab": ele.lab,
                    "Certification": ele.certification,
                    "Cut": ele.cut,
                    "Polish": ele.polish,
                    "Symmetry": ele.symmetry,
                    "Rap Price": ele.rap_price,
                    "Total Price": ele.total_price,
                    "Fluorescence": ele.fluorescence,
                    "location": ele.location,
                    "Measurement": ele.measurement,
                    "Length": ele.lengt,
                    "Width": ele.width,
                    "Depth": ele.depth,
                    "Ratio": ele.ratio,
                    "Depth %": ele.depth_perentage,
                    "Table %": ele.table_percenatge,
                    "Video Url": ele.video_url,
                    "Image Url": ele.image_url,
                    "Cert-Image Url": ele.cert_image_url,
                    "Lab Grown Type": ele.lab_grown_type,
                    "Girdle": ele.girdle,
                    "Retail Price": ele.retail_price,
                    "Title": ele.title,
                }
                return csvDataObj
            }

            )
            exportExcel(filteredCsvData)
        } catch (error) {
            console.log(error);
        }

    }
    const header = (
        <div className='flex gap-2 justify-content-end'>
            <div className='flex gap-2'>
                <Button label="Upload Product CSV" className="text-sm" icon="pi pi-upload" onClick={() => setDisplayBasic(true)} />
                <Button label="Export Product Excel" loading={exportCsvLoader} className="text-sm" icon="pi pi-download" onClick={() => getCsvFile()} />
            </div>
        </div>
    );

    const handleSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append('file', file);
        dispatch(uploadCSV(formData));
        setSubmitted(false);
        inputRef.current.value = null;
        setDisplayBasic(false);
        dispatch(gemFinderResetData());
        setSelectFileName('No file choosen');
        setStoreList(true);
    };

    const handleOnChange = (e) => {
        setSelectFileName(e.target.files[ 0 ]?.name);
        var fileType = e.target.files[ 0 ]?.type;
        if ((fileType == 'application/vnd.ms-excel') || (fileType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")||(fileType == "text/csv")) {
            const selectFile = e.target.files[ 0 ];
            setFile(selectFile);
            setSubmitted(true);
        } else
            // if (fileType == "text/csv") {
            //     toast.current.show({ severity: 'error', summary: 'Error', detail: "Please Provide Excel File", life: 5000 });
            //     setFile("");
            // } else
             {
                toast.current.show({ severity: 'error', summary: 'Error', detail: "Please provide Excel File", life: 5000 });
                setFile("");
            }
    };

    const breadcrumbItems = [ { label: 'Stone Search' }, { label: 'Stone Search Products' } ];
    const dynamicHeight = Math.min(window.innerHeight * 4 + 1, 70) + 'vh';
    const hideExcelModal = () => {
        setDisplayBasic(false);
        setSelectFileName('No file choosen');
    }
    return (
        <>
            <div className="col px-0">
                <div className="breadcrumb-container flex justify-content-between align-items-center">
                    <div className="text-2xl font-bold">
                        <h3 className="m-0">Stone Search Products</h3>
                    </div>
                    <div className="ml-6 text-2xl">
                        <BreadCrumb home={{ icon: 'pi pi-home', url: '/' }} model={breadcrumbItems} />
                    </div>
                </div>
            </div>
            <div className="grid table-demo">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Dialog header={`Upload Product Excel/CSV to store`} visible={displayBasic} style={{ width: '40vw' }} modal onHide={() => hideExcelModal()}>
                            <div>
                                <div className="border-1 border-400 surface-300 p-3">
                                    <p className="text-xl font-bold mb-1">NOTE :</p>
                                    <p className="text-lg font-semibold">Upon uploading, products with a status of "ONHAND" will be stored in the database. Furthermore, any items added to the cart will be created on Shopify.</p>
                                </div>

                                <div className="flex align-items-center gap-3 mt-3">
                                    <span className="flex align-items-center gap-2">
                                        <i className="pi pi-download"></i>
                                        <span className="text-lg font-bold">Download Sample :</span>
                                    </span>
                                    <a href={`${process.env.REACT_APP_GEMFINDER_URL_API}/media/sample/sample_stonesearch.csv`} className="customer-badge status-proposal">
                                        sample.xlsx
                                    </a>
                                </div>
                                <form className="mt-4">
                                    <input ref={inputRef} type={'file'} id={'csvFileInput'} accept={'.xlsx,xls,.csv'} onChange={handleOnChange} hidden />
                                    <div className="flex align-items-center">
                                        <div className="cursor-pointer w-9rem border-round" style={{ backgroundColor: '#607d8b', color: 'white', padding: '0.5rem' }}>
                                            <label htmlFor="csvFileInput" className="flex justify-content-center align-items-center">
                                                <i className="pi pi-upload mr-3"></i>
                                                <span className="text-lg font-bold cursor-pointer">Select file</span>
                                            </label>
                                        </div>
                                        <span id="file-chosen" className="ml-3 text-lg font-bold">
                                            {selectFileName}
                                        </span>
                                    </div>

                                    <div className="grid p-fluid mt-3">
                                        <div className="ml-3">{submitted == true ? <Button icon="pi pi-check" tooltip="Click to proceed" label="Submit" onClick={handleSubmit} /> : <Button icon="pi pi-check" label="Submit" disabled />}</div>
                                    </div>
                                </form>
                            </div>
                        </Dialog>

                        <DataTable
                            responsiveLayout="scroll"
                            scrollable
                            // showGridlines
                            stripedRows
                            scrollHeight={dynamicHeight}
                            size="small"
                            value={productLogData?.results}
                            selection={selectedProducts}
                            onSelectionChange={(e) => setSelectedProducts(e.value)}
                            dataKey="id"
                            className="datatable-responsive stoneSerach-content"
                            emptyMessage="No records found."
                            header={header}
                            loading={isLoading}
                            filters={filters}
                        >
                            <Column
                                field="stock"
                                header="Stock"
                                style={{ minWidth: '130px', display: 'block' }}
                                showFilterMatchModes={false}
                                filter
                                filterElement={stockFilterTemplate}
                                filterClear={filterStockClearTemplate}
                                filterApply={filterApplyTemplate}
                                frozen
                            ></Column>
                            <Column
                                field="title"
                                header="Title"
                                style={{ minWidth: '250px', display: 'block' }}
                                showFilterMatchModes={false}
                                filter
                                filterElement={titleFilterTemplate}
                                filterClear={filterTitleClearTemplate}
                                filterApply={filterApplyTemplate}
                            ></Column>

                            <Column
                                field="color"
                                header="Color"
                                style={{ minWidth: '80px', display: 'block' }}
                                showFilterMatchModes={false}
                                filter
                                filterElement={colorFilterTemplate}
                                filterClear={filterColorClearTemplate}
                                filterApply={filterApplyTemplate}
                            ></Column>
                            <Column
                                field="shape"
                                header="Shape"
                                sortable
                                style={{ minWidth: '100px', display: 'block' }}
                                showFilterMatchModes={false}
                                filter
                                filterElement={shapeFilterTemplate}
                                filterClear={filterShapeClearTemplate}
                                filterApply={filterApplyTemplate}
                            ></Column>
                            <Column
                                field="carat"
                                header="Carat"
                                style={{ minWidth: '150px', display: 'block' }}
                                showFilterMatchModes={false}
                                filter
                                filterElement={caratFilterTemplate}
                                filterClear={filterCaratClearTemplate}
                                filterApply={filterApplyTemplate}
                            ></Column>
                            <Column field="memototal_cost" header="Price" sortable style={{ minWidth: '150px' }} body={priceBodyTemplate}></Column>
                            <Column field="total_price" header="Wholesale Price" sortable style={{ minWidth: '150px' }} body={wholesalePriceBodyTemplate}></Column>
                            <Column field="retail_price" header="Retail Price" sortable style={{ minWidth: '150px' }} body={retailPriceBodyTemplate}></Column>
                            <Column field="clarity" header="Clarity" style={{ minWidth: '150px' }}></Column>
                            <Column field="status" header="Status" style={{ minWidth: '150px' }} body={statusBodyTemplate}></Column>
                            <Column field="actions" header="Actions" style={{ minWidth: '150px' }} body={actionBodyTemplate}></Column>
                        </DataTable>
                        <Paginator template={template} first={first} rows={rows} totalRecords={productLogData?.count} onPageChange={onPageChange} rowsPerPageOptions={[ 100, 200, 300 ]}></Paginator>
                    </div>
                    <Sidebar visible={visibleLeft} style={{ width: '700px' }} onHide={() => setVisibleLeft(false)} baseZIndex={1000} position="right">
                        <div className="container">
                            <div className="grid">
                                <div className="col-6">
                                    <img src={productView?.image_url} className="w-22rem h-18rem" alt="diamond" />
                                </div>
                                <div className="col-6">
                                    <h4 className="font-bold" style={{ color: '#673ab7' }}>
                                        {productView?.title}
                                    </h4>
                                    <p className="line-height-1 font-bold">Product ID: {productView?.shopify_product_id == null ? '-' : productView?.shopify_product_id}</p>
                                    <p className="line-height-1 font-bold">Product Status: {productView?.status} </p>

                                    <p className="font-bold">PRODUCT DESCRIPTION</p>
                                    <ul className="px-3 font-bold">
                                        <li>Stock: {productView?.stock}</li>
                                        <li>Carat: {productView?.carat}</li>
                                        <li>Cut: {productView?.cut_grade == '' ? '-' : productView?.cut_grade}</li>
                                        <li>Shape: {productView?.shape}</li>
                                        <li>Color: {productView?.color}</li>
                                        <li>Ratio: {productView?.ratio}</li>
                                        <li>Clarity: {productView?.clarity}</li>
                                        <li>Measurement :{productView?.measurement}</li>
                                        <li>Depth Perentage :{productView?.depth_perentage}</li>
                                        <li>Table Percenatge: {productView?.table_percenatge}</li>
                                        <li>Certification: {productView?.certification}</li>
                                        <li>Polish: {productView?.polish}</li>
                                        <li>Symmetry: {productView?.symmetry}</li>
                                        <li>Rap Price": {productView?.rap_price}</li>
                                        <li>Location: {productView?.location}</li>
                                        <li>Fluorescence: {productView?.fluorescence}</li>
                                        <li>Off: {productView?.off}</li>
                                        <li>Total Price: {productView?.total_price}</li>
                                        <li>Price Ct: {productView?.price_ct}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Sidebar>
                </div>
            </div>
        </>
    );
};

export default Product;
