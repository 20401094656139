import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppConfig from './AppConfig';
import AppSearch from './AppSearch';
import AppRightMenu from './AppRightMenu';
import AppBreadcrumb from './AppBreadcrumb';
import Dashboard from './components/Dashboard';
// import FormLayoutDemo from './components/FormLayoutDemo';
// import InputDemo from './components/InputDemo';
// import FloatLabelDemo from './components/FloatLabelDemo';
// import ButtonDemo from './components/ButtonDemo';
// import TableDemo from './components/TableDemo';
// import ListDemo from './components/ListDemo';
// import TreeDemo from './components/TreeDemo';
// import PanelDemo from './components/PanelDemo';
// import OverlayDemo from './components/OverlayDemo';
// import MediaDemo from './components/MediaDemo';
// import MenuDemo from './components/MenuDemo';
// import MessagesDemo from './components/MessagesDemo';
// import FileDemo from './components/FileDemo';
// import ChartDemo from './components/ChartDemo';
// import MiscDemo from './components/MiscDemo';
// import Documentation from './components/Documentation';
// import BlocksDemo from './components/BlocksDemo';
// import IconsDemo from './utilities/IconsDemo';
// import CrudDemo from './pages/CrudDemo';
// import CalendarDemo from './pages/CalendarDemo';
// import Invoice from './pages/Invoice';
// import Help from './pages/Help';
// import EmptyPage from './pages/EmptyPage';
// import InvalidStateDemo from './components/InvalidStateDemo';
// import TimelineDemo from './pages/TimelineDemo';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
// import DashboardMain from './components/DashboardMain';
import ProductDestination from './components/stoneShare/ProductDestination';
import ProductSettings from './components/stoneShare/ProductSettings';
import ProductDestinationSettings from './components/stoneShare/ProductDestinationSettings';
import UploadCsv from './components/gemFinder/UploadCsv';
import Product from './components/gemFinder/Product';
import Shopify from './components/stoneShare/StoneSyncLog';
import ProductLog from './components/gemFinder/ProductLog';
import FtpFeedLog from './components/kushDiamond/kdlogs/FtpFeedLog';
import MediaLog from './components/kushDiamond/kdlogs/MediaLog';
import ShopifySyncLog from './components/kushDiamond/kdlogs/ShopifySyncLog';
import ExcelLog from './components/kushDiamond/kdlogs/ExcelLog';
import UserList from './components/kushDiamond/kdUser/UserList';
import ProductList from './components/kushDiamond/kdProducts/ProductList';
import StoneSyncLog from './components/stoneShare/StoneSyncLog';
import AdminRoute from './router/AdminRoute';
import ProductCompanyFilter from './components/gemFinder/ProductCompanyFilter';
import StoneSearchStore from './components/gemFinder/StoneSearchStore';
import StoreDatatable from './components/stoneShare/StoreDatatable';
import DestinationDataTable from './components/stoneShare/DestinationDataTable';
import ProductStore from './components/stoneShare/ProductStore';
import StoneShareStoreSetting from './components/gemFinder/StoneShareStoreSetting';
import ProductDetails from './components/kushDiamond/kdProducts/ProductDetails';
import ProductExcel from './components/kushDiamond/kdProducts/ProductExcel';
import InventoryExcel from './components/kushDiamond/kdProducts/InventoryExcel';
import FtpClientList from './components/kushDiamond/ftp/FtpClientList';
import FtpFeedList from './components/kushDiamond/ftp/FtpFeedList';
import UploadMedia from './components/kushDiamond/kdProducts/UploadMedia';
import ProductStoreDatatable from './components/stoneShare/ProductStoreDatatable';
import OrderList from './components/b2b/orders/OrderList';
import OrderDetails from './components/b2b/orders/OrderDetails';
import DiscountTags from './components/b2b/orders/DiscountTags';
import SourceDashboard from './components/stoneShare/SourceDashboard';
import DestinationDashboard from './components/stoneShare/DestinationDashboard';
import { storeTypeAPI } from './service/storeService';
import { useSelector } from 'react-redux';
import GemFinderDiscountTags from './components/gemFinder/GemFinderDiscountTags';

const App = () => {
    const [menuActive, setMenuActive] = useState(false);
    const [menuMode, setMenuMode] = useState('horizontal');
    const [colorScheme, setColorScheme] = useState('light');
    const [menuTheme, setMenuTheme] = useState('layout-sidebar-darkgray');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
    const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] = useState(false);
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(false);
    const [logoColor, setLogoColor] = useState('white');
    const [componentTheme, setComponentTheme] = useState('blue');
    const [logoUrl, setLogoUrl] = useState('assets/layout/images/logo-dark.svg');
    const copyTooltipRef = useRef();
    const location = useLocation();
    const { verifiedStoreData, storeData } = useSelector((store) => store.store);
    let menuClick = false;
    let searchClick = false;
    let userMenuClick = false;
    let notificationMenuClick = false;
    let rightMenuClick = false;
    let configClick = false;

    const breadcrumb = [
        { path: '/dashboard', parent: 'Dashboard', label: 'Dashboard' },
        { path: '/store', parent: 'Syncio', label: 'Stores' },
        { path: '/products', parent: 'Syncio', label: 'Products' },
        { path: '/sync-store-config', parent: 'Syncio', label: 'Product Settings' },
        { path: '/upload-csv', parent: 'Diamond Search', label: 'Upload CSV' },
        { path: '/setting', parent: 'Diamond Search', label: 'Settings' },
        { path: '/gemfinder-product', parent: 'Diamond Search', label: 'Products' },
        { path: '/gemfinder-product-log', parent: 'Diamond Search', label: 'Products Log' },
        { path: '/gemfinder-shopify', parent: 'Diamond Search', label: 'Shopify Sync Log' }
        // { path: '/formlayout', parent: 'UI Kit', label: 'Form Layout' },
        // { path: '/input', parent: 'UI Kit', label: 'Input' },
        // { path: '/floatlabel', parent: 'UI Kit', label: 'Float Label' },
        // { path: '/invalidstate', parent: 'UI Kit', label: 'Invalid State' },
        // { path: '/button', parent: 'UI Kit', label: 'Button' },
        // { path: '/table', parent: 'UI Kit', label: 'Table' },
        // { path: '/list', parent: 'UI Kit', label: 'List' },
        // { path: '/tree', parent: 'UI Kit', label: 'Tree' },
        // { path: '/panel', parent: 'UI Kit', label: 'Panel' },
        // { path: '/overlay', parent: 'UI Kit', label: 'Overlay' },
        // { path: '/media', parent: 'UI Kit', label: 'Media' },
        // { path: '/menu', parent: 'UI Kit', label: 'Menu' },
        // { path: '/menu/seat', parent: 'UI Kit', label: 'Menu' },
        // { path: '/menu/payment', parent: 'UI Kit', label: 'Menu' },
        // { path: '/menu/confirmation', parent: 'UI Kit', label: 'Menu' },
        // { path: '/messages', parent: 'UI Kit', label: 'Messages' },
        // { path: '/file', parent: 'UI Kit', label: 'File' },
        // { path: '/chart', parent: 'UI Kit', label: 'Charts' },
        // { path: '/misc', parent: 'UI Kit', label: 'Misc' },
        // { path: '/icons', parent: 'Utilities', label: 'Icons' },
        // { path: '/blocks', parent: 'PrimeBlocks', label: 'Blocks' },
        // { path: '/crud', parent: 'Utilities', label: 'Crud' },
        // { path: '/calendar', parent: 'PrimeBlocks', label: 'Calendar' },
        // { path: '/timeline', parent: 'Pages', label: 'Timeline' },
        // { path: '/invoice', parent: 'Pages', label: 'Invoice' },
        // { path: '/help', parent: 'Pages', label: 'Help' },
        // { path: '/empty', parent: 'Pages', label: 'Empty Page' },
        // { path: '/documentation', parent: 'Pages', label: 'Documentation' }
    ];
    const getMenu = () => {
        const storeType = verifiedStoreData?.store_type;
        if (storeType === 'source') {
            return [
                {
                    label: 'Dashboard',
                    icon: 'pi pi-fw pi-home',
                    to: '/source-dashboard'
                },
                {
                    label: 'Stores',
                    icon: 'pi pi-fw pi-link',
                    to: '/store'
                }
            ];
        } else if (storeType === 'destination') {
            return [
                {
                    label: 'Dashboard',
                    icon: 'pi pi-fw pi-home',
                    to: '/destination-dashboard'
                },
                {
                    label: 'Stone Share',
                    icon: 'pi pi-fw pi-sync',
                    items: [
                        {
                            label: 'Stores',
                            icon: 'pi pi-fw pi-link',
                            to: '/store'
                        },
                        {
                            label: 'Products',
                            icon: 'pi pi-fw pi-box',
                            to: '/products'
                        },
                        {
                            label: 'Products Settings',
                            icon: 'pi pi-fw pi-cog',
                            to: '/sync-store-config'
                        },
                        {
                            label: 'Shopify Sync Log',
                            icon: 'pi pi-fw pi-sync',
                            to: '/sync-shopifySyncLog'
                        }
                    ]
                }
            ];
        } else {
            return [
                {
                    label: 'Dashboard',
                    icon: 'pi pi-fw pi-home',
                    to: '/dashboard'
                },
                {
                    label: 'Stone Search',
                    icon: 'far fa-gem',
                    items: [
                        // { label: 'Upload CSV', icon: 'pi pi-fw pi-upload', to: '/upload-csv' },
                        { label: 'Settings', icon: 'pi pi-fw pi-cog', to: '/ds-setting' },
                        {
                            label: 'Stores',
                            icon: 'pi pi-fw pi-link',
                            to: '/ds-store'
                        },
                        { label: 'Products', icon: 'pi pi-fw pi-box', to: '/ds-product' },
                        { label: 'Store Product Filters', icon: 'pi pi-fw pi-filter', to: '/ds-company-filter' },
                        { label: 'Products CSV Log', icon: 'pi pi-fw pi-file', to: '/ds-product-log' },
                        { label: 'Discount Coupon', icon: 'pi pi-fw pi-percentage', to: '/ds-discount-coupon' }
                        // { label: 'Shopify Sync', icon: 'pi pi-fw pi-sync', to: '/ds-shopify' }
                    ]
                },

                // { separator: true },

                // {
                //     label: 'FTP',
                //     icon: 'pi pi-fw pi-server',
                //     items: [
                //         { label: 'FTP List', icon: 'pi pi-fw pi-server', to: '/ftp-list' },
                //         { label: 'FTP Feed', icon: 'pi pi-fw pi-server', to: '/ftpFeed-list' },
                //         { label: 'FTP Feed Log', icon: 'pi pi-fw pi-file', to: '/ftpfeed-log' }
                //     ]
                // },
                // {
                //     label: 'Products',
                //     icon: 'pi pi-fw pi-box',
                //     items: [
                //         { label: 'All Products', icon: 'pi pi-fw pi-box', to: '/product-list' },
                //         { label: 'Product Excel', icon: 'pi pi-fw pi-file-excel', to: '/product/excel' },
                //         { label: 'Inventory Excel', icon: 'pi pi-fw pi-file-excel', to: '/product-inventory/excel' },
                //         { label: 'Upload Media', icon: 'pi pi-fw pi-image', to: '/upload-media' },
                //         { label: 'Excel Upload Log', icon: 'pi pi-fw pi-file-excel', to: '/excel-log' },
                //         { label: 'Media Log', icon: 'pi pi-fw pi-images', to: '/media-log' },
                //         { label: 'Shopify Sync Log', icon: 'pi pi-fw pi-sync', to: '/shopifySync-log' }
                //     ]
                // },
                // {
                //     label: 'User Staff',
                //     icon: 'pi pi-fw pi-users',
                //     items: [ { label: 'All User List', icon: 'pi pi-fw pi-user', to: '/user-list' } ]
                // },

                {
                    label: 'B2B',
                    icon: 'pi pi-fw pi-sitemap',
                    items: [
                        // { label: 'All Orders', icon: 'pi pi-fw pi-shopping-cart', to: '/b2b-orderList' },
                        { label: 'Discount Tags', icon: 'pi pi-fw pi-percentage', to: '/b2b-discountTags' }
                    ]
                }
            ];
        }
    };

    const menu = [
        {
            label: 'Dashboard',
            icon: 'pi pi-fw pi-home',
            to: '/dashboard'
        },
        {
            label: 'Stone Search',
            icon: 'far fa-gem',
            items: [
                // { label: 'Upload CSV', icon: 'pi pi-fw pi-upload', to: '/upload-csv' },
                { label: 'Settings', icon: 'pi pi-fw pi-cog', to: '/ds-setting' },
                {
                    label: 'Stores',
                    icon: 'pi pi-fw pi-link',
                    to: '/ds-store'
                },
                { label: 'Products', icon: 'pi pi-fw pi-box', to: '/ds-product' },
                { label: 'Store Product Filters', icon: 'pi pi-fw pi-filter', to: '/ds-company-filter' },
                { label: 'Products CSV Log', icon: 'pi pi-fw pi-file', to: '/ds-product-log' }
                // { label: 'Shopify Sync', icon: 'pi pi-fw pi-sync', to: '/ds-shopify' }
            ]
        },
        {
            label: 'Stone Share',
            icon: 'pi pi-fw pi-sync',
            items: [
                {
                    label: 'Stores',
                    icon: 'pi pi-fw pi-link',
                    to: '/store'
                },
                {
                    label: 'Products',
                    icon: 'pi pi-fw pi-box',
                    to: '/products'
                },
                {
                    label: 'Products Settings',
                    icon: 'pi pi-fw pi-cog',
                    to: '/sync-store-config'
                },
                {
                    label: 'Shopify Sync Log',
                    icon: 'pi pi-fw pi-sync',
                    to: '/sync-shopifySyncLog'
                }
            ]
        },

        // { separator: true },

        // {
        //     label: 'FTP',
        //     icon: 'pi pi-fw pi-server',
        //     items: [
        //         { label: 'FTP List', icon: 'pi pi-fw pi-server', to: '/ftp-list' },
        //         { label: 'FTP Feed', icon: 'pi pi-fw pi-server', to: '/ftpFeed-list' },
        //         { label: 'FTP Feed Log', icon: 'pi pi-fw pi-file', to: '/ftpfeed-log' }
        //     ]
        // },
        // {
        //     label: 'Products',
        //     icon: 'pi pi-fw pi-box',
        //     items: [
        //         { label: 'All Products', icon: 'pi pi-fw pi-box', to: '/product-list' },
        //         { label: 'Product Excel', icon: 'pi pi-fw pi-file-excel', to: '/product/excel' },
        //         { label: 'Inventory Excel', icon: 'pi pi-fw pi-file-excel', to: '/product-inventory/excel' },
        //         { label: 'Upload Media', icon: 'pi pi-fw pi-image', to: '/upload-media' },
        //         { label: 'Excel Upload Log', icon: 'pi pi-fw pi-file-excel', to: '/excel-log' },
        //         { label: 'Media Log', icon: 'pi pi-fw pi-images', to: '/media-log' },
        //         { label: 'Shopify Sync Log', icon: 'pi pi-fw pi-sync', to: '/shopifySync-log' }
        //     ]
        // },
        // {
        //     label: 'User Staff',
        //     icon: 'pi pi-fw pi-users',
        //     items: [ { label: 'All User List', icon: 'pi pi-fw pi-user', to: '/user-list' } ]
        // },

        {
            label: 'B2B',
            icon: 'pi pi-fw pi-sitemap',
            items: [
                // { label: 'All Orders', icon: 'pi pi-fw pi-shopping-cart', to: '/b2b-orderList' },
                { label: 'Discount Tags', icon: 'pi pi-fw pi-percentage', to: '/b2b-discountTags' }
            ]
        }
    ];

    let meta = breadcrumb.find((obj) => {
        return obj.path === location.pathname;
    });

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const changeMenuTheme = (name, logoColor, componentTheme) => {
        onMenuThemeChange(name);
        changeStyleSheetUrl('theme-css', componentTheme, 2);
        setComponentTheme(componentTheme);

        const appLogoLink = document.getElementById('app-logo');
        const appLogoUrl = `assets/layout/images/logo-${logoColor === 'dark' ? 'dark' : 'white'}.svg`;
        const horizontalLogoLink = document.getElementById('logo-horizontal');
        const horizontalLogoUrl = `assets/layout/images/logo-${logoColor === 'dark' ? 'dark' : 'white'}.svg`;

        if (appLogoLink) {
            appLogoLink.src = appLogoUrl;
        }
        if (horizontalLogoLink) {
            horizontalLogoLink.src = horizontalLogoUrl;
        }
        setLogoColor(logoColor);
    };

    const changeComponentTheme = (theme) => {
        setComponentTheme(theme);
        changeStyleSheetUrl('theme-css', theme, 3);
    };

    const changeColorScheme = (e) => {
        setColorScheme(e.value);

        const scheme = e.value;
        changeStyleSheetUrl('layout-css', 'layout-' + scheme + '.css', 1);
        changeStyleSheetUrl('theme-css', 'theme-' + scheme + '.css', 1);

        changeLogo(scheme);
    };

    const changeStyleSheetUrl = (id, value, from) => {
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute('href').split('/');

        if (from === 1) {
            // which function invoked this function
            urlTokens[urlTokens.length - 1] = value;
        } else if (from === 2) {
            // which function invoked this function
            if (value !== null) {
                urlTokens[urlTokens.length - 2] = value;
            }
        } else if (from === 3) {
            // which function invoked this function
            urlTokens[urlTokens.length - 2] = value;
        }

        const newURL = urlTokens.join('/');

        replaceLink(element, newURL);
    };

    const changeLogo = (scheme) => {
        const appLogoLink = document.getElementById('app-logo');
        const mobileLogoLink = document.getElementById('logo-mobile');
        const invoiceLogoLink = document.getElementById('invoice-logo');
        const footerLogoLink = document.getElementById('footer-logo');
        const horizontalLogoLink = document.getElementById('logo-horizontal');
        setLogoUrl(`assets/layout/images/logo-${scheme === 'light' ? 'dark' : 'white'}.svg`);

        if (appLogoLink) {
            appLogoLink.src = `assets/layout/images/logo-${scheme === 'light' ? logoColor : 'white'}.svg`;
        }

        if (horizontalLogoLink) {
            horizontalLogoLink.src = `assets/layout/images/logo-${scheme === 'light' ? logoColor : 'white'}.svg`;
        }

        if (mobileLogoLink) {
            mobileLogoLink.src = logoUrl;
        }

        if (invoiceLogoLink) {
            invoiceLogoLink.src = logoUrl;
        }

        if (footerLogoLink) {
            footerLogoLink.src = logoUrl;
        }
    };

    const replaceLink = (linkElement, href) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);
        } else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                const _linkElement = document.getElementById(id);
                _linkElement && _linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    };

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    };

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!userMenuClick) {
            setTopbarUserMenuActive(false);
        }

        if (!notificationMenuClick) {
            setTopbarNotificationMenuActive(false);
        }

        if (!rightMenuClick) {
            setRightMenuActive(false);
        }

        if (!menuClick) {
            if (isSlim() || isHorizontal()) {
                setMenuActive(false);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        searchClick = false;
        configClick = false;
        userMenuClick = false;
        rightMenuClick = false;
        notificationMenuClick = false;
        menuClick = false;
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarUserMenuActive(false);
        setTopbarNotificationMenuActive(false);
        setRightMenuActive(false);

        if (isOverlay()) {
            setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive((prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive);
        } else {
            setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
        }

        event.preventDefault();
    };

    const onMenuitemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();

            if (isSlim() || isHorizontal()) {
                setMenuActive(false);
            }
        }
    };

    const onRootMenuitemClick = () => {
        setMenuActive((prevMenuActive) => !prevMenuActive);
    };

    const onMenuThemeChange = (name) => {
        setMenuTheme('layout-sidebar-' + name);
    };

    const onMenuModeChange = (e) => {
        setMenuMode(e.value);
        if (e.value === 'static') {
            setStaticMenuDesktopInactive(false);
        }
    };

    const onTopbarUserMenuButtonClick = (event) => {
        userMenuClick = true;
        setTopbarUserMenuActive((prevTopbarUserMenuActive) => !prevTopbarUserMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const onTopbarNotificationMenuButtonClick = (event) => {
        notificationMenuClick = true;
        setTopbarNotificationMenuActive((prevTopbarNotificationMenuActive) => !prevTopbarNotificationMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const toggleSearch = () => {
        setSearchActive((prevSearchActive) => !prevSearchActive);
        searchClick = true;
    };

    const onSearchClick = () => {
        searchClick = true;
    };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onRightMenuClick = () => {
        rightMenuClick = true;
    };

    const onRightMenuButtonClick = (event) => {
        rightMenuClick = true;
        setRightMenuActive((prevRightMenuActive) => !prevRightMenuActive);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = () => {
        setConfigActive((prevConfigActive) => !prevConfigActive);
        configClick = true;
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
        unblockBodyScroll();
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const isDesktop = () => {
        return window.innerWidth > 1091;
    };

    const containerClassName = classNames(
        'layout-wrapper',
        {
            'layout-overlay': menuMode === 'overlay',
            'layout-static': menuMode === 'static',
            'layout-slim': menuMode === 'slim',
            'layout-horizontal': menuMode === 'horizontal',
            'layout-sidebar-dim': colorScheme === 'dim',
            'layout-sidebar-dark': colorScheme === 'dark',
            'layout-overlay-active': overlayMenuActive,
            'layout-mobile-active': staticMenuMobileActive,
            'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
            'p-input-filled': inputStyle === 'filled',
            'p-ripple-disabled': !ripple
        },
        colorScheme === 'light' ? menuTheme : ''
    );

    return (
        <div className={containerClassName} data-theme={colorScheme} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <div className="layout-content-wrapper">
                <AppTopbar
                    meta={meta}
                    topbarNotificationMenuActive={topbarNotificationMenuActive}
                    topbarUserMenuActive={topbarUserMenuActive}
                    onMenuButtonClick={onMenuButtonClick}
                    onSearchClick={toggleSearch}
                    onTopbarNotification={onTopbarNotificationMenuButtonClick}
                    onTopbarUserMenu={onTopbarUserMenuButtonClick}
                    onRightMenuClick={onRightMenuButtonClick}
                    onRightMenuButtonClick={onRightMenuButtonClick}
                    // menu={menu}
                    menu={getMenu()}
                    menuMode={menuMode}
                    menuActive={menuActive}
                    staticMenuMobileActive={staticMenuMobileActive}
                    onMenuClick={onMenuClick}
                    onMenuitemClick={onMenuitemClick}
                    onRootMenuitemClick={onRootMenuitemClick}
                    colorScheme={colorScheme}
                    changeColorScheme={changeColorScheme}
                ></AppTopbar>

                <div className="layout-content">
                    {/* <div className="layout-breadcrumb viewname text-2xl text-color-secondary font-bold pb-2">
                        <AppBreadcrumb meta={meta} />
                    </div> */}
                    <Routes>
                        {/* <Route
                            path="/"
                            element={
                                <AdminRoute>
                                    <Dashboard />
                                </AdminRoute>
                            }
                        /> */}
                        <Route path="/" element={<Navigate to="/login" />} />
                        <Route
                            path="/dashboard"
                            element={
                                <AdminRoute>
                                    <Dashboard />
                                </AdminRoute>
                            }
                        />
                        {/* <Route path="/inventory-sync-app-install" element={<DashboardMain />} /> */}
                        <Route path="/store" element={<StoreDatatable />} />
                        <Route path="/source-dashboard" element={<SourceDashboard />} />
                        <Route path="/destination-dashboard" element={<DestinationDashboard />} />
                        <Route path="/storeDestination" element={<DestinationDataTable />} />
                        {/* <Route path="/products" element={<ProductStoreDatatable />} /> */}
                        <Route path="/products" element={<ProductStore />} />
                        <Route path="/productDestination" element={<ProductDestination />} />
                        <Route path="/sync-store-config" element={<ProductSettings />} />
                        <Route path="/sync-destination-config" element={<ProductDestinationSettings />} />
                        <Route path="/sync-shopifySyncLog" element={<StoneSyncLog />} />
                        <Route path="/upload-csv" element={<UploadCsv />} />
                        <Route path="/ds-setting" element={<StoneShareStoreSetting />} />
                        <Route path="/ds-store" element={<StoneSearchStore />} />
                        <Route path="/ds-product-log" element={<ProductLog />} />
                        <Route path="/ds-discount-coupon" element={<GemFinderDiscountTags />} />
                        <Route path="/ds-product" element={<Product />} />
                        <Route path="/ds-company-filter" element={<ProductCompanyFilter />} />
                        <Route path="/ftpfeed-log" element={<FtpFeedLog />} />
                        <Route path="/media-log" element={<MediaLog />} />
                        <Route path="/shopifySync-log" element={<ShopifySyncLog />} />
                        <Route path="/excel-log" element={<ExcelLog />} />
                        <Route path="/user-list" element={<UserList />} />
                        <Route path="/product-list" element={<ProductList />} />
                        <Route path="/product-details/:id" element={<ProductDetails />} />
                        <Route path="/product/excel" element={<ProductExcel />} />
                        <Route path="/product-inventory/excel" element={<InventoryExcel />} />
                        <Route path="/upload-media" element={<UploadMedia />} />
                        <Route path="/ftp-list" element={<FtpClientList />} />
                        <Route path="/ftpFeed-list" element={<FtpFeedList />} />
                        {/* <Route path="/b2b-orderList" element={<OrderList />} /> */}
                        <Route path="/b2b-order/details/:id" element={<OrderDetails />} />
                        <Route path="/b2b-discountTags" element={<DiscountTags />} />
                        <Route path="*" element={<Navigate replace to={'/error'} />} />
                        {/* <Route path="/formlayout" element={<FormLayoutDemo />} /> */}
                        {/* <Route path="/input" element={<InputDemo />} />
                        <Route path="/floatlabel" element={<FloatLabelDemo />} />
                        <Route path="/invalidstate" element={<InvalidStateDemo />} />
                        <Route path="/button" element={<ButtonDemo />} />
                        <Route path="/table" element={<TableDemo />} />
                        <Route path="/list" element={<ListDemo />} />
                        <Route path="/tree" element={<TreeDemo />} />
                        <Route path="/panel" element={<PanelDemo />} />
                        <Route path="/overlay" element={<OverlayDemo />} />
                        <Route path="/media" element={<MediaDemo />} />
                        <Route path="/menu/*" element={<MenuDemo />} />
                        <Route path="/messages" element={<MessagesDemo />} />
                        <Route path="/file" element={<FileDemo />} />
                        <Route path="/chart" element={<ChartDemo colorMode={colorScheme} location={location} />} />
                        <Route path="/misc" element={<MiscDemo />} />
                        <Route path="/icons" element={<IconsDemo />} />
                        <Route path="/crud" element={<CrudDemo />} />
                        <Route path="/blocks" element={<BlocksDemo />} />
                        <Route path="/calendar" element={<CalendarDemo />} />
                        <Route path="/timeline" element={<TimelineDemo />} />
                        <Route path="/invoice" element={<Invoice logoUrl={logoUrl} location={location} />} />
                        <Route path="/help" element={<Help />} />
                        <Route path="/empty" element={<EmptyPage />} />
                        <Route path="/documentation" element={<Documentation />} /> */}
                    </Routes>
                </div>

                <AppFooter />
            </div>

            <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuClick={onRightMenuClick}></AppRightMenu>

            <AppConfig
                configActive={configActive}
                menuMode={menuMode}
                onMenuModeChange={onMenuModeChange}
                colorScheme={colorScheme}
                changeColorScheme={changeColorScheme}
                menuTheme={menuTheme}
                changeMenuTheme={changeMenuTheme}
                componentTheme={componentTheme}
                changeComponentTheme={changeComponentTheme}
                onConfigClick={onConfigClick}
                onConfigButtonClick={onConfigButtonClick}
                rippleActive={ripple}
                onRippleChange={onRippleChange}
                inputStyle={inputStyle}
                onInputStyleChange={onInputStyleChange}
            ></AppConfig>

            <AppSearch searchActive={searchActive} onSearchClick={onSearchClick} onSearchHide={onSearchHide} />
        </div>
    );
};

export default App;
