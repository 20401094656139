import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch, useSelector } from 'react-redux';
import { BreadCrumb } from 'primereact/breadcrumb';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { allProductListRequest, folderImgRemoveRequest, handleResetList, productmultiRemoveAvailableUnavailableRequest, singleMultiImgFolderRequest, statusUpdateExcelRequest } from '../../../redux/slices/kushDiamond/productSlice';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { Link, useNavigate } from 'react-router-dom';
import { SlideshowLightbox } from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';
import videoIcon from '../../../assets/videoicon.jpg';

const ProductList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { allProductData, isLoading, productmultiRemoveAvailableUnavailable, singleMultiImgFolderMessage, imgRemoveMessage, statusUpdateExcelMessage } = useSelector((state) => state.kdProducts);

    const [selectedProducts, setSelectedProducts] = useState(null);
    const [searchFilterValue, setSearchFilterValue] = useState('');
    const [sort_order, setSort_order] = useState('desc');
    const [availabilityStatusValue, setAvailabilityStatusValue] = useState(null);
    const [filters, setFilters] = useState(null);
    const [productsDialog, setProductsDialog] = useState({
        multiRemoveProduct: false,
        multiAvailableProduct: false,
        multiUnavailableProduct: false,
        multiMemoProduct: false,
        singleUploadImgModal: false,
        removeImgModal: false,
        removeImgModalId: 0,
        statusUpdateExcelModal: false
    });
    const [productUploadState, setProductUploadState] = useState({
        productSku: null,
        selectFileName: 'No file choosen',
        imgFile: [],
        imgChangeSubmitted: false,
        selectCsvFileName: 'No file choosen',
        csvFile: null,
        csvChangeSubmitted: false
    });
    const [imgGalleryArr, setImgGalleryArr] = useState();
    const [openImgGallery, setOpenImgGallery] = useState(false);

    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(25);
    const [rowsId, setRowsId] = useState(null);

    const availabilityStatusArr = [
        { value: '', label: 'Select All Availability' },
        { value: 'True', label: 'Available' },
        { value: 'False', label: 'Unavailable' },
        { value: 'Memo', label: 'Memo' }
    ];

    const table_data = {
        page: 1,
        per_page: 25,
        sort_order: sort_order,
        order_column: 'created_at'
    };

    const [queryString, setQueryString] = useState(`page=${table_data.page}&per_page=${table_data.per_page}&order_column=${table_data.order_column}`);

    useEffect(() => {
        dispatch(allProductListRequest(queryString));
        initFilters();
        return () => {
            dispatch(handleResetList());
        };
    }, [dispatch, queryString]);

    useEffect(() => {
        if (productmultiRemoveAvailableUnavailable !== null) {
            dispatch(allProductListRequest(queryString));
        }
        if (singleMultiImgFolderMessage !== null) {
            dispatch(allProductListRequest(queryString));
        }
        if (imgRemoveMessage !== null) {
            dispatch(allProductListRequest(queryString));
        }
        if (statusUpdateExcelMessage !== null) {
            dispatch(allProductListRequest(queryString));
        }
    }, [productmultiRemoveAvailableUnavailable, singleMultiImgFolderMessage, imgRemoveMessage, statusUpdateExcelMessage]);

    const initFilters = () => {
        setFilters({
            sku: { value: null, matchMode: FilterMatchMode.CONTAINS },
            avalibity_status: { value: null, matchMode: FilterMatchMode.CONTAINS }
        });
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        tableChangeHandler({ ...table_data, page: event.page + 1, per_page: event.rows });
    };

    const tableChangeHandler = (data) => {
        let queryStr = Object.keys(data)
            .map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
            })
            .join('&');
        setQueryString(queryStr);
    };

    const filterSkuClearCallback = (options) => {
        setSearchFilterValue('');
        tableChangeHandler({ ...table_data, search: '', avalibity_status: availabilityStatusValue == undefined || availabilityStatusValue == null ? '' : availabilityStatusValue });
        options.filterClearCallback();
    };

    const filterAvailableClearCallback = (options) => {
        setAvailabilityStatusValue(null);
        tableChangeHandler({ ...table_data, search: searchFilterValue, avalibity_status: '' });
        options.filterClearCallback();
    };

    const filterSkuClearTemplate = (options) => {
        return <Button type="button" className="p-button-outlined p-button-danger" onClick={() => filterSkuClearCallback(options)} label="Clear" />;
    };

    const filterAvailableClearTemplate = (options) => {
        return <Button type="button" className="p-button-outlined p-button-danger" onClick={() => filterAvailableClearCallback(options)} label="Clear" />;
    };

    const filterSkuApplyCallback = (options) => {
        tableChangeHandler({ ...table_data, search: searchFilterValue, avalibity_status: availabilityStatusValue == undefined || availabilityStatusValue == null ? '' : availabilityStatusValue });
        options.filterApplyCallback();
    };

    const filterAvalibityApplyCallback = (options) => {
        tableChangeHandler({ ...table_data, search: searchFilterValue, avalibity_status: availabilityStatusValue == undefined || availabilityStatusValue == null ? '' : availabilityStatusValue });
        options.filterApplyCallback();
    };

    const filterSkUApplyTemplate = (options) => {
        return <Button type="button" className="p-button-outlined" icon="pi pi-check" onClick={() => filterSkuApplyCallback(options)} severity="success" label="Apply" />;
    };

    const filterAvalibityApplyTemplate = (options) => {
        return <Button type="button" className="p-button-outlined" icon="pi pi-check" onClick={() => filterAvalibityApplyCallback(options)} severity="success" label="Apply" />;
    };

    const handleFilter = (e) => {
        setSearchFilterValue(e.target.value);
    };

    const skuFilterTemplate = () => {
        return <InputText type="search" className="w-15rem" value={searchFilterValue} onChange={(e) => handleFilter(e)} placeholder="Search SKU" />;
    };

    const availabilityStatusFilterTemplate = () => {
        return (
            <Dropdown
                value={availabilityStatusValue}
                onChange={(e) => setAvailabilityStatusValue(e.value)}
                options={availabilityStatusArr}
                optionLabel="label"
                placeholder="Select Availability Status"
                className="w-17rem font-semibold"
                style={{ borderColor: '#673AB7' }}
                showClear
            />
        );
    };

    const handleSubmitProduct = () => {
        const multiIdArr = selectedProducts?.map((ele) => ele.id);
        if (productsDialog?.multiRemoveProduct) {
            const multiRemoveProductObj = {
                id: multiIdArr,
                key: 'delete'
            };
            dispatch(productmultiRemoveAvailableUnavailableRequest(multiRemoveProductObj));
            setProductsDialog({
                ...productsDialog,
                multiRemoveProduct: false
            });
            dispatch(handleResetList());
            return setSelectedProducts(null);
        } else if (productsDialog?.multiAvailableProduct) {
            const multiAvailableProductObj = {
                id: multiIdArr,
                key: 'avalible'
            };
            dispatch(productmultiRemoveAvailableUnavailableRequest(multiAvailableProductObj));
            setProductsDialog({
                ...productsDialog,
                multiAvailableProduct: false
            });
            dispatch(handleResetList());
            return setSelectedProducts(null);
        } else if (productsDialog?.multiUnavailableProduct) {
            const multiUnavailableProductObj = {
                id: multiIdArr,
                key: 'not_avalible'
            };
            dispatch(productmultiRemoveAvailableUnavailableRequest(multiUnavailableProductObj));
            setProductsDialog({
                ...productsDialog,
                multiUnavailableProduct: false
            });
            dispatch(handleResetList());
            return setSelectedProducts(null);
        } else if (productsDialog?.multiMemoProduct) {
            const multiUnavailableProductObj = {
                id: multiIdArr,
                key: 'memo'
            };
            dispatch(productmultiRemoveAvailableUnavailableRequest(multiUnavailableProductObj));
            setProductsDialog({
                ...productsDialog,
                multiMemoProduct: false
            });
            dispatch(handleResetList());
            return setSelectedProducts(null);
        } else if (productsDialog?.removeImgModal) {
            const removeImgObj = {
                product_image_id: productsDialog?.removeImgModalId,
                type: 'all_image_delete'
            };
            dispatch(folderImgRemoveRequest(removeImgObj));
            dispatch(handleResetList());
            return setProductsDialog({
                ...productsDialog,
                removeImgModal: false
            });
        }
    };

    const deleteProductsDialogFooter = (
        <>
            <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-outlined p-button-danger"
                onClick={() =>
                    setProductsDialog({
                        ...productsDialog,
                        multiRemoveProduct: false,
                        multiAvailableProduct: false,
                        multiUnavailableProduct: false,
                        multiMemoProduct: false,
                        removeImgModal: false
                    })
                }
            />
            <Button label="Yes" icon="pi pi-check" className="p-button-outlined ml-2" onClick={handleSubmitProduct} />
        </>
    );

    const confirmDeleteSelected = () => {
        setProductsDialog({ ...productsDialog, multiRemoveProduct: true });
    };

    const confirmAvailableSelected = () => {
        setProductsDialog({ ...productsDialog, multiAvailableProduct: true });
    };
    const confirmUnavailableSelected = () => {
        setProductsDialog({ ...productsDialog, multiUnavailableProduct: true });
    };
    const confirmMemoSelected = () => {
        setProductsDialog({ ...productsDialog, multiMemoProduct: true });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button icon="pi pi-trash" className="p-button-danger mr-2" tooltip="Remove Products" tooltipOptions={{ position: 'top' }} onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} />
                    <Button icon="pi pi-check-circle" className="p-button-secondary mr-2" tooltip="Mark as Available" tooltipOptions={{ position: 'top' }} onClick={confirmAvailableSelected} disabled={!selectedProducts || !selectedProducts.length} />
                    <Button icon="pi pi-ban" className="p-button-secondary mr-2" tooltip="Mark as Unavailable" tooltipOptions={{ position: 'top' }} onClick={confirmUnavailableSelected} disabled={!selectedProducts || !selectedProducts.length} />
                    <Button icon="pi pi-book" className="p-button-secondary" tooltip="Mark as Memo" tooltipOptions={{ position: 'top' }} onClick={confirmMemoSelected} disabled={!selectedProducts || !selectedProducts.length} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span className="p-buttonset">
                    <Button label="Product Excel" icon="pi pi-link" className="p-button-outlined font-bold" onClick={() => navigate('/product/excel')} />
                    <Button label="Inventory Excel" icon="pi pi-link" className="p-button-outlined font-bold" onClick={() => navigate('/product-inventory/excel')} />
                    <Button label="Product Export CSV" icon="pi pi-upload" className="p-button-outlined font-bold" />
                    <Button label="Status Update Excel" icon="pi pi-upload" className="p-button-outlined font-bold" onClick={() => setProductsDialog({ ...productsDialog, statusUpdateExcelModal: true })} />
                </span>
            </React.Fragment>
        );
    };

    const avalibityStatusBodyTemplate = (rowData) => {
        return (
            <>
                {rowData?.avalibity_status == 'True' ? (
                    <Tag icon="pi pi-check-circle" className="status-color-success" severity="success" value="YES"></Tag>
                ) : rowData?.avalibity_status == 'Memo' ? (
                    <Tag icon="pi pi-book" className="status-color-warning" severity="warning" value="Memo"></Tag>
                ) : (
                    <Tag icon="pi pi-ban" className="status-color-danger" severity="danger" value="NO"></Tag>
                )}
            </>
        );
    };

    const shopifyStatusBodyTemplate = (rowData) => {
        return <span className={`product-badge ${rowData?.status == 'ACTIVE' ? 'status-instock p-1 text-xs' : 'status-outofstock p-1 text-xs'}`}>{rowData?.status}</span>;
    };

    const colorBodyTemplate = (rowData) => {
        return <>{rowData?.color == '' ? '-' : rowData?.color}</>;
    };
    const clarityBodyTemplate = (rowData) => {
        return <>{rowData?.clarity == '' ? '-' : rowData?.clarity}</>;
    };
    const measurementBodyTemplate = (rowData) => {
        return <>{rowData?.measurement == '' ? '-' : rowData?.measurement}</>;
    };

    const priceBodyTemplate = (rowData) => {
        return <>{rowData?.price?.toLocaleString('en-US')}</>;
    };

    const certificateBodyTemplate = (rowData) => {
        return <>{rowData?.certificate_no == '' ? '-' : rowData?.certificate_no}</>;
    };

    const labBodyTemplate = (rowData) => {
        return <>{rowData?.lab == '' ? '-' : rowData?.lab}</>;
    };

    const tblBodyTemplate = (rowData) => {
        return <>{rowData?.tbl == '' ? '-' : rowData?.tbl}</>;
    };
    const cutBodyTemplate = (rowData) => {
        return <>{rowData?.cut == '' ? '-' : rowData?.cut}</>;
    };
    const deptBodyTemplate = (rowData) => {
        return <>{rowData?.dept == '' ? '-' : rowData?.dept}</>;
    };
    const flBodyTemplate = (rowData) => {
        return <>{rowData?.fl == '' ? '-' : rowData?.fl}</>;
    };
    const girdleBodyTemplate = (rowData) => {
        return <>{rowData?.girdle == '' ? '-' : rowData?.girdle}</>;
    };
    const culBodyTemplate = (rowData) => {
        return <>{rowData?.cul == '' ? '-' : rowData?.cul}</>;
    };
    const polBodyTemplate = (rowData) => {
        return <>{rowData?.pol == '' ? '-' : rowData?.pol}</>;
    };
    const rapBodyTemplate = (rowData) => {
        return <>{rowData?.rap == '' ? '-' : rowData?.rap}</>;
    };
    const symBodyTemplate = (rowData) => {
        return <>{rowData?.sym == '' ? '-' : rowData?.sym}</>;
    };

    const showImgGallery = (productImg) => {
        let imgArr = [];
        productImg?.forEach((img, index) => {
            if (img?.type == 'Image') {
                var images = {
                    id: index,
                    type: 'Image',
                    src: img.url,
                    thumb: img.url,
                    caption: <a style={{ margin: '0px', color: '#fff', fontSize: '20px' }}>{img.image_name}</a>
                };
                imgArr?.push(images);
            } else if (img?.type == 'image/jpeg') {
                var images = {
                    id: index,
                    type: 'Image',
                    src: img.url,
                    thumb: img.url,
                    caption: <a style={{ margin: '0px', color: '#fff', fontSize: '20px' }}>{img.image_name}</a>
                };
                imgArr?.push(images);
            } else if (img?.type == 'image/png') {
                var images = {
                    id: index,
                    type: 'Image',
                    src: img.url,
                    thumb: img.url,
                    caption: <a style={{ margin: '0px', color: '#fff', fontSize: '20px' }}>{img.image_name}</a>
                };
                imgArr?.push(images);
            } else if (img?.type == 'video/mp4') {
                var images = {
                    id: index,
                    type: 'htmlVideo',
                    videoSrc: img.url,
                    thumbnail: videoIcon,
                    caption: <a style={{ margin: '0px', color: '#fff', fontSize: '20px' }}>{img.image_name}</a>
                };
                imgArr?.push(images);
            } else if (img?.type == 'video/quicktime') {
                var images = {
                    id: index,
                    type: 'htmlVideo',
                    videoSrc: img.url,
                    thumbnail: videoIcon,
                    caption: <a style={{ margin: '0px', color: '#fff', fontSize: '20px' }}>{img.image_name}</a>
                };
                imgArr?.push(images);
            }
            setImgGalleryArr(imgArr);
        });
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {rowData?.product_images?.length > 0 ? (
                    <Button
                        icon="pi pi-image"
                        className="p-button-text"
                        style={{ height: '25px', width: '25px', color: 'black' }}
                        tooltip={`View Gallery, Count ${rowData?.total_count}`}
                        tooltipOptions={{ position: 'top' }}
                        onClick={() => {
                            setOpenImgGallery(true);
                            showImgGallery(rowData?.product_images);
                        }}
                    />
                ) : (
                    <Button icon="pi pi-image" className="p-button-text opacity-60" style={{ height: '25px', width: '25px', color: 'black' }} />
                )}

                <Link
                    to={{
                        pathname: `/product-details/${rowData?.id}`,
                        state: { row: rowData }
                    }}
                >
                    <Button icon="pi pi-eye" className="p-button-text" tooltip="View Products" tooltipOptions={{ position: 'top' }} style={{ height: '25px', width: '25px' }} />
                </Link>

                <Button
                    icon="pi pi-upload"
                    className="p-button-text p-button-secondary"
                    tooltip="Upload Media"
                    tooltipOptions={{ position: 'top' }}
                    style={{ height: '25px', width: '25px' }}
                    onClick={() => {
                        setProductsDialog({ ...productsDialog, singleUploadImgModal: true });
                        setProductUploadState({ ...productUploadState, productSku: rowData?.sku });
                    }}
                />
                {rowData?.product_images?.length > 0 ? (
                    <Button
                        icon="pi pi-trash"
                        className="p-button-text p-button-danger"
                        tooltip="Remove Images"
                        tooltipOptions={{ position: 'top' }}
                        style={{ height: '25px', width: '25px' }}
                        onClick={() => setProductsDialog({ ...productsDialog, removeImgModal: true, removeImgModalId: rowData?.id })}
                    />
                ) : (
                    <Button icon="pi pi-trash" className="p-button-text p-button-danger opacity-60" style={{ height: '25px', width: '25px' }} />
                )}
            </div>
        );
    };

    const template = {
        layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    const handleImgFileChange = (e) => {
        const selectFile = e.target.files;
        setProductUploadState({ ...productUploadState, selectFileName: `${e.target.files?.length} files`, imgFile: selectFile, imgChangeSubmitted: true });
    };

    const handleImgFileSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData();
        [...productUploadState?.imgFile]?.forEach((file) => {
            return formData.append('product_img', file), formData.append('folder_name', file.webkitRelativePath.substring(0, file.webkitRelativePath.lastIndexOf('/') + 1));
        });
        dispatch(singleMultiImgFolderRequest(formData));
        dispatch(handleResetList());
        setProductsDialog({ ...productsDialog, singleUploadImgModal: false });
        setProductUploadState({ ...productUploadState, imgFile: [], selectFileName: 'No file choosen' });
    };

    const handleCsvFileChange = (e) => {
        const selectFile = e.target.files[0];
        setProductUploadState({ ...productUploadState, selectCsvFileName: e.target.files[0]?.name, csvFile: selectFile, csvChangeSubmitted: true });
    };

    const handleCsvFileSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append('file', productUploadState?.csvFile);
        dispatch(statusUpdateExcelRequest(formData));
        dispatch(handleResetList());
        setProductsDialog({ ...productsDialog, statusUpdateExcelModal: false });
        setProductUploadState({ ...productUploadState, csvFile: null, selectCsvFileName: 'No file choosen', csvChangeSubmitted: false });
    };

    const breadcrumbItems = [{ label: 'Kush Diamond' }, { label: 'Products' }];
    const dynamicHeight = Math.min(window.innerHeight * 4 + 1, 70) + 'vh';
    return (
        <>
            <div className="col px-0 pt-0 pb-2">
                <div className="breadcrumb-container flex justify-content-between align-items-center">
                    <div className="text-2xl font-bold">
                        <h3 className="m-0">Products</h3>
                    </div>
                    <div className="ml-6 text-2xl">
                        <BreadCrumb home={{ icon: 'pi pi-home', url: '/' }} model={breadcrumbItems} />
                    </div>
                </div>
            </div>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        {openImgGallery && (
                            <SlideshowLightbox
                                imgAnimation="fade"
                                images={imgGalleryArr}
                                showThumbnails={true}
                                open={openImgGallery}
                                lightboxIdentifier="lbox1"
                                disableImageZoom={true}
                                onClose={(e) => {
                                    if (e >= 0) {
                                        setOpenImgGallery(false);
                                    }
                                }}
                            ></SlideshowLightbox>
                        )}
                        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                        <DataTable
                            responsiveLayout="scroll"
                            scrollable
                            showGridlines
                            stripedRows
                            scrollHeight={dynamicHeight}
                            size="small"
                            value={allProductData?.results}
                            selection={selectedProducts}
                            onSelectionChange={(e) => setSelectedProducts(e.value)}
                            dataKey="id"
                            className="datatable-responsive kd-datatable-content"
                            emptyMessage="No records found."
                            loading={isLoading}
                            filters={filters}
                        >
                            <Column selectionMode="multiple" style={{ minWidth: '50px' }} frozen></Column>
                            <Column
                                field="sku"
                                header="SKU"
                                className="font-bold"
                                style={{ minWidth: '130px', display: 'block' }}
                                showFilterMatchModes={false}
                                filter
                                filterElement={skuFilterTemplate}
                                filterClear={filterSkuClearTemplate}
                                filterApply={filterSkUApplyTemplate}
                                frozen
                            ></Column>
                            <Column
                                field="avalibity_status"
                                header="Available ?"
                                style={{ minWidth: '140px', display: 'block' }}
                                body={avalibityStatusBodyTemplate}
                                showFilterMatchModes={false}
                                filter
                                filterElement={availabilityStatusFilterTemplate}
                                filterClear={filterAvailableClearTemplate}
                                filterApply={filterAvalibityApplyTemplate}
                            ></Column>
                            <Column field="status" header="Shopify Status" body={shopifyStatusBodyTemplate} style={{ minWidth: '130px' }}></Column>
                            <Column field="shape" header="Shape" style={{ minWidth: '100px' }}></Column>
                            <Column field="carat" header="Carat" style={{ minWidth: '100px', justifyContent: 'end' }}></Column>
                            <Column field="color" header="color" body={colorBodyTemplate} style={{ minWidth: '140px' }}></Column>
                            <Column field="clarity" header="Clarity" body={clarityBodyTemplate} style={{ minWidth: '100px' }}></Column>
                            <Column field="measurement" header="Measurement" body={measurementBodyTemplate} style={{ minWidth: '180px' }}></Column>
                            <Column field="price" header="Price" body={priceBodyTemplate} style={{ minWidth: '120px', justifyContent: 'end' }}></Column>
                            <Column field="certificate_no" header="Certificate No" body={certificateBodyTemplate} style={{ minWidth: '180px' }}></Column>
                            <Column field="lab" header="Lab" body={labBodyTemplate} style={{ minWidth: '80px' }}></Column>
                            <Column field="tbl" header="Tbl" body={tblBodyTemplate} style={{ minWidth: '80px', justifyContent: 'end' }}></Column>
                            <Column field="cut" header="Cut" body={cutBodyTemplate} style={{ minWidth: '200px' }}></Column>
                            <Column field="dept" header="Dept" body={deptBodyTemplate} style={{ minWidth: '90px', justifyContent: 'end' }}></Column>
                            <Column field="fl" header="Fl" body={flBodyTemplate} style={{ minWidth: '90px' }}></Column>
                            <Column field="girdle" header="Girdle" body={girdleBodyTemplate} style={{ minWidth: '140px' }}></Column>
                            <Column field="cul" header="Cul" body={culBodyTemplate} style={{ minWidth: '90px' }}></Column>
                            <Column field="pol" header="Pol" body={polBodyTemplate} style={{ minWidth: '90px' }}></Column>
                            <Column field="rap" header="Rap" body={rapBodyTemplate} style={{ minWidth: '90px' }}></Column>
                            <Column field="sym" header="Sym" body={symBodyTemplate} style={{ minWidth: '90px' }}></Column>
                            <Column field="shopify_product_id" header="Shopify Product Id" style={{ minWidth: '180px', justifyContent: 'end' }}></Column>
                            <Column header="Actions" body={actionBodyTemplate} style={{ minWidth: '170px' }} alignFrozen="right" frozen></Column>
                        </DataTable>
                        <Paginator template={template} first={first} rows={rows} totalRecords={allProductData?.count} onPageChange={onPageChange} rowsPerPageOptions={[25, 50, 100, 200]}></Paginator>
                        <Dialog visible={productsDialog?.multiRemoveProduct} style={{ width: '570px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={() => setProductsDialog({ ...productsDialog, multiRemoveProduct: false })}>
                            <div className="flex align-items-center">
                                <Button icon="pi pi-exclamation-triangle text-xl" className="p-button-rounded p-button-outlined p-button-secondary mr-3" />
                                <span className="font-bold" style={{ fontSize: '15px' }}>
                                    Are you sure? Once deleted, you will not be able to recover This data!
                                </span>
                            </div>
                        </Dialog>
                        <Dialog
                            visible={productsDialog?.multiAvailableProduct}
                            style={{ width: '560px' }}
                            header="Confirm"
                            modal
                            footer={deleteProductsDialogFooter}
                            onHide={() => setProductsDialog({ ...productsDialog, multiAvailableProduct: false })}
                        >
                            <div className="flex align-items-center">
                                <Button icon="pi pi-check" className="p-button-rounded p-button-outlined p-button-success mr-3" />
                                <span className="font-bold text-lg">Are you sure? These selected items will be marked as available</span>
                            </div>
                        </Dialog>
                        <Dialog
                            visible={productsDialog?.multiUnavailableProduct}
                            style={{ width: '560px' }}
                            header="Confirm"
                            modal
                            footer={deleteProductsDialogFooter}
                            onHide={() => setProductsDialog({ ...productsDialog, multiUnavailableProduct: false })}
                        >
                            <div className="flex align-items-center">
                                <Button icon="pi pi-exclamation-triangle text-xl" className="p-button-rounded p-button-outlined p-button-secondary mr-3" />
                                <span className="font-bold" style={{ fontSize: '15px' }}>
                                    Are you sure? These selected items will be marked as unavailable
                                </span>
                            </div>
                        </Dialog>
                        <Dialog visible={productsDialog?.multiMemoProduct} style={{ width: '560px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={() => setProductsDialog({ ...productsDialog, multiMemoProduct: false })}>
                            <div className="flex align-items-center">
                                <Button icon="pi pi-exclamation-triangle text-xl" className="p-button-rounded p-button-outlined p-button-secondary mr-3" />
                                <span className="font-bold" style={{ fontSize: '15px' }}>
                                    Are you sure? These selected items will be marked as memo
                                </span>
                            </div>
                        </Dialog>
                        <Dialog visible={productsDialog?.removeImgModal} style={{ width: '560px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={() => setProductsDialog({ ...productsDialog, removeImgModal: false })}>
                            <div className="flex align-items-center">
                                <Button icon="pi pi-exclamation-triangle text-xl" className="p-button-rounded p-button-outlined p-button-secondary mr-3" />
                                <span className="font-bold" style={{ fontSize: '15px' }}>
                                    Are you sure? Once deleted, you will not be able to recover This images!
                                </span>
                            </div>
                        </Dialog>
                        <Dialog
                            header={`Upload ${productUploadState?.productSku} Single Folder`}
                            visible={productsDialog?.singleUploadImgModal}
                            style={{ width: '40vw' }}
                            modal
                            onHide={() => {
                                setProductsDialog({ ...productsDialog, singleUploadImgModal: false });
                                setProductUploadState({ ...productUploadState, selectFileName: 'No file choosen', imgChangeSubmitted: false });
                            }}
                        >
                            <div>
                                <form>
                                    <input type={'file'} id={'csvFileInput'} accept="image/*" webkitdirectory="" directory="" multiple onChange={handleImgFileChange} hidden />
                                    <div className="flex align-items-center">
                                        <div className="cursor-pointer w-9rem border-round" style={{ backgroundColor: '#607d8b', color: 'white', padding: '0.5rem' }}>
                                            <label htmlFor="csvFileInput" className="flex justify-content-center align-items-center">
                                                <i className="pi pi-upload mr-3"></i>
                                                <span className="text-lg font-bold cursor-pointer">Select file</span>
                                            </label>
                                        </div>
                                        <span id="file-chosen" className="ml-3 text-lg font-bold">
                                            {productUploadState?.selectFileName}
                                        </span>
                                    </div>

                                    <div className="grid p-fluid mt-3">
                                        <div className="ml-3">
                                            {productUploadState?.imgChangeSubmitted == true ? (
                                                <Button icon="pi pi-check" onClick={handleImgFileSubmit} tooltip="Click to proceed" label="Submit" />
                                            ) : (
                                                <Button icon="pi pi-check" label="Submit" disabled />
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Dialog>
                        <Dialog
                            header={`Upload Excel`}
                            visible={productsDialog?.statusUpdateExcelModal}
                            style={{ width: '40vw' }}
                            modal
                            onHide={() => {
                                setProductsDialog({ ...productsDialog, statusUpdateExcelModal: false });
                                setProductUploadState({ ...productUploadState, selectCsvFileName: 'No file choosen', csvChangeSubmitted: false });
                            }}
                        >
                            <div>
                                <form>
                                    <input type={'file'} id={'csvFileInput'} onChange={handleCsvFileChange} hidden />
                                    <div className="flex align-items-center">
                                        <div className="cursor-pointer w-9rem border-round" style={{ backgroundColor: '#607d8b', color: 'white', padding: '0.5rem' }}>
                                            <label htmlFor="csvFileInput" className="flex justify-content-center align-items-center">
                                                <i className="pi pi-upload mr-3"></i>
                                                <span className="text-lg font-bold cursor-pointer">Select file</span>
                                            </label>
                                        </div>
                                        <span id="file-chosen" className="ml-3 text-lg font-bold">
                                            {productUploadState?.selectCsvFileName}
                                        </span>
                                    </div>

                                    <div className="grid p-fluid mt-3">
                                        <div className="ml-3">
                                            {productUploadState?.csvChangeSubmitted == true ? (
                                                <Button icon="pi pi-check" tooltip="Click to proceed" label="Submit" onClick={handleCsvFileSubmit} />
                                            ) : (
                                                <Button icon="pi pi-check" label="Submit" disabled />
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductList;
