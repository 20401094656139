import { Button } from 'primereact/button';
import React from 'react';
import { Link } from 'react-router-dom';
import image from '../../assets/success.png';
import { useSelector } from 'react-redux';

const CompleteStore = () => {
    const { storeData } = useSelector((store) => store.store);
    console.log(storeData, 'storeData');
    return (
        // <div>
        //     <div className="mt-4 text-center">
        //         <h3>Installation Complete!</h3>
        //         <h5>Here are a few guides to help you Sync your first product!</h5>
        //     </div>
        //     <div className="grid justify-content-center mt-5 w-full">
        //         <div className="col-12 md:col-6 xl:col-3 ">
        //             <div className="card grid-nogutter orders hover:shadow-5 cursor-pointer">
        //                 <div className="flex justify-content-between">
        //                     <h4 className="font-bold">Orders</h4>
        //                     <span className="pi pi-external-link text-2xl"></span>
        //                 </div>
        //                 <p className="text-2xl">Read our step by step guide to sync your first product!</p>
        //             </div>
        //         </div>
        //         <div className="col-12 md:col-6 xl:col-3 ">
        //             <div className="card grid-nogutter orders hover:shadow-5 cursor-pointer">
        //                 <div className="flex justify-content-between">
        //                     <h4 className="font-bold">Best Practices</h4>
        //                     <span className="pi pi-external-link text-2xl"></span>
        //                 </div>
        //                 <p className="text-2xl">Six steps to ensure your stone share properly</p>
        //             </div>
        //         </div>
        //         <div className="col-12 md:col-6 xl:col-3">
        //             <div className="card grid-nogutter orders hover:shadow-5 cursor-pointer">
        //                 <div className="flex justify-content-between">
        //                     <h4 className="font-bold">Use Cases</h4>
        //                     <span className="pi pi-external-link text-2xl"></span>
        //                 </div>
        //                 <p className="text-2xl">See how other companies use Syncio!</p>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="text-center mt-7">
        //         <Link to="/store">
        //             <Button label="Continue to Store" icon="pi pi-arrow-right" iconPos="right" className="p-button-raised mr-2 mb-2 text-center w-2" />
        //         </Link>
        //     </div>
        // </div>
        <div className=" flex w-6 mx-auto justify-content-center align-items-center mt-6">
            <div className="card shadow-2">
                <div className="mt-1 text-center">
                    <img height={100} src={image} />
                    <h3 className="mb-0" style={{ color: '#3F51B5', fontWeight: 700 }}>
                        Installation Complete! &#128640;
                    </h3>
                    <h5 className="text-xl  font-semibold">Enhance your browsing experience by accessing our powerful sync functionality.</h5>
                </div>
                <div className="grid justify-content-center ">
                    <div className="cursor-pointer">
                        <ul className="text-lg font-semibold line-height-3 mt-4 pl-5 text-justify">
                            {/* <li>
                                Create one menu item in your store's navigation menu The URL for the Stone Search page is <b>"/apps/stone-search"</b>.
                            </li> */}
                            {/* <li className="mt-2"> Congratulations, Stone Share has been successfully installed. Take advantage of  Stone Share and it allows you to sync your product data across multiple stores, so you can access everything in one place</li> */}
                        </ul>

                        <p className="text-xl mx-6 font-semibold">
                            Congratulations, Stone Share has been successfully installed. Take advantage of Stone Share and it allows you to sync your product data across multiple stores, so you can access everything in one place
                        </p>
                    </div>
                </div>
                <div className="text-center mt-5">
                    <Link to={`/${storeData.store_type}-dashboard`}>
                        <Button label="Continue to Store" icon="pi pi-arrow-right" iconPos="right" className="p-button-raised p-button-raised mr-8 text-center w-3" />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default CompleteStore;
