import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { useDispatch, useSelector } from 'react-redux';
import { Paginator } from 'primereact/paginator';
import moment from 'moment';
import { BreadCrumb } from 'primereact/breadcrumb';
import { ftpfeedLogListRequest } from '../../../redux/slices/kushDiamond/kdlogSlice';
import { Tag } from 'primereact/tag';

const FtpFeedLog = () => {
    const dispatch = useDispatch();

    const { ftpfeedLog, isLoading } = useSelector((state) => state.kdlog);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(25);
    const [sort_order, setSort_order] = useState('desc');
    const toast = useRef(null);

    const table_data = {
        page: 1,
        per_page: 25,
        sort_order: sort_order,
        order_column: 'created_at'
    };

    const [queryString, setQueryString] = useState(`page=${table_data.page}&per_page=${table_data.per_page}&order_column=${table_data.order_column}`);

    useEffect(() => {
        dispatch(ftpfeedLogListRequest(queryString));
    }, [dispatch, queryString]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        tableChangeHandler({ ...table_data, page: event.page + 1, per_page: event.rows });
    };

    const tableChangeHandler = (data) => {
        let queryStr = Object.keys(data)
            .map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
            })
            .join('&');
        setQueryString(queryStr);
    };

    const fileBodyTemplate = (rowData) => {
        return (
            <>
                <a href={rowData?.file_url} download={rowData?.file_url}>
                    {rowData?.file}
                </a>
            </>
        );
    };

    const dateBodyTemplate = (rowData) => {
        return (
            <>
                <div>{moment(rowData?.created_at).format('MMM DD YYYY h:mm A')}</div>
            </>
        );
    };
    const statusBodyTemplate = (rowData) => {
        return (
            <>
                {rowData?.status == 'success' ? (
                    <Tag icon="pi pi-check-circle" className="status-color-success text-base" severity="success" value={rowData?.status}></Tag>
                ) : (
                    <Tag icon="pi pi-times-circle" className="status-color-danger text-base" severity="danger" value={rowData?.status}></Tag>
                )}
            </>
        );
    };
    const errorstatusBodyTemplate = (rowData) => {
        return (
            <>
                <div>{rowData?.error_status ? rowData?.error_status : '-'}</div>
            </>
        );
    };

    const template = {
        layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    const breadcrumbItems = [{ label: 'Kush Diamond' }, { label: 'FTP Feed Logs' }];
    const dynamicHeight = Math.min(window.innerHeight * 4 + 1, 70) + 'vh';
    return (
        <>
            <div className="col px-0 pt-0 pb-2">
                <div className="breadcrumb-container flex justify-content-between align-items-center">
                    <div className="text-2xl font-bold">
                        <h3 className="m-0">FTP Feed Logs</h3>
                    </div>
                    <div className="ml-6 text-2xl">
                        <BreadCrumb home={{ icon: 'pi pi-home', url: '/' }} model={breadcrumbItems} />
                    </div>
                </div>
            </div>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <DataTable
                            size="small"
                            scrollable
                            showGridlines
                            stripedRows
                            scrollHeight={dynamicHeight}
                            value={ftpfeedLog?.results}
                            dataKey="id"
                            loading={isLoading}
                            className="datatable-responsive kd-datatable-content"
                            emptyMessage="No records found."
                            responsiveLayout="scroll"
                        >
                            <Column field="created_at" header="Date" style={{ minWidth: '14rem' }} sortable body={dateBodyTemplate}></Column>
                            <Column field="client_name" header="Client" sortable style={{ minWidth: '10rem' }}></Column>
                            <Column field="client" header="Host Name" sortable style={{ minWidth: '24rem' }}></Column>
                            <Column field="user_name" header="User Name" style={{ minWidth: '8rem' }}></Column>
                            <Column field="file" header="File" style={{ minWidth: '21rem' }} body={fileBodyTemplate}></Column>
                            <Column field="status" header="Status" style={{ minWidth: '9rem' }} body={statusBodyTemplate}></Column>
                            <Column field="error_status" header="Error Status" style={{ minWidth: '29rem' }} body={errorstatusBodyTemplate}></Column>
                        </DataTable>
                        <Paginator template={template} first={first} rows={rows} totalRecords={ftpfeedLog?.count} onPageChange={onPageChange} rowsPerPageOptions={[25, 50, 100, 200]}></Paginator>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FtpFeedLog;
