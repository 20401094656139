import { createSlice } from '@reduxjs/toolkit';
import {
    bulkSelectedProductSyncApi,
    bulkStatusApi,
    getProductList,
    modifyProductMapDestinationApi,
    productMapDestinationApi,
    productMapSourceApi,
    productSyncApi,
    productUnSyncApi,
    productViewApi,
    productViewSyncApi,
    productshopifyLogApi,
    reSyncApi
} from '../../service/ProductService';
import toast from 'react-hot-toast';
import { handleUnAuthorizeUserRequest } from './authSlice';

export const authSlice = createSlice({
    name: 'product',
    initialState: {
        isLoading: false,
        productList: [],
        productSyncData: null,
        viewProductData: [],
        productMapSourceData: null,
        viewProductSyncData: null,
        productunSyncData: null,
        bulkSelectedProductSyncData: null,
        bulkStatusData: null,
        modifyProductMapDestination: null,
        modifyProductMapDestinationError: null,
        productMapDestinationData: null,
        reSyncData: null,
        productSyncLog: null,
        error: null
    },
    reducers: {
        setLoading: (state) => {
            state.isLoading = true;
        },

        productListSuccess: (state, action) => {
            state.isLoading = false;
            state.productList = action.payload;
        },

        productSyncDataList: (state, action) => {
            state.isLoading = false;
            state.productSyncData = action.payload;
        },

        viewProductDataList: (state, action) => {
            state.isLoading = false;
            state.viewProductData = action.payload?.data;
        },

        productMapSourceDataList: (state, action) => {
            state.isLoading = false;
            state.productMapSourceData = action.payload;
        },

        viewProductSyncDataList: (state, action) => {
            state.isLoading = false;
            state.viewProductSyncData = action.payload?.data;
        },

        productunSyncDataList: (state, action) => {
            state.isLoading = false;
            state.productunSyncData = action.payload;
        },

        bulkSelectedProductSyncDataList: (state, action) => {
            state.isLoading = false;
            state.bulkSelectedProductSyncData = action.payload;
        },

        bulkStatusDataList: (state, action) => {
            state.isLoading = false;
            state.bulkStatusData = action.payload;
        },

        modifyProductMapDestinationDataList: (state, action) => {
            state.isLoading = false;
            state.modifyProductMapDestination = action.payload;
        },

        modifyProductMapDestinationErrorList: (state, action) => {
            state.isLoading = false;
            state.modifyProductMapDestinationError = action.payload;
        },

        productMapDestinationDataList: (state, action) => {
            state.isLoading = false;
            state.productMapDestinationData = action.payload;
        },

        reSyncDataList: (state, action) => {
            state.isLoading = false;
            state.reSyncData = action.payload;
        },

        productSyncLogList: (state, action) => {
            state.isLoading = false;
            state.productSyncLog = action.payload;
        },

        productListError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        allproductResetData: (state) => {
            state.isLoading = false;
            state.error = null;
            state.productList = [];
        },

        productSyncReset: (state) => {
            state.isLoading = false;
            state.error = null;
            state.productSyncData = null;
        },

        productMapSourceReset: (state) => {
            state.isLoading = false;
            state.error = null;
            state.productMapSourceData = null;
            state.productMapDestinationData = null;
        },

        productViewReset: (state) => {
            state.isLoading = false;
            state.error = null;
            state.viewProductData = [];
        },

        productUnSyncReset: (state) => {
            state.isLoading = false;
            state.error = null;
            state.productunSyncData = null;
        },

        productViewSyncReset: (state) => {
            state.isLoading = false;
            state.error = null;
            state.viewProductSyncData = null;
        },

        productbulkSyncReset: (state) => {
            state.isLoading = false;
            state.error = null;
            state.bulkSelectedProductSyncData = null;
        }
    }
});

export const {
    setLoading,
    productListSuccess,
    productSyncDataList,
    viewProductDataList,
    productMapSourceDataList,
    viewProductSyncDataList,
    productunSyncDataList,
    bulkSelectedProductSyncDataList,
    bulkStatusDataList,
    modifyProductMapDestinationDataList,
    modifyProductMapDestinationErrorList,
    productMapDestinationDataList,
    reSyncDataList,
    productSyncLogList,
    productListError,
    allproductResetData,
    productSyncReset,
    productMapSourceReset,
    productViewReset,
    productUnSyncReset,
    productViewSyncReset,
    productbulkSyncReset
} = authSlice.actions;
export default authSlice.reducer;

export const getProducts = (queryString) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await getProductList(queryString, config);

        dispatch(productListSuccess(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error.response && error.response.data.errors) {
            return dispatch(productListError(error.response.data.errors));
        } else {
            return dispatch(productListError(error.message));
        }
    }
};

export const productSyncDataRequest = (productSync) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await productSyncApi(productSync, config);

        const { statusCode, message } = data;

        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(productSyncDataList(message));
            // dispatch(getProducts());
        }
    } catch (error) {
        const { statusCode } = error.response.data;
        if (statusCode === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (statusCode === 422) {
            dispatch(productListError(error.response.data.message));
            toast.error(error.response.data.message, {
                id: toastId
            });
        }
    }
};

export const productViewRequest = (userid) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await productViewApi(userid, config);
        dispatch(viewProductDataList(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error.response && error.response.data.errors) {
            return dispatch(productListError(error.response.data.errors));
        } else {
            return dispatch(productListError(error.message));
        }
    }
};

export const productMapSourceRequest = (userid, destinationStoreId) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await productMapSourceApi(userid, destinationStoreId, config);
        dispatch(productMapSourceDataList(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error?.response?.data) {
            toast.error(error.response.data.message);
            return dispatch(productListError(error.response.data.message));
        }
    }
};

export const productViewSyncRequest = (userViewSyncData) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await productViewSyncApi(userViewSyncData, config);
        dispatch(viewProductSyncDataList(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error?.response?.data) {
            dispatch(productListError(error?.response?.data?.message));
            toast.error(error?.response?.data?.message);
        }
    }
};

export const bulkSelectedProductSyncRequest = (bulkSelectedProduct) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await bulkSelectedProductSyncApi(bulkSelectedProduct, config);

        const { statusCode, message } = data;

        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(bulkSelectedProductSyncDataList(message));
            dispatch(getProducts());
        }
    } catch (error) {
        const { statusCode } = error.response.data;
        if (statusCode === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else {
        }
        if (statusCode === 422) {
            dispatch(productListError(error.response.data.message));
            toast.error(error.response.data.message, {
                id: toastId
            });
        }
    }
};

export const bulkStatusRequest = (sourceStoreId) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await bulkStatusApi(sourceStoreId, config);
        dispatch(bulkStatusDataList(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else {
            return dispatch(productListError(error.response?.data));
        }
    }
};

export const reSyncRequest = (reSyncProduct) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await reSyncApi(reSyncProduct, config);

        const { statusCode, message } = data;

        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(reSyncDataList(message));
        }
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error.response.data) {
            dispatch(productListError(error.response.data.message));
            toast.error(error.response.data.message, {
                id: toastId
            });
        }
    }
};

export const productUnSyncDataRequest = (productUnSync) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await productUnSyncApi(productUnSync, config);

        const { statusCode, message } = data;

        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(productunSyncDataList(message));
        }
    } catch (error) {
        const { statusCode } = error.response.data;
        if (statusCode === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (statusCode === 422) {
            dispatch(productListError(error.response.data.message));
            toast.error(error.response.data.message, {
                id: toastId
            });
        }
    }
};

export const productMapDestinationRequest = (productMapDestination) => async (dispatch, getState) => {
    dispatch(setLoading());
    // const toastId = toast.loading('Please wait...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await productMapDestinationApi(productMapDestination, config);
        dispatch(productMapDestinationDataList(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error?.response?.data) {
            dispatch(productListError(error?.response?.data?.message));
            toast.error(error?.response?.data?.message);
        }
    }
};

export const modifyProductMapDestinationRequest = (modifyProductMapDestination) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await modifyProductMapDestinationApi(modifyProductMapDestination, config);
        const { statusCode, error, message } = data;

        if (statusCode === 422) {
            toast.error(message, {
                id: toastId
            });
            dispatch(modifyProductMapDestinationErrorList(message));
        }
        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(modifyProductMapDestinationDataList(message));
        }
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error?.response?.data) {
            dispatch(productListError(error?.response?.data?.message));
            toast.error(error?.response?.data?.message, {
                id: toastId
            });
        }
    }
};

export const productShopifyLogRequest = (queryData) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await productshopifyLogApi(queryData, config);
        dispatch(productSyncLogList(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error?.response?.data) {
            return dispatch(productListError(error?.response?.data));
        }
    }
};
