import { createSlice } from '@reduxjs/toolkit';
import {
    destinationStoreApi,
    getDashboardRequestApi,
    getStoreRequestApi,
    keyVerifiedRequestApi,
    sourceStoreApi,
    storeConnectAPI,
    storeDisconnectApi,
    storeEmailAPI,
    storeListingDdApi,
    storeRequestApi,
    storeStatusApi,
    storeTypeAPI,
    storeVerifiedRequestApi
} from '../../service/storeService';
import { toast } from 'react-hot-toast';
import { handleUnAuthorizeUserRequest } from './authSlice';
import { stoneSearchStoreRequest } from './gemFinderSlice';

export const storeSlice = createSlice({
    name: 'store',
    initialState: {
        isLoading: false,
        isConnectStoreLoading: false,
        storeData: null,
        verifiedStoreData: null,
        sourceStoreList: [],
        isSuccess: false,
        destinationStoreList: [],
        storeEmail: null,
        storeConnectKey: null,
        storeDisconnectData: null,
        storeStatusData: null,
        storeListingData: null,
        storeUrlId: null,
        error: null,
        dashboardData: null,
        isStoreVerified: false,
        storeRequestSuccessMessage: null,
        toastError: null,
        activeStepCount: null,
        storeConnectKeyError: null
    },
    reducers: {
        setLoading: (state) => {
            state.isLoading = true;
        },
        setConnectStoreLoading: (state, action) => {
            state.isConnectStoreLoading = action.payload;
        },
        handleStore: (state, action) => {
            state.isLoading = false;
            state.storeData = action.payload;
        },
        handleStoreUrl: (state, action) => {
            state.isLoading = false;
            state.storeUrlId = action.payload;
        },

        sourceStoreData: (state, action) => {
            state.isLoading = false;
            state.sourceStoreList = action.payload;
            state.isSuccess = true;
        },
        handleSuccess: (state, action) => {
            state.isLoading = false;
            state.isStoreVerified = action.payload.is_verified;
            state.verifiedStoreData = action.payload;
        },
        destinationStoreData: (state, action) => {
            state.isLoading = false;
            state.destinationStoreList = action.payload;
        },

        connectStoreEmail: (state, action) => {
            state.isLoading = false;
            state.storeEmail = action.payload;
        },

        connectStoreData: (state, action) => {
            state.isLoading = false;
            state.storeConnectKey = action.payload;
        },

        disconnectStoreData: (state, action) => {
            state.isLoading = false;
            state.storeDisconnectData = action.payload;
        },
        storeStatusData: (state, action) => {
            state.isLoading = false;
            state.storeStatusData = action.payload?.data;
        },
        storeListDdData: (state, action) => {
            state.isLoading = false;
            state.storeListingData = action.payload;
        },
        dashboardSuccess: (state, action) => {
            state.isLoading = false;
            state.dashboardData = action.payload;
        },
        storeRequestSuccess: (state, action) => {
            state.isLoading = false;
            state.storeRequestSuccessMessage = action.payload;
            state.storeConnectKey = null;
            state.storeDisconnectData = null;
        },
        handleStoreError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },

        handleToastError: (state, action) => {
            state.toastError = action.payload;
            state.isLoading = false;
        },

        handleStoreConnectKeyerror: (state, action) => {
            state.storeConnectKeyError = action.payload;
            state.isLoading = false;
        },

        handleStoreReset: (state) => {
            state.isLoading = false;
            state.error = null;
            state.storeConnectKey = null;
            state.toastError = null;
            state.storeEmail = null;
            state.storeConnectKeyError = null;
        },
        updateStepCount: (state, action) => {
            state.isLoading = false;
            state.activeStepCount += 1;
        }
    }
});

export const {
    setLoading,
    setConnectStoreLoading,
    handleStore,
    sourceStoreData,
    destinationStoreData,
    handleStoreUrl,
    connectStoreEmail,
    connectStoreData,
    disconnectStoreData,
    storeStatusData,
    storeListDdData,
    handleStoreError,
    handleStoreConnectKeyerror,
    handleToastError,
    handleStoreReset,
    updateStepCount,
    storeRequestSuccess,
    handleSuccess,
    handleKeyVerifiedSuccess,
    dashboardSuccess
} = storeSlice.actions;
export default storeSlice.reducer;

export const storeApi = (storeData) => async (dispatch) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait...');
    try {
        const { data } = await storeTypeAPI(storeData);

        const { statusCode, message, store_details: responseData } = data;

        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(handleStore(responseData));
        }
    } catch (error) {
        const { statusCode } = error?.response?.data;
        if (error?.response.statusCode === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (statusCode === 422) {
            dispatch(handleStoreError(error?.response?.data?.message));
            toast.error(error?.response?.data?.message, {
                id: toastId
            });
        }
    }
};

export const connectStoreEmailApi = (emailData) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await storeEmailAPI(emailData, config);
        const { statusCode, message } = data;

        if (statusCode === 200) {
            dispatch(connectStoreEmail(message));
        }
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error.response && error.response.data.errors) {
            dispatch(handleStoreError(error.response.data.errors));
        } else {
            dispatch(handleToastError(error.response.data.message));
        }
    }
};

export const sourceStoreListeApi = (queryData) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await sourceStoreApi(queryData, config);
        dispatch(sourceStoreData(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else {
            return dispatch(handleStoreError(error?.response?.data));
        }
    }
};

export const destinationStoreListApi = (queryData) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await destinationStoreApi(queryData, config);
        dispatch(destinationStoreData(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else {
            return dispatch(handleStoreError(error));
        }
    }
};

export const connectStoreApi = (storeDataList) => async (dispatch, getState) => {
    const toastId = toast.loading('Please wait...');
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await storeConnectAPI(storeDataList, config);

        const { statusCode, message } = data;

        if (statusCode === 200) {
            toast.success(message, {
                id: toastId,
                duration: 5000
            });
            dispatch(connectStoreData(message));
        }
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (error?.response.status === 422) {
            toast.error(error.response.data.message, {
                id: toastId,
                duration: 5000
            });
        } else if (error.response && error.response.data) {
            return dispatch(handleStoreConnectKeyerror(error.response.data.message));
        }
    }
};

export const disconnectStoreRequest = (disconnectstore, getQueryParam) => async (dispatch, getState) => {
    dispatch(setLoading());
    const toastId = toast.loading('Please wait...');
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await storeDisconnectApi(disconnectstore, config);

        const { statusCode, message } = data;

        if (statusCode === 200) {
            toast.success(message, {
                id: toastId
            });
            dispatch(disconnectStoreData(message));
            dispatch(stoneSearchStoreRequest(getQueryParam));
        }
    } catch (error) {
        const { statusCode } = error?.response?.data;
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else if (statusCode === 422) {
            dispatch(handleStoreError(error?.response?.data?.message));
            toast.error(error?.response?.data?.message, {
                id: toastId
            });
        }
    }
};
export const storeStatusRequest = (storeKey) => async (dispatch) => {
    dispatch(setLoading());
    try {
        const { data } = await storeStatusApi(storeKey);
        dispatch(storeStatusData(data));
    } catch (error) {
        return dispatch(handleStoreError(error?.response));
    }
};
export const sourceStoreListingRequest = () => async (dispatch) => {
    dispatch(setLoading());
    try {
        const { data } = await storeListingDdApi();
        dispatch(storeListDdData(data?.source_store_list));
    } catch (error) {
        return dispatch(handleStoreError(error?.response));
    }
};
export const getDashboardDetailsRequest = (reqData) => async (dispatch) => {
    dispatch(setLoading());
    try {
        const { data } = await getDashboardRequestApi(reqData);
        dispatch(dashboardSuccess(data?.store_detail));
    } catch (error) {
        return dispatch(handleStoreError(error?.response));
    }
};
export const storeRequest = (reqData) => async (dispatch) => {
    const toastId = toast.loading('Please wait...');
    dispatch(setLoading());
    try {
        const { data } = await storeRequestApi(reqData);
        if (data.statusCode === 200) {
            toast.success(data.message, {
                id: toastId,
                duration: 5000
            });
            dispatch(storeRequestSuccess(data.message));
        }
    } catch (error) {
        return dispatch(handleStoreError(error?.response));
    }
};
export const storeVerifiedRequest = (reqData) => async (dispatch) => {
    // const toastId = toast.loading('Please wait...');
    dispatch(setLoading());
    try {
        const { data } = await storeVerifiedRequestApi(reqData);
        if (data.statusCode === 200) {
            dispatch(handleSuccess(data));
        }
    } catch (error) {
        return dispatch(handleStoreError(error?.response));
    }
};
