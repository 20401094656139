import { createSlice } from '@reduxjs/toolkit';
import { productInventoryApi } from '../../service/ProductInventoryService';
import { handleUnAuthorizeUserRequest } from './authSlice';

export const productInventorySlice = createSlice({
    name: 'productInventory',
    initialState: {
        isLoading: false,
        inventoryData: [],
        error: null
    },
    reducers: {
        setLoading: (state) => {
            state.isLoading = true;
        },
        inventoryList: (state, action) => {
            state.isLoading = false;
            state.inventoryData = action.payload?.data;
        },
        inventoryResetData: (state) => {
            state.isLoading = false;
            state.error = null;
            state.inventoryData = [];
        },
        inventoryError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        }
    }
});

export const { setLoading, inventoryList, inventoryResetData, inventoryError } = productInventorySlice.actions;
export default productInventorySlice.reducer;

export const inventoryListDropdownApi = (sourceStoreId) => async (dispatch, getState) => {
    dispatch(setLoading());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: getState()?.auth?.Token
            }
        };
        const { data } = await productInventoryApi(sourceStoreId, config);
        dispatch(inventoryList(data));
    } catch (error) {
        if (error?.response.status === 401) {
            dispatch(handleUnAuthorizeUserRequest());
        } else {
            return dispatch(inventoryError(error.response));
        }
    }
};
