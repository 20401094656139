import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Error = () => {
    const { verifiedStoreData, storeData } = useSelector((store) => store.store);

    const getNavigationLink = () => {
        if (storeData && storeData.store_type === 'source') {
            return '/source-dashboard';
        } else {
            return '/destination-dashboard';
        }
    };
    return (
        <div className="exception-body error">
            <img src="assets/layout/images/logo-white.svg" alt="diamond-layout" className="logo" />

            <div className="exception-content">
                <div className="exception-title">ERROR</div>
                <div className="exception-detail">Something went wrong.</div>
                <Link to={getNavigationLink()}>Go to Dashboard</Link>
            </div>
        </div>
    );
};
