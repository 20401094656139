import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { excelResetData, productExcelTypeOneRequest } from '../../../redux/slices/kushDiamond/productSlice';
import { Link } from 'react-router-dom';
import { Messages } from 'primereact/messages';

const ProductExcel = () => {
    const dispatch = useDispatch();
    const { productExcelTypeOnemessage } = useSelector((state) => state.kdProducts);

    const [productExcelState, setProductExcelState] = useState({
        selectFileName: 'No file choosen',
        submitted: false,
        file: null
    });
    const inputRef = useRef(null);
    const msgs = useRef(null);

    useEffect(() => {
        if (productExcelTypeOnemessage !== null) {
            msgs.current.show({
                sticky: true,
                severity: 'success',
                content: (
                    <>
                        <span>Product successfully created. now you have to upload product images from the Image Upload page. also, you can see the status of the Shopify sync process from the Shopify Sync Log page</span>
                    </>
                )
            });
            setTimeout(() => {
                msgs.current.clear();
            }, 2000);
        }

        return () => {
            dispatch(excelResetData());
        };
    }, [productExcelTypeOnemessage]);

    const handleOnChange = (e) => {
        const selectFile = e.target.files[0];
        setProductExcelState({ ...productExcelState, selectFileName: e.target.files[0]?.name, file: selectFile, submitted: true });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append('file', productExcelState?.file);
        dispatch(productExcelTypeOneRequest(formData));
        dispatch(excelResetData());
        inputRef.current.value = null;
        setProductExcelState({ ...productExcelState, selectFileName: 'No file choosen', file: null, submitted: false });
    };

    const breadcrumbItems = [{ label: 'Kush Diamond' }, { label: 'Products Excel' }];
    return (
        <>
            <div className="col px-0 pt-0 pb-2">
                <div className="breadcrumb-container flex justify-content-between align-items-center">
                    <div className="text-2xl font-bold">
                        <h3 className="m-0">Products Excel</h3>
                    </div>
                    <div className="ml-6 text-2xl">
                        <BreadCrumb home={{ icon: 'pi pi-home', url: '/' }} model={breadcrumbItems} />
                    </div>
                </div>
            </div>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <Messages ref={msgs} />
                        <div className="border-1 border-400 surface-300 p-3">
                            <p className="text-xl font-bold mb-1">NOTE :</p>
                            <p className="text-lg font-semibold">Please Upload excel to add new and updated existing products into the database. Please make sure that this is only to upload products, not for Shopify synchronize.</p>
                        </div>
                        <div className="flex align-items-center gap-3 mt-3">
                            <span className="flex align-items-center gap-2">
                                <i className="pi pi-download"></i>
                                <span className="text-lg font-bold">Download Sample :</span>
                            </span>
                            <a href={`${process.env.REACT_APP_KUSHDIAMOND_URL_API}/media/sample/sample1.xlsx`} className="customer-badge status-proposal">
                                sample.csv
                            </a>
                        </div>
                        <form className="mt-4">
                            <input ref={inputRef} type={'file'} id={'excelFile'} onChange={handleOnChange} hidden />
                            <div className="flex align-items-center">
                                <div className="cursor-pointer w-9rem border-round" style={{ backgroundColor: '#607d8b', color: 'white', padding: '0.5rem' }}>
                                    <label htmlFor="excelFile" className="flex justify-content-center align-items-center">
                                        <i className="pi pi-upload mr-3"></i>
                                        <span className="text-lg font-bold cursor-pointer">Select file</span>
                                    </label>
                                </div>
                                <span id="file-chosen" className="ml-3 text-lg font-bold">
                                    {productExcelState?.selectFileName}
                                </span>
                            </div>

                            <div className="flex align-items-center mt-3">
                                <div className="mr-4">{productExcelState?.submitted == true ? <Button icon="pi pi-check" label="Submit" onClick={handleSubmit} /> : <Button icon="pi pi-check" label="Submit" disabled />}</div>
                                <Link to={-1}>
                                    <Button className="p-button-outlined" label="Back" />
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductExcel;
