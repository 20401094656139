import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import { sourceStoreListeApi } from '../../redux/slices/storeSlice';
import { Paginator } from 'primereact/paginator';

const DestinationDataTable = () => {
    const dispatch = useDispatch();
    const { sourceStoreList, isLoading } = useSelector((state) => state.store);

    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [sort_order, setSort_order] = useState('desc');
    const [filterValue, setFilterValue] = useState('');
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(25);
    const toast = useRef(null);
    const dt = useRef(null);

    const table_data = {
        page: 1,
        per_page: 25,
        sort_order: sort_order,
        filterValue: filterValue
    };

    const [queryString, setQueryString] = useState(`page=${table_data.page}&search=${table_data.filterValue}&per_page=${table_data.per_page}`);

    useEffect(() => {
        dispatch(sourceStoreListeApi(queryString));
    }, [dispatch, queryString]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        tableChangeHandler({ ...table_data, page: event.page + 1, per_page: event.rows });
    };

    const tableChangeHandler = (data) => {
        let queryStr = Object.keys(data)
            .map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
            })
            .join('&');
        setQueryString(queryStr);
    };

    const handleFilter = (e) => {
        let value = e.target.value;
        tableChangeHandler({ ...table_data, search: value });
        setFilterValue(value);
    };

    const openNew = () => {
        setSubmitted(false);
    };

    const platformBodyTemplate = () => {
        return (
            <>
                <span className="p-column-title">Platform</span>
                <img className="w-2" src="https://app-v2.syncio.io/img/shopify_logo.305ff4da.png" alt="source_store" />
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                {rowData.status == true ? <Badge value="Active" severity="success" /> : <Badge value="InActive" severity="danger" />}
            </>
        );
    };

    const storeUrlBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Store URL</span>
                {rowData.store_url}
            </>
        );
    };

    const commissionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Commission</span>
                None
            </>
        );
    };

    const assignedLocationBodyTemplate = () => {
        return (
            <>
                <span className="p-column-title">Assigned Location</span>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-sitemap" className="p-button-rounded p-button-info mr-2" />
                <Button icon="pi pi-link" className="p-button-rounded p-button-secondary mt-2" />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div className="my-2">
                <Button label="Delete" icon="pi pi-trash" className="p-button-danger font-bold" disabled={!selectedProducts || !selectedProducts.length} />
                <Button label="Connect New Store" icon="pi pi-plus-circle" className="p-button-help ml-4 font-bold" onClick={openNew} />
            </div>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="w-28rem" value={filterValue} onChange={handleFilter} placeholder="Search..." />
            </span>
        </div>
    );

    const template = {
        layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <h5 className="font-bold mt-0">Manage your Source store</h5>

                    <DataTable
                        ref={dt}
                        scrollHeight="350px"
                        size="small"
                        value={sourceStoreList?.results}
                        selection={selectedProducts}
                        // onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id"
                        className="datatable-responsive datatable_container"
                        emptyMessage="No record found."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="platform" header="Platform" headerStyle={{ width: '12%' }} body={platformBodyTemplate}></Column>
                        <Column field="status" header="status" sortable headerStyle={{ width: '15%' }} body={statusBodyTemplate}></Column>
                        <Column field="store_url" header="Store URL" sortable headerStyle={{ width: '25%' }} body={storeUrlBodyTemplate}></Column>
                        <Column field="commission" header="Commission" body={commissionBodyTemplate} sortable headerStyle={{ width: '12%' }}></Column>
                        <Column field="assignedLocation" header="Assigned Location" body={assignedLocationBodyTemplate} sortable headerStyle={{ width: '25%' }}></Column>
                        <Column header="Actions" body={actionBodyTemplate}></Column>
                    </DataTable>
                    <Paginator rowsPerPageOptions={[25, 50, 100, 500]} template={template} first={first} rows={rows} totalRecords={sourceStoreList?.count} onPageChange={onPageChange}></Paginator>
                </div>
            </div>
        </div>
    );
};

export default DestinationDataTable;
