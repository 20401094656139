import React, { useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { Fieldset } from 'primereact/fieldset';
import ProductDestinatonDatatable from './ProductDestinatonDatatable';
import { Dropdown } from 'primereact/dropdown';

const ProductDestination = () => {
    const [value, setValue] = useState([]);

    const legendTemplate = (
        <div className="flex align-items-center text-primary">
            <span className="font-bold text-lg text-color-secondary">Select a Destination store</span>
        </div>
    );

    const items = [
        { label: 'modernrusticdiamond.myshopify.com', value: 'modernrusticdiamond.myshopify.com' },
        { label: 'think-dev-test.myshopify.com', value: 'think-dev-test.myshopify.com' }
    ];

    return (
        <div>
            <div className="grid p-fluid">
                <div className="col-12 md:col-4 product-store-content">
                    <Fieldset legend={legendTemplate}>
                        <Dropdown id="multiselect" options={items} value={value} onChange={(e) => setValue(e.value)} optionLabel="label" />
                    </Fieldset>
                </div>
            </div>
            {value?.length > 0 ? <ProductDestinatonDatatable /> : null}
        </div>
    );
};

export default ProductDestination;
